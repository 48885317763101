import { errorAlert } from 'components/Utilities/Alert/Alert';
import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import { Annotation, ThreadMessage, User } from 'JSUtils/schema/generated/models';
import moment from 'moment';
import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import CreaDate from 'Utilities/CreaDate/CreaDate';

interface ThreadMessagesProps {
  annotation: Annotation;
  setRef: (elem: MutableRefObject<HTMLDivElement>) => void;
}

interface LocalThreadMessage extends ThreadMessage {
  sending: boolean;
}

const ThreadMessages = ({ setRef, annotation, annotation: { threadMessages } }: ThreadMessagesProps): ReactElement => {
  const { addThreadMessage, updateThreadMessage, proposition, version } = usePropositionContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const [blockButton, setBlockButton] = useState(true);
  const [updateMessage, setUpdateMessage] = useState<ThreadMessage | null>(null);
  const [updatingMessageId, setUpdatingMessageId] = useState<string | null>(null);
  // temporary message when sending
  const [localThreadMessage, setLocalThreadMessage] = useState<LocalThreadMessage | null>(null);
  const textInput = useRef<HTMLTextAreaElement>(document.createElement('textarea'));
  const messagesEndRef = useRef<HTMLDivElement>(document.createElement('div'));
  const sendMessageButtonRef = useRef<HTMLButtonElement>(document.createElement('button'));
  const annotationRef = useRef<HTMLDivElement>(document.createElement('div'));

  const scrollToBottom = (): void => {
    if (messagesEndRef?.current?.parentNode) {
      (messagesEndRef.current.parentNode as HTMLElement).scrollTop = messagesEndRef.current.offsetTop;
    }
  };
  useEffect(scrollToBottom, [localThreadMessage, threadMessages]);

  useEffect(() => {
    setRef(annotationRef);
  }, []);

  useEffect(() => {
    if (updateMessage && textInput.current) textInput.current.value = updateMessage.data;
  }, [updateMessage]);

  const isLastVersion = proposition.versions[proposition.versions.length - 1]?.id === version.id;
  const canEdit = (tm: ThreadMessage, isLast: boolean): boolean =>
    isLast
    && isLastVersion
    && moment().subtract(1, 'd').isBefore(moment(tm.createdAt))
    && moment(version.createdAt).isBefore(moment(tm.createdAt))
    && tm.author?.id === user.id;

  const getName = (tm: ThreadMessage): string =>
    (tm.author ? `${tm.author.firstName} ${tm.author.lastName}` : t('project:common:deleted_user'));

  return (
    <div className="annotation-content">
      <div className="annotation-thread" ref={annotationRef}>
        {[...threadMessages, localThreadMessage].map((threadMessage: LocalThreadMessage, index) => {
          if (!threadMessage) return null;
          let messageInfo = '';
          if (threadMessage.sending) messageInfo = t('project:proposition:annotation.thread_message.status.sending');
          else if (threadMessage.id === updatingMessageId) messageInfo = t('project:proposition:annotation.thread_message.status.updating');
          else if (threadMessage.updatedAt) messageInfo = t('project:proposition:annotation.thread_message.status.updated');
          const isLast = threadMessages && threadMessages.length === index + 1;
          return (
            <div key={threadMessage.id}>
              <div className="header">
                <div className="name">
                  <span className="annotation-author">{getName(threadMessage)}</span>
                </div>
                <div className="date">
                  <span className="annotation-date">
                    <CreaDate date={threadMessage.updatedAt || threadMessage.createdAt} />
                  </span>
                  {canEdit(threadMessage, isLast) && (
                    <FaEdit className="editButton" onClick={(): any => setUpdateMessage(threadMessage)} />
                  )}
                </div>
              </div>
              <div className="clearfix" />
              <div className="annotation-message">
                {threadMessage.data}
                <span className="message-info">{` ${messageInfo}`}</span>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      <textarea
        ref={textInput}
        autoFocus
        onChange={(): any => setBlockButton(!textInput.current?.value)}
        className="annotation-textArea"
        placeholder={t('project:proposition:annotation.placeholder')}
        maxLength={255}
        disabled={!isLastVersion}
        onFocus={(): any => {
          // Mobile: The send/reply button should be visible when virtual keyboard is active
          sendMessageButtonRef.current.scrollIntoView();
          // Make sure it's visible
          setTimeout(() => sendMessageButtonRef.current.scrollIntoView(), 500);
        }}
      />

      {updateMessage ? (
        <div className="action-button neutral">
          <button
            type="button"
            ref={sendMessageButtonRef}
            onClick={(): any => {
              const message = textInput.current?.value;
              if (message && updateMessage) {
                setBlockButton(true);
                setUpdatingMessageId(updateMessage.id);
                updateThreadMessage({ id: updateMessage.id, data: message })
                  .catch((err) => {
                    errorAlert(t('project:proposition:annotation.action.edit.error'));
                    console.error('Failed to update thread message');
                    console.error(err);
                  })
                  .finally(() => {
                    textInput.current.value = '';
                    setBlockButton(true);
                    setUpdateMessage(null);
                    setUpdatingMessageId(null);
                  });
              }
            }}
            className="creaflow-primary-button"
            disabled={!isLastVersion || blockButton}
          >
            {t('project:proposition:annotation.action.edit.label')}
          </button>
        </div>
      ) : (
        <div className="action-button success">
          <button
            type="button"
            ref={sendMessageButtonRef}
            onClick={(): any => {
              const message = textInput.current?.value;
              if (message) {
                setBlockButton(true);
                setLocalThreadMessage({
                  id: 'tmp',
                  data: message,
                  sending: true,
                  author: user as User,
                  annotation,
                  createdAt: new Date(),
                });
                addThreadMessage(message)
                  .then(() => {
                    textInput.current.value = '';
                  })
                  .catch((err) => {
                    errorAlert(t('project:proposition:annotation.action.reply.error'));
                    console.error('Failed to create the thread message');
                    console.error(err);
                  })
                  .finally(() => {
                    setLocalThreadMessage(null);
                    setBlockButton(true);
                  });
              }
            }}
            className="creaflow-primary-button"
            disabled={!isLastVersion || blockButton}
          >
            {threadMessages.length >= 1
              ? t('project:proposition:annotation.action.reply.label')
              : t('common:form:action.send.label')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ThreadMessages;
