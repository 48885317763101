import ReactGA from 'react-ga';

export const signUp = (): void => {
  ReactGA.event({
    category: 'User',
    action: 'SignUp',
  });
};

export const signIn = (): void => {
  ReactGA.event({
    category: 'User',
    action: 'SignIn',
  });
};

export const signOut = (): void => {
  ReactGA.event({
    category: 'User',
    action: 'SignOut',
  });
};

export const setUserId = (id: number): void => {
  ReactGA.set({ userId: id });
};

export const deleteAccount = (): void => {
  ReactGA.event({
    category: 'User',
    action: 'Delete account',
  });
};
