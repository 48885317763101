import { errorAlert, successAlert } from 'components/Utilities/Alert/Alert';
import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import * as ToolbarGA from 'JSUtils/ReactGA/toolbar';
import { Version } from 'JSUtils/schema/generated/models';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import Trash2 from 'react-feather/dist/icons/trash-2';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EditVersion from '../EditVersion/EditVersion';
import './VersionItem.scss';

type VersionItemProps = {
  version: Version;
  isCurrentVersion: boolean;
};

function VersionItem({ version, isCurrentVersion }: VersionItemProps): ReactElement {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const {
    proposition,
    deleteVersion,
    setVersion,
    setMobileHistoryVisibility,
    mobileHistoryVisibility,
  } = usePropositionContext();
  const [modalActive, setModalActive] = useState(false);

  const toggleHistoryMobile = (): void => setMobileHistoryVisibility(!mobileHistoryVisibility);

  const toggleDeleteModal = (): void => {
    setModalActive(!modalActive);
  };

  const onDeleteClick = async (e): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await deleteVersion({ id: version.id });
      successAlert(t('project:proposition:sidebar.version_history.action.delete.success'));
    } catch (err) {
      console.error(err);
      errorAlert(t('project:proposition:sidebar.version_history.action.delete.error'));
    }
    toggleDeleteModal();
  };

  const onVersionClick = (e, versionId): void => {
    e.stopPropagation();
    e.preventDefault();
    const currentVersion = proposition.versions.find((v: Version) => v.id === versionId);
    setVersion(currentVersion ?? ({} as Version));
    ToolbarGA.changeVersion();
    if (mobileHistoryVisibility) {
      toggleHistoryMobile();
    }
  };

  const isLast = proposition.versions[proposition.versions.length - 1]?.id === version.id;
  const canEdit = isLast && version.author?.id === user.id;
  const canDelete = proposition.versions.length > 1
    && isLast
    && version.author?.id === user.id
    && moment().subtract(1, 'd').isBefore(moment(version.createdAt))
    && !version.annotations.some((a) => moment(a?.updatedAt || a?.createdAt).isAfter(version.createdAt));

  return (
    <div
      className="historic-project-container"
      onClick={(e): void => {
        onVersionClick(e, version.id);
      }}
    >
      <div className={isCurrentVersion ? 'historic-project selected' : 'historic-project'}>
        <div className="left-col">
          <img src={version.imageUrl} alt={`${version.title}`} className="historic-image" />
        </div>
        <div className="right-col">
          <TextTruncate className="historic-row title" line={2} element="p" truncateText="…" text={version.title} />
          <p className="historic-row">{moment(version.createdAt).format('ll')}</p>
          <div className="historic-row buttons">
            {canDelete && (
              <Button className="delete-version-button button" onClick={toggleDeleteModal}>
                <Trash2 id="trash-icon" />
              </Button>
            )}
            {canEdit && <EditVersion version={version} />}
          </div>
        </div>
      </div>

      <Modal isOpen={modalActive} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal} className="modal-head">
          {t('project:proposition:sidebar.version_history.action.delete.label')}
        </ModalHeader>
        <ModalBody>
          <Label>
            {t('project:proposition:sidebar.version_history.action.delete.ask_confirmation', {
              versionTitle: version.title,
            })}
          </Label>
        </ModalBody>
        <ModalFooter>
          <div className="cancel">
            <button className="creaflow-primary-button" type="button" onClick={toggleDeleteModal}>
              {t('common:form:action.cancel.label')}
            </button>
          </div>
          <div className="danger">
            <button className="creaflow-primary-button" type="button" onClick={onDeleteClick}>
              {t('common:form:action.delete.label')}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default VersionItem;
