import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import { getStatusColorKey, getStatusHumanReadable } from 'models/EStatus';
import React, { ReactElement, useState } from 'react';
import Trash2 from 'react-feather/dist/icons/trash-2';
import X from 'react-feather/dist/icons/x';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Annotation, Status } from 'schema/generated/models';

interface DraggableAnnotationProps {
  annotation: Annotation;
}

function DraggableAnnotation({ annotation }: DraggableAnnotationProps): ReactElement {
  const { user } = useUserContext();
  const { setAnnotation, updateAnnotation, deleteAnnotation, proposition, version } = usePropositionContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModaleActive, setIsModaleActive] = useState(false);
  const { threadMessages } = annotation;
  const { t } = useTranslation();

  const [firstTM] = threadMessages;

  const isLastVersion = proposition.versions[proposition.versions.length - 1]?.id === version.id;
  // 36e5 = 1000 millisecs * 60 seconds * 60 minutes = 1h
  const canDelete = annotation.author?.id === user.id
    && isLastVersion
    && Math.abs(new Date().getTime() - new Date(annotation.createdAt).getTime()) / 36e5 < 24
    && (threadMessages.length === 0 || (threadMessages.length === 1 && firstTM?.author?.id === user.id));

  return (
    <div className="annotation-menu">
      <div className="container">
        <span onClick={(e): void => setAnnotation(null, e.timeStamp)}>{`Annotation # ${annotation.idInCanvas}`}</span>
        <div className="right">
          <Dropdown
            isOpen={isDropdownOpen}
            disabled={!isLastVersion}
            toggle={(): void => setIsDropdownOpen(!isDropdownOpen)}
          >
            <DropdownToggle
              caret
              disabled={!isLastVersion}
              className={`status-${getStatusColorKey(annotation.status ?? 0)}`}
            >
              {getStatusHumanReadable(annotation.status)}
            </DropdownToggle>
            <DropdownMenu>
              {Object.values(Status).map((status) => (
                <DropdownItem key={status} onClick={(): any => updateAnnotation({ id: annotation.id, status })}>
                  {getStatusHumanReadable(status)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {canDelete && (
            <Trash2
              onClick={(e): void => {
                e.stopPropagation();
                setIsModaleActive(true);
              }}
            />
          )}
          <X onClick={(e): void => setAnnotation(null, e.timeStamp)} />
        </div>
      </div>

      <Modal
        id="deleteAnnotationModal"
        isOpen={isModaleActive}
        toggle={(): void => setIsModaleActive(!isModaleActive)}
        centered
      >
        <ModalHeader toggle={(): void => setIsModaleActive(!isModaleActive)} className="modal-head">
          {t('project:proposition:annotation.action.delete.title')}
        </ModalHeader>
        <ModalBody>
          <Label>
            {t('project:proposition:annotation.action.delete.ask_confirmation', { id: annotation.idInCanvas })}
          </Label>
          <div className="clearfix" />
          <div className="buttons">
            <div className="cancel">
              <button
                type="button"
                className="creaflow-primary-button"
                onClick={(): void => setIsModaleActive(!isModaleActive)}
              >
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="danger">
              <button
                type="button"
                className="creaflow-primary-button"
                onClick={(): void => {
                  deleteAnnotation(annotation.id);
                  setIsModaleActive(false);
                }}
              >
                {t('common:form:action.delete.label')}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DraggableAnnotation;
