import { errorAlert, successAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import useDirtyFormFields from 'JSUtils/custom-hooks/useDirtyFormFields';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './ModificationInfos.scss';

enum ModalType {
  none,
  main,
  deleteConfirmation
}

function ModificationInfos(): ReactElement {
  const { t } = useTranslation();
  const { project, updateProject, deleteProject } = useProjectContext();
  const { dirty, markAsDirty } = useDirtyFormFields();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'all',
    defaultValues: {
      title: project.title,
      description: project.description,
    },
  });
  const history = useHistory();
  const [modal, setModal] = useState(ModalType.none);

  const toggleModal = (_modal: ModalType): void => {
    if (modal === _modal) {
      setModal(ModalType.none);
    } else {
      setModal(_modal);
    }
  };

  const onSubmit = async (data): Promise<void> => {
    const editableData = data;
    if (dirty) {
      try {
        const updatedProject = await updateProject(editableData);
        reset({ ...updatedProject });
        successAlert(t('project:common:action.update.success'));
        toggleModal(ModalType.none);
      } catch (err) {
        console.error(err);
      }
    } else {
      toggleModal(ModalType.none);
    }
  };

  const handleDeleteProject = async (): Promise<void> => {
    try {
      await deleteProject({ id: project.id });
      toggleModal(ModalType.deleteConfirmation);
      const locationState: CreaLocationState = {
        pageParams: {},
        popupMessages: [new CreaPopupMessage(EPopupMessageType.success, t('project:common:action.delete.success'))],
      };
      history.push({
        pathname: '/projectList/',
        state: locationState,
      });
    } catch (err) {
      errorAlert(t('project:common:action.delete.error'));
      console.error('[Delete project error] : ', err);
    }
  };

  return (
    <>
      <button
        type="button"
        className="editButton project-button creaflow-secondary-button-v2"
        onClick={(): void => toggleModal(ModalType.main)}
      >
        {t('project:common:action.update.label')}
      </button>
      {project !== undefined && (
        <>
          <Modal isOpen={modal === ModalType.main} toggle={(): void => toggleModal(ModalType.main)} centered>
            <form className="crea-form" id="modification-infos" onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader close={React.createElement((): ReactElement => CloseButton((): void => toggleModal(ModalType.main)))} toggle={(): void => toggleModal(ModalType.main)}>
                {t('project:common:action.update.label_long')}
              </ModalHeader>
              <ModalBody>
                <section>
                  <label htmlFor="title">{t('project:common:attribute.title')}</label>
                  <input
                    className={`${errors.title && 'error'}`}
                    ref={register({
                      required: {
                        value: true,
                        message: t('common:form:validation.required_field'),
                      },
                      maxLength: {
                        value: 254,
                        message: t('common:form:validation.string_max_255'),
                      },
                    })}
                    type="text"
                    name="title"
                    id="title"
                    onChange={markAsDirty}
                  />
                  <ErrorMessage errors={errors} name="title">
                    {({ messages }): any =>
                      messages
                      && Object.entries(messages).map(([type, message]) => (
                        <p className="error" key={type}>
                          {message}
                        </p>
                      ))}
                  </ErrorMessage>
                </section>
                <section>
                  <label htmlFor="description">{t('project:common:attribute.description')}</label>
                  <textarea
                    className={`${errors.description && 'error'}`}
                    ref={register()}
                    name="description"
                    id="description"
                    rows={5}
                    onChange={markAsDirty}
                  />
                  <ErrorMessage errors={errors} name="description">
                    {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
                  </ErrorMessage>
                </section>
                <section className="delete">
                  <label htmlFor="delete">{t('common:form:action.delete.label')}</label>
                  <button
                    color="danger"
                    type="button"
                    className="creaflow-primary-button"
                    onClick={(): void => toggleModal(ModalType.deleteConfirmation)}
                  >
                    {t('common:form:action.delete.label')}
                  </button>
                </section>
              </ModalBody>
              <ModalFooter>
                <div className="cancel">
                  <button
                    type="button"
                    className="creaflow-primary-button cancel"
                    color="secondary"
                    onClick={(): void => toggleModal(ModalType.main)}
                  >
                    {t('common:form:action.cancel.label')}
                  </button>
                </div>
                <div className="success save">
                  <button color="success" type="submit" className="creaflow-primary-button">
                    {t('common:form:action.save.label')}
                  </button>
                </div>
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            id="deleteProjectModal"
            isOpen={modal === ModalType.deleteConfirmation}
            toggle={(): void => toggleModal(ModalType.deleteConfirmation)}
            centered
          >
            <ModalHeader toggle={(): void => toggleModal(ModalType.deleteConfirmation)} className="modal-head">
              {t('project:common:action.delete.label')}
            </ModalHeader>
            <ModalBody>
              <label htmlFor="delete">{t('project:common:action.delete.ask_confirmation')}</label>
              <div className="clearfix" />
              <div className="danger" id="delete">
                <button type="button" className="creaflow-primary-button" onClick={handleDeleteProject}>
                  {t('common:form:action.delete.label')}
                </button>
              </div>
              <div className="cancel">
                <button
                  type="button"
                  className="creaflow-primary-button"
                  onClick={(): void => toggleModal(ModalType.deleteConfirmation)}
                >
                  {t('common:form:action.cancel.label')}
                </button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
}

export default ModificationInfos;
