import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { User } from 'JSUtils/schema/generated/models';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TextTruncate from 'react-text-truncate';
import Invitation from '../ManageCollaborators/ManageCollaborators';
import ModificationInfos from '../ModificationInfos/ModificationInfos';
import './MainInfo.scss';

function MainInfo(): ReactElement {
  const { user } = useUserContext();
  const { project } = useProjectContext();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      {project && (
        <div className="main-info-container">
          <h1>{t('project:common:dashboard.main_info')}</h1>
          <div className="main-info-content">
            <section>
              <TextTruncate line={3} element="h4" truncateText="…" text={project.title} />
            </section>
            <section className="infos">
              <div className="main-info-row">
                <span className="main-info-label">{`${t('project:common:attribute.admin')}: `}</span>
                {`${project.owner.firstName} ${project.owner.lastName}`}
              </div>
              <div className="main-info-row">
                <span className="main-info-label">{`${t('project:common:attribute.created_at')}: `}</span>
                {new Date(project.createdAt).toLocaleDateString(user.culture.replace('_', '-'), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </div>
              <div>
                {project.contributors?.length !== 0 && (
                  <div className="main-info-row">
                    <span className="main-info-label">{`${t('project:common:attribute.collaborators')}: `}</span>
                    {project.contributors.map((contributor: User, idx) => (
                      <span key={contributor.id}>
                        {`${(idx ? ', ' : '') + contributor.firstName} ${contributor.lastName}`}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </section>

            <section className="buttons-container">
              <div className="buttons-row">
                <button
                  type="button"
                  onClick={(): void => {
                    history.push(`/project/${project.id}/documents/`);
                  }}
                  className={`inviteButton project-button creaflow-primary-button ${user.id === project.owner.id && 'solo'}`}
                >
                  {t('project:common:attribute.documents')}
                </button>
                {user.id !== project.owner.id
                  && <Invitation /> }
              </div>
              {user.id === project.owner.id
                && (
                <div className="buttons-row">
                  <Invitation />
                  <ModificationInfos />
                </div>
                )}
            </section>

            <section className="description">
              <p>{project.description}</p>
            </section>

            <section className="image">
              <div className="preview-wrapper">
                <div className={`${!project.previewImageUrl && 'default-placeholder'} preview`}>
                  {project.previewImageUrl !== undefined && project.previewImageUrl !== null && (
                    <img src={project.previewImageUrl} alt="preview" />
                  )}
                  {!project.previewImageUrl && <p>{project.title}</p>}
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default MainInfo;
