import { ContractQuantificationWhat, ContractRecipient, ContractRule, ContractTemporality } from 'JSUtils/schema/generated/models';
import { useTranslation } from 'react-i18next';

function getETemporalityAsStr(temporality: ContractTemporality): string {
  const { t } = useTranslation();
  switch (temporality) {
    case ContractTemporality.While:
      return t('project:workflow:contract.temporality.during');
    case ContractTemporality.AtEnd:
      return t('project:workflow:contract.temporality.at_the_end');
    default:
      return '';
  }
}

function getERecipientAsStr(recipient: ContractRecipient): string {
  const { t } = useTranslation();
  switch (recipient) {
    case ContractRecipient.Client:
      return t('project:workflow:contract.recipient.client');
    case ContractRecipient.Designer:
      return t('project:workflow:contract.recipient.designer');
    default:
      return '';
  }
}

function getERuleAsStr(rule: ContractRule): string {
  const { t } = useTranslation();
  switch (rule) {
    case ContractRule.CanUse:
      return t('project:workflow:contract.rule.can_use');
    case ContractRule.NeedsToReview:
      return t('project:workflow:contract.rule.must_review');
    default:
      return '';
  }
}

function getEQuantificationWhat(quantificationWhat: ContractQuantificationWhat): string {
  const { t } = useTranslation();
  switch (quantificationWhat) {
    case ContractQuantificationWhat.Version:
      return t('project:workflow:contract.what.version');
    case ContractQuantificationWhat.Annotation:
      return t('project:workflow:contract.what.annotation');
    default:
      return '';
  }
}

function lowercaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export { getETemporalityAsStr, getERecipientAsStr, getERuleAsStr, getEQuantificationWhat, lowercaseFirstLetter };
