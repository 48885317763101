import { useUserContext } from 'context/UserContext';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Stripes from 'Utilities/Background/Stripes';
import './PersonalPageHeader.scss';

interface PersonalPageHeaderProps {
  active: string;
}

function PersonalPageHeader({ active }: PersonalPageHeaderProps): ReactElement {
  const { user } = useUserContext();
  const { t } = useTranslation();

  return (
    <div className="ppage-container">
      <Stripes mode={0} />
      <div className="ppage-content">
        <div className="ppage-nav-link-left">
          <Link to={`/user/${user.id}/profile`}>
            <span className={active === 'profile' ? 'active-link' : ''}>{t('user:nav.profile')}</span>
          </Link>
        </div>
        <div className="ppage-nav-link-right">
          <Link to={`/user/${user.id}/account`}>
            <span className={active === 'account' ? 'active-link' : ''}>{t('user:nav.account')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PersonalPageHeader;
