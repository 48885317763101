import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { TutorialType } from 'components/Tutorial/Tutorial';
import * as UserGA from 'JSUtils/ReactGA/user';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentUser, UpdateUserMutationVariables } from 'schema/generated/models';
import {
  ASK_DELETE_CURRENT_USER,
  ASK_RESET_PASSWORD,
  DELETE_CURRENT_USER,
  GET_CURRENT_USER,
  LOGIN,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_GOOGLE,
  SEND_BUG_REPORT,
  SIGNUP,
  UPDATE_CURRENT_USER,
} from 'schema/user';
import createCtx from './createContextHelper';

type UserContextType = {
  user: CurrentUser;
  setUser: React.Dispatch<React.SetStateAction<CurrentUser>>;
  updateUser: (updateUserMutationVariables: UpdateUserMutationVariables) => Promise<CurrentUser>;
  isLoading: boolean;
  login: (e: string, p: string) => Promise<void>;
  facebookLogin: (accessToken: string) => Promise<void>;
  googleLogin: (tokenId: string) => Promise<void>;
  signup: (email: string, password: string, firstName: string, lastName: string, culture: string) => Promise<void>;
  signupError: ApolloError | undefined;
  askDeleteCurrentUser: Function;
  deleteCurrentUser: Function;
  askResetPassword: Function;
  sendBugReport: Function;
};

const [useUserContext, CtxProvider] = createCtx<UserContextType>();

type UserContextProviderProps = {
  children: React.ReactNode;
};

const UserContextProvider = (props: UserContextProviderProps): ReactElement => {
  const { children } = props;

  const [user, setUser] = useState({} as CurrentUser);
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  const setTutorialPreferences = (): void => {
    const tutorialPreferences = localStorage.getItem('tutorial.preferences');
    if (!tutorialPreferences) {
      const tutorialPreferencesAsObject: TutorialType[] = [
        'main_dashboard_page',
        'project_architecture',
        'proposition_page',
        'workflow_page',
      ]; // Add here as more tutorials are added
      localStorage.setItem('tutorial.preferences', JSON.stringify(tutorialPreferencesAsObject));
    }
  };

  const updateLanguage = (updatedUser: CurrentUser): void => {
    if (updatedUser.culture !== user.culture) {
      const newCulture = updatedUser.culture.replace('_', '-');
      i18n.changeLanguage(newCulture);
      moment.locale(newCulture);
      localStorage.setItem('user.culture', newCulture);
    }
  };

  const { refetch: userRefetch } = useQuery(GET_CURRENT_USER, {
    onCompleted: (data) => {
      // This code is executed only the first time useQuery is called, not when refetching.
      UserGA.setUserId(data?.currentUser.id); // Sent when loading page with a token still valid
      setUser(data?.currentUser);
      updateLanguage(data?.currentUser);
      setIsLoading(false);
    },
  });

  const [askDeleteCurrentUser] = useMutation(ASK_DELETE_CURRENT_USER);
  const [deleteCurrentUser] = useMutation(DELETE_CURRENT_USER);
  const [askResetPassword] = useMutation(ASK_RESET_PASSWORD);
  const [sendBugReport] = useMutation(SEND_BUG_REPORT);

  const refetch = async (): Promise<CurrentUser> => {
    setIsLoading(true);
    const { data: userResult } = await userRefetch();
    UserGA.signIn();
    UserGA.setUserId(userResult.currentUser.id); // Sent after login/signup

    localStorage.setItem('user.id', userResult.currentUser.id.toString());
    localStorage.setItem('user.email', userResult.currentUser.email);
    localStorage.setItem('user.authenticated', 'true');
    setTutorialPreferences();
    setUser(userResult.currentUser);
    setIsLoading(false);
    updateLanguage(userResult.currentUser);
    return userResult.currentUser;
  };

  const [_login] = useMutation(LOGIN);
  const login = async (email: string, password: string): Promise<void> => {
    const {
      data: { login: token },
    } = await _login({ variables: { email, password } });
    localStorage.setItem('user.token', token);
    await refetch();
  };

  const [_facebookLogin] = useMutation(LOGIN_WITH_FACEBOOK);
  const facebookLogin = async (accessToken: string): Promise<void> => {
    const {
      data: { facebookLogin: token },
    } = await _facebookLogin({ variables: { accessToken, culture: user.culture } });
    localStorage.setItem('user.token', token);
    localStorage.setItem('TPAutoConnect', 'true');
    await refetch();
  };

  const [_googleLogin] = useMutation(LOGIN_WITH_GOOGLE);
  const googleLogin = async (tokenId: string): Promise<void> => {
    const {
      data: { googleLogin: token },
    } = await _googleLogin({ variables: { tokenId, culture: user.culture || 'fr_FR' } });
    localStorage.setItem('user.token', token);
    localStorage.setItem('TPAutoConnect', 'true');
    await refetch();
  };

  const [_signup, { error: signupError }] = useMutation(SIGNUP);
  const signup = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    culture: string,
  ): Promise<void> => {
    const {
      data: { signUp: token },
    } = await _signup({
      variables: {
        email,
        password,
        firstName,
        lastName,
        culture,
      },
    });
    UserGA.signUp();
    localStorage.setItem('user.token', token);
    await refetch();
  };

  const [_updateUser] = useMutation(UPDATE_CURRENT_USER);
  const updateUser = async (updateUserMutationVariables: UpdateUserMutationVariables): Promise<CurrentUser> => {
    const {
      data: { updateUser: updatedUser },
    } = await _updateUser({
      variables: {
        ...updateUserMutationVariables,
        id: user.id,
      },
    });
    updateLanguage(updatedUser);
    setUser(updatedUser);
    return updatedUser;
  };

  const UserContext = {
    login,
    facebookLogin,
    googleLogin,
    signup,
    signupError,
    askDeleteCurrentUser,
    deleteCurrentUser,
    askResetPassword,
    user,
    setUser,
    updateUser,
    isLoading,
    sendBugReport,
  };

  return <CtxProvider value={UserContext}>{children}</CtxProvider>;
};

export { useUserContext, UserContextProvider };
