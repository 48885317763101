import CreaDate from 'components/Utilities/CreaDate/CreaDate';
import { useProjectContext } from 'context/ProjectContext';
import 'moment/locale/fr';
import React, { ReactElement } from 'react';
import CheckCircle from 'react-feather/dist/icons/check-circle';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ConsultationRequest } from 'schema/generated/models';
import { GetLink } from '../../../../../components/Project/EventRow/EventRow';
import './ActionRow.scss';

function ActionRow({ cr }: { cr: ConsultationRequest }): ReactElement {
  const { project } = useProjectContext();
  const { t } = useTranslation();
  const { push } = useHistory();

  const authorName = cr.seeker === null || cr.seeker === undefined
    ? t('project:common:deleted_user')
    : `${cr.seeker.firstName} ${cr.seeker.lastName}`;

  return (
    <tr className="action-row">
      <td>
        <CreaDate date={new Date(cr.createdAt)} />
      </td>
      <td>
        <GetLink entity={cr.version.proposition.document} />
      </td>
      <td>
        <GetLink entity={cr.version.proposition} />
      </td>
      <td>
        <GetLink entity={cr.version} />
      </td>
      <td>
        <span>
          <Trans i18nKey="project:common:event.review_asked_you">
            {{ authorName }}
          </Trans>
        </span>
      </td>
      <td className="consultation-request-button">
        <button
          type="button"
          className="creaflow-primary-button"
          onClick={(): void => {
            const documentId = cr.version.proposition.document.id;
            const propositionId = cr.version.proposition.id;
            const versionId = cr.version.id;
            push(`/project/${project.id}/${documentId}/${propositionId}/${versionId}`);
          }}
        >
          <CheckCircle className="icon" />
          <p>{t('project:proposition:canvas_action.consultation_answer.label')}</p>
        </button>
      </td>
    </tr>
  );
}

export default ActionRow;
