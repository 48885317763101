import Modal from 'components/Utilities/Overlay/Modal/Modal';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Tutorial.scss';

export type TutorialType = 'main_dashboard_page' | 'project_architecture' | 'proposition_page' | 'workflow_page';

interface TutorialProps {
  tutorialType: TutorialType;
  title: string;
  callToActionLabel: string;
  children: React.ReactNode;
}

export default function Tutorial({ tutorialType, callToActionLabel, title, children }: TutorialProps): ReactElement {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  const isThisTutorialActiveForUser = (): boolean => {
    const tutorialPreferences = localStorage.getItem('tutorial.preferences');
    if (tutorialPreferences) {
      let tutoPrefsAsObject = [''];
      try {
        tutoPrefsAsObject = JSON.parse(tutorialPreferences);
      } catch (err) {
        console.error(err);
      }
      if (tutoPrefsAsObject instanceof Array && tutoPrefsAsObject.includes(tutorialType)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setActive(isThisTutorialActiveForUser());
  }, []);

  const setThisTutorialStateForUser = (value): void => {
    const tutorialPreferences = localStorage.getItem('tutorial.preferences');
    if (tutorialPreferences) {
      let tutoPrefsAsObject = JSON.parse(tutorialPreferences);
      if (tutoPrefsAsObject instanceof Array) {
        if (value) {
          tutoPrefsAsObject.push(tutorialType);
        } else {
          tutoPrefsAsObject = tutoPrefsAsObject.filter((tu) => tu !== tutorialType);
        }
        localStorage.setItem('tutorial.preferences', JSON.stringify(tutoPrefsAsObject));
      }
    }
  };

  const onClick = (): void => {
    setActive(!active);
    setThisTutorialStateForUser(!active);
  };

  return (
    <>
      {active && (
        <Modal toggle={onClick} className="tutorial-container">
          <div className="header">
            <h1>{title}</h1>
          </div>
          {children}
          <br />
          <br />
          <p className="tips">{t('common:misc:tutorial.tips')}</p>
          <div className="footer">
            <button type="button" className="creaflow-primary-button" onClick={onClick}>
              {callToActionLabel}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
