import ProjectCard from 'components/Project/ProjectCard/ProjectCard';
import { useProjectListContext } from 'context/ProjectListContext';
import { Project } from 'JSUtils/schema/generated/models';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RecentProjects.scss';

function RecentProjects(): ReactElement {
  const { t } = useTranslation();
  const { projects } = useProjectListContext();
  const [sortedProjects, setSortedProjects] = useState<Project[]>([]);

  useEffect(() => {
    const result = projects.slice();
    result.sort((a: Project, b: Project) => {
      if (a.updatedAt && b.updatedAt) {
        return moment(a.updatedAt).isBefore(moment(b.updatedAt)) ? 1 : -1;
      }
      return 1;
    });
    setSortedProjects(result.slice(0, 4));
  }, [projects]);

  return (
    <div className="widget-container recent-projects">
      <h2>{t('main_dashboard:widget.recent_projects.title')}</h2>
      <div className="recent-projects-container">
        {
          !projects.length ? (
            <>
              <div className="project-card" key="new-project">
                {/* New project card */}
                <ProjectCard project={null} />
              </div>
              <div className="project-card-flex-placeholder" />
            </>
          ) : (
            <>
              {sortedProjects.map((project) => (
                <div className="project-card" key={project.id}>
                  <ProjectCard project={project} />
                </div>
              ))}
              <div className="project-card" key="new-project">
                {/* New project card */}
                <ProjectCard project={null} />
              </div>
              {
                (projects.length + 1) % 2 !== 0
                && <div className="project-card-flex-placeholder" />
              }
            </>
          )
          }
      </div>
    </div>
  );
}

export default RecentProjects;
