import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { CustomErrorId } from 'JSUtils/types';
import React, { ReactElement, useEffect } from 'react';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Routes from '../../routes/routes';
import '../../styles/style.scss';
import './App.scss';

const redirectToLoginIfUnauthenticated = (): void => {
  if (localStorage.getItem('user.authenticated') === 'true') {
    localStorage.setItem('user.authenticated', 'false');
    localStorage.removeItem('user.token');
    window.location.replace('/login');
  }
};

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('user.token');
  if (!token) {
    redirectToLoginIfUnauthenticated();
  }
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Handle application-wide errors here
    console.error(graphQLErrors);
    graphQLErrors.forEach(({ extensions }) => {
      console.error('[GraphQL error]');
      switch (extensions?.code) {
        case 'UNAUTHENTICATED':
          redirectToLoginIfUnauthenticated();
          break;
        default:
          break;
      }
      switch (extensions?.exception?.errorId) {
        case CustomErrorId.NOT_FOUND:
          window.location.replace('/404');
          break;
        default:
          break;
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    if (!['', '/', '/500'].includes(window.location.pathname)) window.location.replace('/500');
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'none',
    },
    mutate: {
      errorPolicy: 'none',
    },
  },
});

export default function App(): ReactElement {
  useEffect(() => {
    ReactGA.initialize('UA-111551449-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    toast.configure({
      position: 'top-right',
      autoClose: 7000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      draggable: true,
      pauseOnHover: true,
    });
  }, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  );
}
