import CloseButton from 'components/Utilities/CloseButton';
import { usePropositionContext } from 'context/PropositionContext';
import React, { ReactElement, useState } from 'react';
import PlusCircle from 'react-feather/dist/icons/plus-circle';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import { ImageUploader } from 'Utilities/S3ImageUploader/useS3Uploader';
import './AddVersion.scss';

function AddVersion(): ReactElement {
  const { proposition, addVersion } = usePropositionContext();
  const { t } = useTranslation();
  const { errors, register, handleSubmit } = useForm({
    defaultValues: {
      title: '',
    },
    submitFocusError: true,
    validateCriteriaMode: 'all',
    mode: 'onBlur',
  });

  const [modalActive, setModalActive] = useState(false);
  const [imageKey, setImageKey] = useState('');

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const handleAddVersion = async (data): Promise<void> => {
    if (!imageKey) {
      return;
    }
    try {
      await addVersion({
        variables: {
          propositionId: proposition.id,
          title: data.title,
          s3ObjectId: imageKey,
        },
      });
    } catch (err) {
      console.error(err);
      errorAlert(t('project:proposition:sidebar.version_history.action.add.error'));
      return;
    }
    toggleModal();
    successAlert(t('project:proposition:sidebar.version_history.action.add.success'));
  };

  return (
    <div className="addVersion-button">
      <button type="button" onClick={toggleModal} className="addVersion-btn creaflow-secondary-button-v2">
        <PlusCircle />
        <span>{t('project:proposition:sidebar.version_history.action.add.label')}</span>
      </button>

      <Modal centered isOpen={modalActive} toggle={toggleModal} id="add-version-modal">
        <form className="crea-form" onSubmit={handleSubmit(handleAddVersion)}>
          <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))} toggle={toggleModal}>
            {t('project:proposition:sidebar.version_history.action.add.label')}
          </ModalHeader>
          <ModalBody>
            <section>
              <label htmlFor="title">{t('project:proposition:version.attribute.title')}</label>
              <input
                type="text"
                name="title"
                id="title"
                className={`${errors.title && 'error'}`}
                ref={register({
                  required: {
                    value: true,
                    message: t('common:form:validation.required_field'),
                  },
                  maxLength: {
                    value: 254,
                    message: t('common:form:validation.string_max_255'),
                  },
                })}
              />
              <ErrorMessage errors={errors} name="title">
                {({ messages }): any =>
                  messages
                  && Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))}
              </ErrorMessage>
            </section>

            <ImageUploader setImageKey={setImageKey} cancel={!modalActive} />
          </ModalBody>
          <ModalFooter>
            <div className="cancel">
              <button
                type="button"
                className="creaflow-primary-button"
                onClick={(): void => {
                  toggleModal();
                }}
              >
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="success">
              <button className="creaflow-primary-button" disabled={!imageKey} type="submit">
                {t('common:form:action.add.label')}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default AddVersion;
