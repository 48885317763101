import { useMutation } from '@apollo/client';
import { usePropositionContext } from 'context/PropositionContext';
import { MARK_ANNOTATION_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { Annotation } from 'schema/generated/models';
import Menu from '../Menu/Menu';
import ThreadMessages from '../ThreadMessages/ThreadMessages';

interface Vector2 {
  x: number;
  y: number;
}

interface OpenAnnotationProps {
  annotation: Annotation;
  position: Vector2;
}

function OpenAnnotation({ annotation, position }: OpenAnnotationProps): ReactElement {
  const { canvasWidth, canvasHeight } = usePropositionContext();
  const [annotationPos, setAnnotationPos] = useState<Vector2>(position);
  const [annotationRef, setAnnotationRef] = useState<MutableRefObject<HTMLDivElement> | null>(null);

  const computePosition = (): void => {
    const tmpPos: Vector2 = { x: position.x, y: position.y };
    if (!annotationRef) {
      return;
    }
    const annotationRectangle = annotationRef.current.getBoundingClientRect();
    tmpPos.x -= 20; // gap menu - content
    tmpPos.y -= 20;
    if (tmpPos.x + annotationRectangle.width > canvasWidth) tmpPos.x -= annotationRectangle.width;
    if (tmpPos.y + annotationRectangle.height > canvasHeight) tmpPos.y -= annotationRectangle.height;
    if (tmpPos.x < 0) tmpPos.x = 0;
    if (tmpPos.y < 0) tmpPos.y = 0;
    setAnnotationPos(tmpPos);
  };

  useEffect(() => {
    computePosition();
  }, []);

  const [markAnnotationEventsAsSeen] = useMutation(MARK_ANNOTATION_EVENTS_AS_SEEN);
  useEffect((): void => {
    markAnnotationEventsAsSeen({ variables: { annotationId: annotation.id } });
  }, []);

  return (
    <div className="annotation-open" style={{ left: annotationPos.x, top: annotationPos.y }}>
      <Menu annotation={annotation} />
      <ThreadMessages annotation={annotation} setRef={setAnnotationRef} />
    </div>
  );
}

export default OpenAnnotation;
