export enum EPopupMessageType {
  success,
  error,
}

export class CreaPopupMessage {
  constructor(public type: EPopupMessageType, public message: string) {}
}

export class CreaLocationState {
  pageParams: any; // May be nice to redefine later when specs are established

  popupMessages: CreaPopupMessage[];
}

export enum CustomErrorId {
  UNKNOWN,
  INVALID_TOKEN,
  LOGIN_FAILURE,
  NOT_FOUND,
  SIGNUP_ALREADY_USED
}
