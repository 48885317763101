import axios from 'axios';
import { User } from 'models/User';
import { authorize, SERVER_BASE_URL } from './common';

const BASE_URL = `${SERVER_BASE_URL}/user`;

/**
 * Log the user in
 * @param {string} username
 * @param {string} password
 */
export function login(username: string, password: string): Promise<User> {
  return axios
    .post(`${BASE_URL}/login`, {
      username,
      password,
    })
    .then((response) => response.data);
}

export const backQuery = '?back=';

export function localLogout(userDisconnect = false): void {
  localStorage.setItem('user.authenticated', 'false');
  localStorage.removeItem('user.id');
  localStorage.removeItem('user.token');
  localStorage.removeItem('user.email');
  localStorage.removeItem('user.authenticated');
  const old = !userDisconnect ? `${backQuery}${window.location.pathname}${window.location.search}` : '';
  if (window.location.pathname !== '/') window.location.href = `/${old}`;
}

/**
 * Log the user out
 */
export function logout(): Promise<any> {
  localLogout(true);
  return axios
    .get(`${BASE_URL}/logout`, {
      headers: { Authorization: authorize() },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err));
}
