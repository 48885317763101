import ReactGA from 'react-ga';

export const hideAnnotations = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Hide annotations',
  });
};

export const showAnnotations = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Show annotations',
  });
};

export const fullscreen = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Fullscreen',
  });
};

export const resetZoom = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Reset zoom',
  });
};

export const downloadVersion = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Download',
  });
};

export const changeVersion = (): void => {
  ReactGA.event({
    category: 'Toolbar',
    action: 'Change version',
  });
};
