import CloseButton from 'components/Utilities/CloseButton';
import { ImageUploader } from 'components/Utilities/S3ImageUploader/useS3Uploader';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert } from 'Utilities/Alert/Alert';
import './CreateProposition.scss';

type CreatePropositionProps = {
  documentId: string;
};

export default function CreateProposition({ documentId }: CreatePropositionProps): ReactElement {
  const { user } = useUserContext();
  const { addProposition, project } = useProjectContext();
  const methods = useForm({
    mode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'all',
    defaultValues: {
      title: '',
    },
  });

  const { t } = useTranslation();
  const [imageKey, setImageKey] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  const history = useHistory();

  const toggleModal = (e): void => {
    e.stopPropagation();
    e.preventDefault();
    setModalActive(!modalActive);
  };

  const postProject = async (formResults): Promise<void> => {
    if (!imageKey) {
      return;
    }
    setIsPosting(true);
    try {
      const addPropositionMutationVariables = {
        documentId,
        title: formResults.title,
        approved: false,
      };
      const addVersionMutationVariables = {
        propositionId: '',
        title: formResults.title,
        s3ObjectId: imageKey,
      };
      const [newProposition, newVersion] = await addProposition(
        addPropositionMutationVariables,
        addVersionMutationVariables,
      );
      setIsPosting(false);

      const locationState: CreaLocationState = {
        pageParams: {},
        popupMessages: [
          new CreaPopupMessage(EPopupMessageType.success, t('project:documents:proposition_item.action.add.success')),
        ],
      };
      history.push({
        pathname: `/project/${project.id}/${documentId}/${newProposition.id}/${newVersion.id}`,
        state: locationState,
      });
    } catch (err) {
      errorAlert(t('project:documents:proposition_item.action.add.error'));
      console.error(err);
    }
  };

  return (
    <div className="create-proposition-container">
      {project.owner.id === user.id && (
        <button type="button" className="creaflow-secondary-button" onClick={toggleModal}>
          <FaPlus />
          <span className="button-text">{t('project:documents:proposition_item.action.add.label')}</span>
        </button>
      )}

      <Modal isOpen={modalActive} toggle={toggleModal} centered>
        <FormContext {...methods}>
          <form className="crea-form create-project-form" onSubmit={methods.handleSubmit((data) => postProject(data))}>
            <ModalHeader toggle={toggleModal} close={React.createElement((): ReactElement => CloseButton(toggleModal))} className="modal-head">
              {t('project:documents:proposition_item.action.add.label')}
            </ModalHeader>
            <ModalBody>
              <section>
                <label htmlFor="title">{t('project:documents:proposition_item.attribute.title')}</label>
                <div className="input-box">
                  <span className={`fixed-part ${methods.errors.title && 'error'}`}>Proposition</span>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className={methods.errors.title && 'error'}
                    ref={methods.register({
                      required: {
                        value: true,
                        message: t('common:form:validation.required_field'),
                      },
                      maxLength: {
                        value: 254,
                        message: t('common:form:validation.string_max_255'),
                      },
                    })}
                  />
                </div>
                <ErrorMessage errors={methods.errors} name="title">
                  {({ messages }): any =>
                    messages
                    && Object.entries(messages).map(([type, message]) => (
                      <p className="error" key={type}>
                        {message}
                      </p>
                    ))}
                </ErrorMessage>
              </section>
              <ImageUploader setImageKey={setImageKey} cancel={!modalActive} />
            </ModalBody>
            <ModalFooter className="actions">
              <button
                className="creaflow-primary-button"
                color="success"
                type="submit"
                disabled={!imageKey || isPosting}
              >
                {t('project:documents:proposition_item.action.add.label')}
              </button>
            </ModalFooter>
          </form>
        </FormContext>
      </Modal>
    </div>
  );
}
