import { useMutation, useQuery } from '@apollo/client';
import { ADD_CONTRACT, ADD_PHASE, ADD_PROJECT, GET_PROJECTS } from 'JSUtils/schema/project';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { AddContractMutationVariables, AddPhaseMutationVariables, AddProjectMutationVariables, Contract, Phase, Project } from 'schema/generated/models';
import createCtx from './createContextHelper';

interface ProjectListContextType {
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  addProject: (
    projectVariables: AddProjectMutationVariables,
    phaseVariables: AddPhaseMutationVariables,
    contractVariables: AddContractMutationVariables) => Promise<readonly [Project, Phase, Contract]>;
  getFilteredProjects: (i: string, b: boolean, c: boolean) => Project[];
  filter: string;
  isLoading: boolean;
  setFilter: (s: string) => void;
}

const [useProjectListContext, CtxProvider] = createCtx<ProjectListContextType>();

interface ProviderProps {
  children: React.ReactNode;
}

const ProjectListContextProvider = (props: ProviderProps): ReactElement => {
  const { children } = props;

  const [projects, setProjects] = useState<Project[]>([]);
  const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: {} },
    onCompleted: (data) => {
      setProjects(data.projects);
      setIsLoading(false);
    },
  });
  const [_addProject] = useMutation(ADD_PROJECT);
  const [_addContract] = useMutation(ADD_CONTRACT);
  const [_addPhase] = useMutation(ADD_PHASE);

  const addProject = async (
    addProjectMutationVariables: AddProjectMutationVariables,
    addPhaseMutationVariables: AddPhaseMutationVariables,
    addContractMutationVariables: AddContractMutationVariables): Promise<readonly [Project, Phase, Contract]> => {
    try {
      const { data: { addProject: newProject } } = await _addProject({
        variables: addProjectMutationVariables,
      });
      const { data: { addPhase: newPhase } } = await _addPhase({
        variables: {
          ...addPhaseMutationVariables,
          projectId: newProject.id,
        } });
      const { data: { addContract: newContract } } = await _addContract({
        variables: {
          ...addContractMutationVariables,
          phaseId: newPhase.id,
        } });
      // no need to update the local state because after creation we redirect to /project/:id/dashboard
      // and when we go back to this page we init another context consumer (ProjectList.ts)
      return [{ ...newProject }, newPhase, newContract] as const;
    } catch (err) {
      console.error(err);
    }
    return [{} as Project, {} as Phase, {} as Contract];
  };


  const getFilteredProjects = (userId: string, personnalOnly = false, contributeOnly = false): Project[] => projects.filter(
    (project: Project) => (
      project.title.toLowerCase().includes(filter)
        && ((personnalOnly && project.owner.id === userId) || (contributeOnly && project.owner.id !== userId))
    ),
  ).sort((a: Project, b: Project) => {
    if (a.updatedAt && b.updatedAt) {
      return moment(a.updatedAt).isBefore(moment(b.updatedAt)) ? 1 : -1;
    }
    return 1;
  });

  const projectsContext = {
    projects,
    setProjects,
    getFilteredProjects,
    addProject,
    filter,
    setFilter,
    isLoading,
  };

  return <CtxProvider value={projectsContext}>{children}</CtxProvider>;
};

export { useProjectListContext, ProjectListContextProvider };
