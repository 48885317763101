import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      firstName
      lastName
      culture
      email
      avatarUrl
      jobs
      mailingPreferences
    }
  }
`;

export const ASK_RESET_PASSWORD = gql`
  mutation AskResetPassword($email: String!) {
    askResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const SIGNUP = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $culture: UserCulture!
    $jobs: [String]
  ) {
    signUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      culture: $culture
      jobs: $jobs
    )
  }
`;

export const ASK_DELETE_CURRENT_USER = gql`
  mutation AskDeleteUser {
    askDeleteUser
  }
`;

export const DELETE_CURRENT_USER = gql`
  mutation deleteUser($token: String!) {
    deleteUser(token: $token)
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateUser(
    $firstName: String
    $lastName: String
    $culture: UserCulture
    $jobs: [String]
    $email: String
    $mailingPreferences: String
    $s3ObjectId: String
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      culture: $culture
      jobs: $jobs
      email: $email
      mailingPreferences: $mailingPreferences
      s3ObjectId: $s3ObjectId
    ) {
      id
      firstName
      lastName
      culture
      email
      jobs
      avatarUrl
      mailingPreferences
    }
  }
`;

export const SEND_BUG_REPORT = gql`
  mutation SendBugReport($message: String!) {
    sendBugReport(message: $message)
  }
`;

export const LOGIN_WITH_FACEBOOK = gql`
  mutation FacebookLogin($accessToken: String!, $culture: UserCulture!) {
    facebookLogin(accessToken: $accessToken, culture: $culture)
  }
`;

export const LOGIN_WITH_GOOGLE = gql`
  mutation GoogleLogin($tokenId: String!, $culture: UserCulture!) {
    googleLogin(tokenId: $tokenId, culture: $culture)
  }
`;
