import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'JSUtils/schema/user';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { errorAlert } from 'Utilities/Alert/Alert';
import './ResetPasswordForm.scss';

function ResetPasswordForm(): ReactElement {
  const {
    handleSubmit, errors, register, watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      confirm: '',
    },
  });
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const thisResetPassword = async (data): Promise<void> => {
    const token = location.search.substring(location.search.indexOf('?') + 1).split('=');
    if (token[1]) {
      try {
        await resetPassword({
          variables: {
            token: token[1],
            password: data.password,
          },
        });
        const locationState: CreaLocationState = {
          pageParams: {},
          popupMessages: [new CreaPopupMessage(EPopupMessageType.success, t('user:reset_password.success'))],
        };
        history.replace({
          pathname: '/',
          state: locationState,
        });
      } catch (err) {
        console.error(err);
        errorAlert(t('user:reset_password.error.token_expired'));
      }
    } else {
      errorAlert(t('user:reset_password.error.generic'));
    }
  };

  return (
    <div className="login-form-container">
      <p>{t('user:reset_password.label')}</p>
      <form className="crea-form" onSubmit={handleSubmit(thisResetPassword)}>
        <section>
          <label htmlFor="password">{t('user:attribute.new_password')}</label>
          <input
            className={errors.password && 'error'}
            type="password"
            id="password"
            name="password"
            ref={register({
              required: true,
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/,
            })}
          />
          <ErrorMessage errors={errors} name="password">
            {(): ReactElement => <p className="error">{t('common:misc:validation.invalid_email_format')}</p>}
          </ErrorMessage>
        </section>
        <section>
          <label className={errors.confirm && 'error'} htmlFor="confirmationPassword">
            {t('user:attribute.confirmation_password')}
          </label>
          <input
            type="password"
            className={errors.confirm && 'error'}
            name="confirm"
            id="confirm"
            ref={register({
              validate: (value) => value === watch('password'),
            })}
          />
          <ErrorMessage errors={errors} name="confirm">
            {(): ReactElement => <p className="error">{t('user:signup.error.password_mismatch')}</p>}
          </ErrorMessage>
        </section>
        <section className="login-form-button">
          <button type="submit" className="creaflow-primary-button">
            {t('user:reset_password.label')}
          </button>
        </section>
      </form>
    </div>
  );
}

export default ResetPasswordForm;
