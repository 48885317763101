import { useMutation, useQuery } from '@apollo/client';
import * as WorkflowGA from 'JSUtils/ReactGA/workflow';
import {
  AddContractMutationVariables,
  AddPhaseMutationVariables,
  Contract,
  DeleteContractMutationVariables,
  DeletePhaseMutationVariables,
  Phase,
  UpdateContractMutationVariables,
  UpdatePhaseMutationVariables,
} from 'JSUtils/schema/generated/models';
import {
  ADD_CONTRACT,
  ADD_PHASE,
  DELETE_CONTRACT,
  DELETE_PHASE,
  GET_WORKFLOW,
  UPDATE_CONTRACT,
  UPDATE_PHASE,
} from 'JSUtils/schema/project';
import React, { ReactElement, useEffect, useState } from 'react';
import createCtx from './createContextHelper';
import { useProjectContext } from './ProjectContext';

export enum Mode {
  hidden,
  view,
  edit,
}

type WorkflowContextType = {
  mode: Mode;
  setMode: Function;
  isLoading: boolean;
  phases: Phase[];
  currentPhase: Phase;
  setCurrentPhase: React.Dispatch<React.SetStateAction<Phase>>;

  addPhase: (p: AddPhaseMutationVariables, c: AddContractMutationVariables[]) => Promise<readonly [Phase, Contract[]]>;
  updatePhase: (
    u: UpdatePhaseMutationVariables,
    ca?: AddContractMutationVariables[],
    cu?: UpdateContractMutationVariables[],
    cd?: DeletePhaseMutationVariables[],
  ) => Promise<readonly [Phase, Contract[]]>;
  deletePhase: (d: DeletePhaseMutationVariables) => Promise<boolean>;
};

const [useWorkflowContext, CtxProvider] = createCtx<WorkflowContextType>();

type WorkflowContextProviderProps = {
  children: React.ReactNode;
  currentPhaseId?: string;
};

const WorkflowContextProvider = (props: WorkflowContextProviderProps): ReactElement => {
  const { children, currentPhaseId } = props;
  const { project } = useProjectContext();
  const [phases, setPhases] = useState<Phase[]>([]);
  const [currentPhase, setCurrentPhase] = useState<Phase>({} as Phase);
  const [isLoading, setIsLoading] = useState(true);

  const [mode, setMode] = useState<Mode>(Mode.hidden);

  useEffect(() => {
    if (mode === Mode.hidden) setCurrentPhase({} as Phase);
  }, [mode]);

  useEffect(() => {
    const query = currentPhase && currentPhase.id ? `?phase=${currentPhase.id}` : '';
    window.history.replaceState(null, '', `${window.location.pathname}${query}`);
  }, [currentPhase]);

  useQuery(GET_WORKFLOW, {
    variables: { projectId: project.id },
    onCompleted: (data) => {
      if (data.project?.phases) {
        setPhases(data.project.phases);
        const urlPhase = data.project.phases.find((p) => p.id === currentPhaseId);
        if (urlPhase) {
          setCurrentPhase(urlPhase);
          setMode(Mode.view);
        }
      }
      setIsLoading(false);
    },
  });

  const [_addPhase] = useMutation(ADD_PHASE);
  const [_addContract] = useMutation(ADD_CONTRACT);
  const [_updateContract] = useMutation(UPDATE_CONTRACT);
  const [_deleteContract] = useMutation(DELETE_CONTRACT);
  const addPhase = async (
    addPhaseMutationVariables: AddPhaseMutationVariables,
    addContractMutationVariables: AddContractMutationVariables[],
  ): Promise<readonly [Phase, Contract[]]> => {
    try {
      setIsLoading(true);
      const {
        data: { addPhase: newPhase },
      } = await _addPhase({
        variables: addPhaseMutationVariables,
      });
      WorkflowGA.createPhase();

      const newContracts = await Promise.all<Contract>(
        addContractMutationVariables.map(async (addSingleContractMutationVariables) => {
          const {
            data: { addContract: newContract },
          } = await _addContract({
            variables: {
              ...addSingleContractMutationVariables,
              phaseId: newPhase.id,
            },
          });
          WorkflowGA.createContract();
          return newContract;
        }),
      );

      setPhases([...phases, { ...newPhase, contracts: newContracts }]);
      setMode(Mode.hidden);
      setIsLoading(false);
      return [newPhase, newContracts] as const;
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    return [{} as Phase, []];
  };

  const [_updatePhase] = useMutation(UPDATE_PHASE);
  const updatePhase = async (
    updatePhaseMutationVariables: UpdatePhaseMutationVariables,
    addContractMutationVariables: AddContractMutationVariables[] = [],
    updateContractMutationVariables: UpdateContractMutationVariables[] = [],
    deleteContractMutationVariables: DeleteContractMutationVariables[] = [],
  ): Promise<readonly [Phase, Contract[]]> => {
    try {
      // Add contracts
      await Promise.all(
        addContractMutationVariables.map(
          async (addSingleContractMutationVariables) =>
            new Promise<Contract>((resolve) =>
              _addContract({
                variables: {
                  ...addSingleContractMutationVariables,
                  phaseId: updatePhaseMutationVariables.id,
                },
              }).then(({ data: { addContract: newContract } }) => {
                WorkflowGA.createContract();
                resolve(newContract);
              })),
        ),
      );
      // Update contracts
      await Promise.all(
        updateContractMutationVariables.map(
          async (updateSingleContractMutationVariables) =>
            new Promise<Contract>((resolve) =>
              _updateContract({
                variables: {
                  ...updateSingleContractMutationVariables,
                },
              }).then(({ data: { updateContract: updatedContract } }) => {
                WorkflowGA.updateContract();
                resolve(updatedContract);
              })),
        ),
      );
      // Delete contracts
      await Promise.all(
        deleteContractMutationVariables.map(
          async (deleteSingleContractMutationVariables) =>
            new Promise<string>((resolve) =>
              _deleteContract({
                variables: {
                  ...deleteSingleContractMutationVariables,
                },
              }).then(({ data: { deleteContract: result } }) => {
                WorkflowGA.deleteContract();
                resolve(result ? deleteSingleContractMutationVariables.id : '');
              })),
        ),
      );

      // Update phases infos at the end so it returns updated (created and deleted) contracts
      const {
        data: { updatePhase: updatedPhase },
      } = await _updatePhase({
        variables: updatePhaseMutationVariables,
      });
      WorkflowGA.updatePhase(updatePhaseMutationVariables);

      // Update local state with mutations results
      setMode(Mode.hidden);
      setPhases(phases.map((p) => (p.id === updatedPhase.id ? updatedPhase : p)));
      setCurrentPhase(updatedPhase);

      return [updatedPhase, updatedPhase.contracts] as const;
    } catch (err) {
      console.error(err);
    }
    return [{} as Phase, [] as Contract[]];
  };
  const [_deletePhase] = useMutation(DELETE_PHASE);
  const deletePhase = async (deletePhaseMutationVariables: DeletePhaseMutationVariables): Promise<boolean> => {
    try {
      setIsLoading(true);
      await _deletePhase({
        variables: deletePhaseMutationVariables,
      });
      WorkflowGA.deletePhase();
      setPhases(phases.filter((p) => p.id !== deletePhaseMutationVariables.id));
      setMode(Mode.hidden);
      setCurrentPhase({} as Phase);
      setIsLoading(false);
      return true;
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    return false;
  };

  const workflowContext = {
    isLoading,
    phases: phases?.map((p: Phase) => p),
    currentPhase,
    setCurrentPhase,
    mode,
    setMode,

    addPhase,
    updatePhase,
    deletePhase,
  };

  return <CtxProvider value={workflowContext}>{children}</CtxProvider>;
};

export { useWorkflowContext, WorkflowContextProvider };
