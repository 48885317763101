import { useApolloClient } from '@apollo/client';
import { useUserContext } from 'context/UserContext';
import * as UserGA from 'JSUtils/ReactGA/user';
import { EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert } from 'Utilities/Alert/Alert';
import './DeleteAccount.scss';

export default function DeleteAccount(): ReactElement {
  const { deleteCurrentUser } = useUserContext();
  const client = useApolloClient();
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState('');
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const toggleModal = (): void => {
    setModal(!modal);
  };

  useEffect(() => {
    let error = '';
    let userId = 0;
    try {
      userId = Number(localStorage.getItem('user.id'));
      const queryToken = location.search.substring(location.search.indexOf('?') + 1).split('=');
      const tokenParams: { ext: number; user: { id: number } } = JSON.parse(window.atob(queryToken[1].split('.')[1]));
      const currentDate = new Date();
      // exp is is a date in sec, new Date requires a date in ms
      const expireDate = new Date(tokenParams.ext * 1000);
      if (currentDate > expireDate) error = t('user:account_management.delete.expired_token');
      if (tokenParams.user.id !== userId) error = t('user:account_management.delete.invalid_token');
      setToken(queryToken[1]);
    } catch (err) {
      console.error(err);
      error = t('user:account_management.delete.invalid_token');
    }

    if (error) {
      history.replace({
        pathname: Number.isNaN(userId) ? '/' : `/user/${userId}/account`,
        state: {
          pageParams: {},
          popupMessages: [
            {
              type: EPopupMessageType.error,
              message: error,
            },
          ],
        },
      });
    }
  }, []);

  const { signOut: googleSignOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
  });

  const onDeleteAccountClick = async (): Promise<void> => {
    try {
      // Avoid auto reconnect (-> register) after deleting account
      googleSignOut();
    } catch (err) {
      console.warn('Failed to logout from google');
      console.warn(err);
    }
    try {
      await deleteCurrentUser({
        variables: { token },
      });
      UserGA.deleteAccount();
      localStorage.setItem('TPAutoConnect', 'false');
      localStorage.setItem('user.authenticated', 'false');
      localStorage.removeItem('user.token');
      history.replace({
        pathname: '/',
        state: {
          pageParams: {},
          popupMessages: [
            {
              type: EPopupMessageType.success,
              message: t('user:account_management.delete.success'),
            },
          ],
        },
      });
      client.clearStore();
    } catch (err) {
      console.error(err);
      errorAlert(t('user:account_management.delete.error'));
    }
  };

  return (
    <>
      <Col sm={{ size: 6, offset: 3 }} className="main">
        <p>{t('user:account_management.delete.warning')}</p>
        <button type="button" className="creaflow-primary-button" onClick={toggleModal}>
          {t('user:account_management.delete.ask_delete.label')}
        </button>
      </Col>

      <Modal isOpen={modal} centered toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{`${t('user:account_management.delete.ask_delete.label')} ?`}</ModalHeader>
        <ModalBody>{t('user:account_management.delete.warning')}</ModalBody>
        <ModalFooter>
          <div className="cancel">
            <button type="button" className="creaflow-primary-button" onClick={toggleModal}>
              {t('common:form:action.cancel.label')}
            </button>
          </div>
          <div className="danger">
            <button type="button" className="creaflow-primary-button" onClick={onDeleteAccountClick}>
              {t('common:form:action.delete.label')}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
