import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './Error.scss';

type ErrorProps = {
  content: ReactNode;
}

const Error = ({ content }: ErrorProps): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  const onGoBackToDashboardClick = (): void => {
    history.push('/');
  };

  return (
    <div className="error-container">
      <div className="error-modale">
        <h1>Oops</h1>
        {content}
        <button className="creaflow-primary-button" type="button" onClick={onGoBackToDashboardClick}>
          {t('common:misc:error.go_back_to_dashboard')}
        </button>
      </div>
    </div>
  );
};

export const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  return <Error content={<p>{t('common:misc:error.404')}</p>} />;
};

export const InternalServerError = (): ReactElement => {
  const { t } = useTranslation();
  return <Error content={<p>{t('common:misc:error.500')}</p>} />;
};
