import Tooltip from 'components/Utilities/Tooltip/Tooltip';
import { usePropositionContext } from 'context/PropositionContext';
import ColorPicker from 'material-ui-color-picker';
import { Slider } from 'material-ui-slider';
import React, { ReactElement, useRef } from 'react';
import CornerUpLeft from 'react-feather/dist/icons/corner-up-left';
import CornerUpRight from 'react-feather/dist/icons/corner-up-right';
import Edit2 from 'react-feather/dist/icons/edit-2';
import MessageCircle from 'react-feather/dist/icons/message-circle';
import Plus from 'react-feather/dist/icons/plus';
import Save from 'react-feather/dist/icons/save';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import './Toolbox.scss';

type ToolboxProps = {
  isMobile: boolean;
};

const Toolbox = ({ isMobile }: ToolboxProps): ReactElement => {
  const { t } = useTranslation();
  const {
    reviewMode,
    setCreateTextAnnotation,
    pencilEnabled,
    setPencilEnabled,
    setPencilSize,
    setPencilColor,
    getUserDrawings,
    undoDrawings,
    redoDrawings,
    drawingNeedSaving,
    updateVersion,
    toolboxVisibility,
    // setToolboxVisibility,
    mobiletoolboxVisibility,
    // setMobiletoolboxVisibility,
    version,
    proposition,
    setVersion,
  } = usePropositionContext();

  const visibility = isMobile ? mobiletoolboxVisibility : toolboxVisibility;
  // const setVisibility = isMobile ? setMobiletoolboxVisibility : setToolboxVisibility;

  const toolButtonsStyle = reviewMode ? 'toolButtonDisabled' : 'toolButton';
  const saveButtonStyle = drawingNeedSaving ? toolButtonsStyle : 'toolButtonDisabled';
  const annotate = (): void => {
    setCreateTextAnnotation(true);
  };

  const addTextAnnotationButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const addDrawingButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const changeSizeButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const changeColorButtonRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const undoButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const redoButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const saveButtonRef = useRef<HTMLDivElement>(document.createElement('div'));

  const lastVersion = proposition.versions[proposition.versions.length - 1];
  const isLast = lastVersion?.id === version.id;
  if (!isLast) {
    return (
      <div className="Toolbox-container">
        <div className={visibility ? 'Toolbox-main' : 'undisplay'}>
          <p>{t('project:proposition:sidebar.toolbox.immutable_version_text')}</p>
          <Trans i18nKey="project:proposition:sidebar.toolbox.last_version_link_text">
            <Link
              className="link"
              to={`${lastVersion?.id}`}
              onClick={(e): any => {
                e.stopPropagation();
                e.preventDefault();
                if (lastVersion) setVersion(lastVersion);
              }}
            />
          </Trans>
        </div>
      </div>
    );
  }

  return (
    <div className="Toolbox-container">
      <div className={visibility ? 'Toolbox-main' : 'undisplay'}>
        <div className="tool tool-textAnnotation">
          <div className="tool-header tool-textAnnotation-header" ref={addTextAnnotationButtonRef}>
            <div className="tool-textAnnotation-title" onClick={annotate}>
              <MessageCircle className="icon" />
              <span className="toolTitle">{t('project:proposition:sidebar.toolbox.text.title')}</span>
            </div>
            <div className="tool-textAnnotation-button toolButton" onClick={annotate}>
              <Plus className="icon" />
            </div>
          </div>
          <Tooltip reactElementRef={addTextAnnotationButtonRef}>
            <p>{t('project:proposition:sidebar.toolbox.text.tooltip')}</p>
          </Tooltip>
        </div>

        {!isMobile && (
          <div className="tool tool-drawAnnotation">
            <div className="tool-header tool-drawAnnotation-header" ref={addDrawingButtonRef}>
              <div className="tool-drawAnnotation-title">
                <Edit2 className="icon" />
                <span className="toolTitle">{t('project:proposition:sidebar.toolbox.pencil.title.label')}</span>
              </div>
              <div className="tool-drawAnnotation-button toolButton">
                <label htmlFor="pencilEnabled" className="switch">
                  <input
                    checked={pencilEnabled}
                    id="pencilEnabled"
                    type="checkbox"
                    onChange={(): void => setPencilEnabled(!pencilEnabled)}
                  />
                  <span className="switch-slider round" />
                </label>
              </div>
            </div>
            <Tooltip reactElementRef={addDrawingButtonRef}>
              <p>{t('project:proposition:sidebar.toolbox.pencil.title.tooltip')}</p>
            </Tooltip>
            <div className="tool-drawAnnotation-main">
              <div className={`tool-drawAnnotation-size tool-element ${toolButtonsStyle}`} ref={changeSizeButtonRef}>
                <span>{t('project:proposition:sidebar.toolbox.pencil.size.label')}</span>
                <Slider onChangeComplete={setPencilSize} defaultValue={6} min={1} max={20} />
              </div>
              <Tooltip reactElementRef={changeSizeButtonRef}>
                <p>{t('project:proposition:sidebar.toolbox.pencil.size.tooltip')}</p>
              </Tooltip>
              <div className={`tool-drawAnnotation-color tool-element ${toolButtonsStyle}`}>
                <span ref={changeColorButtonRef}>{t('project:proposition:sidebar.toolbox.pencil.color.label')}</span>
                <div className="color-picker">
                  <ColorPicker
                    name="color"
                    onChange={(color): void => {
                      if (color) setPencilColor(color);
                    }}
                    defaultValue="#000000"
                  />
                </div>
              </div>
              <Tooltip reactElementRef={changeColorButtonRef}>
                <p>{t('project:proposition:sidebar.toolbox.pencil.color.tooltip')}</p>
              </Tooltip>
              <div
                className={`tool-drawAnnotation-undo tool-element ${toolButtonsStyle}`}
                onClick={(): void => undoDrawings()}
                ref={undoButtonRef}
              >
                {t('project:proposition:sidebar.toolbox.pencil.undo.label')}
                <CornerUpLeft className="icon" />
              </div>
              <Tooltip reactElementRef={undoButtonRef}>
                <p>{t('project:proposition:sidebar.toolbox.pencil.undo.tooltip')}</p>
              </Tooltip>
              <div
                className={`tool-drawAnnotation-redo tool-element ${toolButtonsStyle}`}
                onClick={(): void => redoDrawings()}
                ref={redoButtonRef}
              >
                {t('project:proposition:sidebar.toolbox.pencil.redo.label')}
                <CornerUpRight className="icon" />
              </div>
              <Tooltip reactElementRef={redoButtonRef}>
                <p>{t('project:proposition:sidebar.toolbox.pencil.redo.tooltip')}</p>
              </Tooltip>
              <div
                className={`tool-drawAnnotation-save tool-element ${saveButtonStyle}`}
                onClick={async (): Promise<void> => {
                  try {
                    await updateVersion({ id: version.id, drawings: getUserDrawings() });
                    successAlert(t('project:proposition:sidebar.toolbox.pencil.save.success'));
                  } catch (error) {
                    errorAlert(t('project:proposition:sidebar.toolbox.pencil.save.error'));
                  }
                }}
                ref={saveButtonRef}
              >
                {t('project:proposition:sidebar.toolbox.pencil.save.label')}
                <Save className="icon" />
              </div>
              <Tooltip reactElementRef={saveButtonRef}>
                <p>{t('project:proposition:sidebar.toolbox.pencil.save.tooltip')}</p>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toolbox;
