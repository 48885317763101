import ReactGA from 'react-ga';

export const goToDashboardFromLogo = (): void => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Dashboard',
    label: 'logo',
  });
};

export const goToDashboardFromTab = (): void => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Dashboard',
    label: 'tab',
  });
};

export const openHelpCenter = (): void => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Open HelpCenter',
  });
};

export const closeHelpCenter = (): void => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Close HelpCenter',
  });
};
