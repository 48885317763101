import { useMutation } from '@apollo/client';
import { useProjectContext } from 'context/ProjectContext';
import { CREATE_PROJECT_TOKEN } from 'JSUtils/schema/project';
import React, { ReactElement, useEffect, useState } from 'react';
import XCircle from 'react-feather/dist/icons/x-circle';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormText } from 'reactstrap';
import './InviteCollaborators.scss';

type InviteByMailProps = {
  emailAddressesArrayMethods: any;
};

function InviteByMail({ emailAddressesArrayMethods }: InviteByMailProps): ReactElement {
  const { errors, register } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="invitation-container">
      <section className="invite-by invite-by-email">
        <div className="header">
          <h1>{t('project:common:collaborators.invite.by_mail.label')}</h1>
          <button
            type="button"
            className="creaflow-secondary-button-v2"
            onClick={(): void => emailAddressesArrayMethods.append({ name: 'emailAddresses' })}
          >
            {t('project:common:collaborators.invite.by_mail.add_address_label')}
          </button>
        </div>
        {emailAddressesArrayMethods.fields.map((item, index) => (
          <div key={item.id}>
            <div className="top-row">
              <input
                type="text"
                className={`${errors.emailAddresses && errors.emailAddresses[index] && 'error'}`}
                ref={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                name={`emailAddresses[${index}]`}
              />
              <XCircle className="icon" onClick={(): void => emailAddressesArrayMethods.remove(index)} />
            </div>
            <div className="bottom-row">
              <ErrorMessage errors={errors} name={`emailAddresses[${index}]`}>
                {(): ReactElement => <p className="error">{t('common:form:validation.invalid_email_format')}</p>}
              </ErrorMessage>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

function InviteByLink(): ReactElement {
  const { project } = useProjectContext();
  const { t } = useTranslation();

  const [inviteUrl, setInviteUrl] = useState('');
  const [createProjectToken] = useMutation(CREATE_PROJECT_TOKEN);
  const generateToken = async (): Promise<void> => {
    try {
      const { data } = await createProjectToken({ variables: { id: project.id } });
      const token = data.createProjectToken;
      const { protocol } = window.location;
      const slashes = protocol.concat('//');
      let host = slashes.concat(window.location.hostname);
      if (window.location.hostname === 'localhost') {
        host += ':8080';
      }
      setInviteUrl(`${host}/invitation?token=${token}`);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    generateToken();
  }, [project.id]);

  return (
    <div className="invitation-container">
      <section>
        <h1>{t('project:common:collaborators.invite.by_link.label')}</h1>
        <input type="text" value={inviteUrl} onChange={(): null => null} />
        <FormText color="muted">{t('project:common:collaborators.invite.by_link.subtitle')}</FormText>
      </section>
    </div>
  );
}

export { InviteByMail, InviteByLink };
