import closeButtonImage from 'assets/close.svg';
import React, { ReactElement } from 'react';

const CloseButton = (toggleModal: any): ReactElement => (
  <button className="close" type="button" onClick={toggleModal}>
    <img src={closeButtonImage} alt="close" />
  </button>
);

export default CloseButton;
