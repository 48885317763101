import React, { ReactElement } from 'react';
import './Stripes.scss';

type StripesProps = {
  mode: number;
}

export default function Stripes({ mode }: StripesProps): ReactElement {
  return (
    <div className={mode === 0 ? 'stripes' : 'stripes2'} aria-hidden="true">
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}
