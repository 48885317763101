import { useMutation } from '@apollo/client';
import { InviteByLink, InviteByMail } from 'components/Project/InviteCollaborators/InviteCollaborators';
import { errorAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import useDirtyFormFields from 'JSUtils/custom-hooks/useDirtyFormFields';
import { ADD_COLLABORATORS } from 'JSUtils/schema/project';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useState } from 'react';
import XCircle from 'react-feather/dist/icons/x-circle';
import { FormContext, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { User } from 'schema/generated/models';
import './ManageCollaborators.scss';

type RemoveCollaboratorsProps = {
  collaboratorsArrayMethods: any;
  markAsDirty: () => void;
  computeAuthorizeRemove: (userItem) => boolean;
};


function RemoveCollaborators({
  collaboratorsArrayMethods,
  markAsDirty,
  computeAuthorizeRemove,
}: RemoveCollaboratorsProps): ReactElement {
  const { register } = useFormContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const [selfRemoveModal, setSelfRemoveModal] = useState(false);
  const [selfIndex, setSelfIndex] = useState(0);

  const removeCollaborator = (idx: number): void => {
    // eslint-disable-next-line react/prop-types
    collaboratorsArrayMethods.remove(idx);
    markAsDirty();
  };

  const getRemoveButton = (userItem, idx): ReactElement => {
    const isAllowedToRemove = computeAuthorizeRemove(userItem);
    if (isAllowedToRemove) {
      return (
        <XCircle
          className="icon"
          onClick={(): void => {
            if (userItem.id === user.id) {
              setSelfIndex(idx);
              setSelfRemoveModal(true);
            } else removeCollaborator(idx);
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <section className="remove-collaborators">
        {collaboratorsArrayMethods?.fields?.map((item, idx) => (
          <div key={item.user.id} className="collaborator-row">
            <input
              className="crea-hidden"
              disabled
              ref={register()}
              type="number"
              value={item.user.id}
              name={`collaborators[${idx}].id`}
            />
            <input
              ref={register()}
              name={`collaborators[${idx}].fullName`}
              readOnly
              type="text"
              className="collaboratorInput"
              value={item.user.fullName}
            />
            {getRemoveButton(item.user, idx)}
          </div>
        )) ?? <div />}
      </section>

      <Modal isOpen={selfRemoveModal} toggle={(): void => setSelfRemoveModal(false)} centered>
        <ModalHeader toggle={(): void => setSelfRemoveModal(false)} close={React.createElement((): ReactElement => CloseButton(() => setSelfRemoveModal(false)))} className="modal-head">
          {t('project:common:action.leave.label')}
        </ModalHeader>
        <ModalBody>
          <Label>{t('project:common:action.leave.ask_confirmation')}</Label>
        </ModalBody>
        <ModalFooter>
          <div className="cancel">
            <button type="button" className="creaflow-primary-button" onClick={(): void => setSelfRemoveModal(false)}>
              {t('common:form:action.cancel.label')}
            </button>
          </div>
          <div className="danger">
            <button
              type="button"
              className="creaflow-primary-button"
              onClick={(): void => {
                removeCollaborator(selfIndex);
                setSelfRemoveModal(false);
              }}
            >
              {t('project:common:action.leave.button')}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

function Invitation(): ReactElement {
  const { user } = useUserContext();
  const { project, removeCollaborators } = useProjectContext();
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      emailAddresses: [],
      collaborators: project.contributors.map((u: User) => ({
        user: {
          id: u.id,
          fullName: `${u.firstName} ${u.lastName}`,
        },
      })),
    },
  });
  const emailAddressesArrayMethods = useFieldArray({
    control: methods.control,
    name: 'emailAddresses',
  });
  const collaboratorsArrayMethods = useFieldArray({
    control: methods.control,
    name: 'collaborators',
  });

  const { t } = useTranslation();
  const { dirty, markAsDirty } = useDirtyFormFields();

  const [modalActive, setModalActive] = useState(false);

  const history = useHistory();

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const redirect = (messages: CreaPopupMessage[], selfRemoving: boolean): void => {
    const locationState: CreaLocationState = {
      pageParams: {},
      popupMessages: messages,
    };
    const url = selfRemoving ? '/' : history.location.pathname;
    history.replace({
      pathname: url,
      state: locationState,
    });
  };

  const [addCollabsByEmail] = useMutation(ADD_COLLABORATORS);
  const onSubmit = async (data): Promise<void> => {
    const messages: CreaPopupMessage[] = [];
    let selfRemoving = false;
    if (data?.emailAddresses) {
      await addCollabsByEmail({ variables: { projectId: project.id, collaboratorsEmails: data.emailAddresses } });
      messages.push(
        new CreaPopupMessage(EPopupMessageType.success, t('project:common:collaborators.invite.by_mail.success')),
      );
    }
    if (dirty) {
      const deletedCollaborators = project.contributors
        .map((u: User) => u)
        .filter((x: User) => !(data?.collaborators ?? []).some((dc: User) => x.id === dc.id));
      try {
        await removeCollaborators({
          collaboratorsIds: deletedCollaborators.map((x) => x.id),
          projectId: project.id,
        });
        if (deletedCollaborators.findIndex((u) => u.id === user.id) !== -1) selfRemoving = true;
        messages.push(
          new CreaPopupMessage(EPopupMessageType.success, t('project:common:collaborators.update_list.success')),
        );
      } catch (err) {
        errorAlert(t('project:common:collaborators.update_list.error'));
        console.error(err);
      }
    }
    if (messages.length) {
      redirect(messages, selfRemoving);
    }
    setModalActive(false);
  };

  return (
    <>
      <button type="button" className="inviteButton project-button creaflow-secondary-button-v2" onClick={toggleModal}>
        {t('project:common:attribute.collaborators')}
      </button>
      {project !== undefined && (
        <Modal isOpen={modalActive} toggle={toggleModal} centered>
          <FormContext {...methods}>
            <form className="crea-form manage-collaborators" onSubmit={methods.handleSubmit(onSubmit)}>
              <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))} toggle={toggleModal}>{t('project:common:collaborators.label')}</ModalHeader>
              <ModalBody>
                {collaboratorsArrayMethods?.fields?.length !== 0 && (
                  <div className="section">
                    <h4>{t('project:common:collaborators.remove.label')}</h4>
                    <RemoveCollaborators
                      markAsDirty={markAsDirty}
                      collaboratorsArrayMethods={collaboratorsArrayMethods}
                      computeAuthorizeRemove={(userItem): boolean =>
                        userItem.id === user.id || project.owner.id === user.id}
                    />
                  </div>
                )}
                <div className="section">
                  <h4>{t('project:common:collaborators.invite.label')}</h4>
                  <InviteByMail emailAddressesArrayMethods={emailAddressesArrayMethods} />
                  <InviteByLink />
                </div>
              </ModalBody>
              <ModalFooter>
                <button className="creaflow-primary-button" color="success" type="submit">
                  {t('common:form:action.save.label')}
                </button>
              </ModalFooter>
            </form>
          </FormContext>
        </Modal>
      )}
    </>
  );
}

export default Invitation;
