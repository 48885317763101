import { useProjectContext } from 'context/ProjectContext';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TextTruncate from 'react-text-truncate';
import { Proposition } from 'schema/generated/models';
import './PropositionItem.scss';


type PropositionItemProps = {
  proposition: Proposition;
};

export default function PropositionItem({ proposition }: PropositionItemProps): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { project } = useProjectContext();

  const onOpenClick = (): void => {
    if (project) {
      history.push(`/project/${project.id}/${proposition.document.id}/${proposition.id}/`);
    }
  };

  return (
    <div className="proposition-content">
      <div className="img-container">
        <img
          className="preview-image"
          src={proposition.previewImageUrl ?? 'TODO: PLACEHOLDER'}
          alt={proposition.title}
        />
      </div>
      <div className="main-info">
        <TextTruncate line={2} element="h2" truncateText="…" text={proposition.title} />
        <p>{t('project:documents:proposition_item:version_count', { count: proposition.versions.length })}</p>
        <div className="actions">
          <button type="button" className="creaflow-primary-button open" onClick={onOpenClick}>
            {t('project:documents:proposition_item.action.open')}
          </button>
        </div>
      </div>
    </div>
  );
}
