import logo from 'assets/logo/256x256.png';
import React, { ReactElement } from 'react';
import './CreaSpinner.scss';

export default function CreaSpinner(): ReactElement {
  return (
    <div className="crea-spinner">
      <img src={logo} alt="spin-loader" className="loader" />
    </div>
  );
}
