import { InternalServerError, NotFound } from 'containers/Errors/Error';
import MainDashboard from 'containers/MainDashboard/MainDashboard';
import ProjectDashboard from 'containers/Project/DashboardSpace/ProjectDashboard';
import DocumentList from 'containers/Project/DocumentSpace/DocumentList/DocumentList';
import PropositionContainer from 'containers/Project/DocumentSpace/PropositionContainer/PropositionContainer';
import ReceiveInvitation from 'containers/Project/ReceiveInvitation/ReceiveInvitation';
import Workflow from 'containers/Project/WorkflowSpace/Workflow';
import ProjectList from 'containers/ProjectList/ProjectList';
import Account from 'containers/User/Authenticated/Account/Account';
import DeleteAccount from 'containers/User/Authenticated/DeleteAccount/DeleteAccount';
import EditProfile from 'containers/User/Authenticated/EditProfile/EditProfile';
import Unsubscribe from 'containers/User/Authenticated/Unsubscribe/Unsubscribe';
import Login from 'containers/User/Unauthenticated/Login/Login';
import ResetPassword from 'containers/User/Unauthenticated/ResetPassword/ResetPassword';
import { UserContextProvider, useUserContext } from 'context/UserContext';
import CookiesConsent from 'CookiesConsent/CookiesConsent';
import MainNavBar from 'Nav/MainNavBar/MainNavBar';
import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Stripes from 'Utilities/Background/Stripes';
import CreaSpinner from 'Utilities/Spinner/CreaSpinner';
import withProject from './withProject';

type ProtectedRouteProps = {
  component: React.ComponentType;
  path: string;
  extendMainNavBar?: boolean;
  exact?: boolean;
};

function ProtectedRoute({
  component: WrappedComponent,
  path,
  extendMainNavBar = false,
  exact = false,
}: ProtectedRouteProps): ReactElement {
  const { isLoading } = useUserContext();
  const Container = (): ReactElement => {
    return (
      <div className="page-container">
        <Stripes mode={0} />
        <MainNavBar extendMainNavBar={extendMainNavBar} />
        {isLoading ? <CreaSpinner /> : <WrappedComponent />}
      </div>
    );
  };

  return (
    <Route
      path={path}
      exact={exact}
      component={(): ReactElement =>
        (localStorage.getItem('user.authenticated') === 'true' ? Container() : <Redirect to="/" />)}
    />
  );
}

/**
 * @class Routes: handle all routes
 * default route is / with <Login/> component
 */
function Routes(): ReactElement {
  return (
    <UserContextProvider>
      <>
        <CookiesConsent />
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/invitation" component={ReceiveInvitation} />
            <ProtectedRoute exact path="/projectList" component={ProjectList} />
            <ProtectedRoute exact path="/deleteAccount" component={DeleteAccount} />
            <ProtectedRoute exact path="/mainDashboard" component={MainDashboard} />
            <ProtectedRoute exact path="/user/:id/profile" component={EditProfile} />
            <ProtectedRoute exact path="/user/:id/account" component={Account} />
            <ProtectedRoute exact path="/user/:id/account/unsubscribe" component={Unsubscribe} />
            <ProtectedRoute
              exact
              extendMainNavBar
              path="/project/:id/dashboard"
              component={withProject(ProjectDashboard)}
            />
            <ProtectedRoute exact extendMainNavBar path="/project/:id/workflow" component={withProject(Workflow)} />
            <ProtectedRoute
              exact
              extendMainNavBar
              path="/project/:id/documents"
              component={withProject(DocumentList)}
            />
            <ProtectedRoute
              extendMainNavBar
              path="/project/:id/documents/:documentId"
              component={withProject(DocumentList)}
            />
            <ProtectedRoute
              exact
              extendMainNavBar
              path="/project/:id/:documentId/:propositionId/:versionId"
              component={withProject(PropositionContainer)}
            />
            <ProtectedRoute
              exact
              extendMainNavBar
              path="/project/:id/:documentId/:propositionId"
              component={withProject(PropositionContainer)}
            />
            <ProtectedRoute exact path="/404" component={NotFound} />
            <ProtectedRoute exact path="/500" component={InternalServerError} />
            <ProtectedRoute exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </>
    </UserContextProvider>
  );
}

export default Routes;
