import { errorAlert } from 'components/Utilities/Alert/Alert';
import { useUserContext } from 'context/UserContext';
import { backQuery } from 'JSUtils/API/auth';
import React, { ReactElement } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './LoginForm.scss';

function LoginForm(): ReactElement {
  const { login } = useUserContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });

  const connect = async (data): Promise<void> => {
    if (data.email && data.password) {
      try {
        await login(data.email, data.password);
        const path = window.location.search;
        const oldPath = path.indexOf(backQuery) === 0 && path.substr(backQuery.length);
        localStorage.setItem('user.authenticated', 'true');
        history.push(oldPath || '/mainDashboard');
      } catch (err) {
        errorAlert(t('user:login.error.invalid_credentials'));
      }
    }
  };

  return (
    <div className="login-form-container">
      <form className="crea-form" onSubmit={handleSubmit(connect)}>
        <section>
          <input
            type="email"
            name="email"
            placeholder={t('user:attribute.email')}
            ref={register({ required: true })}
            id="email"
            className={`${errors.email && 'error'}`}
          />
          <ErrorMessage errors={errors} name="email">
            {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
          </ErrorMessage>
        </section>
        <section>
          <input
            type="password"
            name="password"
            placeholder={t('user:attribute.password')}
            ref={register({ required: true })}
            id="password"
            className={`${errors.password && 'error'}`}
          />
          <ErrorMessage errors={errors} name="password">
            {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
          </ErrorMessage>
        </section>
        <section className="login-form-button">
          <button type="submit" className="creaflow-primary-button">
            {t('user:login.label')}
          </button>
        </section>
      </form>
    </div>
  );
}

export default LoginForm;
