import React, { ReactElement, useEffect, useRef, useState } from 'react';
import './Tooltip.scss';

type SimpleComponentType<P> = (props: P) => React.ReactElement;

// Props to pass to the <Tooltip /> component directly (mandatory)
// E.g. <Tooltip reactElementRef={refToYourTooltipedComponent}> <--- HERE
//        <p>TooltipContent</p>
//      </Tooltip>
type WithTooltipProps = {
  reactElementRef: React.MutableRefObject<any>;
}

type Type = 'hidden' | 'visible' | 'top' | 'right' | 'bottom' | 'left';

const withTooltip = <P extends object>(Content: SimpleComponentType<P>): SimpleComponentType<P & WithTooltipProps> => ({
  reactElementRef,
  ...props
}): ReactElement => {
  const [type, setType] = useState<Type>('hidden');
  const [coordinates, setCoordinates] = useState<Record<any, string>>({ top: '0px', left: '0px' });
  const thisRef = useRef<HTMLDivElement>(document.createElement('div'));

  const computeCoordinates = (): void => {
    // Compute coordinates to always display the tooltip where there is room
    // https://medium.com/@jsmuster/building-a-tooltip-component-with-react-2de14761e02
    if (reactElementRef?.current && thisRef?.current) {
      const tmpCoordinates = { left: 0, top: 0 };

      const docWidth = document.documentElement.clientWidth;
      const docHeight = document.documentElement.clientHeight;
      // Tooltiped element boundaries
      const hoverElementRectangle = reactElementRef.current.getBoundingClientRect();
      // Tooltip boundaries
      const tooltipRectangle = thisRef.current.getBoundingClientRect();

      // test thisRef's right boundary against client's one
      if ((hoverElementRectangle.right + tooltipRectangle.width) <= window.scrollX + docWidth) {
        tmpCoordinates.left = hoverElementRectangle.right;
        tmpCoordinates.top = hoverElementRectangle.top + (hoverElementRectangle.height - tooltipRectangle.height);
        if (tmpCoordinates.top < 0) {
          tmpCoordinates.top = hoverElementRectangle.top;
        }
        setType('right');
      }
      // test against bottom
      if ((hoverElementRectangle.bottom + tooltipRectangle.height) <= window.scrollY + docHeight) {
        tmpCoordinates.top = hoverElementRectangle.bottom;
        tmpCoordinates.left = hoverElementRectangle.left + (hoverElementRectangle.width / 2) - (tooltipRectangle.width / 2);
        if (tmpCoordinates.left < 0) {
          tmpCoordinates.left = hoverElementRectangle.left;
        }
        setType('bottom');
      }
      // test against left
      if ((hoverElementRectangle.left - tooltipRectangle.width) >= 0) {
        tmpCoordinates.left = hoverElementRectangle.left - tooltipRectangle.width;
        tmpCoordinates.top = hoverElementRectangle.top + (hoverElementRectangle.height - tooltipRectangle.height);
        if (tmpCoordinates.top < 0) {
          tmpCoordinates.top = hoverElementRectangle.top;
        }
        setType('left');
      }
      // test against top
      if ((hoverElementRectangle.top - tooltipRectangle.height) >= 0) {
        tmpCoordinates.top = hoverElementRectangle.top - tooltipRectangle.height;
        tmpCoordinates.left = hoverElementRectangle.left + (hoverElementRectangle.width / 2) - (tooltipRectangle.width / 2);
        if (tmpCoordinates.left < 0) {
          tmpCoordinates.left = hoverElementRectangle.left;
        }
        setType('top');
      }
      setCoordinates({
        left: `${tmpCoordinates.left}px`,
        top: `${tmpCoordinates.top}px`,
      });
    }
  };

  const showTooltip = (): void => {
    setType('visible');
    computeCoordinates(); // order is important, compute tooltip's coordinates AFTER it is shown and has a width/height
  };

  const hideTooltip = (): void => {
    setType('hidden');
  };

  useEffect(() => {
    if (reactElementRef?.current) {
      reactElementRef.current.addEventListener('mouseenter', showTooltip);
      reactElementRef.current.addEventListener('mouseleave', hideTooltip);
    }
    return (): void => {
      if (reactElementRef?.current) {
        reactElementRef.current.removeEventListener('mouseenter', showTooltip);
        reactElementRef.current.removeEventListener('mouseleave', hideTooltip);
      }
    };
  }, []);


  return (
    <div
      className={`crea-tooltip ${type}`}
      style={coordinates}
      ref={thisRef}
    >
      <div className="arrow" />
      <div className="label">
        <Content {...props as P} />
      </div>
    </div>
  );
};

type ContentProps = {
  children: React.ReactNode;
}

const Tooltip = withTooltip<ContentProps>(({ children }: ContentProps) => <>{children}</>);

export default Tooltip;
