import { useMutation } from '@apollo/client';
import Tutorial from 'components/Tutorial/Tutorial';
import { ProjectListContextProvider } from 'context/ProjectListContext';
import * as ManageGA from 'JSUtils/ReactGA/manage';
import { JOIN_PROJECT_WITH_TOKEN } from 'JSUtils/schema/project';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { errorAlert, stickyInfoAlert, successAlert } from 'Utilities/Alert/Alert';
import Changelog from './components/Changelog/Changelog';
import RecentProjects from './components/RecentProjects/RecentProjects';
import Tips from './components/Tips/Tips';
import './MainDashboard.scss';


function MainDashboardConsumer(): ReactElement {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const currentVersionKey = 'current_version';
  const currentVersionValue = '2020-10-25';
  const currentVersionAsDate = new Date(currentVersionValue);
  const [storedVersionAsDate, setStoredVersionAsDate] = useState(new Date(localStorage.getItem(currentVersionKey) ?? ''));


  const [joinProjectWithToken] = useMutation(JOIN_PROJECT_WITH_TOKEN);

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(storedVersionAsDate.getTime())) {
    // If localStoredVersion is rubbish, reinit it.
      const origin = '1970-01-01';
      localStorage.setItem(currentVersionKey, origin);
      setStoredVersionAsDate(new Date(origin));
    }
  }, []);

  useEffect(() => {
    const invitation = localStorage.getItem('invitation-token');
    const asyncPostInvite = async (): Promise<void> => {
      if (invitation !== null) {
        const toastId = 'joining-project-toast';
        stickyInfoAlert(t('project:common:action.add.invitation_joining'), toastId);
        try {
          localStorage.removeItem('invitation-token');
          try {
            const { data } = await joinProjectWithToken({ variables: { token: invitation } });
            const newProject = data.joinProjectWithToken;
            ManageGA.joinProjectUsingLink();

            const projectLink = `/project/${newProject.id}/dashboard`;
            const locationState: CreaLocationState = {
              pageParams: {},
              popupMessages: [
                new CreaPopupMessage(EPopupMessageType.success, t('project:common:action.add.invitation_success')),
              ],
            };
            history.push({
              pathname: projectLink,
              state: locationState,
            });
            toast.dismiss(toastId);
          } catch (err) {
            console.error(err);
            errorAlert(t('project:common:action.add.invitation_error'));
            toast.dismiss(toastId);
          }
        } catch (err) {
          console.error(err);
        }
      }
    };
    asyncPostInvite();
  }, []);

  const markChangelogAsRead = (): void => {
    localStorage.setItem(currentVersionKey, currentVersionValue);
    setStoredVersionAsDate(new Date(origin));
  };

  return (
    <div className="main-dashboard-content">
      {
        storedVersionAsDate < currentVersionAsDate && (
          <section className="top"><Changelog markChangelogAsRead={markChangelogAsRead} /></section>
        )
      }
      <section className="bottom">
        <RecentProjects />
        <Tips />
      </section>
      <Tutorial
        tutorialType="main_dashboard_page"
        title={t('help_center:main_dashboard.label')}
        callToActionLabel={t('common:form:got_it')}
      >
        <Trans i18nKey="help_center:main_dashboard.tutorial">
          <strong />
        </Trans>
      </Tutorial>
    </div>
  );
}

function MainDashboard(): ReactElement {
  return (
    <ProjectListContextProvider>
      <MainDashboardConsumer />
    </ProjectListContextProvider>
  );
}

export default MainDashboard;
