import { UpdateAnnotationMutationVariables } from 'JSUtils/schema/generated/models';
import ReactGA from 'react-ga';

export const createAnnotation = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Create text annotation',
  });
};

export const updateAnnotation = (v: UpdateAnnotationMutationVariables): void => {
  if (v.x !== undefined || v.y !== undefined) {
    ReactGA.event({
      category: 'Exchange',
      action: 'Update text annotation position',
    });
  }
  if (v.status !== undefined) {
    ReactGA.event({
      category: 'Exchange',
      action: `Update text annotation status to ${v.status}`,
    });
  }
};

export const deleteAnnotation = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Delete text annotation',
  });
};

export const createThreadMessage = (isFirst: boolean): void => {
  if (isFirst) {
    ReactGA.event({
      category: 'Exchange',
      action: 'Create thread message - first',
    });
  } else {
    ReactGA.event({
      category: 'Exchange',
      action: 'Create thread message - reply',
    });
  }
};

export const updateThreadMessage = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Update Thread Message',
  });
};

export const deleteThreadMessage = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Delete thread message',
  });
};

export const addConsultationRequest = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Add consultation request',
  });
};

export const validateConsultationRequest = (): void => {
  ReactGA.event({
    category: 'Exchange',
    action: 'Validate consultation request',
  });
};
