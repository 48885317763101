import { gql } from '@apollo/client';

export const fragments = {
  project: gql`
    fragment ProjectFragment on Project {
      id
      title
      description
      createdAt
      updatedAt
      previewImageUrl
      owner {
        id
        firstName
        lastName
      }
    }
  `,
  document: gql`
    fragment DocumentFragment on Document {
      id
      title
      createdAt
    }
  `,
  proposition: gql`
    fragment PropositionFragment on Proposition {
      id
      title
      approved
      createdAt
      previewImageUrl
    }
  `,
  version: gql`
    fragment VersionFragment on Version {
      id
      title
      drawings
      createdAt
      imageUrl
    }
  `,
  annotation: gql`
    fragment AnnotationFragment on Annotation {
      id
      status
      createdAt
      updatedAt
      x
      y
      idInCanvas
    }
  `,
  threadMessage: gql`
    fragment ThreadMessageFragment on ThreadMessage {
      id
      data
      createdAt
      updatedAt
    }
  `,
  phase: gql`
    fragment PhaseFragment on Phase {
      id
      title
      cursorIndexStart
      cursorIndexEnd
      startAt
      endAt
      status
      createdAt
      updatedAt
    }
  `,
  contract: gql`
    fragment ContractFragment on Contract {
      id
      temporality
      recipient
      rule
      quantificationWhat
      quantificationHowMuch
      createdAt
      updatedAt
    }
  `,
  user: gql`
    fragment UserFragment on User {
      id
      firstName
      lastName
      avatarUrl
    }
  `,
  consultationRequest: gql`
    fragment ConsultationRequestFragment on ConsultationRequest {
      id
      seeker {
        id
        firstName
        lastName
      }
      target {
        id
        firstName
        lastName
      }
      status
      createdAt
    }
  `,
};

export const GET_PROJECTS = gql`
  query GetProjects($filter: ProjectFilter!) {
    projects(filter: $filter) {
      ...ProjectFragment
      unSeenEventsCount
      awaitingActionsCount
    }
  }
  ${fragments.project}
`;

// Query to get the project when arriving on its dashboard or DocumentList
export const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
      contributors {
        id
        firstName
        lastName
      }
      documents {
        ...DocumentFragment
        propositions {
          ...PropositionFragment
          versions {
            id
            title
            consultationRequests {
              ...ConsultationRequestFragment
            }
          }
        }
      }
    }
  }
  ${fragments.project}
  ${fragments.document}
  ${fragments.proposition}
  ${fragments.consultationRequest}
`;

export const GET_WORKFLOW = gql`
  query GetWorkflow($projectId: ID!) {
    project(id: $projectId) {
      phases {
        ...PhaseFragment
        contracts {
          ...ContractFragment
        }
      }
    }
  }
  ${fragments.phase}
  ${fragments.contract}
`;

// Query used on Proposition page
export const GET_PROPOSITION = gql`
  query GetProposition($id: ID!) {
    proposition(id: $id) {
      document {
        title
      }
      ...PropositionFragment
      versions {
        ...VersionFragment
        author {
          ...UserFragment
        }
        consultationRequests {
          ...ConsultationRequestFragment
        }
        annotations {
          ...AnnotationFragment
          author {
            ...UserFragment
          }
          threadMessages {
            ...ThreadMessageFragment
            author {
              ...UserFragment
            }
          }
        }
      }
    }
  }
  ${fragments.proposition}
  ${fragments.version}
  ${fragments.consultationRequest}
  ${fragments.annotation}
  ${fragments.threadMessage}
  ${fragments.user}
`;

export const ADD_PROJECT = gql`
  mutation AddProject($title: String!, $description: String) {
    addProject(title: $title, description: $description) {
      ...ProjectFragment
    }
  }
  ${fragments.project}
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $title: String!, $description: String) {
    updateProject(id: $id, title: $title, description: $description) {
      ...ProjectFragment
      contributors {
        id
        firstName
        lastName
      }
      documents {
        ...DocumentFragment
        propositions {
          ...PropositionFragment
          versions {
            id
          }
        }
      }
    }
  }
  ${fragments.project}
  ${fragments.document}
  ${fragments.proposition}
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;

export const ADD_COLLABORATORS = gql`
  mutation AddCollaborators($projectId: ID!, $collaboratorsEmails: [String!]!) {
    addCollaborators(projectId: $projectId, collaboratorsEmails: $collaboratorsEmails) {
      ...UserFragment
    }
  }
  ${fragments.user}
`;

export const REMOVE_COLLABORATORS = gql`
  mutation RemoveCollaborators($projectId: ID!, $collaboratorsIds: [ID]!) {
    removeCollaborators(projectId: $projectId, collaboratorsIds: $collaboratorsIds) {
      ...UserFragment
    }
  }
  ${fragments.user}
`;

export const ADD_DOCUMENT = gql`
  mutation AddDocument($projectId: ID!, $title: String!) {
    addDocument(projectId: $projectId, title: $title) {
      ...DocumentFragment
      propositions {
        ...PropositionFragment
        versions {
          id
        }
      }
    }
  }
  ${fragments.document}
  ${fragments.proposition}
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;

export const ADD_PROPOSITION = gql`
  mutation AddProposition($documentId: ID!, $title: String!, $approved: Boolean) {
    addProposition(documentId: $documentId, title: $title, approved: $approved) {
      ...PropositionFragment
    }
  }
  ${fragments.proposition}
`;

export const ADD_VERSION = gql`
  mutation AddVersion($propositionId: ID!, $title: String!, $drawing: String, $s3ObjectId: String!) {
    addVersion(propositionId: $propositionId, title: $title, drawing: $drawing, s3ObjectId: $s3ObjectId) {
      ...VersionFragment
      author {
        ...UserFragment
      }
      consultationRequests {
        ...ConsultationRequestFragment
      }
      annotations {
        ...AnnotationFragment
        author {
          ...UserFragment
        }
        threadMessages {
          ...ThreadMessageFragment
          author {
            ...UserFragment
          }
        }
      }
    }
  }
  ${fragments.user}
  ${fragments.version}
  ${fragments.consultationRequest}
  ${fragments.threadMessage}
  ${fragments.annotation}
  ${fragments.threadMessage}
`;

export const UPDATE_VERSION = gql`
  mutation UpdateVersion($id: ID!, $title: String, $drawings: String) {
    updateVersion(id: $id, title: $title, drawings: $drawings) {
      ...VersionFragment
    }
  }
  ${fragments.version}
`;

export const DELETE_VERSION = gql`
  mutation DeleteVersion($id: ID!) {
    deleteVersion(id: $id)
  }
`;

export const ADD_PHASE = gql`
  mutation AddPhase(
    $projectId: ID!
    $title: String!
    $cursorIndexStart: Int
    $cursorIndexEnd: Int
    $startAt: Date!
    $endAt: Date!
    $status: Status!
  ) {
    addPhase(
      projectId: $projectId
      title: $title
      cursorIndexStart: $cursorIndexStart
      cursorIndexEnd: $cursorIndexEnd
      startAt: $startAt
      endAt: $endAt
      status: $status
    ) {
      ...PhaseFragment
      contracts {
        ...ContractFragment
      }
    }
  }
  ${fragments.phase}
  ${fragments.contract}
`;

export const UPDATE_PHASE = gql`
  mutation UpdatePhase(
    $id: ID!
    $title: String
    $cursorIndexStart: Int
    $cursorIndexEnd: Int
    $startAt: Date
    $endAt: Date
    $status: Status
  ) {
    updatePhase(
      id: $id
      title: $title
      cursorIndexStart: $cursorIndexStart
      cursorIndexEnd: $cursorIndexEnd
      startAt: $startAt
      endAt: $endAt
      status: $status
    ) {
      ...PhaseFragment
      contracts {
        ...ContractFragment
      }
    }
  }
  ${fragments.phase}
  ${fragments.contract}
`;

export const DELETE_PHASE = gql`
  mutation DeletePhase($id: ID!) {
    deletePhase(id: $id)
  }
`;

export const ADD_CONTRACT = gql`
  mutation AddContract(
    $phaseId: ID!
    $temporality: ContractTemporality!
    $recipient: ContractRecipient!
    $rule: ContractRule!
    $quantificationWhat: ContractQuantificationWhat!
    $quantificationHowMuch: Int!
  ) {
    addContract(
      phaseId: $phaseId
      temporality: $temporality
      recipient: $recipient
      rule: $rule
      quantificationWhat: $quantificationWhat
      quantificationHowMuch: $quantificationHowMuch
    ) {
      ...ContractFragment
    }
  }
  ${fragments.contract}
`;

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract(
    $id: ID!
    $temporality: ContractTemporality!
    $recipient: ContractRecipient!
    $rule: ContractRule!
    $quantificationWhat: ContractQuantificationWhat!
    $quantificationHowMuch: Int!
  ) {
    updateContract(
      id: $id
      temporality: $temporality
      recipient: $recipient
      rule: $rule
      quantificationWhat: $quantificationWhat
      quantificationHowMuch: $quantificationHowMuch
    ) {
      ...ContractFragment
    }
  }
  ${fragments.contract}
`;

export const DELETE_CONTRACT = gql`
  mutation DeleteContract($id: ID!) {
    deleteContract(id: $id)
  }
`;

export const ADD_CONSULTATION_REQUEST = gql`
  mutation AddConsultationRequest($targetId: ID!, $versionId: ID!) {
    addConsultationRequest(targetId: $targetId, versionId: $versionId) {
      ...ConsultationRequestFragment
    }
  }
  ${fragments.consultationRequest}
`;

export const UPDATE_CONSULTATION_REQUEST = gql`
  mutation UpdateConsultationRequest($id: ID!, $status: ConsultationRequestStatus!) {
    updateConsultationRequest(id: $id, status: $status) {
      ...ConsultationRequestFragment
    }
  }
  ${fragments.consultationRequest}
`;

export const DELETE_CONSULTATION_REQUEST = gql`
  mutation DeleteConsultationRequest($id: ID!) {
    deleteConsultationRequest(id: $id)
  }
`;

export const ADD_ANNOTATION = gql`
  mutation AddAnnotation($versionId: ID!, $x: Float!, $y: Float!, $status: Status!) {
    addAnnotation(versionId: $versionId, x: $x, y: $y, status: $status) {
      ...AnnotationFragment
      author {
        ...UserFragment
      }
      threadMessages {
        ...ThreadMessageFragment
      }
    }
  }
  ${fragments.annotation}
  ${fragments.user}
  ${fragments.threadMessage}
`;

export const UPDATE_ANNOTATION = gql`
  mutation UpdateAnnotation($id: ID!, $x: Float, $y: Float, $status: Status) {
    updateAnnotation(id: $id, x: $x, y: $y, status: $status) {
      ...AnnotationFragment
    }
  }
  ${fragments.annotation}
`;

export const DELETE_ANNOTATION = gql`
  mutation DeleteAnnotation($id: ID!) {
    deleteAnnotation(id: $id)
  }
`;

export const ADD_THREAD_MESSAGE = gql`
  mutation AddThreadMessage($annotationId: ID!, $data: String!) {
    addThreadMessage(annotationId: $annotationId, data: $data) {
      ...ThreadMessageFragment
      author {
        ...UserFragment
      }
    }
  }
  ${fragments.threadMessage}
  ${fragments.user}
`;

export const UPDATE_THREAD_MESSAGE = gql`
  mutation UpdateThreadMessage($id: ID!, $data: String) {
    updateThreadMessage(id: $id, data: $data) {
      ...ThreadMessageFragment
      author {
        ...UserFragment
      }
    }
  }
  ${fragments.threadMessage}
  ${fragments.user}
`;

export const DELETE_THREAD_MESSAGE = gql`
  mutation DeleteThreadMessage($id: ID!) {
    deleteThreadMessage(id: $id)
  }
`;

export const JOIN_PROJECT_WITH_TOKEN = gql`
  mutation JoinProjectWithToken($token: String!) {
    joinProjectWithToken(token: $token) {
      ...ProjectFragment
    }
  }
  ${fragments.project}
`;

export const CREATE_PROJECT_TOKEN = gql`
  mutation CreateProjectToken($id: ID!) {
    createProjectToken(id: $id)
  }
`;

export const GENEREATE_DOWNLOAD_TOKEN = gql`
  mutation GenerateDownloadToken($id: ID!) {
    generateDownloadToken(id: $id)
  }
`;
