import { gql } from '@apollo/client';

// Not using fragment because the lib doesn't fetch EventBase on child class
// Put id in each event instead of inside base event because of codegen bug
const baseEvent = `
  createdAt
  type
  projectId
  unSeen
  author {
    firstName
    lastName
  }
`;

const documentFragment = `
  document {
    id
    title
  }
`;

const propositionFragment = `
  proposition {
    id
    title
    ${documentFragment}
  }
`;

const versionFragment = `
  version {
    id
    title
    ${propositionFragment}
  }
`;

const annotationFragment = `
  annotation {
    id
    idInCanvas
    ${versionFragment}
  }
`;

const threadMessageFragment = `
  threadMessage {
    ${annotationFragment}
  }
`;

const phaseFragment = `
  phase {
    id
    title
  }
`;

const contractFragment = `
  contract {
    ${phaseFragment}
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($filter: EventFilter!, $pagination: EventPagination!) {
    events(filter: $filter, pagination: $pagination) {
      count
      events {
        ... on THREAD_MESSAGE_ANSWER {
          id
          ${baseEvent}
          ${threadMessageFragment}
        }
        ... on THREAD_MESSAGE_NEW {
          id
          ${baseEvent}
          ${threadMessageFragment}
        }
        ... on THREAD_MESSAGE_ANSWER {
          id
          ${baseEvent}
          ${threadMessageFragment}
        }
        ... on THREAD_MESSAGE_UPDATE {
          id
          ${baseEvent}
          ${threadMessageFragment}
        }
        ... on THREAD_MESSAGE_DELETE {
          id
          ${baseEvent}
          annotationDisplayId
        }
        ... on ANNOTATION_NEW {
          id
          displayedId
          ${baseEvent}
          ${annotationFragment}
        }
        ... on ANNOTATION_DUPLICATE {
          id
          ${baseEvent}
          ${annotationFragment}
        }
        ... on ANNOTATION_UPDATE_STATUS {
          id
          ${baseEvent}
          ${annotationFragment}
          status
          oldStatus
        }
        ... on ANNOTATION_UPDATE_POSITION {
          id
          ${baseEvent}
          ${annotationFragment}
        }
        ... on ANNOTATION_DELETE {
          id
          ${baseEvent}
          displayId
        }
        ... on VERSION_NEW {
          id
          title
          ${baseEvent}
          ${versionFragment}
        }
        ... on VERSION_UPDATE_TITLE {
          id
          ${baseEvent}
          ${versionFragment}
        }
        ... on VERSION_UPDATE_DRAWINGS {
          id
          ${baseEvent}
          ${versionFragment}
        }
        ... on VERSION_DELETE {
          id
          ${baseEvent}
          title
        }
        ... on PROPOSITION_NEW {
          id
          title
          ${baseEvent}
          ${propositionFragment}
        }
        ... on PROPOSITION_UPDATE_TITLE {
          id
          ${baseEvent}
          ${propositionFragment}
        }
        ... on PROPOSITION_DELETE {
          id
          ${baseEvent}
          title
        }
        ... on DOCUMENT_NEW {
          id
          title
          ${baseEvent}
          ${documentFragment}
        }
        ... on DOCUMENT_UPDATE_TITLE {
          id
          ${baseEvent}
          ${documentFragment}
        }
        ... on DOCUMENT_DELETE {
          id
          ${baseEvent}
          title
        }
        ... on PROJECT_NEW {
          id
          ${baseEvent}
        }
        ... on PROJECT_UPDATE_TITLE {
          id
          ${baseEvent}
        }
        ... on PROJECT_UPDATE_DESCRIPTION {
          id
          ${baseEvent}
        }
        ... on COLLAB_NEW {
          id
          ${baseEvent}
          targetUser {
            firstName
            lastName
          }
        }
        ... on COLLAB_JOIN {
          id
          ${baseEvent}
          tokenCreator {
            firstName
            lastName
          }
        }
        ... on COLLAB_DELETE {
          id
          ${baseEvent}
          targetUser {
            firstName
            lastName
          }
        }
        ... on REVIEW_ASKED {
          id
          ${baseEvent}
          ${versionFragment}
          targetUser {
            firstName
            lastName
          }
        }
        ... on REVIEW_FINISHED {
          id
          ${baseEvent}
          ${versionFragment}
        }
        ... on CONTRACT_NEW {
          id
          ${baseEvent}
          ${contractFragment}
        }
        ... on CONTRACT_UPDATE {
          id
          ${baseEvent}
          ${contractFragment}
        }
        ... on CONTRACT_DELETE {
          id
          ${baseEvent}
        }
        ... on PHASE_NEW {
          id
          title
          ${baseEvent}
          ${phaseFragment}
        }
        ... on PHASE_UPDATE_TITLE {
          id
          ${baseEvent}
          ${phaseFragment}
        }
        ... on PHASE_UPDATE_DATE {
          id
          ${baseEvent}
          ${phaseFragment}
        }
        ... on PHASE_UPDATE_STATUS {
          id
          ${baseEvent}
          ${phaseFragment}
          status
          oldStatus
        }
        ... on PHASE_DELETE {
          id
          ${baseEvent}
          title
        }
      }
    }
  }
`;

export const MARK_EVENTS_AS_SEEN = gql`
  mutation MarkEventsAsSeen($eventIds: [ID!]!) {
    markEventsAsSeen(eventIds: $eventIds)
  }
`;

export const MARK_ANNOTATION_EVENTS_AS_SEEN = gql`
  mutation MarkAnnotationEventsAsSeen($annotationId: ID!) {
    markAnnotationEventsAsSeen(annotationId: $annotationId)
  }
`;

export const MARK_VERSION_EVENTS_AS_SEEN = gql`
  mutation MarkVersionEventsAsSeen($versionId: ID!) {
    markVersionEventsAsSeen(versionId: $versionId)
  }
`;

export const MARK_DOCUMENTS_EVENTS_AS_SEEN = gql`
  mutation MarkDocumentsEventsAsSeen($projectId: ID!) {
    markDocumentsEventsAsSeen(projectId: $projectId)
  }
`;

export const MARK_WORKFLOW_EVENTS_AS_SEEN = gql`
  mutation MarkWorkflowEventsAsSeen($projectId: ID!) {
    markWorkflowEventsAsSeen(projectId: $projectId)
  }
`;

export const MARK_PROJECT_EVENTS_AS_SEEN = gql`
  mutation MarkProjectEventsAsSeen($projectId: ID!) {
    markProjectEventsAsSeen(projectId: $projectId)
  }
`;
