import { usePropositionContext } from 'context/PropositionContext';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Historic from './components/Historic/Historic';
import Toolbox from './components/Toolbox/Toolbox';
import './Sidebar.scss';

const Sidebar = (): ReactElement => {
  const { toolboxVisibility, setToolboxVisibility, historyVisibility, setHistoryVisibility } = usePropositionContext();
  const { t } = useTranslation();

  useEffect(() => {
    setHistoryVisibility(true);
  }, []);

  return (
    <div className="sidebar-container">
      <ul className="sidebar-header">
        <li
          className={`tab-title ${toolboxVisibility ? 'active' : ''}`}
          onClick={(): void => setToolboxVisibility(true)}
        >
          <h5>{t('project:proposition:sidebar.toolbox.tab_title')}</h5>
        </li>
        <li
          className={`tab-title ${historyVisibility ? 'active' : ''}`}
          onClick={(): void => setHistoryVisibility(true)}
        >
          <h5>{t('project:proposition:sidebar.version_history.tab_title')}</h5>
        </li>
      </ul>
      <div className="sidebar-content">
        <Toolbox isMobile={false} />
        <Historic isMobile={false} />
      </div>
    </div>
  );
};

export default Sidebar;
