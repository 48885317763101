import React, { ReactElement } from 'react';
import X from 'react-feather/dist/icons/x';
import Overlay from '../Overlay';
import './Modal.scss';

type ModalProps = {
  children: React.ReactNode;
  toggle: () => void;
  className?: string;
  title?: string;
}

function Modal({ title, children, toggle, className }: ModalProps): ReactElement {
  return (
    <Overlay close={toggle} className={className}>
      <div className="modal-container">
        {title !== null && title !== undefined && (
          <div className="header">
            <h1>{title}</h1>
            <div className="close" onClick={toggle}>
              <X />
            </div>
          </div>
        )}
        <div className="content">{children}</div>
      </div>
    </Overlay>
  );
}

export default Modal;
