import {
  ProjectArchitecture,
  ProjectCollaborators,
  PropositionPageAnnotations,
  PropositionPageHistory,
  PropositionPageRequestReview,
  Workflow,
} from 'components/HelpCenter/HelpCenter';
import 'components/HelpCenter/HelpCenter.scss';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './Tips.scss';

function GetRandomTips(): ReactElement {
  const availableTips = [
    ProjectArchitecture,
    ProjectCollaborators,
    PropositionPageAnnotations,
    PropositionPageHistory,
    PropositionPageRequestReview,
    Workflow,
  ];

  const random = Math.floor(Math.random() * availableTips.length) + 0;
  const RandomTips = availableTips[random];
  return <RandomTips />;
}

export default function Tips(): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="widget-container tips">
      <h1 className="widget-title">{t('main_dashboard:widget.tips.title')}</h1>
      <div className="section tips-container">
        <GetRandomTips />
      </div>
    </div>
  );
}
