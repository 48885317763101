import { useApolloClient } from '@apollo/client';
import ppPlaceholder from 'assets/pp-placeholder.png';
import { useUserContext } from 'context/UserContext';
import * as UserGA from 'JSUtils/ReactGA/user';
import React, { ReactElement, useState } from 'react';
import { FacebookProvider, Initialize } from 'react-facebook';
import { useGoogleLogout } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Overlay from 'Utilities/Overlay/Overlay';
import './UserActionsOverlay.scss';

function UserActionsOverlay(): ReactElement {
  const { user } = useUserContext();
  const client = useApolloClient();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [fbAPI, setFbAPI] = useState<any>();
  const history = useHistory();

  const { signOut: googleSignOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
  });

  const thisLogout = async (): Promise<void> => {
    try {
      if (fbAPI !== undefined) {
        const status = await fbAPI.getLoginStatus();
        if (status.status === 'connected') await fbAPI.logout();
      }
    } catch (err) {
      console.warn('Failed to logout from fb');
      console.warn(err);
    }
    try {
      googleSignOut();
    } catch (err) {
      console.warn('Failed to logout from google');
      console.warn(err);
    }
    localStorage.setItem('TPAutoConnect', 'false');
    localStorage.removeItem('user.token');
    localStorage.setItem('user.authenticated', 'false');
    await client?.clearStore(); // https://www.apollographql.com/docs/react/v3.0-beta/api/core/ApolloClient/#ApolloClient.clearStore
    UserGA.signOut();
    history.push('/');
  };

  return (
    <>
      <FacebookProvider appId={FACEBOOK_APP_ID}>
        <Initialize>
          {({ isReady, api }): ReactElement => {
            if (isReady) setFbAPI(api);
            return <></>;
          }}
        </Initialize>
      </FacebookProvider>
      <img
        className="interaction pp_placeholder"
        src={user.avatarUrl === '' ? ppPlaceholder : user.avatarUrl}
        alt="user-avatar"
        onClick={(): void => setIsOpen(true)}
      />
      {isOpen && (
        <Overlay close={(): void => setIsOpen(false)} className="user-action-overlay-container">
          <div className="userName">
            <p>{`${user.firstName} ${user.lastName}`}</p>
          </div>
          <div className="email">
            <p>{user?.email?.substring(0, 30)}</p>
          </div>
          <hr />

          <div className="actions">
            <Link className="link" to={`/user/${user.id}/profile`}>
              {t('nav:user_links.profile')}
            </Link>
            <Link className="link" to={`/user/${user.id}/account`}>
              {t('nav:user_links.account')}
            </Link>
            <Link className="link" to="/" onClick={thisLogout}>
              {t('nav:user_links.logout')}
            </Link>
          </div>
        </Overlay>
      )}
    </>
  );
}

export default UserActionsOverlay;
