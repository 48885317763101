import FBLogo from 'assets/third_party_logo/fb.png';
import { useUserContext } from 'context/UserContext';
import React, { ReactElement, useState } from 'react';
import { FacebookProvider, Initialize } from 'react-facebook';
import './ThirdPartyAuthProvider.scss';

function FacebookLogin(): ReactElement {
  const { facebookLogin } = useUserContext();
  const [reRequest, setRerequest] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [fbAPI, setFbAPI] = useState<any>();
  const login = (tokenDetail): void => {
    const { accessToken: at } = tokenDetail;
    if (!at || at === accessToken) return;
    setAccessToken(at);
    facebookLogin(at).catch((err) => {
      console.error(err);
      setRerequest(true);
    });
  };

  const handleAutoConnect = async (api): Promise<void> => {
    if (localStorage.getItem('TPAutoConnect') !== 'true') return;
    const status = await api.getLoginStatus();
    try {
      if (status.status === 'connected') login(status.authResponse);
    } catch (error) {
      console.warn('Failed to auto reconnect to Facebook');
      console.warn(error);
      setRerequest(true);
    }
  };

  const callFbLogin = (): void => {
    const authType = reRequest ? 'rerequest' : '';
    fbAPI
      // eslint-disable-next-line @typescript-eslint/camelcase
      .login({ scope: 'email', auth_type: authType })
      .then((response) => {
        if (response.status === 'connected') login(response.authResponse);
      })
      .catch((err) => {
        console.warn('Failed to connect to Facebook');
        console.warn(err);
        setRerequest(true);
      });
  };

  return (
    <FacebookProvider appId={FACEBOOK_APP_ID}>
      <div className="facebook-button third-party-auth-provider" onClick={callFbLogin}>
        <img src={FBLogo} alt="FB Logo" draggable={false} />
      </div>
      <Initialize>
        {({ isReady, api }): ReactElement => {
          if (isReady) {
            setFbAPI(api);
            handleAutoConnect(api);
          }
          return <></>;
        }}
      </Initialize>
    </FacebookProvider>
  );
}

export default FacebookLogin;
