/* eslint-disable @typescript-eslint/no-empty-function */
import { useMutation, useQuery } from '@apollo/client';
import * as ExchangeGA from 'JSUtils/ReactGA/exchange';
import * as ManageGA from 'JSUtils/ReactGA/manage';
import {
  ADD_ANNOTATION,
  ADD_CONSULTATION_REQUEST,
  ADD_THREAD_MESSAGE,
  ADD_VERSION,
  DELETE_ANNOTATION,
  DELETE_THREAD_MESSAGE,
  DELETE_VERSION,
  GET_PROPOSITION,
  UPDATE_ANNOTATION,
  UPDATE_CONSULTATION_REQUEST,
  UPDATE_THREAD_MESSAGE,
  UPDATE_VERSION,
} from 'JSUtils/schema/project';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Annotation,
  ConsultationRequest,
  DeleteVersionMutationVariables,
  Proposition,
  Status,
  ThreadMessage,
  UpdateAnnotationMutationVariables,
  UpdateConsultationRequestMutationVariables,
  UpdateThreadMessageMutationVariables,
  UpdateVersionMutationVariables,
  User,
  Version,
} from 'schema/generated/models';
import createCtx from './createContextHelper';
import { useProjectContext } from './ProjectContext';
import { useUserContext } from './UserContext';

export type PropositionContextType = {
  proposition: Proposition;
  version: Version;
  addVersion: Function;
  setVersion: React.Dispatch<React.SetStateAction<Version>>;
  updateVersion: (u: UpdateVersionMutationVariables) => Promise<Version>;
  deleteVersion: (d: DeleteVersionMutationVariables) => Promise<boolean>;
  addConsultationRequest: Function;
  updateConsultationRequest: (u: UpdateConsultationRequestMutationVariables) => Promise<ConsultationRequest>;
  annotation: Annotation;
  isLoading: boolean;
  setAnnotation: Function;
  createTextAnnotation: boolean;
  setCreateTextAnnotation: React.Dispatch<React.SetStateAction<boolean>>;
  pencilEnabled: boolean;
  setPencilEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  pencilColor: string;
  setPencilColor: React.Dispatch<React.SetStateAction<string>>;
  pencilSize: number;
  setPencilSize: React.Dispatch<React.SetStateAction<number>>;
  reviewMode: boolean;
  setReviewMode: React.Dispatch<React.SetStateAction<boolean>>;
  canvasWidth: number;
  setCanvasWidth: React.Dispatch<React.SetStateAction<number>>;
  canvasHeight: number;
  setCanvasHeight: React.Dispatch<React.SetStateAction<number>>;
  getUserDrawings: () => string;
  setGetUserDrawings: React.Dispatch<React.SetStateAction<() => string>>;
  undoDrawings: () => void;
  setUndoDrawings: React.Dispatch<React.SetStateAction<() => void>>;
  redoDrawings: () => void;
  setRedoDrawings: React.Dispatch<React.SetStateAction<() => void>>;
  resetZoom: Function | null;
  setResetZoom: React.Dispatch<React.SetStateAction<() => void>>;
  updateDrawings: (drawings: string) => Promise<void>;
  getWindowPosition: Function | null;
  setGetWindowPosition: React.Dispatch<React.SetStateAction<Function | null>>;
  getImagePosition: Function | null;
  setGetImagePosition: React.Dispatch<React.SetStateAction<Function | null>>;
  getCanvasSize: Function | null;
  setGetCanvasSize: React.Dispatch<React.SetStateAction<Function | null>>;
  observeAnnotationsPositions: number;
  updateAnnotationsPositions: () => void;
  addAnnotation: (x: number, y: number) => Promise<Annotation>;
  updateAnnotation: (variables: UpdateAnnotationMutationVariables) => Promise<Annotation>;
  deleteAnnotation: (id: string) => Promise<boolean>;
  addThreadMessage: (data: string) => Promise<ThreadMessage>;
  updateThreadMessage: (variables: UpdateThreadMessageMutationVariables) => Promise<ThreadMessage>;
  deleteThreadMessage: (id: string) => Promise<boolean>;
  getUser: (id: string) => User | undefined;
  drawingNeedSaving: boolean;
  setDrawingNeedSaving: React.Dispatch<React.SetStateAction<boolean>>;
  toolboxVisibility: boolean;
  setToolboxVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  historyVisibility: boolean;
  setHistoryVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  mobiletoolboxVisibility: boolean;
  setMobiletoolboxVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  mobileHistoryVisibility: boolean;
  setMobileHistoryVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const [usePropositionContext, CtxProvider] = createCtx<PropositionContextType>();

type PropositionContextProviderProps = {
  children: React.ReactNode;
  currentPropositionId: string;
  currentVersionId: string | undefined;
  currentAnnotationId: string;
};

const PropositionContextProvider = (props: PropositionContextProviderProps): ReactElement => {
  const { children, currentPropositionId, currentVersionId, currentAnnotationId } = props;
  const { project } = useProjectContext();
  const { user } = useUserContext();

  // Use State to keep the values
  const [createTextAnnotation, setCreateTextAnnotation] = useState(false);
  const [pencilEnabled, setPencilEnabled] = useState(false);
  const [pencilColor, setPencilColor] = useState('#000');
  const [pencilSize, setPencilSize] = useState(6);
  const [reviewMode, setReviewMode] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [drawingNeedSaving, setDrawingNeedSaving] = useState(false);

  // functions to use canvasDraw
  // useState(function) call the function instead of setting the variable to this function
  // useState([function]) set the variable to an array of 1 function
  const [getUserDrawings, setGetUserDrawings] = useState<() => string>(() => '');
  const [undoDrawings, setUndoDrawings] = useState<() => void>(() => {});
  const [redoDrawings, setRedoDrawings] = useState<() => void>(() => {});
  const [resetZoom, setResetZoom] = useState<Function | null>(() => (): any => null);
  const [getWindowPosition, setGetWindowPosition] = useState<Function | null>(() => (): any => ({
    x: 0,
    y: 0,
    visibility: true,
  }));
  const [getImagePosition, setGetImagePosition] = useState<(() => void) | null>(null);
  const [getCanvasSize, setGetCanvasSize] = useState<(() => void) | null>(null);

  const [observeAnnotationsPositions, setObserveAnnotationsPositions] = useState(0);

  const [annotation, _setAnnotation] = useState({} as Annotation);
  const [openAnnotationTimestamp, setOpenAnnotationTimestamp] = useState(0);

  const [proposition, setProposition] = useState<Proposition>({} as Proposition);
  const [version, setVersion] = useState<Version>({} as Version);
  const [isLoading, setIsLoading] = useState(true);

  // should be in proposition context
  const [toolboxVisibility, setToolboxVisibility] = useState(false);
  const [historyVisibility, setHistoryVisibility] = useState(false);
  const [mobiletoolboxVisibility, setMobiletoolboxVisibility] = useState(false);
  const [mobileHistoryVisibility, setMobileHistoryVisibility] = useState(false);

  useEffect(() => {
    if (toolboxVisibility) setHistoryVisibility(false);
  }, [toolboxVisibility]);
  useEffect(() => {
    if (historyVisibility) setToolboxVisibility(false);
  }, [historyVisibility]);

  const { refetch: refetchProposition } = useQuery(GET_PROPOSITION, {
    variables: { id: currentPropositionId },
    onCompleted: (data) => {
      if (data.proposition) {
        setProposition(data.proposition as Proposition);
        const computedCurrentVersionId = currentVersionId ?? Math.max(...data.proposition.versions.map((v: Version) => parseInt(v.id, 10))).toString();
        const currentVersion = data.proposition.versions.find((v: Version) => v.id === computedCurrentVersionId);
        if (!currentVersion) {
          window.location.replace('/404');
        }
        setVersion(currentVersion);
        if (currentAnnotationId) {
          const currentAnnotation = currentVersion.annotations.find((a: Annotation) => a.id === currentAnnotationId);
          _setAnnotation(currentAnnotation);
        }
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    refetchProposition();
  }, []);

  const [addVersion] = useMutation(ADD_VERSION, {
    onCompleted: (data) => {
      ManageGA.createVersion();
      setProposition({
        ...proposition,
        versions: [...proposition.versions, data.addVersion],
      });
      setVersion(data.addVersion);
    },
  });

  const updateContextVersion = (updatedVersion: Version): void => {
    const updatedVersions = proposition.versions.map((v: Version) => (v.id === updatedVersion.id ? updatedVersion : v));
    setProposition({
      ...proposition,
      versions: updatedVersions,
    });
    setVersion(updatedVersion);
  };

  const [_updateVersion] = useMutation(UPDATE_VERSION);
  const updateVersion = async (updateVersionMutationVariables: UpdateVersionMutationVariables): Promise<Version> => {
    const {
      data: { updateVersion: updatedVersion },
    } = await _updateVersion({ variables: updateVersionMutationVariables });
    ManageGA.updateVersion(updateVersionMutationVariables);

    updateContextVersion({ ...version, ...updatedVersion });
    return updatedVersion;
  };

  const [_deleteVersion] = useMutation(DELETE_VERSION);
  const deleteVersion = async (deleteVersionMutationVariables: DeleteVersionMutationVariables): Promise<boolean> => {
    const {
      data: { deleteVersion: deleteVersionResult },
    } = await _deleteVersion({ variables: deleteVersionMutationVariables });
    ManageGA.deleteVersion();

    const updatedVersions = [...proposition.versions];
    const indexOfDeletedVersion = proposition.versions.findIndex(
      (v: Version) => v.id === deleteVersionMutationVariables.id,
    );
    updatedVersions.splice(indexOfDeletedVersion, 1);
    setProposition({
      ...proposition,
      versions: updatedVersions,
    });
    const newMaxVersionId = Math.max(...updatedVersions.map((v: Version) => parseInt(v.id, 10))).toString();
    const newCurrentVersion = updatedVersions.find((v: Version) => v.id === newMaxVersionId);
    setVersion(newCurrentVersion ?? ({} as Version));
    return deleteVersionResult;
  };

  const [_addAnnotation] = useMutation(ADD_ANNOTATION);
  const addAnnotation = async (x: number, y: number): Promise<Annotation> => {
    const {
      data: { addAnnotation: addedAnnotation },
    } = await _addAnnotation({ variables: { versionId: version.id, x, y, status: Status.Todo } });
    ExchangeGA.createAnnotation();

    const updatedVersion = { ...version, annotations: [...version.annotations, addedAnnotation] };

    updateContextVersion(updatedVersion);
    return addedAnnotation;
  };

  const myUpdateAnnotation = (updatedAnnotation: Annotation): void => {
    const annotUpdate = { ...version.annotations.find((a) => a?.id === updatedAnnotation.id), ...updatedAnnotation };
    const updatedVersion = {
      ...version,
      annotations: version.annotations.map((a) => (a?.id === updatedAnnotation.id ? annotUpdate : a)),
    };
    updateContextVersion(updatedVersion);
    // Compare id because sometime annotation = {} ?? so when moving annotation for the first time opened the annotation ...
    if (annotation && annotation.id === updatedAnnotation.id) _setAnnotation(annotUpdate);
  };

  const [_updateAnnotation] = useMutation(UPDATE_ANNOTATION);
  const updateAnnotation = async (
    updateAnnotationMutationVariables: UpdateAnnotationMutationVariables,
  ): Promise<Annotation> => {
    const preUpdateAnnotation = version.annotations.find((a) => a?.id === updateAnnotationMutationVariables.id);
    if (!preUpdateAnnotation) throw new Error('Annotation not found');
    const heuristicAnnotation = { ...preUpdateAnnotation, ...updateAnnotationMutationVariables } as Annotation;
    myUpdateAnnotation(heuristicAnnotation);

    try {
      const {
        data: { updateAnnotation: updatedAnnotation },
      } = await _updateAnnotation({ variables: updateAnnotationMutationVariables });
      ExchangeGA.updateAnnotation(updateAnnotationMutationVariables);

      myUpdateAnnotation(updatedAnnotation);
      return updatedAnnotation;
    } catch (err) {
      myUpdateAnnotation(preUpdateAnnotation);
      throw err;
    }
  };

  const [_deleteAnnotation] = useMutation(DELETE_ANNOTATION);
  const deleteAnnotation = async (id: string): Promise<boolean> => {
    const {
      data: { deleteAnnotation: deleteAnnotationResult },
    } = await _deleteAnnotation({ variables: { id } });
    ExchangeGA.deleteAnnotation();

    const updatedVersion = {
      ...version,
      annotations: version.annotations.filter((a) => a?.id !== id),
    };

    updateContextVersion(updatedVersion);
    return deleteAnnotationResult;
  };

  const updateContextAnnotation = (updatedAnnotation: Annotation): void => {
    const updatedVersion = {
      ...version,
      annotations: version.annotations.map((a) => (a?.id === updatedAnnotation.id ? updatedAnnotation : a)),
    };
    updateContextVersion(updatedVersion);
    _setAnnotation(updatedAnnotation);
  };

  const [_addThreadMessage] = useMutation(ADD_THREAD_MESSAGE);
  const addThreadMessage = async (data: string): Promise<ThreadMessage> => {
    const {
      data: { addThreadMessage: addedThreadMessage },
    } = await _addThreadMessage({ variables: { annotationId: annotation.id, data } });
    ExchangeGA.createThreadMessage(annotation.threadMessages.length === 0);

    const updatedAnnotation = {
      ...annotation,
      threadMessages: [...annotation.threadMessages, addedThreadMessage],
      updatedAt: moment().toISOString() as any, // updatedAt is defined as a Date but is a string ?
    };

    updateContextAnnotation(updatedAnnotation);
    return addedThreadMessage;
  };

  const [_updateThreadMessage] = useMutation(UPDATE_THREAD_MESSAGE);
  const updateThreadMessage = async (
    updateThreadMessageMutationVariables: UpdateThreadMessageMutationVariables,
  ): Promise<ThreadMessage> => {
    const {
      data: { updateThreadMessage: updatedThreadMessage },
    } = await _updateThreadMessage({ variables: updateThreadMessageMutationVariables });
    ExchangeGA.updateThreadMessage();

    const updatedAnnotation = {
      ...annotation,
      threadMessages: annotation.threadMessages.map((tm) =>
        (tm?.id === updatedThreadMessage.id ? updatedThreadMessage : tm)),
    };

    updateContextAnnotation(updatedAnnotation);
    return updatedThreadMessage;
  };

  const [_deleteThreadMessage] = useMutation(DELETE_THREAD_MESSAGE);
  const deleteThreadMessage = async (id: string): Promise<boolean> => {
    const {
      data: { deleteThreadMessage: deleteThreadMessageResult },
    } = await _deleteThreadMessage({ variables: { id } });
    ExchangeGA.deleteThreadMessage();

    const updatedAnnotation = {
      ...annotation,
      threadMessages: annotation.threadMessages.filter((tm) => tm?.id !== id),
    };

    updateContextAnnotation(updatedAnnotation);
    return deleteThreadMessageResult;
  };

  const [addConsultationRequest] = useMutation(ADD_CONSULTATION_REQUEST, {
    onCompleted: (data) => {
      const newCR = data.addConsultationRequest as ConsultationRequest;
      const isCreated = version.consultationRequests.findIndex((cr) => cr?.id === newCR.id) !== -1;
      const updatedCR = isCreated
        ? [newCR, ...version.consultationRequests]
        : version.consultationRequests.map((cr) => (cr?.id === newCR.id ? newCR : cr));
      const updatedVersion = { ...version, consultationRequests: updatedCR };
      ExchangeGA.addConsultationRequest();

      updateContextVersion(updatedVersion);
    },
  });

  const [_updateConsultationRequest] = useMutation(UPDATE_CONSULTATION_REQUEST);
  const updateConsultationRequest = async (
    variables: UpdateConsultationRequestMutationVariables,
  ): Promise<ConsultationRequest> => {
    const { data } = await _updateConsultationRequest({ variables });
    const updatedCR = version.consultationRequests.map((cr) => {
      return cr?.target.id === user.id ? { ...cr, status: variables.status } : cr;
    });
    const updatedVersion = { ...version, consultationRequests: updatedCR };
    ExchangeGA.validateConsultationRequest();

    updateContextVersion(updatedVersion);
    return data.updateConsultationRequest;
  };

  useEffect(() => {
    if (pencilEnabled) {
      setReviewMode(false);
      setCreateTextAnnotation(false);
    }
  }, [pencilEnabled]);

  useEffect(() => {
    if (reviewMode) {
      setPencilEnabled(false);
      setCreateTextAnnotation(false);
    }
  }, [reviewMode]);

  useEffect(() => {
    if (createTextAnnotation) {
      setPencilEnabled(false);
      setReviewMode(false);
    }
  }, [createTextAnnotation]);

  const updateDrawings = async (/* drawings: string */): Promise<void> => {
    // await DrawingAPI.updateDrawings(currentCanvasId, drawings);
    // projectcontext.setDrawings(drawings);
  };

  const setAnnotation = (a: Annotation, timestamp = 0): void => {
    if (timestamp !== 0) {
      if (timestamp - openAnnotationTimestamp > 100) _setAnnotation(a);
    } else _setAnnotation(annotation);
    setOpenAnnotationTimestamp(timestamp);
  };

  const updateAnnotationsPositions = (): void => setObserveAnnotationsPositions(observeAnnotationsPositions + 1);

  const getUser = (id: string): User | undefined => {
    if (project.owner.id === id) {
      return project.owner;
    }
    return project.contributors.find((c) => c?.id === id) ?? undefined;
  };

  useEffect(() => {
    const query = annotation && annotation.id ? `?annotation=${annotation.id}` : '';
    window.history.replaceState(null, '', `${window.location.pathname}${query}`);
  }, [annotation]);

  // Make the context object:
  const propositionContext = {
    proposition,
    version,
    setVersion,
    addVersion,
    updateVersion,
    deleteVersion,
    addConsultationRequest,
    updateConsultationRequest,
    annotation,
    isLoading,
    setAnnotation,
    createTextAnnotation,
    setCreateTextAnnotation,
    pencilEnabled,
    setPencilEnabled,
    pencilColor,
    setPencilColor,
    pencilSize,
    setPencilSize,
    reviewMode,
    setReviewMode,
    canvasWidth,
    setCanvasWidth,
    canvasHeight,
    setCanvasHeight,
    getUserDrawings,
    setGetUserDrawings,
    undoDrawings,
    setUndoDrawings,
    redoDrawings,
    setRedoDrawings,
    resetZoom,
    setResetZoom,
    updateDrawings,
    getWindowPosition,
    setGetWindowPosition,
    getImagePosition,
    setGetImagePosition,
    getCanvasSize,
    setGetCanvasSize,
    observeAnnotationsPositions,
    updateAnnotationsPositions,
    addAnnotation,
    updateAnnotation,
    deleteAnnotation,
    addThreadMessage,
    updateThreadMessage,
    deleteThreadMessage,
    getUser,
    drawingNeedSaving,
    setDrawingNeedSaving,
    toolboxVisibility,
    setToolboxVisibility,
    historyVisibility,
    setHistoryVisibility,
    mobiletoolboxVisibility,
    setMobiletoolboxVisibility,
    mobileHistoryVisibility,
    setMobileHistoryVisibility,
  };

  return <CtxProvider value={propositionContext}>{children}</CtxProvider>;
};

export { usePropositionContext, PropositionContextProvider };
