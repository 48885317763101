/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useMutation } from '@apollo/client';
import { usePropositionContext } from 'context/PropositionContext';
import * as ToolbarGA from 'JSUtils/ReactGA/toolbar';
import { GENEREATE_DOWNLOAD_TOKEN } from 'JSUtils/schema/project';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Download from 'react-feather/dist/icons/download';
import Eye from 'react-feather/dist/icons/eye';
import EyeOff from 'react-feather/dist/icons/eye-off';
import Maximize from 'react-feather/dist/icons/maximize';
import Minimize from 'react-feather/dist/icons/minimize';
import { useTranslation } from 'react-i18next';
import { errorAlert } from 'Utilities/Alert/Alert';
import './CanvasActions.scss';
import ConsultationRequest from './components/ConsultationRequest';

const CanvasAction = (): ReactElement => {
  const { t } = useTranslation();
  const { reviewMode, setReviewMode, resetZoom, version } = usePropositionContext();
  const [downloadToken, setDownloadToken] = useState<string>();
  const downloadRef = useRef<HTMLAnchorElement>(document.createElement('a'));

  const [generateDownloadToken] = useMutation(GENEREATE_DOWNLOAD_TOKEN);
  const getToken = (): void => {
    generateDownloadToken({ variables: { id: version.id } })
      .then(({ data }) => {
        setDownloadToken(data.generateDownloadToken);
      })
      .catch((err) => {
        console.error(err);
        errorAlert(t('project:proposition:canvas_action.download.error'));
      });
    ToolbarGA.downloadVersion();
  };
  useEffect(() => {
    if (downloadToken) {
      downloadRef.current.click();
      setDownloadToken(undefined);
    }
  }, [downloadToken]);

  const downloader = downloadToken ? (
    <a href={`/api/canvas/download/${downloadToken}`} className="toolButton" ref={downloadRef} download />
  ) : null;

  const toggleReviewMode = (): void => {
    if (reviewMode) ToolbarGA.showAnnotations();
    else ToolbarGA.hideAnnotations();
    setReviewMode(!reviewMode);
  };

  return (
    <div className="canvasActions-container">
      <div className="toolButton" onClick={(): void => toggleReviewMode()}>
        {!reviewMode ? (
          <>
            <EyeOff className="icon" />
            {t('project:proposition:canvas_action.revision.label_toggle_off')}
          </>
        ) : (
          <>
            <Eye className="icon" />
            {t('project:proposition:canvas_action.revision.label_toggle_on')}
          </>
        )}
      </div>
      <div className="toolButton">
        <Maximize className="icon" />
        <a
          href={version.imageUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(): void => ToolbarGA.fullscreen()}
        >
          {t('project:proposition:canvas_action.fullscreen.label')}
        </a>
      </div>
      <div
        className="toolButton"
        onClick={(): void => {
          ToolbarGA.resetZoom();
          if (resetZoom) resetZoom();
        }}
      >
        <Minimize className="icon" />
        {t('project:proposition:canvas_action.redefine.label')}
      </div>
      <div className="toolButton" onClick={(): void => getToken()}>
        <Download className="icon" />
        {t('project:proposition:canvas_action.download.label')}
      </div>
      {downloader}
      <ConsultationRequest />
    </div>
  );
};

export default CanvasAction;
