import { usePropositionContext } from 'context/PropositionContext';
import React, { ReactElement, useEffect, useState } from 'react';
import { Annotation } from 'schema/generated/models';
import './AnnotationContainer.scss';
import DraggableAnnotation from './DraggableAnnotation/DraggableAnnotation';
import OpenAnnotation from './OpenAnnotation/OpenAnnotation';

interface AnnotationProps {
  annotation: Annotation;
  currentOpenAnnotation?: Annotation;
}

const AnnotationContainer = ({ annotation, currentOpenAnnotation }: AnnotationProps): ReactElement => {
  const { getWindowPosition, observeAnnotationsPositions } = usePropositionContext();
  const [position, setPosition] = useState(() =>
    (getWindowPosition ? getWindowPosition(annotation.x, annotation.y) : null));

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setPosition(getWindowPosition ? getWindowPosition(annotation.x, annotation.y) : null);
    }
    return (): void => {
      isSubscribed = false;
    };
  }, [observeAnnotationsPositions, annotation.x, annotation.y]);

  if (currentOpenAnnotation?.id === annotation.id) {
    return <OpenAnnotation annotation={annotation} position={position} />;
  }
  if (position?.visibility) {
    return <DraggableAnnotation annotation={annotation} position={position} />;
  }
  return <></>;
};

export default AnnotationContainer;
