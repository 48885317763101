import GoogleLogo from 'assets/third_party_logo/google.png';
import { useUserContext } from 'context/UserContext';
import React, { ReactElement } from 'react';
import { GoogleLogin as GL, GoogleLoginResponse } from 'react-google-login';

function GoogleLogin(): ReactElement {
  const { googleLogin } = useUserContext();
  return (
    <GL
      clientId={GOOGLE_CLIENT_ID}
      render={(renderProps): ReactElement => (
        <div className="google-button third-party-auth-provider" onClick={renderProps.onClick}>
          <img src={GoogleLogo} alt="Google Logo" draggable={false} />
        </div>
      )}
      onSuccess={(res): void => {
        const data = res as GoogleLoginResponse;
        googleLogin(data.tokenId);
      }}
      onFailure={(err): void => {
        console.warn('Google login error');
        console.warn(err);
      }}
      cookiePolicy="single_host_origin"
      isSignedIn={localStorage.getItem('TPAutoConnect') === 'true'}
    />
  );
}

export default GoogleLogin;
