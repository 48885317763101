import gql from 'graphql-tag';
import * as ApolloReactHooks from '@apollo/react-hooks';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
};

export type Annotation = {
  __typename?: 'Annotation';
  id: Scalars['ID'];
  status: Status;
  threadMessages: Array<ThreadMessage>;
  version: Version;
  author?: Maybe<User>;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  x: Scalars['Float'];
  y: Scalars['Float'];
  idInCanvas: Scalars['Int'];
};

export type Annotation_Delete = EventBase & {
  __typename?: 'ANNOTATION_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  annotationId: Scalars['ID'];
  displayId: Scalars['Int'];
};

export type Annotation_Duplicate = EventBase & {
  __typename?: 'ANNOTATION_DUPLICATE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  annotationId: Scalars['ID'];
  parentAnnotationId: Scalars['ID'];
  annotation?: Maybe<Annotation>;
  parentAnnotation?: Maybe<Annotation>;
};

export type Annotation_New = EventBase & {
  __typename?: 'ANNOTATION_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  annotationId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  status: Status;
  annotation?: Maybe<Annotation>;
  displayedId: Scalars['Int'];
};

export type Annotation_Update_Position = EventBase & {
  __typename?: 'ANNOTATION_UPDATE_POSITION';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  annotationId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  annotation?: Maybe<Annotation>;
};

export type Annotation_Update_Status = EventBase & {
  __typename?: 'ANNOTATION_UPDATE_STATUS';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  annotationId: Scalars['ID'];
  status: Status;
  oldStatus: Status;
  annotation?: Maybe<Annotation>;
};

export type Collab_Delete = EventBase & {
  __typename?: 'COLLAB_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  targetUserId: Scalars['ID'];
  targetUser?: Maybe<User>;
};

export type Collab_Join = EventBase & {
  __typename?: 'COLLAB_JOIN';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  tokenCreatedAt: Scalars['Date'];
  tokenCreatorId: Scalars['ID'];
  tokenCreator?: Maybe<User>;
};

export type Collab_New = EventBase & {
  __typename?: 'COLLAB_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  targetUserId: Scalars['ID'];
  targetUser?: Maybe<User>;
};

export type ConsultationRequest = {
  __typename?: 'ConsultationRequest';
  id: Scalars['ID'];
  status: ConsultationRequestStatus;
  version: Version;
  seeker: User;
  target: User;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum ConsultationRequestStatus {
  Waiting = 'WAITING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  temporality?: Maybe<ContractTemporality>;
  recipient?: Maybe<ContractRecipient>;
  rule?: Maybe<ContractRule>;
  quantificationWhat?: Maybe<ContractQuantificationWhat>;
  quantificationHowMuch: Scalars['Int'];
  phase: Phase;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type Contract_Delete = EventBase & {
  __typename?: 'CONTRACT_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  contractId: Scalars['ID'];
};

export type Contract_New = EventBase & {
  __typename?: 'CONTRACT_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  contractId: Scalars['ID'];
  temporality: ContractTemporality;
  recipient: ContractRecipient;
  rule: ContractRule;
  quantificationWhat: ContractQuantificationWhat;
  quantificationHowMuch: Scalars['Int'];
  contract?: Maybe<Contract>;
};

export type Contract_Update = EventBase & {
  __typename?: 'CONTRACT_UPDATE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  contractId: Scalars['ID'];
  temporality: ContractTemporality;
  recipient: ContractRecipient;
  rule: ContractRule;
  quantificationWhat: ContractQuantificationWhat;
  quantificationHowMuch: Scalars['Int'];
  contract?: Maybe<Contract>;
};

export enum ContractQuantificationWhat {
  Version = 'VERSION',
  Annotation = 'ANNOTATION'
}

export enum ContractRecipient {
  Client = 'CLIENT',
  Designer = 'DESIGNER'
}

export enum ContractRule {
  CanUse = 'CAN_USE',
  NeedsToReview = 'NEEDS_TO_REVIEW'
}

export enum ContractTemporality {
  While = 'WHILE',
  AtEnd = 'AT_END'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  culture: UserCulture;
  email: Scalars['String'];
  jobs: Array<Scalars['String']>;
  avatarUrl: Scalars['String'];
  mailingPreferences: Scalars['String'];
};


export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  title: Scalars['String'];
  project: Project;
  propositions: Array<Proposition>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type Document_Delete = EventBase & {
  __typename?: 'DOCUMENT_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  documentId: Scalars['ID'];
  title: Scalars['String'];
};

export type Document_New = EventBase & {
  __typename?: 'DOCUMENT_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  documentId: Scalars['ID'];
  title: Scalars['String'];
  document?: Maybe<Document>;
};

export type Document_Update_Title = EventBase & {
  __typename?: 'DOCUMENT_UPDATE_TITLE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  documentId: Scalars['ID'];
  title: Scalars['String'];
  document?: Maybe<Document>;
};

export type Event = Thread_Message_New | Thread_Message_Answer | Thread_Message_Update | Thread_Message_Delete | Annotation_New | Annotation_Duplicate | Annotation_Update_Status | Annotation_Update_Position | Annotation_Delete | Version_New | Version_Update_Title | Version_Update_Drawings | Version_Delete | Proposition_New | Proposition_Update_Title | Proposition_Delete | Document_New | Document_Update_Title | Document_Delete | Project_New | Project_Update_Title | Project_Update_Description | Collab_New | Collab_Join | Collab_Delete | Review_Asked | Review_Finished | Contract_New | Contract_Update | Contract_Delete | Phase_New | Phase_Update_Title | Phase_Update_Date | Phase_Update_Status | Phase_Delete;

export enum Event_Type {
  ThreadMessageNew = 'THREAD_MESSAGE_NEW',
  ThreadMessageAnswer = 'THREAD_MESSAGE_ANSWER',
  ThreadMessageUpdate = 'THREAD_MESSAGE_UPDATE',
  ThreadMessageDelete = 'THREAD_MESSAGE_DELETE',
  AnnotationNew = 'ANNOTATION_NEW',
  AnnotationDuplicate = 'ANNOTATION_DUPLICATE',
  AnnotationUpdateStatus = 'ANNOTATION_UPDATE_STATUS',
  AnnotationUpdatePosition = 'ANNOTATION_UPDATE_POSITION',
  AnnotationDelete = 'ANNOTATION_DELETE',
  VersionNew = 'VERSION_NEW',
  VersionUpdateTitle = 'VERSION_UPDATE_TITLE',
  VersionUpdateDrawings = 'VERSION_UPDATE_DRAWINGS',
  VersionDelete = 'VERSION_DELETE',
  PropositionNew = 'PROPOSITION_NEW',
  PropositionUpdateTitle = 'PROPOSITION_UPDATE_TITLE',
  PropositionDelete = 'PROPOSITION_DELETE',
  DocumentNew = 'DOCUMENT_NEW',
  DocumentUpdateTitle = 'DOCUMENT_UPDATE_TITLE',
  DocumentDelete = 'DOCUMENT_DELETE',
  ProjectNew = 'PROJECT_NEW',
  ProjectUpdateTitle = 'PROJECT_UPDATE_TITLE',
  ProjectUpdateDescription = 'PROJECT_UPDATE_DESCRIPTION',
  CollabNew = 'COLLAB_NEW',
  CollabJoin = 'COLLAB_JOIN',
  CollabDelete = 'COLLAB_DELETE',
  ReviewAsked = 'REVIEW_ASKED',
  ReviewFinished = 'REVIEW_FINISHED',
  ContractNew = 'CONTRACT_NEW',
  ContractUpdate = 'CONTRACT_UPDATE',
  ContractDelete = 'CONTRACT_DELETE',
  PhaseNew = 'PHASE_NEW',
  PhaseUpdateTitle = 'PHASE_UPDATE_TITLE',
  PhaseUpdateDate = 'PHASE_UPDATE_DATE',
  PhaseUpdateStatus = 'PHASE_UPDATE_STATUS',
  PhaseDelete = 'PHASE_DELETE'
}

export type EventBase = {
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
};

export type EventFilter = {
  /** List of event type you're looking for */
  eventTypes?: Maybe<Array<Event_Type>>;
  /**
   * Search events of a specific project
   * Mandatory if unSeen is set to False
   */
  projectId?: Maybe<Scalars['ID']>;
  /**
   * Get events user has unseen.
   * Default: False
   * Mandatory if projectId is unset
   */
  unSeen?: Maybe<Scalars['Boolean']>;
};

export type EventPagination = {
  /**
   * Skip X first entries
   * Min: 0
   */
  offset?: Maybe<Scalars['Int']>;
  /**
   * Limit replies to X entries
   * Min: 1
   * Max: 1000
   */
  limit?: Maybe<Scalars['Int']>;
};

export type EventResponse = {
  __typename?: 'EventResponse';
  /** Total count (Without pagination) */
  count: Scalars['Int'];
  events: Array<Event>;
};

export type Mutation = {
  __typename?: 'Mutation';
  null?: Maybe<Scalars['Boolean']>;
  /**
   * Generate a Bearer token using user's credentials.
   * This token will be used to authenticate the user in the next requests.
   * Queries and Mutations will use the http header: Authorization.
   * The token expires in 7d.
   * The token payload contains expiration date and user id: {user: {id: number}}
   * For more info: check https://jwt.io/.
   * 
   * Return: The token.
   */
  login: Scalars['String'];
  /**
   * Create a new account and generate a Bearer token.
   * More info about token in login mutation documentation.
   * 
   * Return: The token.
   */
  signUp: Scalars['String'];
  /**
   * PROTECTED
   * 
   * Partial update of the current user (based on the auth token).
   * Unspecified fields will not be updated.
   * 
   * Return: The updated user (every fields, even the one not updated)
   */
  updateUser: CurrentUser;
  /**
   * PROTECTED
   * 
   * Send an email to the user using it's authentication token.
   * The email contain a token to delete his account.
   * 
   * Return: true
   */
  askDeleteUser: Scalars['Boolean'];
  /**
   * Send an email to the user (if it exists) with a token to update his password.
   * 
   * Return: true
   */
  askResetPassword: Scalars['Boolean'];
  /**
   * Delete an account using the token received by email.
   * 
   * Return: true
   */
  deleteUser: Scalars['Boolean'];
  /**
   * Change an account password using the token received by email.
   * 
   * Return: true
   */
  resetPassword: Scalars['String'];
  /**
   * Report a bug or give a feedback.
   * 
   * Return: true
   */
  sendBugReport: Scalars['String'];
  /**
   * Login or register using Facebook accessToken.
   * More info about token in login mutation documentation.
   * 
   * Return: The token
   */
  facebookLogin: Scalars['String'];
  /**
   * Login or register using Google tokenId.
   * More info about token in login mutation documentation.
   * 
   * Return: The token
   */
  googleLogin: Scalars['String'];
  /**
   * PROTECTED
   * 
   * Add a thread message to an annotation
   * 
   * Return: The created thread message
   */
  addThreadMessage: ThreadMessage;
  /**
   * PROTECTED
   * 
   * Partial update of a thread message
   * Unspecified fields will not be updated.
   * 
   * Return: The updated thread message (every fields, even the one not updated)
   */
  updateThreadMessage: ThreadMessage;
  /**
   * PROTECTED
   * 
   * Delete a thread message
   * 
   * Return: true
   */
  deleteThreadMessage: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add an annotation to a version
   * 
   * Return: The created annotation
   */
  addAnnotation: Annotation;
  /**
   * PROTECTED
   * 
   * Partial update of an annotation
   * Unspecified fields will not be updated.
   * 
   * Return: The updated annotation (every fields, even the one not updated)
   */
  updateAnnotation: Annotation;
  /**
   * PROTECTED
   * 
   * Delete an annotation
   * 
   * Return: true
   */
  deleteAnnotation: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add an version to a project
   * 
   * Return: The created version
   */
  addVersion: Version;
  /**
   * PROTECTED
   * 
   * Partial update of an version
   * Unspecified fields will not be updated.
   * 
   * Drawings can be updated by everyone on the project.
   * Title can only by updated by version author.
   * 
   * Return: The updated version (every fields, even the one not updated)
   */
  updateVersion: Version;
  /**
   * PROTECTED
   * 
   * Delete an version
   * 
   * Return: true
   */
  deleteVersion: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Generate a download token
   * 
   * Return: token
   */
  generateDownloadToken: Scalars['String'];
  /**
   * PROTECTED
   * 
   * Add an proposition to a document
   * 
   * Return: The created proposition
   */
  addProposition: Proposition;
  /**
   * PROTECTED
   * 
   * Partial update of an proposition
   * Unspecified fields will not be updated.
   * 
   * Return: The updated proposition (every fields, even the one not updated)
   */
  updateProposition: Proposition;
  /**
   * PROTECTED
   * 
   * Delete an proposition
   * 
   * Return: true
   */
  deleteProposition: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add an document to a project
   * 
   * Return: The created document
   */
  addDocument: Document;
  /**
   * PROTECTED
   * 
   * Partial update of an document
   * Unspecified fields will not be updated.
   * 
   * Return: The updated document (every fields, even the one not updated)
   */
  updateDocument: Document;
  /**
   * PROTECTED
   * 
   * Delete an document
   * 
   * Return: true
   */
  deleteDocument: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add a project to the current user
   * 
   * Return: The created project
   */
  addProject: Project;
  /**
   * PROTECTED
   * 
   * Partial update of an project
   * Unspecified fields will not be updated.
   * 
   * Return: The updated project (every fields, even the one not updated)
   */
  updateProject: Project;
  /**
   * PROTECTED
   * 
   * Delete an project
   * 
   * Return: true
   */
  deleteProject: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add collaborators to project using their emails.
   * 
   * Return: The updated list of collaborators.
   */
  addCollaborators: Array<Maybe<User>>;
  /**
   * PROTECTED
   * 
   * Remove collaborators from project.
   * 
   * Return: The updated list of collaborators.
   */
  removeCollaborators: Array<Maybe<User>>;
  /**
   * PROTECTED
   * 
   * Join a project using a token
   * 
   * Return: The joined project.
   */
  joinProjectWithToken: Project;
  /**
   * PROTECTED
   * 
   * Create a token to join the project
   * 
   * Return: a token.
   */
  createProjectToken: Scalars['String'];
  /**
   * PROTECTED
   * 
   * Add an phase to a project.
   * cursorIndexStart and cursorIndexEnd are a couple define both or none.
   * startAt and endAt are a couple define both or none (For now and until the
   * front end library requires these 2 variables, they are non-nullable!).
   * cursorIndex{start/end} and {start/end}At are incompatible.
   * 
   * Return: The created phase
   */
  addPhase: Phase;
  /**
   * PROTECTED
   * 
   * Partial update of an phase
   * Unspecified fields will not be updated.
   * 
   * Return: The updated phase (every fields, even the one not updated)
   */
  updatePhase: Phase;
  /**
   * PROTECTED
   * 
   * Delete an phase
   * 
   * Return: true
   */
  deletePhase: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add an contract to a phase
   * 
   * Return: The created contract
   */
  addContract: Contract;
  /**
   * PROTECTED
   * 
   * Partial update of an contract
   * Unspecified fields will not be updated.
   * 
   * Return: The updated contract (every fields, even the one not updated)
   */
  updateContract: Contract;
  /**
   * PROTECTED
   * 
   * Delete an contract
   * 
   * Return: true
   */
  deleteContract: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Add a consultationrequest to a version
   * 
   * Return: The created consultationrequest
   */
  addConsultationRequest: ConsultationRequest;
  /**
   * PROTECTED
   * 
   * Partial update of a consultationrequest
   * Unspecified fields will not be updated.
   * 
   * Return: The updated consultationrequest (every fields, even the one not updated)
   */
  updateConsultationRequest: ConsultationRequest;
  /**
   * PROTECTED
   * 
   * Delete a consultationrequest
   * 
   * Return: true
   */
  deleteConsultationRequest: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark events as seen
   */
  markEventsAsSeen: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark thread messages events of annotation as seen
   */
  markAnnotationEventsAsSeen: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark version, review asked and annotations events as seen
   */
  markVersionEventsAsSeen: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark documents and propositions events as seen
   */
  markDocumentsEventsAsSeen: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark phases and contracts events as seen
   */
  markWorkflowEventsAsSeen: Scalars['Boolean'];
  /**
   * PROTECTED
   * 
   * Mark project, review finished, collab and deleted entities events as seen
   */
  markProjectEventsAsSeen: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  culture: UserCulture;
  jobs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateUserArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  culture?: Maybe<UserCulture>;
  jobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  s3ObjectId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mailingPreferences?: Maybe<Scalars['String']>;
};


export type MutationAskResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSendBugReportArgs = {
  message: Scalars['String'];
};


export type MutationFacebookLoginArgs = {
  accessToken: Scalars['String'];
  culture: UserCulture;
};


export type MutationGoogleLoginArgs = {
  tokenId: Scalars['String'];
  culture: UserCulture;
};


export type MutationAddThreadMessageArgs = {
  annotationId: Scalars['ID'];
  data: Scalars['String'];
};


export type MutationUpdateThreadMessageArgs = {
  id: Scalars['ID'];
  data?: Maybe<Scalars['String']>;
};


export type MutationDeleteThreadMessageArgs = {
  id: Scalars['ID'];
};


export type MutationAddAnnotationArgs = {
  versionId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  status: Status;
};


export type MutationUpdateAnnotationArgs = {
  id: Scalars['ID'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  status?: Maybe<Status>;
};


export type MutationDeleteAnnotationArgs = {
  id: Scalars['ID'];
};


export type MutationAddVersionArgs = {
  propositionId: Scalars['ID'];
  title: Scalars['String'];
  drawing?: Maybe<Scalars['String']>;
  s3ObjectId: Scalars['String'];
};


export type MutationUpdateVersionArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  drawings?: Maybe<Scalars['String']>;
};


export type MutationDeleteVersionArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateDownloadTokenArgs = {
  id: Scalars['ID'];
};


export type MutationAddPropositionArgs = {
  documentId: Scalars['ID'];
  title: Scalars['String'];
  approved?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePropositionArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
};


export type MutationDeletePropositionArgs = {
  id: Scalars['ID'];
};


export type MutationAddDocumentArgs = {
  projectId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationAddProjectArgs = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type MutationAddCollaboratorsArgs = {
  projectId: Scalars['ID'];
  collaboratorsEmails: Array<Scalars['String']>;
};


export type MutationRemoveCollaboratorsArgs = {
  projectId: Scalars['ID'];
  collaboratorsIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationJoinProjectWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateProjectTokenArgs = {
  id: Scalars['ID'];
};


export type MutationAddPhaseArgs = {
  projectId: Scalars['ID'];
  title: Scalars['String'];
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: Status;
};


export type MutationUpdatePhaseArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Status>;
};


export type MutationDeletePhaseArgs = {
  id: Scalars['ID'];
};


export type MutationAddContractArgs = {
  phaseId: Scalars['ID'];
  temporality: ContractTemporality;
  recipient: ContractRecipient;
  rule: ContractRule;
  quantificationWhat: ContractQuantificationWhat;
  quantificationHowMuch: Scalars['Int'];
};


export type MutationUpdateContractArgs = {
  id: Scalars['ID'];
  temporality?: Maybe<ContractTemporality>;
  recipient?: Maybe<ContractRecipient>;
  rule?: Maybe<ContractRule>;
  quantificationWhat?: Maybe<ContractQuantificationWhat>;
  quantificationHowMuch?: Maybe<Scalars['Int']>;
};


export type MutationDeleteContractArgs = {
  id: Scalars['ID'];
};


export type MutationAddConsultationRequestArgs = {
  versionId: Scalars['ID'];
  targetId: Scalars['ID'];
};


export type MutationUpdateConsultationRequestArgs = {
  id: Scalars['ID'];
  status: ConsultationRequestStatus;
};


export type MutationDeleteConsultationRequestArgs = {
  id: Scalars['ID'];
};


export type MutationMarkEventsAsSeenArgs = {
  eventIds: Array<Scalars['ID']>;
};


export type MutationMarkAnnotationEventsAsSeenArgs = {
  annotationId: Scalars['ID'];
};


export type MutationMarkVersionEventsAsSeenArgs = {
  versionId: Scalars['ID'];
};


export type MutationMarkDocumentsEventsAsSeenArgs = {
  projectId: Scalars['ID'];
};


export type MutationMarkWorkflowEventsAsSeenArgs = {
  projectId: Scalars['ID'];
};


export type MutationMarkProjectEventsAsSeenArgs = {
  projectId: Scalars['ID'];
};

export type Phase = {
  __typename?: 'Phase';
  id: Scalars['ID'];
  title: Scalars['String'];
  project: Project;
  contracts: Array<Contract>;
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status?: Maybe<Status>;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Phase_Delete = EventBase & {
  __typename?: 'PHASE_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  phaseId: Scalars['ID'];
  title: Scalars['String'];
};

export type Phase_New = EventBase & {
  __typename?: 'PHASE_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  phaseId: Scalars['ID'];
  title: Scalars['String'];
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: Status;
  phase?: Maybe<Phase>;
};

export type Phase_Update_Date = EventBase & {
  __typename?: 'PHASE_UPDATE_DATE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  phaseId: Scalars['ID'];
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  phase?: Maybe<Phase>;
};

export type Phase_Update_Status = EventBase & {
  __typename?: 'PHASE_UPDATE_STATUS';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  phaseId: Scalars['ID'];
  status: Status;
  oldStatus: Status;
  phase?: Maybe<Phase>;
};

export type Phase_Update_Title = EventBase & {
  __typename?: 'PHASE_UPDATE_TITLE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  phaseId: Scalars['ID'];
  title: Scalars['String'];
  phase?: Maybe<Phase>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  owner: User;
  contributors: Array<User>;
  documents: Array<Document>;
  phases: Array<Phase>;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  unSeenEventsCount: Scalars['Int'];
  awaitingActionsCount: Scalars['Int'];
};

export type Project_New = EventBase & {
  __typename?: 'PROJECT_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Project_Update_Description = EventBase & {
  __typename?: 'PROJECT_UPDATE_DESCRIPTION';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type Project_Update_Title = EventBase & {
  __typename?: 'PROJECT_UPDATE_TITLE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  title: Scalars['String'];
};

export type ProjectFilter = {
  /** Incompatible with excludeOwnerId */
  includeOwnerId?: Maybe<Scalars['Int']>;
  /** Incompatible with includeOwnerId */
  excludeOwnerId?: Maybe<Scalars['Int']>;
  titleLike?: Maybe<Scalars['String']>;
};

export type Proposition = {
  __typename?: 'Proposition';
  id: Scalars['ID'];
  title: Scalars['String'];
  approved: Scalars['Boolean'];
  document: Document;
  versions: Array<Version>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  previewImageUrl?: Maybe<Scalars['String']>;
};

export type Proposition_Delete = EventBase & {
  __typename?: 'PROPOSITION_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  propositionId: Scalars['ID'];
  title: Scalars['String'];
};

export type Proposition_New = EventBase & {
  __typename?: 'PROPOSITION_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  propositionId: Scalars['ID'];
  title: Scalars['String'];
  proposition?: Maybe<Proposition>;
};

export type Proposition_Update_Title = EventBase & {
  __typename?: 'PROPOSITION_UPDATE_TITLE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  propositionId: Scalars['ID'];
  title: Scalars['String'];
  proposition?: Maybe<Proposition>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  /** PROTECTED */
  users?: Maybe<Array<Maybe<User>>>;
  /** PROTECTED */
  user?: Maybe<User>;
  /** PROTECTED */
  currentUser: CurrentUser;
  /** PROTECTED */
  threadMessage: ThreadMessage;
  /** PROTECTED */
  annotation: Annotation;
  /** PROTECTED */
  version: Version;
  /** PROTECTED */
  proposition: Proposition;
  /** PROTECTED */
  document: Document;
  /**
   * PROTECTED
   * 
   * Return only projects I own or I contribute to
   */
  projects: Array<Maybe<Project>>;
  /** PROTECTED */
  project: Project;
  /** PROTECTED */
  phase: Phase;
  /** PROTECTED */
  contract: Contract;
  /** PROTECTED */
  consultationrequest: ConsultationRequest;
  /**
   * PROTECTED
   * 
   * Return events following filters
   */
  events: EventResponse;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryThreadMessageArgs = {
  id: Scalars['ID'];
};


export type QueryAnnotationArgs = {
  id: Scalars['ID'];
};


export type QueryVersionArgs = {
  id: Scalars['ID'];
};


export type QueryPropositionArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  filter: ProjectFilter;
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryPhaseArgs = {
  id: Scalars['ID'];
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryConsultationrequestArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  filter: EventFilter;
  pagination: EventPagination;
};

export type Review_Asked = EventBase & {
  __typename?: 'REVIEW_ASKED';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  targetUserId: Scalars['ID'];
  targetUser?: Maybe<User>;
  versionId: Scalars['ID'];
  version?: Maybe<Version>;
  requestId: Scalars['ID'];
  request?: Maybe<ConsultationRequest>;
};

export type Review_Finished = EventBase & {
  __typename?: 'REVIEW_FINISHED';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  versionId: Scalars['ID'];
  version?: Maybe<Version>;
  status: ConsultationRequestStatus;
  requestId: Scalars['ID'];
  request?: Maybe<ConsultationRequest>;
};

export enum Status {
  ToSpecify = 'TO_SPECIFY',
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Waiting = 'WAITING',
  InReview = 'IN_REVIEW',
  Done = 'DONE',
  Canceled = 'CANCELED'
}

export type Thread_Message_Answer = EventBase & {
  __typename?: 'THREAD_MESSAGE_ANSWER';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  threadMessageId: Scalars['ID'];
  message: Scalars['String'];
  threadMessage?: Maybe<ThreadMessage>;
};

export type Thread_Message_Delete = EventBase & {
  __typename?: 'THREAD_MESSAGE_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  threadMessageId: Scalars['ID'];
  annotationDisplayId: Scalars['Int'];
};

export type Thread_Message_New = EventBase & {
  __typename?: 'THREAD_MESSAGE_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  threadMessageId: Scalars['ID'];
  message: Scalars['String'];
  threadMessage?: Maybe<ThreadMessage>;
};

export type Thread_Message_Update = EventBase & {
  __typename?: 'THREAD_MESSAGE_UPDATE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  threadMessageId: Scalars['ID'];
  message: Scalars['String'];
  threadMessage?: Maybe<ThreadMessage>;
};

export type ThreadMessage = {
  __typename?: 'ThreadMessage';
  id: Scalars['ID'];
  data: Scalars['String'];
  annotation: Annotation;
  author?: Maybe<User>;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobs: Array<Scalars['String']>;
  avatarUrl: Scalars['String'];
};

/** fr_FR instead of fr-FR because dash (-) is not supported in GraphQL schema */
export enum UserCulture {
  FrFr = 'fr_FR',
  EnUs = 'en_US'
}

export type Version = {
  __typename?: 'Version';
  id: Scalars['ID'];
  title: Scalars['String'];
  drawings: Scalars['String'];
  annotations: Array<Annotation>;
  author?: Maybe<User>;
  createdAt: Scalars['Date'];
  imageUrl: Scalars['String'];
  proposition: Proposition;
  consultationRequests: Array<ConsultationRequest>;
};

export type Version_Delete = EventBase & {
  __typename?: 'VERSION_DELETE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  versionId: Scalars['ID'];
  title: Scalars['String'];
};

export type Version_New = EventBase & {
  __typename?: 'VERSION_NEW';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  versionId: Scalars['ID'];
  title: Scalars['String'];
  version?: Maybe<Version>;
};

export type Version_Update_Drawings = EventBase & {
  __typename?: 'VERSION_UPDATE_DRAWINGS';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  versionId: Scalars['ID'];
  drawings: Scalars['String'];
  version?: Maybe<Version>;
};

export type Version_Update_Title = EventBase & {
  __typename?: 'VERSION_UPDATE_TITLE';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  authorId: Scalars['ID'];
  author?: Maybe<User>;
  projectId: Scalars['ID'];
  project: Project;
  type: Event_Type;
  unSeen: Scalars['Boolean'];
  versionId: Scalars['ID'];
  title: Scalars['String'];
  version?: Maybe<Version>;
};

export type GetEventsQueryVariables = Exact<{
  filter: EventFilter;
  pagination: EventPagination;
}>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'count'>
    & { events: Array<(
      { __typename?: 'THREAD_MESSAGE_NEW' }
      & Pick<Thread_Message_New, 'id'>
    ) | (
      { __typename?: 'THREAD_MESSAGE_ANSWER' }
      & Pick<Thread_Message_Answer, 'id'>
    ) | (
      { __typename?: 'THREAD_MESSAGE_UPDATE' }
      & Pick<Thread_Message_Update, 'id'>
    ) | (
      { __typename?: 'THREAD_MESSAGE_DELETE' }
      & Pick<Thread_Message_Delete, 'id' | 'annotationDisplayId'>
    ) | (
      { __typename?: 'ANNOTATION_NEW' }
      & Pick<Annotation_New, 'id' | 'displayedId'>
    ) | (
      { __typename?: 'ANNOTATION_DUPLICATE' }
      & Pick<Annotation_Duplicate, 'id'>
    ) | (
      { __typename?: 'ANNOTATION_UPDATE_STATUS' }
      & Pick<Annotation_Update_Status, 'id' | 'status' | 'oldStatus'>
    ) | (
      { __typename?: 'ANNOTATION_UPDATE_POSITION' }
      & Pick<Annotation_Update_Position, 'id'>
    ) | (
      { __typename?: 'ANNOTATION_DELETE' }
      & Pick<Annotation_Delete, 'id' | 'displayId'>
    ) | (
      { __typename?: 'VERSION_NEW' }
      & Pick<Version_New, 'id' | 'title'>
    ) | (
      { __typename?: 'VERSION_UPDATE_TITLE' }
      & Pick<Version_Update_Title, 'id'>
    ) | (
      { __typename?: 'VERSION_UPDATE_DRAWINGS' }
      & Pick<Version_Update_Drawings, 'id'>
    ) | (
      { __typename?: 'VERSION_DELETE' }
      & Pick<Version_Delete, 'id' | 'title'>
    ) | (
      { __typename?: 'PROPOSITION_NEW' }
      & Pick<Proposition_New, 'id' | 'title'>
    ) | (
      { __typename?: 'PROPOSITION_UPDATE_TITLE' }
      & Pick<Proposition_Update_Title, 'id'>
    ) | (
      { __typename?: 'PROPOSITION_DELETE' }
      & Pick<Proposition_Delete, 'id' | 'title'>
    ) | (
      { __typename?: 'DOCUMENT_NEW' }
      & Pick<Document_New, 'id' | 'title'>
    ) | (
      { __typename?: 'DOCUMENT_UPDATE_TITLE' }
      & Pick<Document_Update_Title, 'id'>
    ) | (
      { __typename?: 'DOCUMENT_DELETE' }
      & Pick<Document_Delete, 'id' | 'title'>
    ) | (
      { __typename?: 'PROJECT_NEW' }
      & Pick<Project_New, 'id'>
    ) | (
      { __typename?: 'PROJECT_UPDATE_TITLE' }
      & Pick<Project_Update_Title, 'id'>
    ) | (
      { __typename?: 'PROJECT_UPDATE_DESCRIPTION' }
      & Pick<Project_Update_Description, 'id'>
    ) | (
      { __typename?: 'COLLAB_NEW' }
      & Pick<Collab_New, 'id'>
      & { targetUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) | (
      { __typename?: 'COLLAB_JOIN' }
      & Pick<Collab_Join, 'id'>
      & { tokenCreator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) | (
      { __typename?: 'COLLAB_DELETE' }
      & Pick<Collab_Delete, 'id'>
      & { targetUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) | (
      { __typename?: 'REVIEW_ASKED' }
      & Pick<Review_Asked, 'id'>
      & { targetUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) | (
      { __typename?: 'REVIEW_FINISHED' }
      & Pick<Review_Finished, 'id'>
    ) | (
      { __typename?: 'CONTRACT_NEW' }
      & Pick<Contract_New, 'id'>
    ) | (
      { __typename?: 'CONTRACT_UPDATE' }
      & Pick<Contract_Update, 'id'>
    ) | (
      { __typename?: 'CONTRACT_DELETE' }
      & Pick<Contract_Delete, 'id'>
    ) | (
      { __typename?: 'PHASE_NEW' }
      & Pick<Phase_New, 'id' | 'title'>
    ) | (
      { __typename?: 'PHASE_UPDATE_TITLE' }
      & Pick<Phase_Update_Title, 'id'>
    ) | (
      { __typename?: 'PHASE_UPDATE_DATE' }
      & Pick<Phase_Update_Date, 'id'>
    ) | (
      { __typename?: 'PHASE_UPDATE_STATUS' }
      & Pick<Phase_Update_Status, 'id' | 'status' | 'oldStatus'>
    ) | (
      { __typename?: 'PHASE_DELETE' }
      & Pick<Phase_Delete, 'id' | 'title'>
    )> }
  ) }
);

export type MarkEventsAsSeenMutationVariables = Exact<{
  eventIds: Array<Scalars['ID']>;
}>;


export type MarkEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markEventsAsSeen'>
);

export type MarkAnnotationEventsAsSeenMutationVariables = Exact<{
  annotationId: Scalars['ID'];
}>;


export type MarkAnnotationEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAnnotationEventsAsSeen'>
);

export type MarkVersionEventsAsSeenMutationVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type MarkVersionEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markVersionEventsAsSeen'>
);

export type MarkDocumentsEventsAsSeenMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type MarkDocumentsEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markDocumentsEventsAsSeen'>
);

export type MarkWorkflowEventsAsSeenMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type MarkWorkflowEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markWorkflowEventsAsSeen'>
);

export type MarkProjectEventsAsSeenMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type MarkProjectEventsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markProjectEventsAsSeen'>
);

export type ProjectFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'title' | 'description' | 'createdAt' | 'updatedAt' | 'previewImageUrl'>
  & { owner: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ) }
);

export type DocumentFragmentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'title' | 'createdAt'>
);

export type PropositionFragmentFragment = (
  { __typename?: 'Proposition' }
  & Pick<Proposition, 'id' | 'title' | 'approved' | 'createdAt' | 'previewImageUrl'>
);

export type VersionFragmentFragment = (
  { __typename?: 'Version' }
  & Pick<Version, 'id' | 'title' | 'drawings' | 'createdAt' | 'imageUrl'>
);

export type AnnotationFragmentFragment = (
  { __typename?: 'Annotation' }
  & Pick<Annotation, 'id' | 'status' | 'createdAt' | 'updatedAt' | 'x' | 'y' | 'idInCanvas'>
);

export type ThreadMessageFragmentFragment = (
  { __typename?: 'ThreadMessage' }
  & Pick<ThreadMessage, 'id' | 'data' | 'createdAt' | 'updatedAt'>
);

export type PhaseFragmentFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'title' | 'cursorIndexStart' | 'cursorIndexEnd' | 'startAt' | 'endAt' | 'status' | 'createdAt' | 'updatedAt'>
);

export type ContractFragmentFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'temporality' | 'recipient' | 'rule' | 'quantificationWhat' | 'quantificationHowMuch' | 'createdAt' | 'updatedAt'>
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
);

export type ConsultationRequestFragmentFragment = (
  { __typename?: 'ConsultationRequest' }
  & Pick<ConsultationRequest, 'id' | 'status' | 'createdAt'>
  & { seeker: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ), target: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ) }
);

export type GetProjectsQueryVariables = Exact<{
  filter: ProjectFilter;
}>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'unSeenEventsCount' | 'awaitingActionsCount'>
    & ProjectFragmentFragment
  )>> }
);

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { contributors: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, documents: Array<(
      { __typename?: 'Document' }
      & { propositions: Array<(
        { __typename?: 'Proposition' }
        & { versions: Array<(
          { __typename?: 'Version' }
          & Pick<Version, 'id' | 'title'>
          & { consultationRequests: Array<(
            { __typename?: 'ConsultationRequest' }
            & ConsultationRequestFragmentFragment
          )> }
        )> }
        & PropositionFragmentFragment
      )> }
      & DocumentFragmentFragment
    )> }
    & ProjectFragmentFragment
  ) }
);

export type GetWorkflowQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetWorkflowQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { phases: Array<(
      { __typename?: 'Phase' }
      & { contracts: Array<(
        { __typename?: 'Contract' }
        & ContractFragmentFragment
      )> }
      & PhaseFragmentFragment
    )> }
  ) }
);

export type GetPropositionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPropositionQuery = (
  { __typename?: 'Query' }
  & { proposition: (
    { __typename?: 'Proposition' }
    & { document: (
      { __typename?: 'Document' }
      & Pick<Document, 'title'>
    ), versions: Array<(
      { __typename?: 'Version' }
      & { author?: Maybe<(
        { __typename?: 'User' }
        & UserFragmentFragment
      )>, consultationRequests: Array<(
        { __typename?: 'ConsultationRequest' }
        & ConsultationRequestFragmentFragment
      )>, annotations: Array<(
        { __typename?: 'Annotation' }
        & { author?: Maybe<(
          { __typename?: 'User' }
          & UserFragmentFragment
        )>, threadMessages: Array<(
          { __typename?: 'ThreadMessage' }
          & { author?: Maybe<(
            { __typename?: 'User' }
            & UserFragmentFragment
          )> }
          & ThreadMessageFragmentFragment
        )> }
        & AnnotationFragmentFragment
      )> }
      & VersionFragmentFragment
    )> }
    & PropositionFragmentFragment
  ) }
);

export type AddProjectMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type AddProjectMutation = (
  { __typename?: 'Mutation' }
  & { addProject: (
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & { contributors: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, documents: Array<(
      { __typename?: 'Document' }
      & { propositions: Array<(
        { __typename?: 'Proposition' }
        & { versions: Array<(
          { __typename?: 'Version' }
          & Pick<Version, 'id'>
        )> }
        & PropositionFragmentFragment
      )> }
      & DocumentFragmentFragment
    )> }
    & ProjectFragmentFragment
  ) }
);

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProject'>
);

export type AddCollaboratorsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  collaboratorsEmails: Array<Scalars['String']>;
}>;


export type AddCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { addCollaborators: Array<Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )>> }
);

export type RemoveCollaboratorsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  collaboratorsIds: Array<Maybe<Scalars['ID']>>;
}>;


export type RemoveCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { removeCollaborators: Array<Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )>> }
);

export type AddDocumentMutationVariables = Exact<{
  projectId: Scalars['ID'];
  title: Scalars['String'];
}>;


export type AddDocumentMutation = (
  { __typename?: 'Mutation' }
  & { addDocument: (
    { __typename?: 'Document' }
    & { propositions: Array<(
      { __typename?: 'Proposition' }
      & { versions: Array<(
        { __typename?: 'Version' }
        & Pick<Version, 'id'>
      )> }
      & PropositionFragmentFragment
    )> }
    & DocumentFragmentFragment
  ) }
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocument'>
);

export type AddPropositionMutationVariables = Exact<{
  documentId: Scalars['ID'];
  title: Scalars['String'];
  approved?: Maybe<Scalars['Boolean']>;
}>;


export type AddPropositionMutation = (
  { __typename?: 'Mutation' }
  & { addProposition: (
    { __typename?: 'Proposition' }
    & PropositionFragmentFragment
  ) }
);

export type AddVersionMutationVariables = Exact<{
  propositionId: Scalars['ID'];
  title: Scalars['String'];
  drawing?: Maybe<Scalars['String']>;
  s3ObjectId: Scalars['String'];
}>;


export type AddVersionMutation = (
  { __typename?: 'Mutation' }
  & { addVersion: (
    { __typename?: 'Version' }
    & { author?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>, consultationRequests: Array<(
      { __typename?: 'ConsultationRequest' }
      & ConsultationRequestFragmentFragment
    )>, annotations: Array<(
      { __typename?: 'Annotation' }
      & { author?: Maybe<(
        { __typename?: 'User' }
        & UserFragmentFragment
      )>, threadMessages: Array<(
        { __typename?: 'ThreadMessage' }
        & { author?: Maybe<(
          { __typename?: 'User' }
          & UserFragmentFragment
        )> }
        & ThreadMessageFragmentFragment
      )> }
      & AnnotationFragmentFragment
    )> }
    & VersionFragmentFragment
  ) }
);

export type UpdateVersionMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  drawings?: Maybe<Scalars['String']>;
}>;


export type UpdateVersionMutation = (
  { __typename?: 'Mutation' }
  & { updateVersion: (
    { __typename?: 'Version' }
    & VersionFragmentFragment
  ) }
);

export type DeleteVersionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteVersionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVersion'>
);

export type AddPhaseMutationVariables = Exact<{
  projectId: Scalars['ID'];
  title: Scalars['String'];
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  endAt: Scalars['Date'];
  status: Status;
}>;


export type AddPhaseMutation = (
  { __typename?: 'Mutation' }
  & { addPhase: (
    { __typename?: 'Phase' }
    & { contracts: Array<(
      { __typename?: 'Contract' }
      & ContractFragmentFragment
    )> }
    & PhaseFragmentFragment
  ) }
);

export type UpdatePhaseMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cursorIndexStart?: Maybe<Scalars['Int']>;
  cursorIndexEnd?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Status>;
}>;


export type UpdatePhaseMutation = (
  { __typename?: 'Mutation' }
  & { updatePhase: (
    { __typename?: 'Phase' }
    & { contracts: Array<(
      { __typename?: 'Contract' }
      & ContractFragmentFragment
    )> }
    & PhaseFragmentFragment
  ) }
);

export type DeletePhaseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePhaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePhase'>
);

export type AddContractMutationVariables = Exact<{
  phaseId: Scalars['ID'];
  temporality: ContractTemporality;
  recipient: ContractRecipient;
  rule: ContractRule;
  quantificationWhat: ContractQuantificationWhat;
  quantificationHowMuch: Scalars['Int'];
}>;


export type AddContractMutation = (
  { __typename?: 'Mutation' }
  & { addContract: (
    { __typename?: 'Contract' }
    & ContractFragmentFragment
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  id: Scalars['ID'];
  temporality: ContractTemporality;
  recipient: ContractRecipient;
  rule: ContractRule;
  quantificationWhat: ContractQuantificationWhat;
  quantificationHowMuch: Scalars['Int'];
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & { updateContract: (
    { __typename?: 'Contract' }
    & ContractFragmentFragment
  ) }
);

export type DeleteContractMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContract'>
);

export type AddConsultationRequestMutationVariables = Exact<{
  targetId: Scalars['ID'];
  versionId: Scalars['ID'];
}>;


export type AddConsultationRequestMutation = (
  { __typename?: 'Mutation' }
  & { addConsultationRequest: (
    { __typename?: 'ConsultationRequest' }
    & ConsultationRequestFragmentFragment
  ) }
);

export type UpdateConsultationRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  status: ConsultationRequestStatus;
}>;


export type UpdateConsultationRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationRequest: (
    { __typename?: 'ConsultationRequest' }
    & ConsultationRequestFragmentFragment
  ) }
);

export type DeleteConsultationRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteConsultationRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConsultationRequest'>
);

export type AddAnnotationMutationVariables = Exact<{
  versionId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  status: Status;
}>;


export type AddAnnotationMutation = (
  { __typename?: 'Mutation' }
  & { addAnnotation: (
    { __typename?: 'Annotation' }
    & { author?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>, threadMessages: Array<(
      { __typename?: 'ThreadMessage' }
      & ThreadMessageFragmentFragment
    )> }
    & AnnotationFragmentFragment
  ) }
);

export type UpdateAnnotationMutationVariables = Exact<{
  id: Scalars['ID'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  status?: Maybe<Status>;
}>;


export type UpdateAnnotationMutation = (
  { __typename?: 'Mutation' }
  & { updateAnnotation: (
    { __typename?: 'Annotation' }
    & AnnotationFragmentFragment
  ) }
);

export type DeleteAnnotationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAnnotationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAnnotation'>
);

export type AddThreadMessageMutationVariables = Exact<{
  annotationId: Scalars['ID'];
  data: Scalars['String'];
}>;


export type AddThreadMessageMutation = (
  { __typename?: 'Mutation' }
  & { addThreadMessage: (
    { __typename?: 'ThreadMessage' }
    & { author?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
    & ThreadMessageFragmentFragment
  ) }
);

export type UpdateThreadMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  data?: Maybe<Scalars['String']>;
}>;


export type UpdateThreadMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateThreadMessage: (
    { __typename?: 'ThreadMessage' }
    & { author?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
    & ThreadMessageFragmentFragment
  ) }
);

export type DeleteThreadMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteThreadMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteThreadMessage'>
);

export type JoinProjectWithTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type JoinProjectWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { joinProjectWithToken: (
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  ) }
);

export type CreateProjectTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateProjectTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProjectToken'>
);

export type GenerateDownloadTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateDownloadTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateDownloadToken'>
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'id' | 'firstName' | 'lastName' | 'culture' | 'email' | 'avatarUrl' | 'jobs' | 'mailingPreferences'>
  ) }
);

export type AskResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AskResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'askResetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  culture: UserCulture;
  jobs?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signUp'>
);

export type AskDeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type AskDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'askDeleteUser'>
);

export type DeleteUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  culture?: Maybe<UserCulture>;
  jobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  mailingPreferences?: Maybe<Scalars['String']>;
  s3ObjectId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'id' | 'firstName' | 'lastName' | 'culture' | 'email' | 'jobs' | 'avatarUrl' | 'mailingPreferences'>
  ) }
);

export type SendBugReportMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type SendBugReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendBugReport'>
);

export type FacebookLoginMutationVariables = Exact<{
  accessToken: Scalars['String'];
  culture: UserCulture;
}>;


export type FacebookLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'facebookLogin'>
);

export type GoogleLoginMutationVariables = Exact<{
  tokenId: Scalars['String'];
  culture: UserCulture;
}>;


export type GoogleLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'googleLogin'>
);

export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  title
  description
  createdAt
  updatedAt
  previewImageUrl
  owner {
    id
    firstName
    lastName
  }
}
    `;
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on Document {
  id
  title
  createdAt
}
    `;
export const PropositionFragmentFragmentDoc = gql`
    fragment PropositionFragment on Proposition {
  id
  title
  approved
  createdAt
  previewImageUrl
}
    `;
export const VersionFragmentFragmentDoc = gql`
    fragment VersionFragment on Version {
  id
  title
  drawings
  createdAt
  imageUrl
}
    `;
export const AnnotationFragmentFragmentDoc = gql`
    fragment AnnotationFragment on Annotation {
  id
  status
  createdAt
  updatedAt
  x
  y
  idInCanvas
}
    `;
export const ThreadMessageFragmentFragmentDoc = gql`
    fragment ThreadMessageFragment on ThreadMessage {
  id
  data
  createdAt
  updatedAt
}
    `;
export const PhaseFragmentFragmentDoc = gql`
    fragment PhaseFragment on Phase {
  id
  title
  cursorIndexStart
  cursorIndexEnd
  startAt
  endAt
  status
  createdAt
  updatedAt
}
    `;
export const ContractFragmentFragmentDoc = gql`
    fragment ContractFragment on Contract {
  id
  temporality
  recipient
  rule
  quantificationWhat
  quantificationHowMuch
  createdAt
  updatedAt
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  firstName
  lastName
  avatarUrl
}
    `;
export const ConsultationRequestFragmentFragmentDoc = gql`
    fragment ConsultationRequestFragment on ConsultationRequest {
  id
  seeker {
    id
    firstName
    lastName
  }
  target {
    id
    firstName
    lastName
  }
  status
  createdAt
}
    `;
export const GetEventsDocument = gql`
    query GetEvents($filter: EventFilter!, $pagination: EventPagination!) {
  events(filter: $filter, pagination: $pagination) {
    count
    events {
      ... on THREAD_MESSAGE_ANSWER {
        id
      }
      ... on THREAD_MESSAGE_NEW {
        id
      }
      ... on THREAD_MESSAGE_ANSWER {
        id
      }
      ... on THREAD_MESSAGE_UPDATE {
        id
      }
      ... on THREAD_MESSAGE_DELETE {
        id
        annotationDisplayId
      }
      ... on ANNOTATION_NEW {
        id
        displayedId
      }
      ... on ANNOTATION_DUPLICATE {
        id
      }
      ... on ANNOTATION_UPDATE_STATUS {
        id
        status
        oldStatus
      }
      ... on ANNOTATION_UPDATE_POSITION {
        id
      }
      ... on ANNOTATION_DELETE {
        id
        displayId
      }
      ... on VERSION_NEW {
        id
        title
      }
      ... on VERSION_UPDATE_TITLE {
        id
      }
      ... on VERSION_UPDATE_DRAWINGS {
        id
      }
      ... on VERSION_DELETE {
        id
        title
      }
      ... on PROPOSITION_NEW {
        id
        title
      }
      ... on PROPOSITION_UPDATE_TITLE {
        id
      }
      ... on PROPOSITION_DELETE {
        id
        title
      }
      ... on DOCUMENT_NEW {
        id
        title
      }
      ... on DOCUMENT_UPDATE_TITLE {
        id
      }
      ... on DOCUMENT_DELETE {
        id
        title
      }
      ... on PROJECT_NEW {
        id
      }
      ... on PROJECT_UPDATE_TITLE {
        id
      }
      ... on PROJECT_UPDATE_DESCRIPTION {
        id
      }
      ... on COLLAB_NEW {
        id
        targetUser {
          firstName
          lastName
        }
      }
      ... on COLLAB_JOIN {
        id
        tokenCreator {
          firstName
          lastName
        }
      }
      ... on COLLAB_DELETE {
        id
        targetUser {
          firstName
          lastName
        }
      }
      ... on REVIEW_ASKED {
        id
        targetUser {
          firstName
          lastName
        }
      }
      ... on REVIEW_FINISHED {
        id
      }
      ... on CONTRACT_NEW {
        id
      }
      ... on CONTRACT_UPDATE {
        id
      }
      ... on CONTRACT_DELETE {
        id
      }
      ... on PHASE_NEW {
        id
        title
      }
      ... on PHASE_UPDATE_TITLE {
        id
      }
      ... on PHASE_UPDATE_DATE {
        id
      }
      ... on PHASE_UPDATE_STATUS {
        id
        status
        oldStatus
      }
      ... on PHASE_DELETE {
        id
        title
      }
    }
  }
}
    `;
export type GetEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEventsQuery, GetEventsQueryVariables>, 'query'> & ({ variables: GetEventsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEventsComponent = (props: GetEventsComponentProps) => (
      <ApolloReactComponents.Query<GetEventsQuery, GetEventsQueryVariables> query={GetEventsDocument} {...props} />
    );
    
export type GetEventsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEventsQuery, GetEventsQueryVariables>
    } & TChildProps;
export function withGetEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEventsQuery,
  GetEventsQueryVariables,
  GetEventsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEventsQuery, GetEventsQueryVariables, GetEventsProps<TChildProps, TDataName>>(GetEventsDocument, {
      alias: 'getEvents',
      ...operationOptions
    });
};

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, baseOptions);
      }
export function useGetEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, baseOptions);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = ApolloReactCommon.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const MarkEventsAsSeenDocument = gql`
    mutation MarkEventsAsSeen($eventIds: [ID!]!) {
  markEventsAsSeen(eventIds: $eventIds)
}
    `;
export type MarkEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>;
export type MarkEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkEventsAsSeenComponent = (props: MarkEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables> mutation={MarkEventsAsSeenDocument} {...props} />
    );
    
export type MarkEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkEventsAsSeenMutation,
  MarkEventsAsSeenMutationVariables,
  MarkEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables, MarkEventsAsSeenProps<TChildProps, TDataName>>(MarkEventsAsSeenDocument, {
      alias: 'markEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markEventsAsSeenMutation, { data, loading, error }] = useMarkEventsAsSeenMutation({
 *   variables: {
 *      eventIds: // value for 'eventIds'
 *   },
 * });
 */
export function useMarkEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>(MarkEventsAsSeenDocument, baseOptions);
      }
export type MarkEventsAsSeenMutationHookResult = ReturnType<typeof useMarkEventsAsSeenMutation>;
export type MarkEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkEventsAsSeenMutation>;
export type MarkEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkEventsAsSeenMutation, MarkEventsAsSeenMutationVariables>;
export const MarkAnnotationEventsAsSeenDocument = gql`
    mutation MarkAnnotationEventsAsSeen($annotationId: ID!) {
  markAnnotationEventsAsSeen(annotationId: $annotationId)
}
    `;
export type MarkAnnotationEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>;
export type MarkAnnotationEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkAnnotationEventsAsSeenComponent = (props: MarkAnnotationEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables> mutation={MarkAnnotationEventsAsSeenDocument} {...props} />
    );
    
export type MarkAnnotationEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkAnnotationEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkAnnotationEventsAsSeenMutation,
  MarkAnnotationEventsAsSeenMutationVariables,
  MarkAnnotationEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables, MarkAnnotationEventsAsSeenProps<TChildProps, TDataName>>(MarkAnnotationEventsAsSeenDocument, {
      alias: 'markAnnotationEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkAnnotationEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAnnotationEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAnnotationEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAnnotationEventsAsSeenMutation, { data, loading, error }] = useMarkAnnotationEventsAsSeenMutation({
 *   variables: {
 *      annotationId: // value for 'annotationId'
 *   },
 * });
 */
export function useMarkAnnotationEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>(MarkAnnotationEventsAsSeenDocument, baseOptions);
      }
export type MarkAnnotationEventsAsSeenMutationHookResult = ReturnType<typeof useMarkAnnotationEventsAsSeenMutation>;
export type MarkAnnotationEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkAnnotationEventsAsSeenMutation>;
export type MarkAnnotationEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAnnotationEventsAsSeenMutation, MarkAnnotationEventsAsSeenMutationVariables>;
export const MarkVersionEventsAsSeenDocument = gql`
    mutation MarkVersionEventsAsSeen($versionId: ID!) {
  markVersionEventsAsSeen(versionId: $versionId)
}
    `;
export type MarkVersionEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>;
export type MarkVersionEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkVersionEventsAsSeenComponent = (props: MarkVersionEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables> mutation={MarkVersionEventsAsSeenDocument} {...props} />
    );
    
export type MarkVersionEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkVersionEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkVersionEventsAsSeenMutation,
  MarkVersionEventsAsSeenMutationVariables,
  MarkVersionEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables, MarkVersionEventsAsSeenProps<TChildProps, TDataName>>(MarkVersionEventsAsSeenDocument, {
      alias: 'markVersionEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkVersionEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkVersionEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkVersionEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markVersionEventsAsSeenMutation, { data, loading, error }] = useMarkVersionEventsAsSeenMutation({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useMarkVersionEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>(MarkVersionEventsAsSeenDocument, baseOptions);
      }
export type MarkVersionEventsAsSeenMutationHookResult = ReturnType<typeof useMarkVersionEventsAsSeenMutation>;
export type MarkVersionEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkVersionEventsAsSeenMutation>;
export type MarkVersionEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkVersionEventsAsSeenMutation, MarkVersionEventsAsSeenMutationVariables>;
export const MarkDocumentsEventsAsSeenDocument = gql`
    mutation MarkDocumentsEventsAsSeen($projectId: ID!) {
  markDocumentsEventsAsSeen(projectId: $projectId)
}
    `;
export type MarkDocumentsEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>;
export type MarkDocumentsEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkDocumentsEventsAsSeenComponent = (props: MarkDocumentsEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables> mutation={MarkDocumentsEventsAsSeenDocument} {...props} />
    );
    
export type MarkDocumentsEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkDocumentsEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkDocumentsEventsAsSeenMutation,
  MarkDocumentsEventsAsSeenMutationVariables,
  MarkDocumentsEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables, MarkDocumentsEventsAsSeenProps<TChildProps, TDataName>>(MarkDocumentsEventsAsSeenDocument, {
      alias: 'markDocumentsEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkDocumentsEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkDocumentsEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentsEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentsEventsAsSeenMutation, { data, loading, error }] = useMarkDocumentsEventsAsSeenMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMarkDocumentsEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>(MarkDocumentsEventsAsSeenDocument, baseOptions);
      }
export type MarkDocumentsEventsAsSeenMutationHookResult = ReturnType<typeof useMarkDocumentsEventsAsSeenMutation>;
export type MarkDocumentsEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkDocumentsEventsAsSeenMutation>;
export type MarkDocumentsEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkDocumentsEventsAsSeenMutation, MarkDocumentsEventsAsSeenMutationVariables>;
export const MarkWorkflowEventsAsSeenDocument = gql`
    mutation MarkWorkflowEventsAsSeen($projectId: ID!) {
  markWorkflowEventsAsSeen(projectId: $projectId)
}
    `;
export type MarkWorkflowEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>;
export type MarkWorkflowEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkWorkflowEventsAsSeenComponent = (props: MarkWorkflowEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables> mutation={MarkWorkflowEventsAsSeenDocument} {...props} />
    );
    
export type MarkWorkflowEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkWorkflowEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkWorkflowEventsAsSeenMutation,
  MarkWorkflowEventsAsSeenMutationVariables,
  MarkWorkflowEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables, MarkWorkflowEventsAsSeenProps<TChildProps, TDataName>>(MarkWorkflowEventsAsSeenDocument, {
      alias: 'markWorkflowEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkWorkflowEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkWorkflowEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWorkflowEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWorkflowEventsAsSeenMutation, { data, loading, error }] = useMarkWorkflowEventsAsSeenMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMarkWorkflowEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>(MarkWorkflowEventsAsSeenDocument, baseOptions);
      }
export type MarkWorkflowEventsAsSeenMutationHookResult = ReturnType<typeof useMarkWorkflowEventsAsSeenMutation>;
export type MarkWorkflowEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkWorkflowEventsAsSeenMutation>;
export type MarkWorkflowEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkWorkflowEventsAsSeenMutation, MarkWorkflowEventsAsSeenMutationVariables>;
export const MarkProjectEventsAsSeenDocument = gql`
    mutation MarkProjectEventsAsSeen($projectId: ID!) {
  markProjectEventsAsSeen(projectId: $projectId)
}
    `;
export type MarkProjectEventsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>;
export type MarkProjectEventsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>, 'mutation'>;

    export const MarkProjectEventsAsSeenComponent = (props: MarkProjectEventsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables> mutation={MarkProjectEventsAsSeenDocument} {...props} />
    );
    
export type MarkProjectEventsAsSeenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>
    } & TChildProps;
export function withMarkProjectEventsAsSeen<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkProjectEventsAsSeenMutation,
  MarkProjectEventsAsSeenMutationVariables,
  MarkProjectEventsAsSeenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables, MarkProjectEventsAsSeenProps<TChildProps, TDataName>>(MarkProjectEventsAsSeenDocument, {
      alias: 'markProjectEventsAsSeen',
      ...operationOptions
    });
};

/**
 * __useMarkProjectEventsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkProjectEventsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkProjectEventsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markProjectEventsAsSeenMutation, { data, loading, error }] = useMarkProjectEventsAsSeenMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMarkProjectEventsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>(MarkProjectEventsAsSeenDocument, baseOptions);
      }
export type MarkProjectEventsAsSeenMutationHookResult = ReturnType<typeof useMarkProjectEventsAsSeenMutation>;
export type MarkProjectEventsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkProjectEventsAsSeenMutation>;
export type MarkProjectEventsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkProjectEventsAsSeenMutation, MarkProjectEventsAsSeenMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects($filter: ProjectFilter!) {
  projects(filter: $filter) {
    ...ProjectFragment
    unSeenEventsCount
    awaitingActionsCount
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type GetProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectsQuery, GetProjectsQueryVariables>, 'query'> & ({ variables: GetProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectsComponent = (props: GetProjectsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectsQuery, GetProjectsQueryVariables> query={GetProjectsDocument} {...props} />
    );
    
export type GetProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetProjectsQuery, GetProjectsQueryVariables>
    } & TChildProps;
export function withGetProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectsQuery,
  GetProjectsQueryVariables,
  GetProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectsQuery, GetProjectsQueryVariables, GetProjectsProps<TChildProps, TDataName>>(GetProjectsDocument, {
      alias: 'getProjects',
      ...operationOptions
    });
};

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
      }
export function useGetProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = ApolloReactCommon.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($id: ID!) {
  project(id: $id) {
    ...ProjectFragment
    contributors {
      id
      firstName
      lastName
    }
    documents {
      ...DocumentFragment
      propositions {
        ...PropositionFragment
        versions {
          id
          title
          consultationRequests {
            ...ConsultationRequestFragment
          }
        }
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${DocumentFragmentFragmentDoc}
${PropositionFragmentFragmentDoc}
${ConsultationRequestFragmentFragmentDoc}`;
export type GetProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectQuery, GetProjectQueryVariables>, 'query'> & ({ variables: GetProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectComponent = (props: GetProjectComponentProps) => (
      <ApolloReactComponents.Query<GetProjectQuery, GetProjectQueryVariables> query={GetProjectDocument} {...props} />
    );
    
export type GetProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetProjectQuery, GetProjectQueryVariables>
    } & TChildProps;
export function withGetProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectQuery,
  GetProjectQueryVariables,
  GetProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectQuery, GetProjectQueryVariables, GetProjectProps<TChildProps, TDataName>>(GetProjectDocument, {
      alias: 'getProject',
      ...operationOptions
    });
};

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetWorkflowDocument = gql`
    query GetWorkflow($projectId: ID!) {
  project(id: $projectId) {
    phases {
      ...PhaseFragment
      contracts {
        ...ContractFragment
      }
    }
  }
}
    ${PhaseFragmentFragmentDoc}
${ContractFragmentFragmentDoc}`;
export type GetWorkflowComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWorkflowQuery, GetWorkflowQueryVariables>, 'query'> & ({ variables: GetWorkflowQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWorkflowComponent = (props: GetWorkflowComponentProps) => (
      <ApolloReactComponents.Query<GetWorkflowQuery, GetWorkflowQueryVariables> query={GetWorkflowDocument} {...props} />
    );
    
export type GetWorkflowProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetWorkflowQuery, GetWorkflowQueryVariables>
    } & TChildProps;
export function withGetWorkflow<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetWorkflowQuery,
  GetWorkflowQueryVariables,
  GetWorkflowProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetWorkflowQuery, GetWorkflowQueryVariables, GetWorkflowProps<TChildProps, TDataName>>(GetWorkflowDocument, {
      alias: 'getWorkflow',
      ...operationOptions
    });
};

/**
 * __useGetWorkflowQuery__
 *
 * To run a query within a React component, call `useGetWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetWorkflowQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(GetWorkflowDocument, baseOptions);
      }
export function useGetWorkflowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(GetWorkflowDocument, baseOptions);
        }
export type GetWorkflowQueryHookResult = ReturnType<typeof useGetWorkflowQuery>;
export type GetWorkflowLazyQueryHookResult = ReturnType<typeof useGetWorkflowLazyQuery>;
export type GetWorkflowQueryResult = ApolloReactCommon.QueryResult<GetWorkflowQuery, GetWorkflowQueryVariables>;
export const GetPropositionDocument = gql`
    query GetProposition($id: ID!) {
  proposition(id: $id) {
    document {
      title
    }
    ...PropositionFragment
    versions {
      ...VersionFragment
      author {
        ...UserFragment
      }
      consultationRequests {
        ...ConsultationRequestFragment
      }
      annotations {
        ...AnnotationFragment
        author {
          ...UserFragment
        }
        threadMessages {
          ...ThreadMessageFragment
          author {
            ...UserFragment
          }
        }
      }
    }
  }
}
    ${PropositionFragmentFragmentDoc}
${VersionFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${ConsultationRequestFragmentFragmentDoc}
${AnnotationFragmentFragmentDoc}
${ThreadMessageFragmentFragmentDoc}`;
export type GetPropositionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPropositionQuery, GetPropositionQueryVariables>, 'query'> & ({ variables: GetPropositionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPropositionComponent = (props: GetPropositionComponentProps) => (
      <ApolloReactComponents.Query<GetPropositionQuery, GetPropositionQueryVariables> query={GetPropositionDocument} {...props} />
    );
    
export type GetPropositionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPropositionQuery, GetPropositionQueryVariables>
    } & TChildProps;
export function withGetProposition<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPropositionQuery,
  GetPropositionQueryVariables,
  GetPropositionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPropositionQuery, GetPropositionQueryVariables, GetPropositionProps<TChildProps, TDataName>>(GetPropositionDocument, {
      alias: 'getProposition',
      ...operationOptions
    });
};

/**
 * __useGetPropositionQuery__
 *
 * To run a query within a React component, call `useGetPropositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropositionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPropositionQuery, GetPropositionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPropositionQuery, GetPropositionQueryVariables>(GetPropositionDocument, baseOptions);
      }
export function useGetPropositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPropositionQuery, GetPropositionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPropositionQuery, GetPropositionQueryVariables>(GetPropositionDocument, baseOptions);
        }
export type GetPropositionQueryHookResult = ReturnType<typeof useGetPropositionQuery>;
export type GetPropositionLazyQueryHookResult = ReturnType<typeof useGetPropositionLazyQuery>;
export type GetPropositionQueryResult = ApolloReactCommon.QueryResult<GetPropositionQuery, GetPropositionQueryVariables>;
export const AddProjectDocument = gql`
    mutation AddProject($title: String!, $description: String) {
  addProject(title: $title, description: $description) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type AddProjectMutationFn = ApolloReactCommon.MutationFunction<AddProjectMutation, AddProjectMutationVariables>;
export type AddProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProjectMutation, AddProjectMutationVariables>, 'mutation'>;

    export const AddProjectComponent = (props: AddProjectComponentProps) => (
      <ApolloReactComponents.Mutation<AddProjectMutation, AddProjectMutationVariables> mutation={AddProjectDocument} {...props} />
    );
    
export type AddProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddProjectMutation, AddProjectMutationVariables>
    } & TChildProps;
export function withAddProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddProjectMutation,
  AddProjectMutationVariables,
  AddProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddProjectMutation, AddProjectMutationVariables, AddProjectProps<TChildProps, TDataName>>(AddProjectDocument, {
      alias: 'addProject',
      ...operationOptions
    });
};

/**
 * __useAddProjectMutation__
 *
 * To run a mutation, you first call `useAddProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMutation, { data, loading, error }] = useAddProjectMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProjectMutation, AddProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<AddProjectMutation, AddProjectMutationVariables>(AddProjectDocument, baseOptions);
      }
export type AddProjectMutationHookResult = ReturnType<typeof useAddProjectMutation>;
export type AddProjectMutationResult = ApolloReactCommon.MutationResult<AddProjectMutation>;
export type AddProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProjectMutation, AddProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $title: String!, $description: String) {
  updateProject(id: $id, title: $title, description: $description) {
    ...ProjectFragment
    contributors {
      id
      firstName
      lastName
    }
    documents {
      ...DocumentFragment
      propositions {
        ...PropositionFragment
        versions {
          id
        }
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${DocumentFragmentFragmentDoc}
${PropositionFragmentFragmentDoc}`;
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export type UpdateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectMutation, UpdateProjectMutationVariables>, 'mutation'>;

    export const UpdateProjectComponent = (props: UpdateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> mutation={UpdateProjectDocument} {...props} />
    );
    
export type UpdateProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>
    } & TChildProps;
export function withUpdateProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  UpdateProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProjectMutation, UpdateProjectMutationVariables, UpdateProjectProps<TChildProps, TDataName>>(UpdateProjectDocument, {
      alias: 'updateProject',
      ...operationOptions
    });
};

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: ID!) {
  deleteProject(id: $id)
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;
export type DeleteProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectMutation, DeleteProjectMutationVariables>, 'mutation'>;

    export const DeleteProjectComponent = (props: DeleteProjectComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> mutation={DeleteProjectDocument} {...props} />
    );
    
export type DeleteProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>
    } & TChildProps;
export function withDeleteProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  DeleteProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteProjectMutation, DeleteProjectMutationVariables, DeleteProjectProps<TChildProps, TDataName>>(DeleteProjectDocument, {
      alias: 'deleteProject',
      ...operationOptions
    });
};

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const AddCollaboratorsDocument = gql`
    mutation AddCollaborators($projectId: ID!, $collaboratorsEmails: [String!]!) {
  addCollaborators(projectId: $projectId, collaboratorsEmails: $collaboratorsEmails) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type AddCollaboratorsMutationFn = ApolloReactCommon.MutationFunction<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;
export type AddCollaboratorsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>, 'mutation'>;

    export const AddCollaboratorsComponent = (props: AddCollaboratorsComponentProps) => (
      <ApolloReactComponents.Mutation<AddCollaboratorsMutation, AddCollaboratorsMutationVariables> mutation={AddCollaboratorsDocument} {...props} />
    );
    
export type AddCollaboratorsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>
    } & TChildProps;
export function withAddCollaborators<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddCollaboratorsMutation,
  AddCollaboratorsMutationVariables,
  AddCollaboratorsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddCollaboratorsMutation, AddCollaboratorsMutationVariables, AddCollaboratorsProps<TChildProps, TDataName>>(AddCollaboratorsDocument, {
      alias: 'addCollaborators',
      ...operationOptions
    });
};

/**
 * __useAddCollaboratorsMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorsMutation, { data, loading, error }] = useAddCollaboratorsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      collaboratorsEmails: // value for 'collaboratorsEmails'
 *   },
 * });
 */
export function useAddCollaboratorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>(AddCollaboratorsDocument, baseOptions);
      }
export type AddCollaboratorsMutationHookResult = ReturnType<typeof useAddCollaboratorsMutation>;
export type AddCollaboratorsMutationResult = ApolloReactCommon.MutationResult<AddCollaboratorsMutation>;
export type AddCollaboratorsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;
export const RemoveCollaboratorsDocument = gql`
    mutation RemoveCollaborators($projectId: ID!, $collaboratorsIds: [ID]!) {
  removeCollaborators(projectId: $projectId, collaboratorsIds: $collaboratorsIds) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type RemoveCollaboratorsMutationFn = ApolloReactCommon.MutationFunction<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;
export type RemoveCollaboratorsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>, 'mutation'>;

    export const RemoveCollaboratorsComponent = (props: RemoveCollaboratorsComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables> mutation={RemoveCollaboratorsDocument} {...props} />
    );
    
export type RemoveCollaboratorsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>
    } & TChildProps;
export function withRemoveCollaborators<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveCollaboratorsMutation,
  RemoveCollaboratorsMutationVariables,
  RemoveCollaboratorsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables, RemoveCollaboratorsProps<TChildProps, TDataName>>(RemoveCollaboratorsDocument, {
      alias: 'removeCollaborators',
      ...operationOptions
    });
};

/**
 * __useRemoveCollaboratorsMutation__
 *
 * To run a mutation, you first call `useRemoveCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollaboratorsMutation, { data, loading, error }] = useRemoveCollaboratorsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      collaboratorsIds: // value for 'collaboratorsIds'
 *   },
 * });
 */
export function useRemoveCollaboratorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>(RemoveCollaboratorsDocument, baseOptions);
      }
export type RemoveCollaboratorsMutationHookResult = ReturnType<typeof useRemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationResult = ApolloReactCommon.MutationResult<RemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;
export const AddDocumentDocument = gql`
    mutation AddDocument($projectId: ID!, $title: String!) {
  addDocument(projectId: $projectId, title: $title) {
    ...DocumentFragment
    propositions {
      ...PropositionFragment
      versions {
        id
      }
    }
  }
}
    ${DocumentFragmentFragmentDoc}
${PropositionFragmentFragmentDoc}`;
export type AddDocumentMutationFn = ApolloReactCommon.MutationFunction<AddDocumentMutation, AddDocumentMutationVariables>;
export type AddDocumentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddDocumentMutation, AddDocumentMutationVariables>, 'mutation'>;

    export const AddDocumentComponent = (props: AddDocumentComponentProps) => (
      <ApolloReactComponents.Mutation<AddDocumentMutation, AddDocumentMutationVariables> mutation={AddDocumentDocument} {...props} />
    );
    
export type AddDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddDocumentMutation, AddDocumentMutationVariables>
    } & TChildProps;
export function withAddDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddDocumentMutation,
  AddDocumentMutationVariables,
  AddDocumentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddDocumentMutation, AddDocumentMutationVariables, AddDocumentProps<TChildProps, TDataName>>(AddDocumentDocument, {
      alias: 'addDocument',
      ...operationOptions
    });
};

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDocumentMutation, AddDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(AddDocumentDocument, baseOptions);
      }
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>;
export type AddDocumentMutationResult = ApolloReactCommon.MutationResult<AddDocumentMutation>;
export type AddDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDocumentMutation, AddDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: ID!) {
  deleteDocument(id: $id)
}
    `;
export type DeleteDocumentMutationFn = ApolloReactCommon.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export type DeleteDocumentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>, 'mutation'>;

    export const DeleteDocumentComponent = (props: DeleteDocumentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDocumentMutation, DeleteDocumentMutationVariables> mutation={DeleteDocumentDocument} {...props} />
    );
    
export type DeleteDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>
    } & TChildProps;
export function withDeleteDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  DeleteDocumentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDocumentMutation, DeleteDocumentMutationVariables, DeleteDocumentProps<TChildProps, TDataName>>(DeleteDocumentDocument, {
      alias: 'deleteDocument',
      ...operationOptions
    });
};

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, baseOptions);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = ApolloReactCommon.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const AddPropositionDocument = gql`
    mutation AddProposition($documentId: ID!, $title: String!, $approved: Boolean) {
  addProposition(documentId: $documentId, title: $title, approved: $approved) {
    ...PropositionFragment
  }
}
    ${PropositionFragmentFragmentDoc}`;
export type AddPropositionMutationFn = ApolloReactCommon.MutationFunction<AddPropositionMutation, AddPropositionMutationVariables>;
export type AddPropositionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPropositionMutation, AddPropositionMutationVariables>, 'mutation'>;

    export const AddPropositionComponent = (props: AddPropositionComponentProps) => (
      <ApolloReactComponents.Mutation<AddPropositionMutation, AddPropositionMutationVariables> mutation={AddPropositionDocument} {...props} />
    );
    
export type AddPropositionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddPropositionMutation, AddPropositionMutationVariables>
    } & TChildProps;
export function withAddProposition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddPropositionMutation,
  AddPropositionMutationVariables,
  AddPropositionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddPropositionMutation, AddPropositionMutationVariables, AddPropositionProps<TChildProps, TDataName>>(AddPropositionDocument, {
      alias: 'addProposition',
      ...operationOptions
    });
};

/**
 * __useAddPropositionMutation__
 *
 * To run a mutation, you first call `useAddPropositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropositionMutation, { data, loading, error }] = useAddPropositionMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      title: // value for 'title'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useAddPropositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPropositionMutation, AddPropositionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPropositionMutation, AddPropositionMutationVariables>(AddPropositionDocument, baseOptions);
      }
export type AddPropositionMutationHookResult = ReturnType<typeof useAddPropositionMutation>;
export type AddPropositionMutationResult = ApolloReactCommon.MutationResult<AddPropositionMutation>;
export type AddPropositionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPropositionMutation, AddPropositionMutationVariables>;
export const AddVersionDocument = gql`
    mutation AddVersion($propositionId: ID!, $title: String!, $drawing: String, $s3ObjectId: String!) {
  addVersion(propositionId: $propositionId, title: $title, drawing: $drawing, s3ObjectId: $s3ObjectId) {
    ...VersionFragment
    author {
      ...UserFragment
    }
    consultationRequests {
      ...ConsultationRequestFragment
    }
    annotations {
      ...AnnotationFragment
      author {
        ...UserFragment
      }
      threadMessages {
        ...ThreadMessageFragment
        author {
          ...UserFragment
        }
      }
    }
  }
}
    ${VersionFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${ConsultationRequestFragmentFragmentDoc}
${AnnotationFragmentFragmentDoc}
${ThreadMessageFragmentFragmentDoc}`;
export type AddVersionMutationFn = ApolloReactCommon.MutationFunction<AddVersionMutation, AddVersionMutationVariables>;
export type AddVersionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddVersionMutation, AddVersionMutationVariables>, 'mutation'>;

    export const AddVersionComponent = (props: AddVersionComponentProps) => (
      <ApolloReactComponents.Mutation<AddVersionMutation, AddVersionMutationVariables> mutation={AddVersionDocument} {...props} />
    );
    
export type AddVersionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddVersionMutation, AddVersionMutationVariables>
    } & TChildProps;
export function withAddVersion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddVersionMutation,
  AddVersionMutationVariables,
  AddVersionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddVersionMutation, AddVersionMutationVariables, AddVersionProps<TChildProps, TDataName>>(AddVersionDocument, {
      alias: 'addVersion',
      ...operationOptions
    });
};

/**
 * __useAddVersionMutation__
 *
 * To run a mutation, you first call `useAddVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVersionMutation, { data, loading, error }] = useAddVersionMutation({
 *   variables: {
 *      propositionId: // value for 'propositionId'
 *      title: // value for 'title'
 *      drawing: // value for 'drawing'
 *      s3ObjectId: // value for 's3ObjectId'
 *   },
 * });
 */
export function useAddVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVersionMutation, AddVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVersionMutation, AddVersionMutationVariables>(AddVersionDocument, baseOptions);
      }
export type AddVersionMutationHookResult = ReturnType<typeof useAddVersionMutation>;
export type AddVersionMutationResult = ApolloReactCommon.MutationResult<AddVersionMutation>;
export type AddVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVersionMutation, AddVersionMutationVariables>;
export const UpdateVersionDocument = gql`
    mutation UpdateVersion($id: ID!, $title: String, $drawings: String) {
  updateVersion(id: $id, title: $title, drawings: $drawings) {
    ...VersionFragment
  }
}
    ${VersionFragmentFragmentDoc}`;
export type UpdateVersionMutationFn = ApolloReactCommon.MutationFunction<UpdateVersionMutation, UpdateVersionMutationVariables>;
export type UpdateVersionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateVersionMutation, UpdateVersionMutationVariables>, 'mutation'>;

    export const UpdateVersionComponent = (props: UpdateVersionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateVersionMutation, UpdateVersionMutationVariables> mutation={UpdateVersionDocument} {...props} />
    );
    
export type UpdateVersionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateVersionMutation, UpdateVersionMutationVariables>
    } & TChildProps;
export function withUpdateVersion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateVersionMutation,
  UpdateVersionMutationVariables,
  UpdateVersionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateVersionMutation, UpdateVersionMutationVariables, UpdateVersionProps<TChildProps, TDataName>>(UpdateVersionDocument, {
      alias: 'updateVersion',
      ...operationOptions
    });
};

/**
 * __useUpdateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVersionMutation, { data, loading, error }] = useUpdateVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      drawings: // value for 'drawings'
 *   },
 * });
 */
export function useUpdateVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVersionMutation, UpdateVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVersionMutation, UpdateVersionMutationVariables>(UpdateVersionDocument, baseOptions);
      }
export type UpdateVersionMutationHookResult = ReturnType<typeof useUpdateVersionMutation>;
export type UpdateVersionMutationResult = ApolloReactCommon.MutationResult<UpdateVersionMutation>;
export type UpdateVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVersionMutation, UpdateVersionMutationVariables>;
export const DeleteVersionDocument = gql`
    mutation DeleteVersion($id: ID!) {
  deleteVersion(id: $id)
}
    `;
export type DeleteVersionMutationFn = ApolloReactCommon.MutationFunction<DeleteVersionMutation, DeleteVersionMutationVariables>;
export type DeleteVersionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteVersionMutation, DeleteVersionMutationVariables>, 'mutation'>;

    export const DeleteVersionComponent = (props: DeleteVersionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteVersionMutation, DeleteVersionMutationVariables> mutation={DeleteVersionDocument} {...props} />
    );
    
export type DeleteVersionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteVersionMutation, DeleteVersionMutationVariables>
    } & TChildProps;
export function withDeleteVersion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteVersionMutation,
  DeleteVersionMutationVariables,
  DeleteVersionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteVersionMutation, DeleteVersionMutationVariables, DeleteVersionProps<TChildProps, TDataName>>(DeleteVersionDocument, {
      alias: 'deleteVersion',
      ...operationOptions
    });
};

/**
 * __useDeleteVersionMutation__
 *
 * To run a mutation, you first call `useDeleteVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVersionMutation, { data, loading, error }] = useDeleteVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVersionMutation, DeleteVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVersionMutation, DeleteVersionMutationVariables>(DeleteVersionDocument, baseOptions);
      }
export type DeleteVersionMutationHookResult = ReturnType<typeof useDeleteVersionMutation>;
export type DeleteVersionMutationResult = ApolloReactCommon.MutationResult<DeleteVersionMutation>;
export type DeleteVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVersionMutation, DeleteVersionMutationVariables>;
export const AddPhaseDocument = gql`
    mutation AddPhase($projectId: ID!, $title: String!, $cursorIndexStart: Int, $cursorIndexEnd: Int, $startAt: Date!, $endAt: Date!, $status: Status!) {
  addPhase(projectId: $projectId, title: $title, cursorIndexStart: $cursorIndexStart, cursorIndexEnd: $cursorIndexEnd, startAt: $startAt, endAt: $endAt, status: $status) {
    ...PhaseFragment
    contracts {
      ...ContractFragment
    }
  }
}
    ${PhaseFragmentFragmentDoc}
${ContractFragmentFragmentDoc}`;
export type AddPhaseMutationFn = ApolloReactCommon.MutationFunction<AddPhaseMutation, AddPhaseMutationVariables>;
export type AddPhaseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPhaseMutation, AddPhaseMutationVariables>, 'mutation'>;

    export const AddPhaseComponent = (props: AddPhaseComponentProps) => (
      <ApolloReactComponents.Mutation<AddPhaseMutation, AddPhaseMutationVariables> mutation={AddPhaseDocument} {...props} />
    );
    
export type AddPhaseProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddPhaseMutation, AddPhaseMutationVariables>
    } & TChildProps;
export function withAddPhase<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddPhaseMutation,
  AddPhaseMutationVariables,
  AddPhaseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddPhaseMutation, AddPhaseMutationVariables, AddPhaseProps<TChildProps, TDataName>>(AddPhaseDocument, {
      alias: 'addPhase',
      ...operationOptions
    });
};

/**
 * __useAddPhaseMutation__
 *
 * To run a mutation, you first call `useAddPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhaseMutation, { data, loading, error }] = useAddPhaseMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *      cursorIndexStart: // value for 'cursorIndexStart'
 *      cursorIndexEnd: // value for 'cursorIndexEnd'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAddPhaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPhaseMutation, AddPhaseMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPhaseMutation, AddPhaseMutationVariables>(AddPhaseDocument, baseOptions);
      }
export type AddPhaseMutationHookResult = ReturnType<typeof useAddPhaseMutation>;
export type AddPhaseMutationResult = ApolloReactCommon.MutationResult<AddPhaseMutation>;
export type AddPhaseMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPhaseMutation, AddPhaseMutationVariables>;
export const UpdatePhaseDocument = gql`
    mutation UpdatePhase($id: ID!, $title: String, $cursorIndexStart: Int, $cursorIndexEnd: Int, $startAt: Date, $endAt: Date, $status: Status) {
  updatePhase(id: $id, title: $title, cursorIndexStart: $cursorIndexStart, cursorIndexEnd: $cursorIndexEnd, startAt: $startAt, endAt: $endAt, status: $status) {
    ...PhaseFragment
    contracts {
      ...ContractFragment
    }
  }
}
    ${PhaseFragmentFragmentDoc}
${ContractFragmentFragmentDoc}`;
export type UpdatePhaseMutationFn = ApolloReactCommon.MutationFunction<UpdatePhaseMutation, UpdatePhaseMutationVariables>;
export type UpdatePhaseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePhaseMutation, UpdatePhaseMutationVariables>, 'mutation'>;

    export const UpdatePhaseComponent = (props: UpdatePhaseComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePhaseMutation, UpdatePhaseMutationVariables> mutation={UpdatePhaseDocument} {...props} />
    );
    
export type UpdatePhaseProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdatePhaseMutation, UpdatePhaseMutationVariables>
    } & TChildProps;
export function withUpdatePhase<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdatePhaseMutation,
  UpdatePhaseMutationVariables,
  UpdatePhaseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdatePhaseMutation, UpdatePhaseMutationVariables, UpdatePhaseProps<TChildProps, TDataName>>(UpdatePhaseDocument, {
      alias: 'updatePhase',
      ...operationOptions
    });
};

/**
 * __useUpdatePhaseMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseMutation, { data, loading, error }] = useUpdatePhaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      cursorIndexStart: // value for 'cursorIndexStart'
 *      cursorIndexEnd: // value for 'cursorIndexEnd'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePhaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePhaseMutation, UpdatePhaseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePhaseMutation, UpdatePhaseMutationVariables>(UpdatePhaseDocument, baseOptions);
      }
export type UpdatePhaseMutationHookResult = ReturnType<typeof useUpdatePhaseMutation>;
export type UpdatePhaseMutationResult = ApolloReactCommon.MutationResult<UpdatePhaseMutation>;
export type UpdatePhaseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePhaseMutation, UpdatePhaseMutationVariables>;
export const DeletePhaseDocument = gql`
    mutation DeletePhase($id: ID!) {
  deletePhase(id: $id)
}
    `;
export type DeletePhaseMutationFn = ApolloReactCommon.MutationFunction<DeletePhaseMutation, DeletePhaseMutationVariables>;
export type DeletePhaseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePhaseMutation, DeletePhaseMutationVariables>, 'mutation'>;

    export const DeletePhaseComponent = (props: DeletePhaseComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePhaseMutation, DeletePhaseMutationVariables> mutation={DeletePhaseDocument} {...props} />
    );
    
export type DeletePhaseProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeletePhaseMutation, DeletePhaseMutationVariables>
    } & TChildProps;
export function withDeletePhase<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePhaseMutation,
  DeletePhaseMutationVariables,
  DeletePhaseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePhaseMutation, DeletePhaseMutationVariables, DeletePhaseProps<TChildProps, TDataName>>(DeletePhaseDocument, {
      alias: 'deletePhase',
      ...operationOptions
    });
};

/**
 * __useDeletePhaseMutation__
 *
 * To run a mutation, you first call `useDeletePhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhaseMutation, { data, loading, error }] = useDeletePhaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePhaseMutation, DeletePhaseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePhaseMutation, DeletePhaseMutationVariables>(DeletePhaseDocument, baseOptions);
      }
export type DeletePhaseMutationHookResult = ReturnType<typeof useDeletePhaseMutation>;
export type DeletePhaseMutationResult = ApolloReactCommon.MutationResult<DeletePhaseMutation>;
export type DeletePhaseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePhaseMutation, DeletePhaseMutationVariables>;
export const AddContractDocument = gql`
    mutation AddContract($phaseId: ID!, $temporality: ContractTemporality!, $recipient: ContractRecipient!, $rule: ContractRule!, $quantificationWhat: ContractQuantificationWhat!, $quantificationHowMuch: Int!) {
  addContract(phaseId: $phaseId, temporality: $temporality, recipient: $recipient, rule: $rule, quantificationWhat: $quantificationWhat, quantificationHowMuch: $quantificationHowMuch) {
    ...ContractFragment
  }
}
    ${ContractFragmentFragmentDoc}`;
export type AddContractMutationFn = ApolloReactCommon.MutationFunction<AddContractMutation, AddContractMutationVariables>;
export type AddContractComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddContractMutation, AddContractMutationVariables>, 'mutation'>;

    export const AddContractComponent = (props: AddContractComponentProps) => (
      <ApolloReactComponents.Mutation<AddContractMutation, AddContractMutationVariables> mutation={AddContractDocument} {...props} />
    );
    
export type AddContractProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddContractMutation, AddContractMutationVariables>
    } & TChildProps;
export function withAddContract<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddContractMutation,
  AddContractMutationVariables,
  AddContractProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddContractMutation, AddContractMutationVariables, AddContractProps<TChildProps, TDataName>>(AddContractDocument, {
      alias: 'addContract',
      ...operationOptions
    });
};

/**
 * __useAddContractMutation__
 *
 * To run a mutation, you first call `useAddContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContractMutation, { data, loading, error }] = useAddContractMutation({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *      temporality: // value for 'temporality'
 *      recipient: // value for 'recipient'
 *      rule: // value for 'rule'
 *      quantificationWhat: // value for 'quantificationWhat'
 *      quantificationHowMuch: // value for 'quantificationHowMuch'
 *   },
 * });
 */
export function useAddContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddContractMutation, AddContractMutationVariables>) {
        return ApolloReactHooks.useMutation<AddContractMutation, AddContractMutationVariables>(AddContractDocument, baseOptions);
      }
export type AddContractMutationHookResult = ReturnType<typeof useAddContractMutation>;
export type AddContractMutationResult = ApolloReactCommon.MutationResult<AddContractMutation>;
export type AddContractMutationOptions = ApolloReactCommon.BaseMutationOptions<AddContractMutation, AddContractMutationVariables>;
export const UpdateContractDocument = gql`
    mutation UpdateContract($id: ID!, $temporality: ContractTemporality!, $recipient: ContractRecipient!, $rule: ContractRule!, $quantificationWhat: ContractQuantificationWhat!, $quantificationHowMuch: Int!) {
  updateContract(id: $id, temporality: $temporality, recipient: $recipient, rule: $rule, quantificationWhat: $quantificationWhat, quantificationHowMuch: $quantificationHowMuch) {
    ...ContractFragment
  }
}
    ${ContractFragmentFragmentDoc}`;
export type UpdateContractMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;
export type UpdateContractComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateContractMutation, UpdateContractMutationVariables>, 'mutation'>;

    export const UpdateContractComponent = (props: UpdateContractComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateContractMutation, UpdateContractMutationVariables> mutation={UpdateContractDocument} {...props} />
    );
    
export type UpdateContractProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>
    } & TChildProps;
export function withUpdateContract<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateContractMutation,
  UpdateContractMutationVariables,
  UpdateContractProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateContractMutation, UpdateContractMutationVariables, UpdateContractProps<TChildProps, TDataName>>(UpdateContractDocument, {
      alias: 'updateContract',
      ...operationOptions
    });
};

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      temporality: // value for 'temporality'
 *      recipient: // value for 'recipient'
 *      rule: // value for 'rule'
 *      quantificationWhat: // value for 'quantificationWhat'
 *      quantificationHowMuch: // value for 'quantificationHowMuch'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, baseOptions);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = ApolloReactCommon.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMutation, UpdateContractMutationVariables>;
export const DeleteContractDocument = gql`
    mutation DeleteContract($id: ID!) {
  deleteContract(id: $id)
}
    `;
export type DeleteContractMutationFn = ApolloReactCommon.MutationFunction<DeleteContractMutation, DeleteContractMutationVariables>;
export type DeleteContractComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteContractMutation, DeleteContractMutationVariables>, 'mutation'>;

    export const DeleteContractComponent = (props: DeleteContractComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteContractMutation, DeleteContractMutationVariables> mutation={DeleteContractDocument} {...props} />
    );
    
export type DeleteContractProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteContractMutation, DeleteContractMutationVariables>
    } & TChildProps;
export function withDeleteContract<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteContractMutation,
  DeleteContractMutationVariables,
  DeleteContractProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteContractMutation, DeleteContractMutationVariables, DeleteContractProps<TChildProps, TDataName>>(DeleteContractDocument, {
      alias: 'deleteContract',
      ...operationOptions
    });
};

/**
 * __useDeleteContractMutation__
 *
 * To run a mutation, you first call `useDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMutation, { data, loading, error }] = useDeleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractMutation, DeleteContractMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractMutation, DeleteContractMutationVariables>(DeleteContractDocument, baseOptions);
      }
export type DeleteContractMutationHookResult = ReturnType<typeof useDeleteContractMutation>;
export type DeleteContractMutationResult = ApolloReactCommon.MutationResult<DeleteContractMutation>;
export type DeleteContractMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractMutation, DeleteContractMutationVariables>;
export const AddConsultationRequestDocument = gql`
    mutation AddConsultationRequest($targetId: ID!, $versionId: ID!) {
  addConsultationRequest(targetId: $targetId, versionId: $versionId) {
    ...ConsultationRequestFragment
  }
}
    ${ConsultationRequestFragmentFragmentDoc}`;
export type AddConsultationRequestMutationFn = ApolloReactCommon.MutationFunction<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>;
export type AddConsultationRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>, 'mutation'>;

    export const AddConsultationRequestComponent = (props: AddConsultationRequestComponentProps) => (
      <ApolloReactComponents.Mutation<AddConsultationRequestMutation, AddConsultationRequestMutationVariables> mutation={AddConsultationRequestDocument} {...props} />
    );
    
export type AddConsultationRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>
    } & TChildProps;
export function withAddConsultationRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddConsultationRequestMutation,
  AddConsultationRequestMutationVariables,
  AddConsultationRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddConsultationRequestMutation, AddConsultationRequestMutationVariables, AddConsultationRequestProps<TChildProps, TDataName>>(AddConsultationRequestDocument, {
      alias: 'addConsultationRequest',
      ...operationOptions
    });
};

/**
 * __useAddConsultationRequestMutation__
 *
 * To run a mutation, you first call `useAddConsultationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConsultationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConsultationRequestMutation, { data, loading, error }] = useAddConsultationRequestMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useAddConsultationRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>(AddConsultationRequestDocument, baseOptions);
      }
export type AddConsultationRequestMutationHookResult = ReturnType<typeof useAddConsultationRequestMutation>;
export type AddConsultationRequestMutationResult = ApolloReactCommon.MutationResult<AddConsultationRequestMutation>;
export type AddConsultationRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<AddConsultationRequestMutation, AddConsultationRequestMutationVariables>;
export const UpdateConsultationRequestDocument = gql`
    mutation UpdateConsultationRequest($id: ID!, $status: ConsultationRequestStatus!) {
  updateConsultationRequest(id: $id, status: $status) {
    ...ConsultationRequestFragment
  }
}
    ${ConsultationRequestFragmentFragmentDoc}`;
export type UpdateConsultationRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>;
export type UpdateConsultationRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>, 'mutation'>;

    export const UpdateConsultationRequestComponent = (props: UpdateConsultationRequestComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables> mutation={UpdateConsultationRequestDocument} {...props} />
    );
    
export type UpdateConsultationRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>
    } & TChildProps;
export function withUpdateConsultationRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateConsultationRequestMutation,
  UpdateConsultationRequestMutationVariables,
  UpdateConsultationRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables, UpdateConsultationRequestProps<TChildProps, TDataName>>(UpdateConsultationRequestDocument, {
      alias: 'updateConsultationRequest',
      ...operationOptions
    });
};

/**
 * __useUpdateConsultationRequestMutation__
 *
 * To run a mutation, you first call `useUpdateConsultationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultationRequestMutation, { data, loading, error }] = useUpdateConsultationRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateConsultationRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>(UpdateConsultationRequestDocument, baseOptions);
      }
export type UpdateConsultationRequestMutationHookResult = ReturnType<typeof useUpdateConsultationRequestMutation>;
export type UpdateConsultationRequestMutationResult = ApolloReactCommon.MutationResult<UpdateConsultationRequestMutation>;
export type UpdateConsultationRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConsultationRequestMutation, UpdateConsultationRequestMutationVariables>;
export const DeleteConsultationRequestDocument = gql`
    mutation DeleteConsultationRequest($id: ID!) {
  deleteConsultationRequest(id: $id)
}
    `;
export type DeleteConsultationRequestMutationFn = ApolloReactCommon.MutationFunction<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>;
export type DeleteConsultationRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>, 'mutation'>;

    export const DeleteConsultationRequestComponent = (props: DeleteConsultationRequestComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables> mutation={DeleteConsultationRequestDocument} {...props} />
    );
    
export type DeleteConsultationRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>
    } & TChildProps;
export function withDeleteConsultationRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteConsultationRequestMutation,
  DeleteConsultationRequestMutationVariables,
  DeleteConsultationRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables, DeleteConsultationRequestProps<TChildProps, TDataName>>(DeleteConsultationRequestDocument, {
      alias: 'deleteConsultationRequest',
      ...operationOptions
    });
};

/**
 * __useDeleteConsultationRequestMutation__
 *
 * To run a mutation, you first call `useDeleteConsultationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultationRequestMutation, { data, loading, error }] = useDeleteConsultationRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsultationRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>(DeleteConsultationRequestDocument, baseOptions);
      }
export type DeleteConsultationRequestMutationHookResult = ReturnType<typeof useDeleteConsultationRequestMutation>;
export type DeleteConsultationRequestMutationResult = ApolloReactCommon.MutationResult<DeleteConsultationRequestMutation>;
export type DeleteConsultationRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConsultationRequestMutation, DeleteConsultationRequestMutationVariables>;
export const AddAnnotationDocument = gql`
    mutation AddAnnotation($versionId: ID!, $x: Float!, $y: Float!, $status: Status!) {
  addAnnotation(versionId: $versionId, x: $x, y: $y, status: $status) {
    ...AnnotationFragment
    author {
      ...UserFragment
    }
    threadMessages {
      ...ThreadMessageFragment
    }
  }
}
    ${AnnotationFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${ThreadMessageFragmentFragmentDoc}`;
export type AddAnnotationMutationFn = ApolloReactCommon.MutationFunction<AddAnnotationMutation, AddAnnotationMutationVariables>;
export type AddAnnotationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAnnotationMutation, AddAnnotationMutationVariables>, 'mutation'>;

    export const AddAnnotationComponent = (props: AddAnnotationComponentProps) => (
      <ApolloReactComponents.Mutation<AddAnnotationMutation, AddAnnotationMutationVariables> mutation={AddAnnotationDocument} {...props} />
    );
    
export type AddAnnotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddAnnotationMutation, AddAnnotationMutationVariables>
    } & TChildProps;
export function withAddAnnotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddAnnotationMutation,
  AddAnnotationMutationVariables,
  AddAnnotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddAnnotationMutation, AddAnnotationMutationVariables, AddAnnotationProps<TChildProps, TDataName>>(AddAnnotationDocument, {
      alias: 'addAnnotation',
      ...operationOptions
    });
};

/**
 * __useAddAnnotationMutation__
 *
 * To run a mutation, you first call `useAddAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnotationMutation, { data, loading, error }] = useAddAnnotationMutation({
 *   variables: {
 *      versionId: // value for 'versionId'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAddAnnotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAnnotationMutation, AddAnnotationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAnnotationMutation, AddAnnotationMutationVariables>(AddAnnotationDocument, baseOptions);
      }
export type AddAnnotationMutationHookResult = ReturnType<typeof useAddAnnotationMutation>;
export type AddAnnotationMutationResult = ApolloReactCommon.MutationResult<AddAnnotationMutation>;
export type AddAnnotationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAnnotationMutation, AddAnnotationMutationVariables>;
export const UpdateAnnotationDocument = gql`
    mutation UpdateAnnotation($id: ID!, $x: Float, $y: Float, $status: Status) {
  updateAnnotation(id: $id, x: $x, y: $y, status: $status) {
    ...AnnotationFragment
  }
}
    ${AnnotationFragmentFragmentDoc}`;
export type UpdateAnnotationMutationFn = ApolloReactCommon.MutationFunction<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>;
export type UpdateAnnotationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>, 'mutation'>;

    export const UpdateAnnotationComponent = (props: UpdateAnnotationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAnnotationMutation, UpdateAnnotationMutationVariables> mutation={UpdateAnnotationDocument} {...props} />
    );
    
export type UpdateAnnotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>
    } & TChildProps;
export function withUpdateAnnotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAnnotationMutation,
  UpdateAnnotationMutationVariables,
  UpdateAnnotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAnnotationMutation, UpdateAnnotationMutationVariables, UpdateAnnotationProps<TChildProps, TDataName>>(UpdateAnnotationDocument, {
      alias: 'updateAnnotation',
      ...operationOptions
    });
};

/**
 * __useUpdateAnnotationMutation__
 *
 * To run a mutation, you first call `useUpdateAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnotationMutation, { data, loading, error }] = useUpdateAnnotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAnnotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>(UpdateAnnotationDocument, baseOptions);
      }
export type UpdateAnnotationMutationHookResult = ReturnType<typeof useUpdateAnnotationMutation>;
export type UpdateAnnotationMutationResult = ApolloReactCommon.MutationResult<UpdateAnnotationMutation>;
export type UpdateAnnotationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>;
export const DeleteAnnotationDocument = gql`
    mutation DeleteAnnotation($id: ID!) {
  deleteAnnotation(id: $id)
}
    `;
export type DeleteAnnotationMutationFn = ApolloReactCommon.MutationFunction<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>;
export type DeleteAnnotationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>, 'mutation'>;

    export const DeleteAnnotationComponent = (props: DeleteAnnotationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAnnotationMutation, DeleteAnnotationMutationVariables> mutation={DeleteAnnotationDocument} {...props} />
    );
    
export type DeleteAnnotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>
    } & TChildProps;
export function withDeleteAnnotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAnnotationMutation,
  DeleteAnnotationMutationVariables,
  DeleteAnnotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAnnotationMutation, DeleteAnnotationMutationVariables, DeleteAnnotationProps<TChildProps, TDataName>>(DeleteAnnotationDocument, {
      alias: 'deleteAnnotation',
      ...operationOptions
    });
};

/**
 * __useDeleteAnnotationMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationMutation, { data, loading, error }] = useDeleteAnnotationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>(DeleteAnnotationDocument, baseOptions);
      }
export type DeleteAnnotationMutationHookResult = ReturnType<typeof useDeleteAnnotationMutation>;
export type DeleteAnnotationMutationResult = ApolloReactCommon.MutationResult<DeleteAnnotationMutation>;
export type DeleteAnnotationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>;
export const AddThreadMessageDocument = gql`
    mutation AddThreadMessage($annotationId: ID!, $data: String!) {
  addThreadMessage(annotationId: $annotationId, data: $data) {
    ...ThreadMessageFragment
    author {
      ...UserFragment
    }
  }
}
    ${ThreadMessageFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export type AddThreadMessageMutationFn = ApolloReactCommon.MutationFunction<AddThreadMessageMutation, AddThreadMessageMutationVariables>;
export type AddThreadMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddThreadMessageMutation, AddThreadMessageMutationVariables>, 'mutation'>;

    export const AddThreadMessageComponent = (props: AddThreadMessageComponentProps) => (
      <ApolloReactComponents.Mutation<AddThreadMessageMutation, AddThreadMessageMutationVariables> mutation={AddThreadMessageDocument} {...props} />
    );
    
export type AddThreadMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddThreadMessageMutation, AddThreadMessageMutationVariables>
    } & TChildProps;
export function withAddThreadMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddThreadMessageMutation,
  AddThreadMessageMutationVariables,
  AddThreadMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddThreadMessageMutation, AddThreadMessageMutationVariables, AddThreadMessageProps<TChildProps, TDataName>>(AddThreadMessageDocument, {
      alias: 'addThreadMessage',
      ...operationOptions
    });
};

/**
 * __useAddThreadMessageMutation__
 *
 * To run a mutation, you first call `useAddThreadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThreadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThreadMessageMutation, { data, loading, error }] = useAddThreadMessageMutation({
 *   variables: {
 *      annotationId: // value for 'annotationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddThreadMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddThreadMessageMutation, AddThreadMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddThreadMessageMutation, AddThreadMessageMutationVariables>(AddThreadMessageDocument, baseOptions);
      }
export type AddThreadMessageMutationHookResult = ReturnType<typeof useAddThreadMessageMutation>;
export type AddThreadMessageMutationResult = ApolloReactCommon.MutationResult<AddThreadMessageMutation>;
export type AddThreadMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddThreadMessageMutation, AddThreadMessageMutationVariables>;
export const UpdateThreadMessageDocument = gql`
    mutation UpdateThreadMessage($id: ID!, $data: String) {
  updateThreadMessage(id: $id, data: $data) {
    ...ThreadMessageFragment
    author {
      ...UserFragment
    }
  }
}
    ${ThreadMessageFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export type UpdateThreadMessageMutationFn = ApolloReactCommon.MutationFunction<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>;
export type UpdateThreadMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>, 'mutation'>;

    export const UpdateThreadMessageComponent = (props: UpdateThreadMessageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables> mutation={UpdateThreadMessageDocument} {...props} />
    );
    
export type UpdateThreadMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>
    } & TChildProps;
export function withUpdateThreadMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateThreadMessageMutation,
  UpdateThreadMessageMutationVariables,
  UpdateThreadMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables, UpdateThreadMessageProps<TChildProps, TDataName>>(UpdateThreadMessageDocument, {
      alias: 'updateThreadMessage',
      ...operationOptions
    });
};

/**
 * __useUpdateThreadMessageMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMessageMutation, { data, loading, error }] = useUpdateThreadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>(UpdateThreadMessageDocument, baseOptions);
      }
export type UpdateThreadMessageMutationHookResult = ReturnType<typeof useUpdateThreadMessageMutation>;
export type UpdateThreadMessageMutationResult = ApolloReactCommon.MutationResult<UpdateThreadMessageMutation>;
export type UpdateThreadMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateThreadMessageMutation, UpdateThreadMessageMutationVariables>;
export const DeleteThreadMessageDocument = gql`
    mutation DeleteThreadMessage($id: ID!) {
  deleteThreadMessage(id: $id)
}
    `;
export type DeleteThreadMessageMutationFn = ApolloReactCommon.MutationFunction<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>;
export type DeleteThreadMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>, 'mutation'>;

    export const DeleteThreadMessageComponent = (props: DeleteThreadMessageComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables> mutation={DeleteThreadMessageDocument} {...props} />
    );
    
export type DeleteThreadMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>
    } & TChildProps;
export function withDeleteThreadMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteThreadMessageMutation,
  DeleteThreadMessageMutationVariables,
  DeleteThreadMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables, DeleteThreadMessageProps<TChildProps, TDataName>>(DeleteThreadMessageDocument, {
      alias: 'deleteThreadMessage',
      ...operationOptions
    });
};

/**
 * __useDeleteThreadMessageMutation__
 *
 * To run a mutation, you first call `useDeleteThreadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThreadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThreadMessageMutation, { data, loading, error }] = useDeleteThreadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteThreadMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>(DeleteThreadMessageDocument, baseOptions);
      }
export type DeleteThreadMessageMutationHookResult = ReturnType<typeof useDeleteThreadMessageMutation>;
export type DeleteThreadMessageMutationResult = ApolloReactCommon.MutationResult<DeleteThreadMessageMutation>;
export type DeleteThreadMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteThreadMessageMutation, DeleteThreadMessageMutationVariables>;
export const JoinProjectWithTokenDocument = gql`
    mutation JoinProjectWithToken($token: String!) {
  joinProjectWithToken(token: $token) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type JoinProjectWithTokenMutationFn = ApolloReactCommon.MutationFunction<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>;
export type JoinProjectWithTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>, 'mutation'>;

    export const JoinProjectWithTokenComponent = (props: JoinProjectWithTokenComponentProps) => (
      <ApolloReactComponents.Mutation<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables> mutation={JoinProjectWithTokenDocument} {...props} />
    );
    
export type JoinProjectWithTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>
    } & TChildProps;
export function withJoinProjectWithToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JoinProjectWithTokenMutation,
  JoinProjectWithTokenMutationVariables,
  JoinProjectWithTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables, JoinProjectWithTokenProps<TChildProps, TDataName>>(JoinProjectWithTokenDocument, {
      alias: 'joinProjectWithToken',
      ...operationOptions
    });
};

/**
 * __useJoinProjectWithTokenMutation__
 *
 * To run a mutation, you first call `useJoinProjectWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinProjectWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinProjectWithTokenMutation, { data, loading, error }] = useJoinProjectWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useJoinProjectWithTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>(JoinProjectWithTokenDocument, baseOptions);
      }
export type JoinProjectWithTokenMutationHookResult = ReturnType<typeof useJoinProjectWithTokenMutation>;
export type JoinProjectWithTokenMutationResult = ApolloReactCommon.MutationResult<JoinProjectWithTokenMutation>;
export type JoinProjectWithTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinProjectWithTokenMutation, JoinProjectWithTokenMutationVariables>;
export const CreateProjectTokenDocument = gql`
    mutation CreateProjectToken($id: ID!) {
  createProjectToken(id: $id)
}
    `;
export type CreateProjectTokenMutationFn = ApolloReactCommon.MutationFunction<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>;
export type CreateProjectTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>, 'mutation'>;

    export const CreateProjectTokenComponent = (props: CreateProjectTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectTokenMutation, CreateProjectTokenMutationVariables> mutation={CreateProjectTokenDocument} {...props} />
    );
    
export type CreateProjectTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>
    } & TChildProps;
export function withCreateProjectToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateProjectTokenMutation,
  CreateProjectTokenMutationVariables,
  CreateProjectTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateProjectTokenMutation, CreateProjectTokenMutationVariables, CreateProjectTokenProps<TChildProps, TDataName>>(CreateProjectTokenDocument, {
      alias: 'createProjectToken',
      ...operationOptions
    });
};

/**
 * __useCreateProjectTokenMutation__
 *
 * To run a mutation, you first call `useCreateProjectTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTokenMutation, { data, loading, error }] = useCreateProjectTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateProjectTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>(CreateProjectTokenDocument, baseOptions);
      }
export type CreateProjectTokenMutationHookResult = ReturnType<typeof useCreateProjectTokenMutation>;
export type CreateProjectTokenMutationResult = ApolloReactCommon.MutationResult<CreateProjectTokenMutation>;
export type CreateProjectTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>;
export const GenerateDownloadTokenDocument = gql`
    mutation GenerateDownloadToken($id: ID!) {
  generateDownloadToken(id: $id)
}
    `;
export type GenerateDownloadTokenMutationFn = ApolloReactCommon.MutationFunction<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>;
export type GenerateDownloadTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>, 'mutation'>;

    export const GenerateDownloadTokenComponent = (props: GenerateDownloadTokenComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables> mutation={GenerateDownloadTokenDocument} {...props} />
    );
    
export type GenerateDownloadTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>
    } & TChildProps;
export function withGenerateDownloadToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateDownloadTokenMutation,
  GenerateDownloadTokenMutationVariables,
  GenerateDownloadTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables, GenerateDownloadTokenProps<TChildProps, TDataName>>(GenerateDownloadTokenDocument, {
      alias: 'generateDownloadToken',
      ...operationOptions
    });
};

/**
 * __useGenerateDownloadTokenMutation__
 *
 * To run a mutation, you first call `useGenerateDownloadTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDownloadTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDownloadTokenMutation, { data, loading, error }] = useGenerateDownloadTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateDownloadTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>(GenerateDownloadTokenDocument, baseOptions);
      }
export type GenerateDownloadTokenMutationHookResult = ReturnType<typeof useGenerateDownloadTokenMutation>;
export type GenerateDownloadTokenMutationResult = ApolloReactCommon.MutationResult<GenerateDownloadTokenMutation>;
export type GenerateDownloadTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateDownloadTokenMutation, GenerateDownloadTokenMutationVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    firstName
    lastName
    culture
    email
    avatarUrl
    jobs
    mailingPreferences
  }
}
    `;
export type GetCurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>, 'query'>;

    export const GetCurrentUserComponent = (props: GetCurrentUserComponentProps) => (
      <ApolloReactComponents.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> query={GetCurrentUserDocument} {...props} />
    );
    
export type GetCurrentUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCurrentUserQuery, GetCurrentUserQueryVariables>
    } & TChildProps;
export function withGetCurrentUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetCurrentUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCurrentUserQuery, GetCurrentUserQueryVariables, GetCurrentUserProps<TChildProps, TDataName>>(GetCurrentUserDocument, {
      alias: 'getCurrentUser',
      ...operationOptions
    });
};

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const AskResetPasswordDocument = gql`
    mutation AskResetPassword($email: String!) {
  askResetPassword(email: $email)
}
    `;
export type AskResetPasswordMutationFn = ApolloReactCommon.MutationFunction<AskResetPasswordMutation, AskResetPasswordMutationVariables>;
export type AskResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AskResetPasswordMutation, AskResetPasswordMutationVariables>, 'mutation'>;

    export const AskResetPasswordComponent = (props: AskResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<AskResetPasswordMutation, AskResetPasswordMutationVariables> mutation={AskResetPasswordDocument} {...props} />
    );
    
export type AskResetPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AskResetPasswordMutation, AskResetPasswordMutationVariables>
    } & TChildProps;
export function withAskResetPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AskResetPasswordMutation,
  AskResetPasswordMutationVariables,
  AskResetPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AskResetPasswordMutation, AskResetPasswordMutationVariables, AskResetPasswordProps<TChildProps, TDataName>>(AskResetPasswordDocument, {
      alias: 'askResetPassword',
      ...operationOptions
    });
};

/**
 * __useAskResetPasswordMutation__
 *
 * To run a mutation, you first call `useAskResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askResetPasswordMutation, { data, loading, error }] = useAskResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAskResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AskResetPasswordMutation, AskResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<AskResetPasswordMutation, AskResetPasswordMutationVariables>(AskResetPasswordDocument, baseOptions);
      }
export type AskResetPasswordMutationHookResult = ReturnType<typeof useAskResetPasswordMutation>;
export type AskResetPasswordMutationResult = ApolloReactCommon.MutationResult<AskResetPasswordMutation>;
export type AskResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<AskResetPasswordMutation, AskResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    
export type ResetPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>
    } & TChildProps;
export function withResetPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResetPasswordMutation, ResetPasswordMutationVariables, ResetPasswordProps<TChildProps, TDataName>>(ResetPasswordDocument, {
      alias: 'resetPassword',
      ...operationOptions
    });
};

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>
    } & TChildProps;
export function withLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps, TDataName>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!, $firstName: String!, $lastName: String!, $culture: UserCulture!, $jobs: [String]) {
  signUp(email: $email, password: $password, firstName: $firstName, lastName: $lastName, culture: $culture, jobs: $jobs)
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;
export type SignUpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignUpMutation, SignUpMutationVariables>, 'mutation'>;

    export const SignUpComponent = (props: SignUpComponentProps) => (
      <ApolloReactComponents.Mutation<SignUpMutation, SignUpMutationVariables> mutation={SignUpDocument} {...props} />
    );
    
export type SignUpProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>
    } & TChildProps;
export function withSignUp<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignUpMutation,
  SignUpMutationVariables,
  SignUpProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SignUpMutation, SignUpMutationVariables, SignUpProps<TChildProps, TDataName>>(SignUpDocument, {
      alias: 'signUp',
      ...operationOptions
    });
};

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      culture: // value for 'culture'
 *      jobs: // value for 'jobs'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const AskDeleteUserDocument = gql`
    mutation AskDeleteUser {
  askDeleteUser
}
    `;
export type AskDeleteUserMutationFn = ApolloReactCommon.MutationFunction<AskDeleteUserMutation, AskDeleteUserMutationVariables>;
export type AskDeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AskDeleteUserMutation, AskDeleteUserMutationVariables>, 'mutation'>;

    export const AskDeleteUserComponent = (props: AskDeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<AskDeleteUserMutation, AskDeleteUserMutationVariables> mutation={AskDeleteUserDocument} {...props} />
    );
    
export type AskDeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AskDeleteUserMutation, AskDeleteUserMutationVariables>
    } & TChildProps;
export function withAskDeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AskDeleteUserMutation,
  AskDeleteUserMutationVariables,
  AskDeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AskDeleteUserMutation, AskDeleteUserMutationVariables, AskDeleteUserProps<TChildProps, TDataName>>(AskDeleteUserDocument, {
      alias: 'askDeleteUser',
      ...operationOptions
    });
};

/**
 * __useAskDeleteUserMutation__
 *
 * To run a mutation, you first call `useAskDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askDeleteUserMutation, { data, loading, error }] = useAskDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useAskDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AskDeleteUserMutation, AskDeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AskDeleteUserMutation, AskDeleteUserMutationVariables>(AskDeleteUserDocument, baseOptions);
      }
export type AskDeleteUserMutationHookResult = ReturnType<typeof useAskDeleteUserMutation>;
export type AskDeleteUserMutationResult = ApolloReactCommon.MutationResult<AskDeleteUserMutation>;
export type AskDeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AskDeleteUserMutation, AskDeleteUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($token: String!) {
  deleteUser(token: $token)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>;

    export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables> mutation={DeleteUserDocument} {...props} />
    );
    
export type DeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>
    } & TChildProps;
export function withDeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserMutation, DeleteUserMutationVariables, DeleteUserProps<TChildProps, TDataName>>(DeleteUserDocument, {
      alias: 'deleteUser',
      ...operationOptions
    });
};

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($firstName: String, $lastName: String, $culture: UserCulture, $jobs: [String], $email: String, $mailingPreferences: String, $s3ObjectId: String) {
  updateUser(firstName: $firstName, lastName: $lastName, culture: $culture, jobs: $jobs, email: $email, mailingPreferences: $mailingPreferences, s3ObjectId: $s3ObjectId) {
    id
    firstName
    lastName
    culture
    email
    jobs
    avatarUrl
    mailingPreferences
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>
    } & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      culture: // value for 'culture'
 *      jobs: // value for 'jobs'
 *      email: // value for 'email'
 *      mailingPreferences: // value for 'mailingPreferences'
 *      s3ObjectId: // value for 's3ObjectId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const SendBugReportDocument = gql`
    mutation SendBugReport($message: String!) {
  sendBugReport(message: $message)
}
    `;
export type SendBugReportMutationFn = ApolloReactCommon.MutationFunction<SendBugReportMutation, SendBugReportMutationVariables>;
export type SendBugReportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendBugReportMutation, SendBugReportMutationVariables>, 'mutation'>;

    export const SendBugReportComponent = (props: SendBugReportComponentProps) => (
      <ApolloReactComponents.Mutation<SendBugReportMutation, SendBugReportMutationVariables> mutation={SendBugReportDocument} {...props} />
    );
    
export type SendBugReportProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendBugReportMutation, SendBugReportMutationVariables>
    } & TChildProps;
export function withSendBugReport<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendBugReportMutation,
  SendBugReportMutationVariables,
  SendBugReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendBugReportMutation, SendBugReportMutationVariables, SendBugReportProps<TChildProps, TDataName>>(SendBugReportDocument, {
      alias: 'sendBugReport',
      ...operationOptions
    });
};

/**
 * __useSendBugReportMutation__
 *
 * To run a mutation, you first call `useSendBugReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBugReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBugReportMutation, { data, loading, error }] = useSendBugReportMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendBugReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBugReportMutation, SendBugReportMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBugReportMutation, SendBugReportMutationVariables>(SendBugReportDocument, baseOptions);
      }
export type SendBugReportMutationHookResult = ReturnType<typeof useSendBugReportMutation>;
export type SendBugReportMutationResult = ApolloReactCommon.MutationResult<SendBugReportMutation>;
export type SendBugReportMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBugReportMutation, SendBugReportMutationVariables>;
export const FacebookLoginDocument = gql`
    mutation FacebookLogin($accessToken: String!, $culture: UserCulture!) {
  facebookLogin(accessToken: $accessToken, culture: $culture)
}
    `;
export type FacebookLoginMutationFn = ApolloReactCommon.MutationFunction<FacebookLoginMutation, FacebookLoginMutationVariables>;
export type FacebookLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FacebookLoginMutation, FacebookLoginMutationVariables>, 'mutation'>;

    export const FacebookLoginComponent = (props: FacebookLoginComponentProps) => (
      <ApolloReactComponents.Mutation<FacebookLoginMutation, FacebookLoginMutationVariables> mutation={FacebookLoginDocument} {...props} />
    );
    
export type FacebookLoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<FacebookLoginMutation, FacebookLoginMutationVariables>
    } & TChildProps;
export function withFacebookLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FacebookLoginMutation,
  FacebookLoginMutationVariables,
  FacebookLoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, FacebookLoginMutation, FacebookLoginMutationVariables, FacebookLoginProps<TChildProps, TDataName>>(FacebookLoginDocument, {
      alias: 'facebookLogin',
      ...operationOptions
    });
};

/**
 * __useFacebookLoginMutation__
 *
 * To run a mutation, you first call `useFacebookLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookLoginMutation, { data, loading, error }] = useFacebookLoginMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      culture: // value for 'culture'
 *   },
 * });
 */
export function useFacebookLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FacebookLoginMutation, FacebookLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<FacebookLoginMutation, FacebookLoginMutationVariables>(FacebookLoginDocument, baseOptions);
      }
export type FacebookLoginMutationHookResult = ReturnType<typeof useFacebookLoginMutation>;
export type FacebookLoginMutationResult = ApolloReactCommon.MutationResult<FacebookLoginMutation>;
export type FacebookLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<FacebookLoginMutation, FacebookLoginMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin($tokenId: String!, $culture: UserCulture!) {
  googleLogin(tokenId: $tokenId, culture: $culture)
}
    `;
export type GoogleLoginMutationFn = ApolloReactCommon.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;
export type GoogleLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GoogleLoginMutation, GoogleLoginMutationVariables>, 'mutation'>;

    export const GoogleLoginComponent = (props: GoogleLoginComponentProps) => (
      <ApolloReactComponents.Mutation<GoogleLoginMutation, GoogleLoginMutationVariables> mutation={GoogleLoginDocument} {...props} />
    );
    
export type GoogleLoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>
    } & TChildProps;
export function withGoogleLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GoogleLoginMutation,
  GoogleLoginMutationVariables,
  GoogleLoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GoogleLoginMutation, GoogleLoginMutationVariables, GoogleLoginProps<TChildProps, TDataName>>(GoogleLoginDocument, {
      alias: 'googleLogin',
      ...operationOptions
    });
};

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      culture: // value for 'culture'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, baseOptions);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = ApolloReactCommon.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  User: ResolverTypeWrapper<User>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  CurrentUser: ResolverTypeWrapper<CurrentUser>;
  UserCulture: UserCulture;
  ThreadMessage: ResolverTypeWrapper<ThreadMessage>;
  Annotation: ResolverTypeWrapper<Annotation>;
  Status: Status;
  Version: ResolverTypeWrapper<Version>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Proposition: ResolverTypeWrapper<Proposition>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Document: ResolverTypeWrapper<Document>;
  Project: ResolverTypeWrapper<Project>;
  Phase: ResolverTypeWrapper<Phase>;
  Contract: ResolverTypeWrapper<Contract>;
  ContractTemporality: ContractTemporality;
  ContractRecipient: ContractRecipient;
  ContractRule: ContractRule;
  ContractQuantificationWhat: ContractQuantificationWhat;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ConsultationRequest: ResolverTypeWrapper<ConsultationRequest>;
  ConsultationRequestStatus: ConsultationRequestStatus;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ProjectFilter: ProjectFilter;
  EventFilter: EventFilter;
  EVENT_TYPE: Event_Type;
  EventPagination: EventPagination;
  EventResponse: ResolverTypeWrapper<Omit<EventResponse, 'events'> & { events: Array<ResolversTypes['Event']> }>;
  Event: ResolversTypes['THREAD_MESSAGE_NEW'] | ResolversTypes['THREAD_MESSAGE_ANSWER'] | ResolversTypes['THREAD_MESSAGE_UPDATE'] | ResolversTypes['THREAD_MESSAGE_DELETE'] | ResolversTypes['ANNOTATION_NEW'] | ResolversTypes['ANNOTATION_DUPLICATE'] | ResolversTypes['ANNOTATION_UPDATE_STATUS'] | ResolversTypes['ANNOTATION_UPDATE_POSITION'] | ResolversTypes['ANNOTATION_DELETE'] | ResolversTypes['VERSION_NEW'] | ResolversTypes['VERSION_UPDATE_TITLE'] | ResolversTypes['VERSION_UPDATE_DRAWINGS'] | ResolversTypes['VERSION_DELETE'] | ResolversTypes['PROPOSITION_NEW'] | ResolversTypes['PROPOSITION_UPDATE_TITLE'] | ResolversTypes['PROPOSITION_DELETE'] | ResolversTypes['DOCUMENT_NEW'] | ResolversTypes['DOCUMENT_UPDATE_TITLE'] | ResolversTypes['DOCUMENT_DELETE'] | ResolversTypes['PROJECT_NEW'] | ResolversTypes['PROJECT_UPDATE_TITLE'] | ResolversTypes['PROJECT_UPDATE_DESCRIPTION'] | ResolversTypes['COLLAB_NEW'] | ResolversTypes['COLLAB_JOIN'] | ResolversTypes['COLLAB_DELETE'] | ResolversTypes['REVIEW_ASKED'] | ResolversTypes['REVIEW_FINISHED'] | ResolversTypes['CONTRACT_NEW'] | ResolversTypes['CONTRACT_UPDATE'] | ResolversTypes['CONTRACT_DELETE'] | ResolversTypes['PHASE_NEW'] | ResolversTypes['PHASE_UPDATE_TITLE'] | ResolversTypes['PHASE_UPDATE_DATE'] | ResolversTypes['PHASE_UPDATE_STATUS'] | ResolversTypes['PHASE_DELETE'];
  THREAD_MESSAGE_NEW: ResolverTypeWrapper<Thread_Message_New>;
  EventBase: ResolversTypes['THREAD_MESSAGE_NEW'] | ResolversTypes['THREAD_MESSAGE_ANSWER'] | ResolversTypes['THREAD_MESSAGE_UPDATE'] | ResolversTypes['THREAD_MESSAGE_DELETE'] | ResolversTypes['ANNOTATION_NEW'] | ResolversTypes['ANNOTATION_DUPLICATE'] | ResolversTypes['ANNOTATION_UPDATE_STATUS'] | ResolversTypes['ANNOTATION_UPDATE_POSITION'] | ResolversTypes['ANNOTATION_DELETE'] | ResolversTypes['VERSION_NEW'] | ResolversTypes['VERSION_UPDATE_TITLE'] | ResolversTypes['VERSION_UPDATE_DRAWINGS'] | ResolversTypes['VERSION_DELETE'] | ResolversTypes['PROPOSITION_NEW'] | ResolversTypes['PROPOSITION_UPDATE_TITLE'] | ResolversTypes['PROPOSITION_DELETE'] | ResolversTypes['DOCUMENT_NEW'] | ResolversTypes['DOCUMENT_UPDATE_TITLE'] | ResolversTypes['DOCUMENT_DELETE'] | ResolversTypes['PROJECT_NEW'] | ResolversTypes['PROJECT_UPDATE_TITLE'] | ResolversTypes['PROJECT_UPDATE_DESCRIPTION'] | ResolversTypes['COLLAB_NEW'] | ResolversTypes['COLLAB_JOIN'] | ResolversTypes['COLLAB_DELETE'] | ResolversTypes['REVIEW_ASKED'] | ResolversTypes['REVIEW_FINISHED'] | ResolversTypes['CONTRACT_NEW'] | ResolversTypes['CONTRACT_UPDATE'] | ResolversTypes['CONTRACT_DELETE'] | ResolversTypes['PHASE_NEW'] | ResolversTypes['PHASE_UPDATE_TITLE'] | ResolversTypes['PHASE_UPDATE_DATE'] | ResolversTypes['PHASE_UPDATE_STATUS'] | ResolversTypes['PHASE_DELETE'];
  THREAD_MESSAGE_ANSWER: ResolverTypeWrapper<Thread_Message_Answer>;
  THREAD_MESSAGE_UPDATE: ResolverTypeWrapper<Thread_Message_Update>;
  THREAD_MESSAGE_DELETE: ResolverTypeWrapper<Thread_Message_Delete>;
  ANNOTATION_NEW: ResolverTypeWrapper<Annotation_New>;
  ANNOTATION_DUPLICATE: ResolverTypeWrapper<Annotation_Duplicate>;
  ANNOTATION_UPDATE_STATUS: ResolverTypeWrapper<Annotation_Update_Status>;
  ANNOTATION_UPDATE_POSITION: ResolverTypeWrapper<Annotation_Update_Position>;
  ANNOTATION_DELETE: ResolverTypeWrapper<Annotation_Delete>;
  VERSION_NEW: ResolverTypeWrapper<Version_New>;
  VERSION_UPDATE_TITLE: ResolverTypeWrapper<Version_Update_Title>;
  VERSION_UPDATE_DRAWINGS: ResolverTypeWrapper<Version_Update_Drawings>;
  VERSION_DELETE: ResolverTypeWrapper<Version_Delete>;
  PROPOSITION_NEW: ResolverTypeWrapper<Proposition_New>;
  PROPOSITION_UPDATE_TITLE: ResolverTypeWrapper<Proposition_Update_Title>;
  PROPOSITION_DELETE: ResolverTypeWrapper<Proposition_Delete>;
  DOCUMENT_NEW: ResolverTypeWrapper<Document_New>;
  DOCUMENT_UPDATE_TITLE: ResolverTypeWrapper<Document_Update_Title>;
  DOCUMENT_DELETE: ResolverTypeWrapper<Document_Delete>;
  PROJECT_NEW: ResolverTypeWrapper<Project_New>;
  PROJECT_UPDATE_TITLE: ResolverTypeWrapper<Project_Update_Title>;
  PROJECT_UPDATE_DESCRIPTION: ResolverTypeWrapper<Project_Update_Description>;
  COLLAB_NEW: ResolverTypeWrapper<Collab_New>;
  COLLAB_JOIN: ResolverTypeWrapper<Collab_Join>;
  COLLAB_DELETE: ResolverTypeWrapper<Collab_Delete>;
  REVIEW_ASKED: ResolverTypeWrapper<Review_Asked>;
  REVIEW_FINISHED: ResolverTypeWrapper<Review_Finished>;
  CONTRACT_NEW: ResolverTypeWrapper<Contract_New>;
  CONTRACT_UPDATE: ResolverTypeWrapper<Contract_Update>;
  CONTRACT_DELETE: ResolverTypeWrapper<Contract_Delete>;
  PHASE_NEW: ResolverTypeWrapper<Phase_New>;
  PHASE_UPDATE_TITLE: ResolverTypeWrapper<Phase_Update_Title>;
  PHASE_UPDATE_DATE: ResolverTypeWrapper<Phase_Update_Date>;
  PHASE_UPDATE_STATUS: ResolverTypeWrapper<Phase_Update_Status>;
  PHASE_DELETE: ResolverTypeWrapper<Phase_Delete>;
  Mutation: ResolverTypeWrapper<{}>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  String: Scalars['String'];
  User: User;
  ID: Scalars['ID'];
  CurrentUser: CurrentUser;
  ThreadMessage: ThreadMessage;
  Annotation: Annotation;
  Version: Version;
  Date: Scalars['Date'];
  Proposition: Proposition;
  Boolean: Scalars['Boolean'];
  Document: Document;
  Project: Project;
  Phase: Phase;
  Contract: Contract;
  Int: Scalars['Int'];
  ConsultationRequest: ConsultationRequest;
  Float: Scalars['Float'];
  ProjectFilter: ProjectFilter;
  EventFilter: EventFilter;
  EventPagination: EventPagination;
  EventResponse: Omit<EventResponse, 'events'> & { events: Array<ResolversParentTypes['Event']> };
  Event: ResolversParentTypes['THREAD_MESSAGE_NEW'] | ResolversParentTypes['THREAD_MESSAGE_ANSWER'] | ResolversParentTypes['THREAD_MESSAGE_UPDATE'] | ResolversParentTypes['THREAD_MESSAGE_DELETE'] | ResolversParentTypes['ANNOTATION_NEW'] | ResolversParentTypes['ANNOTATION_DUPLICATE'] | ResolversParentTypes['ANNOTATION_UPDATE_STATUS'] | ResolversParentTypes['ANNOTATION_UPDATE_POSITION'] | ResolversParentTypes['ANNOTATION_DELETE'] | ResolversParentTypes['VERSION_NEW'] | ResolversParentTypes['VERSION_UPDATE_TITLE'] | ResolversParentTypes['VERSION_UPDATE_DRAWINGS'] | ResolversParentTypes['VERSION_DELETE'] | ResolversParentTypes['PROPOSITION_NEW'] | ResolversParentTypes['PROPOSITION_UPDATE_TITLE'] | ResolversParentTypes['PROPOSITION_DELETE'] | ResolversParentTypes['DOCUMENT_NEW'] | ResolversParentTypes['DOCUMENT_UPDATE_TITLE'] | ResolversParentTypes['DOCUMENT_DELETE'] | ResolversParentTypes['PROJECT_NEW'] | ResolversParentTypes['PROJECT_UPDATE_TITLE'] | ResolversParentTypes['PROJECT_UPDATE_DESCRIPTION'] | ResolversParentTypes['COLLAB_NEW'] | ResolversParentTypes['COLLAB_JOIN'] | ResolversParentTypes['COLLAB_DELETE'] | ResolversParentTypes['REVIEW_ASKED'] | ResolversParentTypes['REVIEW_FINISHED'] | ResolversParentTypes['CONTRACT_NEW'] | ResolversParentTypes['CONTRACT_UPDATE'] | ResolversParentTypes['CONTRACT_DELETE'] | ResolversParentTypes['PHASE_NEW'] | ResolversParentTypes['PHASE_UPDATE_TITLE'] | ResolversParentTypes['PHASE_UPDATE_DATE'] | ResolversParentTypes['PHASE_UPDATE_STATUS'] | ResolversParentTypes['PHASE_DELETE'];
  THREAD_MESSAGE_NEW: Thread_Message_New;
  EventBase: ResolversParentTypes['THREAD_MESSAGE_NEW'] | ResolversParentTypes['THREAD_MESSAGE_ANSWER'] | ResolversParentTypes['THREAD_MESSAGE_UPDATE'] | ResolversParentTypes['THREAD_MESSAGE_DELETE'] | ResolversParentTypes['ANNOTATION_NEW'] | ResolversParentTypes['ANNOTATION_DUPLICATE'] | ResolversParentTypes['ANNOTATION_UPDATE_STATUS'] | ResolversParentTypes['ANNOTATION_UPDATE_POSITION'] | ResolversParentTypes['ANNOTATION_DELETE'] | ResolversParentTypes['VERSION_NEW'] | ResolversParentTypes['VERSION_UPDATE_TITLE'] | ResolversParentTypes['VERSION_UPDATE_DRAWINGS'] | ResolversParentTypes['VERSION_DELETE'] | ResolversParentTypes['PROPOSITION_NEW'] | ResolversParentTypes['PROPOSITION_UPDATE_TITLE'] | ResolversParentTypes['PROPOSITION_DELETE'] | ResolversParentTypes['DOCUMENT_NEW'] | ResolversParentTypes['DOCUMENT_UPDATE_TITLE'] | ResolversParentTypes['DOCUMENT_DELETE'] | ResolversParentTypes['PROJECT_NEW'] | ResolversParentTypes['PROJECT_UPDATE_TITLE'] | ResolversParentTypes['PROJECT_UPDATE_DESCRIPTION'] | ResolversParentTypes['COLLAB_NEW'] | ResolversParentTypes['COLLAB_JOIN'] | ResolversParentTypes['COLLAB_DELETE'] | ResolversParentTypes['REVIEW_ASKED'] | ResolversParentTypes['REVIEW_FINISHED'] | ResolversParentTypes['CONTRACT_NEW'] | ResolversParentTypes['CONTRACT_UPDATE'] | ResolversParentTypes['CONTRACT_DELETE'] | ResolversParentTypes['PHASE_NEW'] | ResolversParentTypes['PHASE_UPDATE_TITLE'] | ResolversParentTypes['PHASE_UPDATE_DATE'] | ResolversParentTypes['PHASE_UPDATE_STATUS'] | ResolversParentTypes['PHASE_DELETE'];
  THREAD_MESSAGE_ANSWER: Thread_Message_Answer;
  THREAD_MESSAGE_UPDATE: Thread_Message_Update;
  THREAD_MESSAGE_DELETE: Thread_Message_Delete;
  ANNOTATION_NEW: Annotation_New;
  ANNOTATION_DUPLICATE: Annotation_Duplicate;
  ANNOTATION_UPDATE_STATUS: Annotation_Update_Status;
  ANNOTATION_UPDATE_POSITION: Annotation_Update_Position;
  ANNOTATION_DELETE: Annotation_Delete;
  VERSION_NEW: Version_New;
  VERSION_UPDATE_TITLE: Version_Update_Title;
  VERSION_UPDATE_DRAWINGS: Version_Update_Drawings;
  VERSION_DELETE: Version_Delete;
  PROPOSITION_NEW: Proposition_New;
  PROPOSITION_UPDATE_TITLE: Proposition_Update_Title;
  PROPOSITION_DELETE: Proposition_Delete;
  DOCUMENT_NEW: Document_New;
  DOCUMENT_UPDATE_TITLE: Document_Update_Title;
  DOCUMENT_DELETE: Document_Delete;
  PROJECT_NEW: Project_New;
  PROJECT_UPDATE_TITLE: Project_Update_Title;
  PROJECT_UPDATE_DESCRIPTION: Project_Update_Description;
  COLLAB_NEW: Collab_New;
  COLLAB_JOIN: Collab_Join;
  COLLAB_DELETE: Collab_Delete;
  REVIEW_ASKED: Review_Asked;
  REVIEW_FINISHED: Review_Finished;
  CONTRACT_NEW: Contract_New;
  CONTRACT_UPDATE: Contract_Update;
  CONTRACT_DELETE: Contract_Delete;
  PHASE_NEW: Phase_New;
  PHASE_UPDATE_TITLE: Phase_Update_Title;
  PHASE_UPDATE_DATE: Phase_Update_Date;
  PHASE_UPDATE_STATUS: Phase_Update_Status;
  PHASE_DELETE: Phase_Delete;
  Mutation: {};
};

export type AnnotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Annotation'] = ResolversParentTypes['Annotation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  threadMessages?: Resolver<Array<ResolversTypes['ThreadMessage']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Version'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  x?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  idInCanvas?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Annotation_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ANNOTATION_DELETE'] = ResolversParentTypes['ANNOTATION_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  displayId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Annotation_DuplicateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ANNOTATION_DUPLICATE'] = ResolversParentTypes['ANNOTATION_DUPLICATE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  parentAnnotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType>;
  parentAnnotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Annotation_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ANNOTATION_NEW'] = ResolversParentTypes['ANNOTATION_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  x?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType>;
  displayedId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Annotation_Update_PositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ANNOTATION_UPDATE_POSITION'] = ResolversParentTypes['ANNOTATION_UPDATE_POSITION']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  x?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Annotation_Update_StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ANNOTATION_UPDATE_STATUS'] = ResolversParentTypes['ANNOTATION_UPDATE_STATUS']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  annotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  oldStatus?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Collab_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['COLLAB_DELETE'] = ResolversParentTypes['COLLAB_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Collab_JoinResolvers<ContextType = any, ParentType extends ResolversParentTypes['COLLAB_JOIN'] = ResolversParentTypes['COLLAB_JOIN']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tokenCreatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  tokenCreatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tokenCreator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Collab_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['COLLAB_NEW'] = ResolversParentTypes['COLLAB_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ConsultationRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsultationRequest'] = ResolversParentTypes['ConsultationRequest']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ConsultationRequestStatus'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Version'], ParentType, ContextType>;
  seeker?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  temporality?: Resolver<Maybe<ResolversTypes['ContractTemporality']>, ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['ContractRecipient']>, ParentType, ContextType>;
  rule?: Resolver<Maybe<ResolversTypes['ContractRule']>, ParentType, ContextType>;
  quantificationWhat?: Resolver<Maybe<ResolversTypes['ContractQuantificationWhat']>, ParentType, ContextType>;
  quantificationHowMuch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phase?: Resolver<ResolversTypes['Phase'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Contract_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CONTRACT_DELETE'] = ResolversParentTypes['CONTRACT_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Contract_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['CONTRACT_NEW'] = ResolversParentTypes['CONTRACT_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  temporality?: Resolver<ResolversTypes['ContractTemporality'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['ContractRecipient'], ParentType, ContextType>;
  rule?: Resolver<ResolversTypes['ContractRule'], ParentType, ContextType>;
  quantificationWhat?: Resolver<ResolversTypes['ContractQuantificationWhat'], ParentType, ContextType>;
  quantificationHowMuch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Contract_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CONTRACT_UPDATE'] = ResolversParentTypes['CONTRACT_UPDATE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  temporality?: Resolver<ResolversTypes['ContractTemporality'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['ContractRecipient'], ParentType, ContextType>;
  rule?: Resolver<ResolversTypes['ContractRule'], ParentType, ContextType>;
  quantificationWhat?: Resolver<ResolversTypes['ContractQuantificationWhat'], ParentType, ContextType>;
  quantificationHowMuch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CurrentUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentUser'] = ResolversParentTypes['CurrentUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  culture?: Resolver<ResolversTypes['UserCulture'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mailingPreferences?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  propositions?: Resolver<Array<ResolversTypes['Proposition']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Document_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['DOCUMENT_DELETE'] = ResolversParentTypes['DOCUMENT_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Document_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['DOCUMENT_NEW'] = ResolversParentTypes['DOCUMENT_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Document_Update_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DOCUMENT_UPDATE_TITLE'] = ResolversParentTypes['DOCUMENT_UPDATE_TITLE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  __resolveType: TypeResolveFn<'THREAD_MESSAGE_NEW' | 'THREAD_MESSAGE_ANSWER' | 'THREAD_MESSAGE_UPDATE' | 'THREAD_MESSAGE_DELETE' | 'ANNOTATION_NEW' | 'ANNOTATION_DUPLICATE' | 'ANNOTATION_UPDATE_STATUS' | 'ANNOTATION_UPDATE_POSITION' | 'ANNOTATION_DELETE' | 'VERSION_NEW' | 'VERSION_UPDATE_TITLE' | 'VERSION_UPDATE_DRAWINGS' | 'VERSION_DELETE' | 'PROPOSITION_NEW' | 'PROPOSITION_UPDATE_TITLE' | 'PROPOSITION_DELETE' | 'DOCUMENT_NEW' | 'DOCUMENT_UPDATE_TITLE' | 'DOCUMENT_DELETE' | 'PROJECT_NEW' | 'PROJECT_UPDATE_TITLE' | 'PROJECT_UPDATE_DESCRIPTION' | 'COLLAB_NEW' | 'COLLAB_JOIN' | 'COLLAB_DELETE' | 'REVIEW_ASKED' | 'REVIEW_FINISHED' | 'CONTRACT_NEW' | 'CONTRACT_UPDATE' | 'CONTRACT_DELETE' | 'PHASE_NEW' | 'PHASE_UPDATE_TITLE' | 'PHASE_UPDATE_DATE' | 'PHASE_UPDATE_STATUS' | 'PHASE_DELETE', ParentType, ContextType>;
};

export type EventBaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventBase'] = ResolversParentTypes['EventBase']> = {
  __resolveType: TypeResolveFn<'THREAD_MESSAGE_NEW' | 'THREAD_MESSAGE_ANSWER' | 'THREAD_MESSAGE_UPDATE' | 'THREAD_MESSAGE_DELETE' | 'ANNOTATION_NEW' | 'ANNOTATION_DUPLICATE' | 'ANNOTATION_UPDATE_STATUS' | 'ANNOTATION_UPDATE_POSITION' | 'ANNOTATION_DELETE' | 'VERSION_NEW' | 'VERSION_UPDATE_TITLE' | 'VERSION_UPDATE_DRAWINGS' | 'VERSION_DELETE' | 'PROPOSITION_NEW' | 'PROPOSITION_UPDATE_TITLE' | 'PROPOSITION_DELETE' | 'DOCUMENT_NEW' | 'DOCUMENT_UPDATE_TITLE' | 'DOCUMENT_DELETE' | 'PROJECT_NEW' | 'PROJECT_UPDATE_TITLE' | 'PROJECT_UPDATE_DESCRIPTION' | 'COLLAB_NEW' | 'COLLAB_JOIN' | 'COLLAB_DELETE' | 'REVIEW_ASKED' | 'REVIEW_FINISHED' | 'CONTRACT_NEW' | 'CONTRACT_UPDATE' | 'CONTRACT_DELETE' | 'PHASE_NEW' | 'PHASE_UPDATE_TITLE' | 'PHASE_UPDATE_DATE' | 'PHASE_UPDATE_STATUS' | 'PHASE_DELETE', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type EventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventResponse'] = ResolversParentTypes['EventResponse']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  null?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  login?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'password'>>;
  signUp?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'email' | 'password' | 'firstName' | 'lastName' | 'culture'>>;
  updateUser?: Resolver<ResolversTypes['CurrentUser'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, never>>;
  askDeleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  askResetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAskResetPasswordArgs, 'email'>>;
  deleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'token'>>;
  resetPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'token' | 'password'>>;
  sendBugReport?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSendBugReportArgs, 'message'>>;
  facebookLogin?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationFacebookLoginArgs, 'accessToken' | 'culture'>>;
  googleLogin?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGoogleLoginArgs, 'tokenId' | 'culture'>>;
  addThreadMessage?: Resolver<ResolversTypes['ThreadMessage'], ParentType, ContextType, RequireFields<MutationAddThreadMessageArgs, 'annotationId' | 'data'>>;
  updateThreadMessage?: Resolver<ResolversTypes['ThreadMessage'], ParentType, ContextType, RequireFields<MutationUpdateThreadMessageArgs, 'id'>>;
  deleteThreadMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteThreadMessageArgs, 'id'>>;
  addAnnotation?: Resolver<ResolversTypes['Annotation'], ParentType, ContextType, RequireFields<MutationAddAnnotationArgs, 'versionId' | 'x' | 'y' | 'status'>>;
  updateAnnotation?: Resolver<ResolversTypes['Annotation'], ParentType, ContextType, RequireFields<MutationUpdateAnnotationArgs, 'id'>>;
  deleteAnnotation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAnnotationArgs, 'id'>>;
  addVersion?: Resolver<ResolversTypes['Version'], ParentType, ContextType, RequireFields<MutationAddVersionArgs, 'propositionId' | 'title' | 's3ObjectId'>>;
  updateVersion?: Resolver<ResolversTypes['Version'], ParentType, ContextType, RequireFields<MutationUpdateVersionArgs, 'id'>>;
  deleteVersion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteVersionArgs, 'id'>>;
  generateDownloadToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGenerateDownloadTokenArgs, 'id'>>;
  addProposition?: Resolver<ResolversTypes['Proposition'], ParentType, ContextType, RequireFields<MutationAddPropositionArgs, 'documentId' | 'title'>>;
  updateProposition?: Resolver<ResolversTypes['Proposition'], ParentType, ContextType, RequireFields<MutationUpdatePropositionArgs, 'id'>>;
  deleteProposition?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePropositionArgs, 'id'>>;
  addDocument?: Resolver<ResolversTypes['Document'], ParentType, ContextType, RequireFields<MutationAddDocumentArgs, 'projectId' | 'title'>>;
  updateDocument?: Resolver<ResolversTypes['Document'], ParentType, ContextType, RequireFields<MutationUpdateDocumentArgs, 'id'>>;
  deleteDocument?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDocumentArgs, 'id'>>;
  addProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationAddProjectArgs, 'title'>>;
  updateProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationUpdateProjectArgs, 'id'>>;
  deleteProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProjectArgs, 'id'>>;
  addCollaborators?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<MutationAddCollaboratorsArgs, 'projectId' | 'collaboratorsEmails'>>;
  removeCollaborators?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<MutationRemoveCollaboratorsArgs, 'projectId' | 'collaboratorsIds'>>;
  joinProjectWithToken?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationJoinProjectWithTokenArgs, 'token'>>;
  createProjectToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateProjectTokenArgs, 'id'>>;
  addPhase?: Resolver<ResolversTypes['Phase'], ParentType, ContextType, RequireFields<MutationAddPhaseArgs, 'projectId' | 'title' | 'startAt' | 'endAt' | 'status'>>;
  updatePhase?: Resolver<ResolversTypes['Phase'], ParentType, ContextType, RequireFields<MutationUpdatePhaseArgs, 'id'>>;
  deletePhase?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePhaseArgs, 'id'>>;
  addContract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationAddContractArgs, 'phaseId' | 'temporality' | 'recipient' | 'rule' | 'quantificationWhat' | 'quantificationHowMuch'>>;
  updateContract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationUpdateContractArgs, 'id'>>;
  deleteContract?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteContractArgs, 'id'>>;
  addConsultationRequest?: Resolver<ResolversTypes['ConsultationRequest'], ParentType, ContextType, RequireFields<MutationAddConsultationRequestArgs, 'versionId' | 'targetId'>>;
  updateConsultationRequest?: Resolver<ResolversTypes['ConsultationRequest'], ParentType, ContextType, RequireFields<MutationUpdateConsultationRequestArgs, 'id' | 'status'>>;
  deleteConsultationRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteConsultationRequestArgs, 'id'>>;
  markEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkEventsAsSeenArgs, 'eventIds'>>;
  markAnnotationEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkAnnotationEventsAsSeenArgs, 'annotationId'>>;
  markVersionEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkVersionEventsAsSeenArgs, 'versionId'>>;
  markDocumentsEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkDocumentsEventsAsSeenArgs, 'projectId'>>;
  markWorkflowEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkWorkflowEventsAsSeenArgs, 'projectId'>>;
  markProjectEventsAsSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMarkProjectEventsAsSeenArgs, 'projectId'>>;
};

export type PhaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Phase'] = ResolversParentTypes['Phase']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  contracts?: Resolver<Array<ResolversTypes['Contract']>, ParentType, ContextType>;
  cursorIndexStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cursorIndexEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  endAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Phase_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PHASE_DELETE'] = ResolversParentTypes['PHASE_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Phase_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PHASE_NEW'] = ResolversParentTypes['PHASE_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cursorIndexStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cursorIndexEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  endAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  phase?: Resolver<Maybe<ResolversTypes['Phase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Phase_Update_DateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PHASE_UPDATE_DATE'] = ResolversParentTypes['PHASE_UPDATE_DATE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  cursorIndexStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cursorIndexEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  endAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  phase?: Resolver<Maybe<ResolversTypes['Phase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Phase_Update_StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['PHASE_UPDATE_STATUS'] = ResolversParentTypes['PHASE_UPDATE_STATUS']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  oldStatus?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  phase?: Resolver<Maybe<ResolversTypes['Phase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Phase_Update_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PHASE_UPDATE_TITLE'] = ResolversParentTypes['PHASE_UPDATE_TITLE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phase?: Resolver<Maybe<ResolversTypes['Phase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  contributors?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>;
  phases?: Resolver<Array<ResolversTypes['Phase']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  previewImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unSeenEventsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  awaitingActionsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Project_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROJECT_NEW'] = ResolversParentTypes['PROJECT_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Project_Update_DescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROJECT_UPDATE_DESCRIPTION'] = ResolversParentTypes['PROJECT_UPDATE_DESCRIPTION']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Project_Update_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROJECT_UPDATE_TITLE'] = ResolversParentTypes['PROJECT_UPDATE_TITLE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PropositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Proposition'] = ResolversParentTypes['Proposition']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  document?: Resolver<ResolversTypes['Document'], ParentType, ContextType>;
  versions?: Resolver<Array<ResolversTypes['Version']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  previewImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Proposition_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROPOSITION_DELETE'] = ResolversParentTypes['PROPOSITION_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  propositionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Proposition_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROPOSITION_NEW'] = ResolversParentTypes['PROPOSITION_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  propositionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proposition?: Resolver<Maybe<ResolversTypes['Proposition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Proposition_Update_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PROPOSITION_UPDATE_TITLE'] = ResolversParentTypes['PROPOSITION_UPDATE_TITLE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  propositionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proposition?: Resolver<Maybe<ResolversTypes['Proposition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  currentUser?: Resolver<ResolversTypes['CurrentUser'], ParentType, ContextType>;
  threadMessage?: Resolver<ResolversTypes['ThreadMessage'], ParentType, ContextType, RequireFields<QueryThreadMessageArgs, 'id'>>;
  annotation?: Resolver<ResolversTypes['Annotation'], ParentType, ContextType, RequireFields<QueryAnnotationArgs, 'id'>>;
  version?: Resolver<ResolversTypes['Version'], ParentType, ContextType, RequireFields<QueryVersionArgs, 'id'>>;
  proposition?: Resolver<ResolversTypes['Proposition'], ParentType, ContextType, RequireFields<QueryPropositionArgs, 'id'>>;
  document?: Resolver<ResolversTypes['Document'], ParentType, ContextType, RequireFields<QueryDocumentArgs, 'id'>>;
  projects?: Resolver<Array<Maybe<ResolversTypes['Project']>>, ParentType, ContextType, RequireFields<QueryProjectsArgs, 'filter'>>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<QueryProjectArgs, 'id'>>;
  phase?: Resolver<ResolversTypes['Phase'], ParentType, ContextType, RequireFields<QueryPhaseArgs, 'id'>>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<QueryContractArgs, 'id'>>;
  consultationrequest?: Resolver<ResolversTypes['ConsultationRequest'], ParentType, ContextType, RequireFields<QueryConsultationrequestArgs, 'id'>>;
  events?: Resolver<ResolversTypes['EventResponse'], ParentType, ContextType, RequireFields<QueryEventsArgs, 'filter' | 'pagination'>>;
};

export type Review_AskedResolvers<ContextType = any, ParentType extends ResolversParentTypes['REVIEW_ASKED'] = ResolversParentTypes['REVIEW_ASKED']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  requestId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  request?: Resolver<Maybe<ResolversTypes['ConsultationRequest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Review_FinishedResolvers<ContextType = any, ParentType extends ResolversParentTypes['REVIEW_FINISHED'] = ResolversParentTypes['REVIEW_FINISHED']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ConsultationRequestStatus'], ParentType, ContextType>;
  requestId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  request?: Resolver<Maybe<ResolversTypes['ConsultationRequest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Thread_Message_AnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['THREAD_MESSAGE_ANSWER'] = ResolversParentTypes['THREAD_MESSAGE_ANSWER']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  threadMessageId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  threadMessage?: Resolver<Maybe<ResolversTypes['ThreadMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Thread_Message_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['THREAD_MESSAGE_DELETE'] = ResolversParentTypes['THREAD_MESSAGE_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  threadMessageId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  annotationDisplayId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Thread_Message_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['THREAD_MESSAGE_NEW'] = ResolversParentTypes['THREAD_MESSAGE_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  threadMessageId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  threadMessage?: Resolver<Maybe<ResolversTypes['ThreadMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Thread_Message_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['THREAD_MESSAGE_UPDATE'] = ResolversParentTypes['THREAD_MESSAGE_UPDATE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  threadMessageId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  threadMessage?: Resolver<Maybe<ResolversTypes['ThreadMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ThreadMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreadMessage'] = ResolversParentTypes['ThreadMessage']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  annotation?: Resolver<ResolversTypes['Annotation'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Version'] = ResolversParentTypes['Version']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drawings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  annotations?: Resolver<Array<ResolversTypes['Annotation']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proposition?: Resolver<ResolversTypes['Proposition'], ParentType, ContextType>;
  consultationRequests?: Resolver<Array<ResolversTypes['ConsultationRequest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Version_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['VERSION_DELETE'] = ResolversParentTypes['VERSION_DELETE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Version_NewResolvers<ContextType = any, ParentType extends ResolversParentTypes['VERSION_NEW'] = ResolversParentTypes['VERSION_NEW']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Version_Update_DrawingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VERSION_UPDATE_DRAWINGS'] = ResolversParentTypes['VERSION_UPDATE_DRAWINGS']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  drawings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Version_Update_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['VERSION_UPDATE_TITLE'] = ResolversParentTypes['VERSION_UPDATE_TITLE']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['EVENT_TYPE'], ParentType, ContextType>;
  unSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = any> = {
  Annotation?: AnnotationResolvers<ContextType>;
  ANNOTATION_DELETE?: Annotation_DeleteResolvers<ContextType>;
  ANNOTATION_DUPLICATE?: Annotation_DuplicateResolvers<ContextType>;
  ANNOTATION_NEW?: Annotation_NewResolvers<ContextType>;
  ANNOTATION_UPDATE_POSITION?: Annotation_Update_PositionResolvers<ContextType>;
  ANNOTATION_UPDATE_STATUS?: Annotation_Update_StatusResolvers<ContextType>;
  COLLAB_DELETE?: Collab_DeleteResolvers<ContextType>;
  COLLAB_JOIN?: Collab_JoinResolvers<ContextType>;
  COLLAB_NEW?: Collab_NewResolvers<ContextType>;
  ConsultationRequest?: ConsultationRequestResolvers<ContextType>;
  Contract?: ContractResolvers<ContextType>;
  CONTRACT_DELETE?: Contract_DeleteResolvers<ContextType>;
  CONTRACT_NEW?: Contract_NewResolvers<ContextType>;
  CONTRACT_UPDATE?: Contract_UpdateResolvers<ContextType>;
  CurrentUser?: CurrentUserResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Document?: DocumentResolvers<ContextType>;
  DOCUMENT_DELETE?: Document_DeleteResolvers<ContextType>;
  DOCUMENT_NEW?: Document_NewResolvers<ContextType>;
  DOCUMENT_UPDATE_TITLE?: Document_Update_TitleResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventBase?: EventBaseResolvers<ContextType>;
  EventResponse?: EventResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Phase?: PhaseResolvers<ContextType>;
  PHASE_DELETE?: Phase_DeleteResolvers<ContextType>;
  PHASE_NEW?: Phase_NewResolvers<ContextType>;
  PHASE_UPDATE_DATE?: Phase_Update_DateResolvers<ContextType>;
  PHASE_UPDATE_STATUS?: Phase_Update_StatusResolvers<ContextType>;
  PHASE_UPDATE_TITLE?: Phase_Update_TitleResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  PROJECT_NEW?: Project_NewResolvers<ContextType>;
  PROJECT_UPDATE_DESCRIPTION?: Project_Update_DescriptionResolvers<ContextType>;
  PROJECT_UPDATE_TITLE?: Project_Update_TitleResolvers<ContextType>;
  Proposition?: PropositionResolvers<ContextType>;
  PROPOSITION_DELETE?: Proposition_DeleteResolvers<ContextType>;
  PROPOSITION_NEW?: Proposition_NewResolvers<ContextType>;
  PROPOSITION_UPDATE_TITLE?: Proposition_Update_TitleResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  REVIEW_ASKED?: Review_AskedResolvers<ContextType>;
  REVIEW_FINISHED?: Review_FinishedResolvers<ContextType>;
  THREAD_MESSAGE_ANSWER?: Thread_Message_AnswerResolvers<ContextType>;
  THREAD_MESSAGE_DELETE?: Thread_Message_DeleteResolvers<ContextType>;
  THREAD_MESSAGE_NEW?: Thread_Message_NewResolvers<ContextType>;
  THREAD_MESSAGE_UPDATE?: Thread_Message_UpdateResolvers<ContextType>;
  ThreadMessage?: ThreadMessageResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Version?: VersionResolvers<ContextType>;
  VERSION_DELETE?: Version_DeleteResolvers<ContextType>;
  VERSION_NEW?: Version_NewResolvers<ContextType>;
  VERSION_UPDATE_DRAWINGS?: Version_Update_DrawingsResolvers<ContextType>;
  VERSION_UPDATE_TITLE?: Version_Update_TitleResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
