import React, { ReactElement, useEffect, useRef } from 'react';
import './Overlay.scss';

type OverlayProps = {
  close: () => void;
  children: React.ReactNode;
  className?: string;
}

function Overlay({ close, children, className }: OverlayProps): ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(document.createElement('div'));

  const handleClickOutside = (event): void => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup(): void {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay-container">
      <div ref={wrapperRef} className={className}>
        {children}
      </div>
    </div>
  );
}

export default Overlay;
