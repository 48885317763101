import { useProjectListContext } from 'context/ProjectListContext';
import { useUserContext } from 'context/UserContext';
import ProjectCard from 'Project/ProjectCard/ProjectCard';
import PropTypes from 'prop-types';
import React, { ReactElement, useEffect, useState } from 'react';
import Archive from 'react-feather/dist/icons/archive';
import Users from 'react-feather/dist/icons/users';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Project } from 'schema/generated/models';
import './ProjectCarrousel.scss';

interface ProjectItemProps {
  project: Project;
}

const ProjectItem = ({ project }: ProjectItemProps): ReactElement => (
  <ProjectCard key={project.id} project={project} clickableLinks={false} />
);

interface ArrowProps {
  text: string;
  className: string;
}
const Arrow = ({ text, className }: ArrowProps): ReactElement => <div className={className}>{text}</div>;

Arrow.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = (additionnalCN: string): ReactElement =>
  Arrow({ text: '>', className: `arrow-next ${additionnalCN}` });

interface ProjectCarouselProps {
  personnalOnly?: boolean;
  contributeOnly?: boolean;
}
const ProjectCarrousel = ({ personnalOnly = false, contributeOnly = false }: ProjectCarouselProps): ReactElement => {
  const { user } = useUserContext();
  const { getFilteredProjects, filter, projects } = useProjectListContext();
  const [, setData] = useState<Project[]>([]);
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    const filteredProjects = getFilteredProjects(user.id, personnalOnly, contributeOnly);
    setData(filteredProjects);
  }, [user.id, personalbar, contributeOnly, filter, projects.length]);

  if (!getFilteredProjects(user.id, personnalOnly, contributeOnly).length) {
    return <></>;
  }

  return (
    <div className={`management-row-${personnalOnly ? '1' : '2'}`}>
      {personnalOnly ? (
        <span className="project-title-personal">
          <Archive className="project-title-icon" />
          {t('project_list:section_header.admin_of')}
        </span>
      ) : (
        <span className="project-title-personal">
          <Users className="project-title-icon" />
          {t('project_list:section_header.contributor_of')}
        </span>
      )}
      <ScrollMenu
        data={getFilteredProjects(user.id, personnalOnly, contributeOnly).map((project) => (
          <ProjectItem project={project} key={project.id} />
        ))}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight(contributeOnly ? 'arrow-next-black' : '')}
        onSelect={(key): void => history.push(`/project/${key}/dashboard`)}
        alignCenter={false}
        wheel
      />
    </div>
  );
};

export default ProjectCarrousel;
