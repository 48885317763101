import CreaSpinner from 'components/Utilities/Spinner/CreaSpinner';
import { ProjectContextProvider, useProjectContext } from 'context/ProjectContext';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

const withProject = (Component: React.ComponentType): React.FC => (): ReactElement => {
  const { id } = useParams<{ id: string }>();

  const ProjectConsumer = (): ReactElement => {
    const { isLoading, projectError } = useProjectContext();
    if (isLoading) {
      return <CreaSpinner />;
    }
    if (projectError) {
      console.error(projectError);
      return <p>projectError</p>;
    }

    return <Component />;
  };

  return (
    <ProjectContextProvider projectId={id.toString()}>
      <ProjectConsumer />
    </ProjectContextProvider>
  );
};

export default withProject;
