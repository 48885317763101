import qs from 'query-string';
import { useCallback, useState } from 'react';


const setQueryStringWithoutPageReload = (qsValue): void => {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (key, value, queryString = window.location.search): void => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, queryString = window.location.search): string|string[]|undefined|null => {
  const values = qs.parse(queryString);
  return values[key];
};

function useQueryString(key, initialValue?): readonly[string, Function] {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key],
  );

  return [value, onSetValue] as const;
}

export default useQueryString;
