const browserLocale = navigator.language || navigator.languages[0] || 'en';
const getLocale = (): string => {
  const loc = browserLocale.split('-')[0];
  switch (loc) {
    case 'en':
      return 'en-US';
    case 'fr':
      return 'fr-FR';
    default:
      return 'en-US';
  }
};
const locale = getLocale();

export default locale;
