import CloseButton from 'components/Utilities/CloseButton';
import { useUserContext } from 'context/UserContext';
import * as OtherGA from 'JSUtils/ReactGA/other';
import React, { ReactElement } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import './BugReportModal.scss';


type BugReportModalProps = {
  close: () => void;
};

function BugReportModal({ close }: BugReportModalProps): ReactElement {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    submitFocusError: true,
    defaultValues: {
      description: '',
    },
  });
  const { t } = useTranslation();

  const { sendBugReport } = useUserContext();
  const sendReport = async (data): Promise<any> => {
    try {
      await sendBugReport({ variables: { message: data.description } });
    } catch (err) {
      errorAlert(t('common:misc:help_overlay.bug_report.form.error'));
      return;
    }
    successAlert(t('common:misc:help_overlay.bug_report.form.success'));
    OtherGA.sendReport();
    close();
  };

  return (
    <Modal id="bugReportModal" isOpen toggle={close} centered>
      <ModalHeader toggle={close} close={React.createElement((): ReactElement => CloseButton(close))}>{t('common:misc:help_overlay.bug_report.form.description.label')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(sendReport)} className="crea-form">
          <section>
            <p className="bugReportDescription">{t('common:misc:help_overlay.bug_report.form.description.subtitle')}</p>
            <textarea
              className={`${errors.description && 'error'}`}
              rows={10}
              name="description"
              id="description"
              ref={register({ required: true })}
            />
            <ErrorMessage errors={errors} name="description">
              {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
            </ErrorMessage>
          </section>
          <div className="action-buttons">
            <div className="cancel">
              <button type="button" onClick={close} className="creaflow-primary-button">
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="validation">
              <button className="creaflow-primary-button" type="submit">
                {t('common:form:action.send.label')}
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default BugReportModal;
