import { UpdatePhaseMutationVariables } from 'JSUtils/schema/generated/models';
import ReactGA from 'react-ga';

export const createPhase = (): void => {
  ReactGA.event({
    category: 'Workflow',
    action: 'Create phase',
  });
};

export const createContract = (): void => {
  ReactGA.event({
    category: 'Workflow',
    action: 'Create contract',
  });
};

export const updatePhase = (v: UpdatePhaseMutationVariables): void => {
  if (v.startAt !== undefined || v.endAt !== undefined) {
    ReactGA.event({
      category: 'Workflow',
      action: 'Update phase start/endAt',
    });
  }
  if (v.cursorIndexStart !== undefined || v.cursorIndexEnd !== undefined) {
    ReactGA.event({
      category: 'Workflow',
      action: 'Update phase cursorIndex start/end',
    });
  }
  if (v.status !== undefined) {
    ReactGA.event({
      category: 'Workflow',
      action: `Update phase status to ${v.status}`,
    });
  }
  if (v.title !== undefined) {
    ReactGA.event({
      category: 'Workflow',
      action: 'Update phase title',
    });
  }
};

export const updateContract = (): void => {
  ReactGA.event({
    category: 'Workflow',
    action: 'Update contract',
  });
};

export const deletePhase = (): void => {
  ReactGA.event({
    category: 'Workflow',
    action: 'Delete phase',
  });
};

export const deleteContract = (): void => {
  ReactGA.event({
    category: 'Workflow',
    action: 'Delete contract',
  });
};
