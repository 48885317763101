import ppPlaceholder from 'assets/pp-placeholder.png';
import { useUserContext } from 'context/UserContext';
import { base64StringtoFile, extractImageFileExtensionFromBase64 } from 'JSUtils/fileHandles';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Upload from 'react-feather/dist/icons/upload';
import XCircle from 'react-feather/dist/icons/x-circle';
import { ErrorMessage, FormContext, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CurrentUser } from 'schema/generated/models';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import { ProgressBar, UploadState, useS3Uploader } from 'Utilities/S3ImageUploader/useS3Uploader';
import PersonalPageHeader from '../components/PersonalPageHeader';
import './EditProfile.scss';

function EditProfileForm(): ReactElement {
  const { user, updateUser } = useUserContext();
  const { t } = useTranslation();
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [uploadState, setUploadState] = useState(UploadState.notStarted);
  const { postFile, progress } = useS3Uploader();

  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      jobs: user.jobs.map((j: string) => j),
    },
  });

  const jobsArrayMethods = useFieldArray({
    control: methods.control,
    name: 'jobs',
  });

  const editProfile = async (data: Record<string, any>): Promise<void> => {
    let s3ObjectId;
    if (avatarFile) {
      setUploadState(UploadState.uploading);
      const onError = (err): void => {
        if (err.response?.status === 422) {
          let error = t('common:misc:error.generic');
          switch (err.response.data) {
            case 'TYPE':
              error = t('common:misc:error.img.wrong_format');
              break;
            case 'SIZE':
              error = t('common:misc:error.img.too_big');
              break;
            default:
              break;
          }
          errorAlert(error);
        } else {
          errorAlert(err.message);
        }
      };
      const result = await postFile(avatarFile, avatarFile.name, onError);
      setUploadState(result.uploadState);
      s3ObjectId = result.key;
    }

    const tmpUser = {
      ...data,
      id: user.id,
      jobs: data.jobs !== undefined ? data.jobs : [],
      s3ObjectId,
    };

    try {
      await updateUser(tmpUser);
      successAlert(t('user:account_management.save.success'));
    } catch (err) {
      console.error(err);
      errorAlert(t('common:misc:error.generic'));
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(editProfile)} className="crea-form profile-content">
        <div id="avatar" className="section">
          <h1>{t('user:profile.picture.title')}</h1>
          {uploadState !== UploadState.notStarted && <ProgressBar progress={progress} uploadState={uploadState} />}
          <FormPicture user={user} setAvatarFile={setAvatarFile} />
        </div>
        <hr />
        <div id="jobs" className="section">
          <h1>{t('user:profile.jobs.title')}</h1>
          <FormJobs user={user} jobsArrayMethods={jobsArrayMethods} />
        </div>
        <hr />
        <div className="action-buttons success">
          <button className="creaflow-primary-button modify-ppage-save-button" type="submit">
            {t('common:form:action.save.label')}
          </button>
        </div>
      </form>
    </FormContext>
  );
}

export default function EditProfile(): ReactElement {
  const { isLoading } = useUserContext();

  return (
    <>
      <PersonalPageHeader active="profile" />
      <div className="edit-profile-content">
        <div className="centered">{!isLoading && <EditProfileForm />}</div>
      </div>
    </>
  );
}

interface FormPictureProps {
  user: CurrentUser;
  setAvatarFile: (file: File) => void;
}

const FormPicture = ({ user, setAvatarFile }: FormPictureProps): ReactElement => {
  const [upImg, setUpImg] = useState(null);
  const [imgExtension, setImgExtension] = useState('');
  const [imgRef, setImgRef] = useState<any>(null);
  const canvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', height: 100, aspect: 1 / 1 });
  const [imageName, setImageName] = useState('');
  const { t } = useTranslation();

  const getCroppedImg = (image, c, canvas): string => {
    const thisCanvas = canvas;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    thisCanvas.width = c.width;
    thisCanvas.height = c.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(image, c.x * scaleX, c.y * scaleY, c.width * scaleX, c.height * scaleY, 0, 0, c.width, c.height);
    }

    return canvas.toDataURL('image/jpeg');
  };
  const makeClientCrop = (thisCrop): void => {
    if (imgRef && thisCrop.width && thisCrop.height) {
      getCroppedImg(imgRef, thisCrop, canvasRef.current);
      const canvas: any = canvasRef.current;
      const imageData64 = canvas.toDataURL(`image/${imgExtension}`);
      const myFilename = `previewFile.${imgExtension}`;

      const file = base64StringtoFile(imageData64, myFilename);
      setAvatarFile(file);
    }
  };

  useEffect(() => {
    if (imgRef && canvasRef.current) {
      makeClientCrop(crop);
    }
  }, [imgRef, canvasRef.current]);

  const onSelectFile = (e): void => {
    if (e.target.files && e.target.files.length > 0) {
      setImageName(e.target.files[0].name);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgExtension(extractImageFileExtensionFromBase64(reader.result as any));
        setUpImg(reader.result as any);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    setImgRef(img);
  }, []);

  return (
    <div className="modify-ppage-box avatar">
      <div className="modify-ppage-box-content">
        <section className="input-avatar crea-img-upload">
          <label htmlFor="avatarUrl">{t('user:profile.picture.title')}</label>
          <img
            src={user.avatarUrl === '' ? ppPlaceholder : user.avatarUrl}
            alt="preview"
            className="preview"
            style={{ display: !upImg ? 'flex' : 'none' }}
          />
          <canvas ref={canvasRef} className="preview" style={{ display: !upImg ? 'none' : 'flex' }} />

          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            name="avatarUrl"
            id="avatarUrl"
            onChange={onSelectFile}
          />
          <label htmlFor="avatarUrl">
            <Upload className="icon" />
            {imageName !== '' ? t('user:profile.picture.replace') : t('user:profile.picture.update')}
          </label>
        </section>
        <section className="img-crop-tool" style={{ display: !upImg ? 'none' : 'flex' }}>
          <ReactCrop
            src={upImg}
            ruleOfThirds
            circularCrop
            onChange={(c): void => setCrop(c)}
            onImageLoaded={onLoad}
            crop={crop}
            onComplete={makeClientCrop}
          />
        </section>
      </div>
    </div>
  );
};

interface FormJobsProps {
  user: CurrentUser;
  jobsArrayMethods: any;
}

function FormJobs({ jobsArrayMethods }: FormJobsProps): ReactElement {
  const { errors, register } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="modify-ppage-box jobs">
      {/* <span className="modify-ppage-box-title">
        <Briefcase className="modify-ppage-icon" />
        {i18n.ppage_modify_title_jobs[user.culture]}
      </span> */}
      <div className="modify-ppage-box-content">
        <section>
          <div className="actions">
            <button
              type="button"
              className="creaflow-secondary-button-v2 modify-ppage-add"
              onClick={(): void => jobsArrayMethods.append({ name: 'jobs' })}
            >
              <FaPlus />
              {t('user:profile.jobs.add')}
            </button>
          </div>
        </section>
        <section>
          {jobsArrayMethods?.fields?.map((item, index) => (
            <div key={item.id} className="content">
              <input
                type="text"
                placeholder={t('user:profile.jobs.placeholder')}
                className={errors.jobs && errors.jobs[index] && 'error'}
                ref={register({ required: true })}
                name={`jobs[${index}]`}
              />
              <ErrorMessage errors={errors} name={`jobs[${index}]`}>
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
              <Button className="modify-ppage-delete" onClick={(): void => jobsArrayMethods.remove(index)}>
                <XCircle className="modify-ppage-icon" />
              </Button>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
