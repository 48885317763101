/* eslint-disable @typescript-eslint/camelcase */
import Tooltip from 'components/Utilities/Tooltip/Tooltip';
import { TabsNames } from 'containers/Project/DashboardSpace/components/Events/EventList';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { TFunction } from 'i18next';
import { getStatusHumanReadable } from 'models/EStatus';
import 'moment/locale/fr';
import React, { ReactElement, useRef } from 'react';
import EyeOff from 'react-feather/dist/icons/eye-off';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Annotation,
  Annotation_Delete,
  Annotation_Duplicate,
  Annotation_New,
  Annotation_Update_Position,
  Annotation_Update_Status,
  Collab_Delete,
  Collab_Join,
  Collab_New,
  Contract_Delete,
  Contract_New,
  Contract_Update,
  Document,
  Document_Delete,
  Document_New,
  Document_Update_Title,
  Event,
  Event_Type,
  Phase,
  Phase_Delete,
  Phase_New,
  Phase_Update_Date,
  Phase_Update_Status,
  Phase_Update_Title,
  Project_New,
  Project_Update_Description,
  Project_Update_Title,
  Proposition,
  Proposition_Delete,
  Proposition_New,
  Proposition_Update_Title,
  Review_Asked,
  Review_Finished,
  Thread_Message_Answer,
  Thread_Message_Delete,
  Thread_Message_New,
  Thread_Message_Update,
  User,
  Version,
  Version_Delete,
  Version_New,
  Version_Update_Drawings,
  Version_Update_Title,
} from 'schema/generated/models';
import CreaDate from 'Utilities/CreaDate/CreaDate';
import DocumentLink from './DocumentLink';
import './EventRow.scss';
import PropositionLink from './PropositionLink';
import VersionLink from './VersionLink';

type GetLinkType = {
  entity?: Annotation | Version | Proposition | Document | Phase;
  defaultTitle?: string;
  reachable?: boolean;
};

function GetLink({ entity, defaultTitle, reachable = true }: GetLinkType): ReactElement {
  const { t } = useTranslation();
  const { project } = useProjectContext();
  let documentId: string;
  let propositionId: string;
  let versionId: string;
  let annotationId: string;
  let linkUrl: string | undefined;
  let linkTitle: string;

  if (entity?.__typename === 'Annotation') {
    documentId = entity.version.proposition.document.id;
    propositionId = entity.version.proposition.id;
    versionId = entity.version.id;
    annotationId = entity.id;
    linkUrl = `/project/${project.id}/${documentId}/${propositionId}/${versionId}?annotation=${annotationId}`;
    linkTitle = `#${entity.idInCanvas}`;
  } else if (entity?.__typename === 'Version') {
    documentId = entity.proposition.document.id;
    propositionId = entity.proposition.id;
    versionId = entity.id;
    linkUrl = `/project/${project.id}/${documentId}/${propositionId}/${versionId}`;
    linkTitle = entity.title;
  } else if (entity?.__typename === 'Proposition') {
    documentId = entity.document.id;
    propositionId = entity.id;
    linkUrl = `/project/${project.id}/${documentId}/${propositionId}`;
    linkTitle = entity.title;
  } else if (entity?.__typename === 'Document') {
    documentId = entity.id;
    linkUrl = `/project/${project.id}/documents/${documentId}`;
    linkTitle = entity.title;
  } else if (entity?.__typename === 'Phase') {
    linkUrl = `/project/${project.id}/workflow?phase=${entity.id}`;
    linkTitle = entity.title;
  } else if (reachable === false) {
    linkTitle = t('project:common:event.entity_unreachable');
  } else {
    linkTitle = t('project:common:event.entity_deleted');
    if (defaultTitle !== undefined) linkTitle = `${defaultTitle} ${linkTitle}`;
  }

  if (!linkUrl) return <span>{linkTitle}</span>;
  return <Link to={linkUrl}>{linkTitle}</Link>;
}

const getAuthorName = (t: TFunction, author?: User | null): string => {
  if (author === null || author === undefined) return t('project:common:deleted_user');
  return `${author.firstName} ${author.lastName}`;
};

function EventThreadMessageNew(event: Thread_Message_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.thread_message_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.threadMessage?.annotation} reachable={event.threadMessage?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventThreadMessageAnswer(event: Thread_Message_Answer): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.thread_message_answer">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.threadMessage?.annotation} reachable={event.threadMessage?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventThreadMessageUpdate(event: Thread_Message_Update): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.thread_message_update">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.threadMessage?.annotation} reachable={event.threadMessage?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventThreadMessageDelete(event: Thread_Message_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.thread_message_delete">
        {{ authorName: getAuthorName(t, event.author) }}
        {{ displayId: `#${event.annotationDisplayId}` }}
      </Trans>
    </span>
  );
}

function EventAnnotationNew(event: Annotation_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.annotation_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.annotation || undefined} defaultTitle={`#${event.displayedId}`} />
        <GetLink entity={event.annotation?.version} reachable={event.annotation?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventAnnotationUpdatePosition(event: Annotation_Update_Position): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.annotation_update_position">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.annotation || undefined} />
      </Trans>
    </span>
  );
}

function EventAnnotationUpdateStatus(event: Annotation_Update_Status): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.annotation_update_status">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.annotation || undefined} />
        {{ status: getStatusHumanReadable(event.status) }}
        {{ oldStatus: getStatusHumanReadable(event.oldStatus) }}
      </Trans>
    </span>
  );
}

function EventAnnotationDuplicate(event: Annotation_Duplicate): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.annotation_duplicate">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.annotation || undefined} />
      </Trans>
    </span>
  );
}

function EventAnnotationDelete(event: Annotation_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.annotation_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventVersionNew(event: Version_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.version_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.version || undefined} defaultTitle={event.title} />
        <GetLink entity={event.version?.proposition} reachable={event.version?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventVersionUpdateDrawings(event: Version_Update_Drawings): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.version_update_drawings">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.version || undefined} />
      </Trans>
    </span>
  );
}

function EventVersionUpdateTitle(event: Version_Update_Title): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.version_update_title">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.version || undefined} />
      </Trans>
    </span>
  );
}

function EventVersionDelete(event: Version_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.version_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventPropositionNew(event: Proposition_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.proposition_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.proposition || undefined} defaultTitle={event.title} />
        <GetLink entity={event.proposition?.document} reachable={event.proposition?.id !== undefined} />
      </Trans>
    </span>
  );
}

function EventPropositionUpdateTitle(event: Proposition_Update_Title): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.proposition_update_title">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.proposition || undefined} />
      </Trans>
    </span>
  );
}

function EventPropositionDelete(event: Proposition_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.proposition_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventDocumentNew(event: Document_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.document_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.document || undefined} defaultTitle={event.title} />
      </Trans>
    </span>
  );
}

function EventDocumentUpdateTitle(event: Document_Update_Title): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.document_update_title">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.document || undefined} />
      </Trans>
    </span>
  );
}

function EventDocumentDelete(event: Document_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.document_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventProjectNew(event: Project_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.project_new">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventProjectUpdateTitle(event: Project_Update_Title): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.project_update_title">
        {{ authorName: getAuthorName(t, event.author) }}
      </Trans>
    </span>
  );
}

function EventProjectUpdateDescription(event: Project_Update_Description): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.project_update_description">
        {{ authorName: getAuthorName(t, event.author) }}
      </Trans>
    </span>
  );
}

function EventPhaseNew(event: Phase_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.phase_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.phase || undefined} defaultTitle={event.title} />
      </Trans>
    </span>
  );
}

function EventPhaseUpdateTitle(event: Phase_Update_Title): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.phase_update_title">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.phase || undefined} />
      </Trans>
    </span>
  );
}

function EventPhaseUpdateDate(event: Phase_Update_Date): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.phase_update_date">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.phase || undefined} />
      </Trans>
    </span>
  );
}

function EventPhaseUpdateStatus(event: Phase_Update_Status): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.phase_update_status">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.phase || undefined} />
        {{ status: getStatusHumanReadable(event.status) }}
        {{ oldStatus: getStatusHumanReadable(event.oldStatus) }}
      </Trans>
    </span>
  );
}

function EventPhaseDelete(event: Phase_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.phase_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventContractNew(event: Contract_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.contract_new">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.contract?.phase} />
      </Trans>
    </span>
  );
}

function EventContractUpdate(event: Contract_Update): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.contract_update">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.contract?.phase} />
      </Trans>
    </span>
  );
}

function EventContractDelete(event: Contract_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.contract_delete">{{ authorName: getAuthorName(t, event.author) }}</Trans>
    </span>
  );
}

function EventProjectCollabNew(event: Collab_New): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.collab_new">
        {{ authorName: getAuthorName(t, event.author), collabName: getAuthorName(t, event.targetUser) }}
      </Trans>
    </span>
  );
}

function EventProjectCollabJoin(event: Collab_Join): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.collab_join">
        {{ authorName: getAuthorName(t, event.author), tokenCreatorName: getAuthorName(t, event.tokenCreator) }}
      </Trans>
    </span>
  );
}

function EventProjectCollabDelete(event: Collab_Delete): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.collab_delete">
        {{ authorName: getAuthorName(t, event.author), collabName: getAuthorName(t, event.targetUser) }}
      </Trans>
    </span>
  );
}

function EventProjectReviewAsked(event: Review_Asked): ReactElement {
  const { t } = useTranslation();
  const { user } = useUserContext();
  if (event.author?.id === user.id) {
    return (
      <span>
        <Trans i18nKey="project:common:event.review_asked_you">
          {{ authorName: getAuthorName(t, event.author) }}
          <GetLink entity={event.version || undefined} />
        </Trans>
      </span>
    );
  }
  return (
    <span>
      <Trans i18nKey="project:common:event.review_asked_someone">
        {{ authorName: getAuthorName(t, event.author), targetName: getAuthorName(t, event.targetUser) }}
        <GetLink entity={event.version || undefined} />
      </Trans>
    </span>
  );
}

function EventProjectReviewFinished(event: Review_Finished): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.review_finished">
        {{ authorName: getAuthorName(t, event.author) }}
        <GetLink entity={event.version || undefined} />
      </Trans>
    </span>
  );
}

function EventUnknown(event: Event): ReactElement {
  const { t } = useTranslation();
  return (
    <span>
      <Trans i18nKey="project:common:event.unknown">
        {event}
        {{ authorName: getAuthorName(t, event.author) }}
      </Trans>
    </span>
  );
}

function EventMessage({ event }: { event: Event }): ReactElement {
  switch (event.type) {
    case Event_Type.ThreadMessageNew:
      return EventThreadMessageNew(event as Thread_Message_New);
    case Event_Type.ThreadMessageAnswer:
      return EventThreadMessageAnswer(event as Thread_Message_Answer);
    case Event_Type.ThreadMessageUpdate:
      return EventThreadMessageUpdate(event as Thread_Message_Update);
    case Event_Type.ThreadMessageDelete:
      return EventThreadMessageDelete(event as Thread_Message_Delete);
    case Event_Type.AnnotationNew:
      return EventAnnotationNew(event as Annotation_New);
    case Event_Type.AnnotationUpdatePosition:
      return EventAnnotationUpdatePosition(event as Annotation_Update_Position);
    case Event_Type.AnnotationUpdateStatus:
      return EventAnnotationUpdateStatus(event as Annotation_Update_Status);
    case Event_Type.AnnotationDuplicate:
      return EventAnnotationDuplicate(event as Annotation_Duplicate);
    case Event_Type.AnnotationDelete:
      return EventAnnotationDelete(event as Annotation_Delete);
    case Event_Type.VersionNew:
      return EventVersionNew(event as Version_New);
    case Event_Type.VersionUpdateDrawings:
      return EventVersionUpdateDrawings(event as Version_Update_Drawings);
    case Event_Type.VersionUpdateTitle:
      return EventVersionUpdateTitle(event as Version_Update_Title);
    case Event_Type.VersionDelete:
      return EventVersionDelete(event as Version_Delete);
    case Event_Type.PropositionNew:
      return EventPropositionNew(event as Proposition_New);
    case Event_Type.PropositionUpdateTitle:
      return EventPropositionUpdateTitle(event as Proposition_Update_Title);
    case Event_Type.PropositionDelete:
      return EventPropositionDelete(event as Proposition_Delete);
    case Event_Type.DocumentNew:
      return EventDocumentNew(event as Document_New);
    case Event_Type.DocumentUpdateTitle:
      return EventDocumentUpdateTitle(event as Document_Update_Title);
    case Event_Type.DocumentDelete:
      return EventDocumentDelete(event as Document_Delete);
    case Event_Type.ProjectNew:
      return EventProjectNew(event as Project_New);
    case Event_Type.ProjectUpdateTitle:
      return EventProjectUpdateTitle(event as Project_Update_Title);
    case Event_Type.ProjectUpdateDescription:
      return EventProjectUpdateDescription(event as Project_Update_Description);
    case Event_Type.PhaseNew:
      return EventPhaseNew(event as Phase_New);
    case Event_Type.PhaseUpdateTitle:
      return EventPhaseUpdateTitle(event as Phase_Update_Title);
    case Event_Type.PhaseUpdateDate:
      return EventPhaseUpdateDate(event as Phase_Update_Date);
    case Event_Type.PhaseUpdateStatus:
      return EventPhaseUpdateStatus(event as Phase_Update_Status);
    case Event_Type.PhaseDelete:
      return EventPhaseDelete(event as Phase_Delete);
    case Event_Type.ContractNew:
      return EventContractNew(event as Contract_New);
    case Event_Type.ContractUpdate:
      return EventContractUpdate(event as Contract_Update);
    case Event_Type.ContractDelete:
      return EventContractDelete(event as Contract_Delete);
    case Event_Type.CollabNew:
      return EventProjectCollabNew(event as Collab_New);
    case Event_Type.CollabJoin:
      return EventProjectCollabJoin(event as Collab_Join);
    case Event_Type.CollabDelete:
      return EventProjectCollabDelete(event as Collab_Delete);
    case Event_Type.ReviewAsked:
      return EventProjectReviewAsked(event as Review_Asked);
    case Event_Type.ReviewFinished:
      return EventProjectReviewFinished(event as Review_Finished);

    default:
      return EventUnknown(event);
  }
}

function EventRow({ event, type }: { event: Event; type: TabsNames}): ReactElement {
  const { markEventsAsSeen } = useProjectContext();
  const unseenButtonRef = useRef<HTMLDivElement>(document.createElement('div'));
  const { t } = useTranslation();
  return (
    <tr className="event-row">
      <td>
        <CreaDate date={new Date(event.createdAt)} />
      </td>
      <td>
        <DocumentLink event={event} />
      </td>
      <td>
        <PropositionLink event={event} />
      </td>
      <td>
        <VersionLink event={event} />
      </td>
      <td className="event-line">
        <EventMessage event={event} />
      </td>
      {
        type === TabsNames.EVENTS_UNSEEN && (
          <td>
            {event.unSeen ? (
              <div ref={unseenButtonRef}>
                <EyeOff className="unseen-icon" onClick={(): void => markEventsAsSeen([event.id])} />
              </div>
            ) : (
              ''
            )}

            <Tooltip reactElementRef={unseenButtonRef}>
              <p>{t('project:common:event.action.unseen.tooltip')}</p>
            </Tooltip>
          </td>
        )
      }
    </tr>
  );
}

export { EventRow, GetLink };
