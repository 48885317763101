import { successAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { Mode, useWorkflowContext } from 'context/WorkflowContext';
import { getStatusHumanReadable } from 'models/EStatus';
import 'moment/locale/fr';
import moment from 'moment/moment';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Contract } from 'schema/generated/models';
import { getEQuantificationWhat, getERecipientAsStr, getERuleAsStr, getETemporalityAsStr, lowercaseFirstLetter } from '../utils';
import './ViewPhase.scss';

interface ContractProps {
  contract: Contract;
}

function ContractElem({ contract }: ContractProps): ReactElement {
  const { t } = useTranslation();
  if (
    !contract
    || !contract.temporality
    || !contract.recipient
    || !contract.rule
    || contract.quantificationHowMuch === undefined
    || contract.quantificationHowMuch === null
    || !contract.quantificationWhat
  ) {
    return <div />;
  }

  return (
    <div>
      {t('project:workflow:contract.human_readable', {
        temporality: getETemporalityAsStr(contract.temporality),
        recipient: lowercaseFirstLetter(getERecipientAsStr(contract.recipient)),
        rule: lowercaseFirstLetter(getERuleAsStr(contract.rule)),
        howMuch: contract.quantificationHowMuch,
        what: lowercaseFirstLetter(getEQuantificationWhat(contract.quantificationWhat)),
      })}
    </div>
  );
}

function ViewPhase(): ReactElement {
  const { user } = useUserContext();
  const { currentPhase, setMode, deletePhase } = useWorkflowContext();
  const { project } = useProjectContext();
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  const toggleModal = (): void => {
    setModalActive(!modalActive);
    setMode(Mode.hidden);
  };

  const toggleEditModal = (): void => {
    setMode(Mode.edit);
  };

  const getPhaseTitle = (): ReactElement => {
    const { title } = currentPhase;
    return title.length < 3 ? (
      <p>title</p>
    ) : (
      <TextTruncate line={2} element="span" truncateText="…" text={currentPhase.title} />
    );
  };

  const onDeleteClick = async (): Promise<void> => {
    await deletePhase({
      id: currentPhase.id,
    });
    successAlert(t('project:workflow:phase.action.delete.success'));
  };

  return (
    <div>
      <Modal isOpen toggle={toggleModal} centered className="view-phase-modal">
        <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))} toggle={toggleModal}>{getPhaseTitle()}</ModalHeader>
        <ModalBody>
          {currentPhase.startAt && (
            <Row className="modal-item">
              <Col className="label">{t('project:workflow:phase.attribute.start_at')}</Col>
              <Col className="value">{moment(currentPhase.startAt).format('ll')}</Col>
            </Row>
          )}
          {currentPhase.endAt && (
            <Row className="modal-item">
              <Col className="label">{t('project:workflow:phase.attribute.end_at')}</Col>
              <Col className="value">{moment(currentPhase.endAt).format('ll')}</Col>
            </Row>
          )}
          {currentPhase.status && (
            <Row className="modal-item">
              <Col className="label">{t('project:workflow:phase.attribute.status')}</Col>
              <Col className="value">{getStatusHumanReadable(currentPhase.status)}</Col>
            </Row>
          )}
          {currentPhase.contracts && currentPhase.contracts.map((c) => c as Contract).length !== 0 && (
            <div className="modal-item">
              <Row>
                <Col className="contracts-col label">{t('project:workflow:phase.attribute.contracts')}</Col>
              </Row>
              <ul className="contracts">
                {currentPhase.contracts.map(
                  (contract: Contract) =>
                    contract.temporality
                    && contract.recipient
                    && contract.rule
                    && contract.quantificationHowMuch !== undefined
                    && contract.quantificationHowMuch != null
                    && contract.quantificationWhat && (
                      <li key={contract.id}>
                        <ContractElem contract={contract} />
                      </li>
                    ),
                )}
              </ul>
            </div>
          )}
          {project.owner.id === user.id && (
            <>
              <div className="neutral">
                <button type="button" onClick={toggleEditModal} className="action-button creaflow-primary-button">
                  {t('common:form:action.edit.label')}
                </button>
              </div>
              <div className="danger">
                <button type="button" onClick={onDeleteClick} className="action-button creaflow-primary-button">
                  {t('common:form:action.delete.label')}
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ViewPhase;
