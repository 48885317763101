import { useUserContext } from 'context/UserContext';
import { UpdateUserMutationVariables } from 'JSUtils/schema/generated/models';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Unsubscribe.scss';

enum SubscriptionType {
  loading,
  subscribed,
  unsubscribed,
}

function Unsubscribe(): ReactElement {
  const { user, updateUser } = useUserContext();
  const { t } = useTranslation();

  const userHasUnsubscribed = (mailingPreferences): boolean => {
    const parsedMailingPrefs = JSON.parse(mailingPreferences);
    return (
      !parsedMailingPrefs.promotional
      && !parsedMailingPrefs.onCollaboratorAddedToProject
      && !parsedMailingPrefs.onCollaboratorRemovedFromProject
      && !parsedMailingPrefs.onProjectAddedToLibrary
      && !parsedMailingPrefs.onVersionAddedToProject
    );
  };

  const [subscriptionState, setSubscriptionState] = useState(
    !userHasUnsubscribed(user.mailingPreferences) ? SubscriptionType.loading : SubscriptionType.unsubscribed,
  );

  useEffect(() => {
    let isSubscribed = true;
    if (!userHasUnsubscribed(user.mailingPreferences)) {
      const mailingPreferences = {
        promotional: false,
        onProjectAddedToLibrary: false,
        onVersionAddedToProject: false,
        onCollaboratorAddedToProject: false,
        onCollaboratorRemovedFromProject: false,
      };
      const userInput: UpdateUserMutationVariables = {
        ...user,
        mailingPreferences: JSON.stringify(mailingPreferences),
      };
      updateUser(userInput).then((updatedUser) => {
        if (isSubscribed && userHasUnsubscribed(updatedUser.mailingPreferences)) {
          setSubscriptionState(SubscriptionType.unsubscribed);
        } else {
          setSubscriptionState(SubscriptionType.subscribed);
        }
        return (): void => {
          isSubscribed = false;
        };
      });
    }
  }, []);

  return (
    <div className="unsubscribe-container">
      <div id="response">
        {subscriptionState === SubscriptionType.loading && <p>{t('user:mailing_preferences.unsubscribe.wait')}</p>}
        {subscriptionState === SubscriptionType.subscribed && <p>{t('user:mailing_preferences.unsubscribe.error')}</p>}
        {subscriptionState === SubscriptionType.unsubscribed && (
          <p>{t('user:mailing_preferences.unsubscribe.success')}</p>
        )}
      </div>
    </div>
  );
}

export default Unsubscribe;
