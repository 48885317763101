import { errorAlert, successAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import SelectContributor from 'components/Utilities/SelectContributor/SelectContributor';
import { useProjectContext } from 'context/ProjectContext';
import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import { ConsultationRequestStatus, User } from 'JSUtils/schema/generated/models';
import React, { ReactElement, useState } from 'react';
import CheckCircle from 'react-feather/dist/icons/check-circle';
import { FormContext, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './ConsultationRequest.scss';

function ConsultationRequest(): ReactElement {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const {
    version,
    addConsultationRequest,
    updateConsultationRequest,
  } = usePropositionContext();
  const [sendingRequests, setSendingRequests] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      contributors: [], // Keep to empty as we want to reset the selected users each time we open up the form.
    },
  });
  const contributorsArrayMethods = useFieldArray({
    control: methods.control,
    name: 'contributors',
  });

  const [collaborators] = useState<User[]>([...project.contributors, project.owner].filter((u) => u?.id !== user.id).map((u: User) => u));

  const [consultationRequestModalActive, setConsultationRequestModalActive] = useState(false);
  const [consultationAnswerModalActive, setConsultationAnswerModalActive] = useState(false);

  const toggleConsultationRequestModal = (): void => setConsultationRequestModalActive(!consultationRequestModalActive);
  const toggleConsultationAnswerModal = (): void => setConsultationAnswerModalActive(!consultationAnswerModalActive);

  const consultationButton = version.consultationRequests?.findIndex(
    (c) => c?.target.id === user.id && c.status === ConsultationRequestStatus.Waiting,
  ) !== -1 ? (
    <button type="button" className="creaflow-primary-button toolButton" onClick={(): void => toggleConsultationAnswerModal()}>
      <CheckCircle className="icon" />
      {t('project:proposition:canvas_action.consultation_answer.label')}
    </button>
    ) : (
      <div className="toolButton" onClick={(): void => toggleConsultationRequestModal()}>
        <CheckCircle className="icon" />
        {t('project:proposition:canvas_action.consultation_request.label')}
      </div>
    );

  const sendConsultationRequest = async (formData): Promise<void> => {
    if (sendingRequests) return;
    setSendingRequests(true);
    const { contributors } = formData as { contributors?: Array<{ id: string }> };
    const users = contributors?.map((contrib) => contrib.id) || [];
    const requests = users.map((userId) =>
      addConsultationRequest({
        variables: {
          targetId: userId,
          versionId: version.id,
        },
      }));
    requests.forEach((req) => {
      req
        .then((data) => {
          const target = data.data.addConsultationRequest.target as User;
          successAlert(t('project:proposition:canvas_action.consultation_request.success', { user: target }));
        })
        .catch((err) => {
          console.error(err);
          errorAlert(t('project:proposition:canvas_action.consultation_request.error'));
        });
    });
    try {
      await Promise.all(requests);
      setConsultationRequestModalActive(false);
      methods.reset();
    } catch (err) {
      console.error(err);
    }
    setSendingRequests(false);
  };


  const [approvingRequests, setApprovingRequests] = useState(false);
  const sendConsultationAnswer = async (): Promise<void> => {
    if (approvingRequests) return;
    setApprovingRequests(true);
    const cr = version.consultationRequests?.find((c) => c?.target.id === user.id);
    if (!cr) return;
    try {
      await updateConsultationRequest({
        id: cr.id,
        status: ConsultationRequestStatus.Approved,
      });
      setConsultationAnswerModalActive(false);
      successAlert(t('project:proposition:canvas_action.consultation_answer.success'));
    } catch (err) {
      console.error(err);
      errorAlert(t('project:proposition:canvas_action.consultation_answer.error'));
    }
    setApprovingRequests(false);
  };


  return (
    <div className="consultation-request">
      {consultationButton}

      <Modal
        id="consultationRequestModal"
        isOpen={consultationRequestModalActive}
        toggle={toggleConsultationRequestModal}
        centered
      >
        <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleConsultationRequestModal))} toggle={toggleConsultationRequestModal} className="modal-head">
          {t('project:proposition:canvas_action.consultation_request.label')}
        </ModalHeader>
        {collaborators.length !== 0 ? (
          <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(sendConsultationRequest)} className="crea-form">
              <ModalBody>
                <label htmlFor="contributors">
                  {t('project:proposition:canvas_action.consultation_request.ask_confirmation')}
                </label>
                <SelectContributor
                  fieldArrayMethods={contributorsArrayMethods}
                  computeAuthorizeRemove={(): boolean => true}
                  users={collaborators}
                />
                <div className="clearfix" />
              </ModalBody>
              <ModalFooter>
                <div className="cancel">
                  <button type="button" className="creaflow-primary-button" onClick={toggleConsultationRequestModal}>
                    {t('common:form:action.cancel.label')}
                  </button>
                </div>
                <div className="success">
                  <button className="creaflow-primary-button" disabled={sendingRequests} type="submit">
                    {t('common:form:action.send.label')}
                  </button>
                </div>
              </ModalFooter>
            </form>
          </FormContext>
        ) : (
          <ModalBody>
            <div className="no-collaborator">
              <Trans i18nKey="project:proposition:canvas_action.consultation_request.no_collaborator">
                <Link to={`/project/${project.id}/dashboard`} />
              </Trans>
            </div>
          </ModalBody>
        )}
      </Modal>

      <Modal
        id="consultationAnswerModal"
        isOpen={consultationAnswerModalActive}
        toggle={toggleConsultationAnswerModal}
        centered
      >
        <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleConsultationAnswerModal))} toggle={toggleConsultationAnswerModal} className="modal-head">
          {t('project:proposition:canvas_action.consultation_answer.label')}
        </ModalHeader>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(sendConsultationAnswer)} className="crea-form">
            <ModalBody>
              <label htmlFor="contributors">
                {t('project:proposition:canvas_action.consultation_answer.ask_confirmation')}
              </label>
              <p>
                {version.consultationRequests
                  ?.filter((c) => c?.target.id === user.id)
                  ?.map((c) => `${c?.seeker.firstName} ${c?.seeker.lastName}`)
                  ?.join(', ')}
              </p>
              <div className="clearfix" />
            </ModalBody>
            <ModalFooter>
              <div className="cancel">
                <button type="button" className="creaflow-primary-button" onClick={toggleConsultationAnswerModal}>
                  {t('common:form:action.cancel.label')}
                </button>
              </div>
              <div className="success">
                <button className="creaflow-primary-button" type="submit">
                  {t('common:form:action.send.label')}
                </button>
              </div>
            </ModalFooter>
          </form>
        </FormContext>
      </Modal>
    </div>
  );
}

export default ConsultationRequest;
