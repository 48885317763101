import enFlag from 'assets/locale/en.png';
import frFlag from 'assets/locale/fr.png';
import logo from 'assets/logo.png';
import { useUserContext } from 'context/UserContext';
import locale from 'JSUtils/Locale/locale';
import { stringToUserCulture } from 'JSUtils/schema/enumUtils';
import { UserCulture } from 'JSUtils/schema/generated/models';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import HelpOverlay from 'Nav/MainNavBar/HelpOverlay/HelpOverlay';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import CreateAccount from './components/CreateAccount/CreateAccount';
import ForgottenPassword from './components/ForgottenPassword/ForgottenPassword';
import LoginForm from './components/LoginForm/LoginForm';
import OnboardingInvitation from './components/OnboardingInvitation/OnboardingInvitation';
import FacebookLogin from './components/ThirdPartyAuthProvider/FacebookLogin';
import GoogleLogin from './components/ThirdPartyAuthProvider/GoogleLogin';
import './Login.scss';

function Login(): ReactElement {
  const { user, setUser } = useUserContext();
  const { t } = useTranslation();

  const [invitationParams, setInvitationParams] = useState({
    fromInvitation: false,
    project: {
      sender: '',
      title: '',
    },
    expired: false,
  });

  const location = useLocation();

  const { i18n } = useTranslation();

  useEffect(() => {
    let culture = localStorage.getItem('user.culture');
    if (!culture) {
      culture = locale || 'en-US';
    }
    setUser({
      id: '0',
      firstName: '',
      lastName: '',
      avatarUrl: '',
      email: localStorage.getItem('user.email') || '',
      culture: stringToUserCulture(culture),
      jobs: [''],
      mailingPreferences: '{}',
    });
    i18n.changeLanguage(culture);
  }, []);

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      if (locationState.pageParams.fromInvitation) {
        setInvitationParams(locationState.pageParams);
      }
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      if (localStorage.getItem('user.authenticated') === 'false') {
        window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
      }
    }
  }, []);

  const onCultureClick = (newCulture): void => {
    setUser({
      ...user,
      culture: stringToUserCulture(newCulture),
    });
    i18n.changeLanguage(newCulture);
  };

  const renderLoginPage = (): ReactElement => (
    <div className="login-container">
      <div className="login-header">
        <img
          className="locale-icon"
          alt="fr-flag"
          src={frFlag}
          style={{ opacity: user.culture === UserCulture.FrFr ? 1 : 0.5 }}
          onClick={(): void => {
            onCultureClick('fr-FR');
          }}
        />
        <img
          className="locale-icon"
          alt="en-flag"
          src={enFlag}
          style={{ opacity: user.culture === UserCulture.EnUs ? 1 : 0.5 }}
          onClick={(): void => {
            onCultureClick('en-US');
          }}
        />
        <HelpOverlay />
      </div>
      <div className="login-logo">
        <img src={logo} alt="logo" />
      </div>

      <div className="login-main">
        {invitationParams.fromInvitation && (
          <OnboardingInvitation project={invitationParams.project} expired={invitationParams.expired} />
        )}
        <LoginForm />
        <section className="login-form-links">
          <CreateAccount />
          <ForgottenPassword />
        </section>
        <div className="login-form-third-party-container">
          <p>{t('user:login.alternate_login')}</p>
          <section className="login-form-third-party">
            <GoogleLogin />
            <FacebookLogin />
          </section>
        </div>
      </div>
    </div>
  );

  return localStorage.getItem('user.authenticated') === 'true' ? <Redirect to="/mainDashboard" /> : renderLoginPage();
}

export default Login;
