import { Catenary } from 'catenary-curve';
import { LazyBrush } from 'lazy-brush';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import trackTransforms from './trackTransforms';

function midPointBtw(p1, p2) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

const canvasStyle = {
  display: 'block',
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0)',
};

const imageCanvasStyle = { ...canvasStyle, background: 'rgba(255, 255, 255, 0.7)' };

const canvasTypes = [
  {
    name: 'interface',
    zIndex: 8,
  },
  {
    name: 'drawing',
    zIndex: 6,
  },
  {
    name: 'temp',
    zIndex: 7,
  },
  {
    name: 'image',
    zIndex: 5,
  },
];

export default class CanvasDraw extends Component {
  static propTypes = {
    loadTimeOffset: PropTypes.number,
    lazyRadius: PropTypes.number,
    brushRadius: PropTypes.number,
    brushColor: PropTypes.string,
    catenaryColor: PropTypes.string,
    disabled: PropTypes.bool,
    imgSrc: PropTypes.string,
    saveData: PropTypes.string,
    immediateLoading: PropTypes.bool,
    hasDrawn: PropTypes.func,
    setCanvasWidth: PropTypes.func,
    setCanvasHeight: PropTypes.func,
  };

  static defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 10,
    brushColor: '#444',
    catenaryColor: '#0a0302',
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hasDrawn: () => null,
    setCanvasWidth: () => null,
    setCanvasHeight: () => null,
  };

  constructor(props) {
    super(props);

    this.canvas = {};
    this.ctx = {};

    this.catenary = new Catenary();

    this.points = [];
    this.lines = [];
    this.undoneLines = [];

    this.mouseHasMoved = true;
    this.valuesChanged = true;
    this.isDrawing = false;
    this.isPressing = false;
    this.image = this.loadImage();

    this.bUpdateCanvasSize = this.updateCanvasSize.bind(this);
    this.bUndo = this.undo.bind(this);
    this.bRedo = this.redo.bind(this);
  }
  scaleFactor = 1.1;
  lastX = 0;
  lastY = 0;
  dragStart = null;
  dragged = false;
  ctx = null;
  pencilEnabled = false;

  enablePencil = () => (this.pencilEnabled = true);
  disablePencil = () => (this.pencilEnabled = false);

  getTransformedPoint = (width, height) => this.ctx.image.transformedPoint(width, height);

  getWindowPosition = (x, y) => {
    const pos = this.ctx.image.relativeToCanvas(x, y);
    const visibility =
      pos.x >= 0 && pos.x <= this.container?.clientWidth && pos.y >= 0 && pos.y <= this.container?.clientHeight;
    return { x: pos.x, y: pos.y, visibility };
  };

  getCanvasSize = () => ({ width: this.container?.clientWidth, height: this.container?.clientHeight });

  clearCtx = (ctx) => {
    // Clear the entire this.canvas
    const width = this.container?.clientWidth;
    const height = this.container?.clientHeight;
    if (!width || !height) return;
    const p1 = this.getTransformedPoint(0, 0);
    const p2 = this.getTransformedPoint(width, height);
    ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
  };

  redrawTextAnnotations = () => this.props.updateAnnotationsPosition();

  redrawDrawing = () => {
    this.clearCtx(this.ctx.drawing);
    this.simulateDrawingLines({
      lines: this.lines,
      immediate: true,
    });
  };

  redrawImage = () => {
    this.clearCtx(this.ctx.image);

    this.ctx.image.save();
    this.ctx.image.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.image.clearRect(0, 0, this.canvas.image.width, this.canvas.image.height);
    this.ctx.image.restore();

    this.ctx.image.drawImage(this.image, 0, 0);
  };

  zoom = (clicks) => {
    const pt = this.getTransformedPoint(this.lastX, this.lastY);
    const factor = Math.pow(this.scaleFactor, clicks);
    canvasTypes.forEach(({ name }) => {
      const ctx = this.ctx[name];
      this.clearCtx(this.ctx.drawing);
      ctx.translate(pt.x, pt.y);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      if (name === 'image') this.redrawImage();
      if (name === 'drawing') this.redrawDrawing();
    });
    this.redrawTextAnnotations();
  };

  handleScroll = (evt) => {
    var delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
    if (delta) this.zoom(delta);
    // evt.preventDefault() && false
    return false;
  };

  panMouseDown = () => {
    this.dragStart = this.getTransformedPoint(this.lastX, this.lastY);
    this.dragged = false;
  };

  drawMouseDown = () => (this.isPressing = true);

  handleMouseDown = (evt) => {
    this.setPointerPos(evt.nativeEvent);
    this.pencilEnabled ? this.drawMouseDown() : this.panMouseDown(evt);
  };

  panMouseMove = () => {
    this.dragged = true;
    if (this.dragStart) {
      const pt = this.getTransformedPoint(this.lastX, this.lastY);
      canvasTypes.forEach(({ name }) => {
        this.ctx[name].translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
        if (name === 'image') this.redrawImage();
      });
    }
  };

  handlePointerMove = (x, y) => {
    if (this.props.disabled) return;

    const hasChanged = this.lazy.update({ x, y });
    const isDisabled = !this.lazy.isEnabled();

    if ((this.isPressing && hasChanged && !this.isDrawing) || (isDisabled && this.isPressing)) {
      // Start drawing and add point
      this.isDrawing = true;
      this.points.push(this.lazy.brush.toObject());
    }

    if (this.isDrawing && (this.lazy.brushHasMoved() || isDisabled)) {
      // Add new point
      this.points.push(this.lazy.brush.toObject());

      // Draw current points
      this.drawPoints({
        points: this.points,
        brushColor: this.props.brushColor,
        brushRadius: this.props.brushRadius,
      });
    }

    this.mouseHasMoved = true;
  };

  drawMouseMove = () => {
    const { x, y } = this.getPointerPos();
    this.handlePointerMove(x, y);
  };

  handlePointerMove = (x, y) => {
    const hasChanged = this.lazy.update({ x, y });
    const isDisabled = !this.lazy.isEnabled();

    if ((this.isPressing && hasChanged && !this.isDrawing) || (isDisabled && this.isPressing)) {
      // Start drawing and add point
      this.isDrawing = true;
      this.points.push(this.lazy.brush.toObject());
    }

    if (this.isDrawing && (this.lazy.brushHasMoved() || isDisabled)) {
      // Add new point
      this.points.push(this.lazy.brush.toObject());

      // Draw current points
      this.drawPoints({
        points: this.points,
        brushColor: this.props.brushColor,
        brushRadius: this.props.brushRadius,
      });
    }
  };

  handleMouseMove = (evt) => {
    this.mouseHasMoved = true;
    this.setPointerPos(evt.nativeEvent);
    this.pencilEnabled ? this.drawMouseMove() : this.panMouseMove();
  };

  panMouseUp = (nativeEvent, out = false) => {
    if (!out) this.redrawDrawing();
    this.redrawTextAnnotations();
    this.dragStart = null;
  };

  drawMouseUp = () => {
    if (this.isDrawing) this.props.hasDrawn();
    this.isDrawing = false;
    this.isPressing = false;

    this.resetRedo();
    this.saveLine();
  };

  handleMouseUp = (evt) => {
    this.pencilEnabled ? this.drawMouseUp() : this.panMouseUp(evt.nativeEvent);
  };

  handleMouseOut = (evt) => {
    this.pencilEnabled ? this.drawMouseUp() : this.panMouseUp(evt.nativeEvent, true);
  };

  handleImageLoaded = () => {
    this.updateCanvasSize();
  };

  centerImage = () => {
    const contWidth = this.container?.clientWidth;
    const contHeight = this.container?.clientHeight;
    if (!contWidth || !contHeight) return;
    const ratioWidth = contWidth / this.image.width;
    const ratioHeight = contHeight / this.image.height;

    let scale = 1;
    if (ratioWidth < 1 || ratioHeight < 1) scale = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;

    let widthTranslation = 0;
    let heightTranslation = 0;
    if (scale < 1) {
      widthTranslation = ratioWidth > ratioHeight ? (contWidth / ratioHeight - this.image.width) / 2 : 0;
      heightTranslation = ratioHeight > ratioWidth ? (contHeight / ratioWidth - this.image.height) / 2 : 0;
    } else {
      widthTranslation = (contWidth - this.image.width) / 2;
      heightTranslation = (contHeight - this.image.height) / 2;
    }

    canvasTypes.forEach(({ name }) => {
      const ctx = this.ctx[name];
      ctx.scale(scale, scale);
      ctx.translate(widthTranslation, heightTranslation);
      if (name === 'image') this.redrawImage();
      if (name === 'drawing') this.redrawDrawing();
    });
    this.redrawTextAnnotations();
  };

  loadImage = (imageUrl = this.props.imgSrc) => {
    const newImage = new Image();
    newImage.src = imageUrl;
    newImage.onload = this.handleImageLoaded;
    return newImage;
  };

  updateCanvasSize() {
    //this.canvas.image.width = this.container.clientWidth
    //this.canvas.image.height = this.container.clientHeight
    const width = this.container?.clientWidth;
    const height = this.container?.clientHeight;
    if (!width || !height) return;
    this.setCanvasSize(this.canvas.interface, width, height);
    this.setCanvasSize(this.canvas.drawing, width, height);
    this.setCanvasSize(this.canvas.temp, width, height);
    this.setCanvasSize(this.canvas.image, width, height);

    this.ctx.image = trackTransforms(this.canvas.image.getContext('2d'));
    this.ctx.image.setTransform(1, 0, 0, 1, 0, 0);

    this.centerImage();

    this.props.setCanvasWidth(width);
    this.props.setCanvasHeight(height);
  }

  /* TODO: implement something like this

  getAnnotationRelativePos(x, y) {
    const ratioHeight = this.imageSize.height / this.state.canvasHeight;
    const ratioWidth = this.imageSize.width / this.state.canvasWidth;
    // annotation size: 30px -> 30 / 2 = 17
    return { x: Math.trunc((x - 17) * ratioWidth), y: Math.trunc((y - 17) * ratioHeight) - 17 }
  }
  */

  undo = () => {
    if (!this.lines.length) return;

    this.undoneLines.push(this.lines[this.lines.length - 1]);
    const lines = this.lines.slice(0, -1);
    this.clearDrawings();
    this.simulateDrawingLines({ lines, immediate: true });
    this.props.hasDrawn();
  };

  redo = () => {
    if (!this.undoneLines.length) return;

    const lines = this.lines;
    lines.push(this.undoneLines.pop());
    this.clearDrawings();
    this.simulateDrawingLines({ lines, immediate: true });
    this.props.hasDrawn();
  };

  resetRedo = () => {
    this.undoneLines = [];
  };

  getSaveData = () => {
    // Construct and return the stringified saveData object
    return JSON.stringify({
      lines: this.lines,
      width: this.container?.clientWidth,
      height: this.container?.clientHeight,
    });
  };

  loadSaveData = (saveData, immediate = this.props.immediateLoading) => {
    this.clearDrawPointsTimeout();
    if (typeof saveData !== 'string') {
      throw new Error('saveData needs to be of type string!');
    }

    try {
      this.clearDrawings();
      const { lines } = JSON.parse(saveData);

      if (!lines || typeof lines.push !== 'function') {
        throw new Error('saveData.lines needs to be an array!');
      }

      this.simulateDrawingLines({
        lines,
        immediate,
      });
    } catch {}
  };

  drawPointsTimeout = [];
  clearDrawPointsTimeout = () =>
    this.drawPointsTimeout.forEach((element) => {
      clearTimeout(element);
    });

  simulateDrawingLines = ({ lines, immediate }) => {
    if (!this.props.showDrawings) return;
    this.clearDrawPointsTimeout();
    this.drawPointsTimeout = [];
    // Simulate live-drawing of the loaded lines
    // TODO use a generator
    let curTime = 0;
    let timeoutGap = immediate ? 0 : this.props.loadTimeOffset;

    lines.forEach((line) => {
      const { points, brushColor, brushRadius } = line;

      for (let i = 1; i < points.length; i++) {
        curTime += timeoutGap;
        this.drawPointsTimeout.push(
          window.setTimeout(() => {
            this.drawPoints({
              points: points.slice(0, i + 1),
              brushColor,
              brushRadius,
            });
          }, curTime),
        );
      }

      curTime += timeoutGap;
      window.setTimeout(() => {
        // Save this line with its props instead of this.props
        this.points = points;
        this.saveLine({ brushColor, brushRadius });
      }, curTime);
    });
  };

  getPointerPos = () => this.getTransformedPoint(this.lastX, this.lastY);

  setPointerPos = (nativeEvent) => {
    this.lastX = nativeEvent.offsetX || nativeEvent.pageX - this.container?.offsetLeft;
    this.lastY = nativeEvent.offsetY || nativeEvent.pageY - this.container?.offsetTop;
  };

  drawPoints = ({ points, brushColor, brushRadius }) => {
    if (!points[1]) return;
    this.ctx.temp.lineJoin = 'round';
    this.ctx.temp.lineCap = 'round';
    this.ctx.temp.strokeStyle = brushColor;

    this.clearCtx(this.ctx.temp);
    this.ctx.temp.lineWidth = brushRadius * 2;

    let p1 = points[0];
    let p2 = points[1];

    this.ctx.temp.moveTo(p2.x, p2.y);
    this.ctx.temp.beginPath();

    for (var i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      var midPoint = midPointBtw(p1, p2);
      this.ctx.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
      p1 = points[i];
      p2 = points[i + 1];
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    this.ctx.temp.lineTo(p1.x, p1.y);
    this.ctx.temp.stroke();
  };

  saveLine = ({ brushColor, brushRadius } = {}) => {
    if (this.points.length < 2) return;

    // Save as new line
    this.lines.push({
      points: [...this.points],
      brushColor: brushColor || this.props.brushColor,
      brushRadius: brushRadius || this.props.brushRadius,
    });

    // Reset points array
    this.points.length = 0;

    const width = this.container?.clientWidth;
    const height = this.container?.clientHeight;
    if (!width || !height) return;
    const p1 = this.getTransformedPoint(0, 0);
    let p2 = this.getTransformedPoint(width, height);
    p2.x += 0 - p1.x;
    p2.y += 0 - p1.y;
    /*
      PSEUDO-CODE
      drawImage(src, Pos x on top right, Pos y on top right, width, height)
      width = pos x on bottom left of the image
      height = pos y on bottom left of the image
      width += pos x=0 of the image - pos x on top right
      height += pos y=0 of the image - pos y on top right
      
      This add the space between the end of the canvas and the image to the other side of the image
    */

    // Copy the line to the drawing canvas
    this.ctx.drawing.drawImage(this.canvas.temp, p1.x, p1.y, p2.x, p2.y);

    // Clear the temporary line-drawing canvas
    this.clearCtx(this.ctx.temp);
  };

  clearDrawings = () => {
    this.clearDrawPointsTimeout();
    this.lines = [];
    this.valuesChanged = true;
    this.clearCtx(this.ctx.drawing);
    this.clearCtx(this.ctx.temp);
  };

  loop = ({ once = false } = {}) => {
    if (this.mouseHasMoved || this.valuesChanged) {
      const pointer = this.getPointerPos();
      const brush = pointer;

      this.drawInterface(this.ctx.interface, pointer, brush);
      this.mouseHasMoved = false;
      this.valuesChanged = false;
    }

    if (!once) {
      window.requestAnimationFrame(() => {
        this.loop();
      });
    }
  };

  setCanvasSize = (canvas, width, height) => {
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = width;
    canvas.style.height = height;
  };

  drawInterface = (ctx, pointer, brush) => {
    this.clearCtx(ctx);
    if (!this.pencilEnabled) return;

    // Draw brush preview
    ctx.beginPath();
    ctx.fillStyle = this.props.brushColor;
    ctx.arc(brush.x, brush.y, this.props.brushRadius, 0, Math.PI * 2, true);
    ctx.fill();

    // Draw mouse point (the one directly at the cursor)
    ctx.beginPath();
    ctx.fillStyle = this.props.catenaryColor;
    ctx.arc(pointer.x, pointer.y, 4, 0, Math.PI * 2, true);
    ctx.fill();

    // Draw catenary
    if (this.lazy.isEnabled()) {
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.setLineDash([2, 4]);
      ctx.strokeStyle = this.props.catenaryColor;
      this.catenary.drawToCanvas(this.ctx.interface, brush, pointer, this.chainLength);
      ctx.stroke();
    }

    // Draw brush point (the one in the middle of the brush preview)
    ctx.beginPath();
    ctx.fillStyle = this.props.catenaryColor;
    ctx.arc(brush.x, brush.y, 2, 0, Math.PI * 2, true);
    ctx.fill();
  };

  componentDidMount() {
    this.updateCanvasSize();

    this.lastX = this.container?.clientWidth / 2;
    this.lastY = this.container?.clientHeight / 2;

    window.addEventListener('resize', this.updateCanvasSize.bind(this));
    this.canvas.interface.addEventListener('DOMMouseScroll', this.handleScroll);
    this.canvas.interface.addEventListener('mousewheel', this.handleScroll);

    // load image
    this.image.src = this.props.imgSrc;
    this.image.onload = this.handleImageLoaded;

    this.lazy = new LazyBrush({
      radius: this.props.lazyRadius * window.devicePixelRatio,
      enabled: true,
      initialPoint: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
    });
    this.chainLength = this.props.lazyRadius * window.devicePixelRatio;

    this.loop();

    window.setTimeout(() => {
      const initX = window.innerWidth / 2;
      const initY = window.innerHeight / 2;
      this.lazy.update({ x: initX - this.chainLength / 4, y: initY }, { both: true });
      this.lazy.update({ x: initX + this.chainLength / 4, y: initY }, { both: false });
      this.mouseHasMoved = true;
      this.valuesChanged = true;
      this.clearDrawings();

      // Load saveData from prop if it exists
      if (this.props.saveData) {
        this.loadSaveData(this.props.saveData);
      }
    }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCanvasSize);
    this.canvas.interface.removeEventListener('DOMMouseScroll', this.handleScroll);
    this.canvas.interface.removeEventListener('mousewheel', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    // Make sure lines are reset
    if (prevProps.saveData != this.props.saveData || !this.props.saveData) this.lines = [];
    if (this.canvas.width != this.container?.clientWidth || this.canvas.height != this.container?.clientHeight) {
      this.updateCanvasSize();
    }

    if (prevProps.imgSrc != this.props.imgSrc) {
      this.clearDrawings();
      this.image = this.loadImage();
    }

    if (prevProps.saveData != this.props.saveData) {
      this.loadSaveData(this.props.saveData);
    }

    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      // Signal this.loop function that values changed
      this.valuesChanged = true;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (nextProps && JSON.stringify(nextProps) !== JSON.stringify(this.props)) ||
      (nextState && JSON.stringify(nextState) !== JSON.stringify(this.state))
    );
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          display: 'block',
          touchAction: 'none',
          width: '100%',
          height: '100%',
          ...this.props.style,
        }}
        ref={(e) => {
          this.container = e;
        }}
      >
        {canvasTypes.map(({ name, zIndex }) => {
          const isInterface = name === 'interface';
          const style = { ...(name === 'image' ? imageCanvasStyle : canvasStyle), zIndex };
          return (
            <canvas
              key={name}
              ref={(canvas) => {
                if (canvas) {
                  this.canvas[name] = canvas;
                  this.ctx[name] = canvas.getContext('2d');
                  if (name === 'image') this.ctx[name] = trackTransforms(this.ctx[name]);
                }
              }}
              style={style}
              onMouseDown={isInterface ? this.handleMouseDown : undefined}
              onMouseMove={isInterface ? this.handleMouseMove : undefined}
              onMouseUp={isInterface ? this.handleMouseUp : undefined}
              onMouseOut={isInterface ? this.handleMouseOut : undefined}
              onTouchStart={isInterface ? this.handleTouchStart : undefined}
              onTouchMove={isInterface ? this.handleTouchMove : undefined}
              onTouchEnd={isInterface ? this.handleTouchEnd : undefined}
              onTouchCancel={isInterface ? this.handleTouchEnd : undefined}
              onWheel={isInterface ? this.handleScroll : undefined}
            />
          );
        })}
      </div>
    );
  }
}
