import { useProjectContext } from 'context/ProjectContext';
import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import 'moment/locale/fr';
import React, { ReactElement } from 'react';
import { Version } from 'schema/generated/models';
import AddVersion from './AddVersion/AddVersion';
import './Historic.scss';
import VersionItem from './VersionItem/VersionItem';

type HistoricProps = {
  isMobile: boolean;
};

function Historic({ isMobile }: HistoricProps): ReactElement {
  const { user } = useUserContext();
  const { project } = useProjectContext();
  const {
    proposition,
    version,
    historyVisibility,
    // setHistoryVisibility,
    mobileHistoryVisibility,
    // setMobileHistoryVisibility,
  } = usePropositionContext();

  const visibility = isMobile ? mobileHistoryVisibility : historyVisibility;
  // const setVisibility = isMobile ? setMobileHistoryVisibility : setHistoryVisibility;

  return (
    <div className="historic-container">
      <div className={visibility ? 'historic-main' : 'undisplay'}>
        {project.owner.id === user.id && !isMobile && <AddVersion />}
        {proposition.versions
          ?.map((v: Version) => v)
          ?.sort((a: Version, b: Version) => (Number(a.id) > Number(b.id) ? -1 : 1))
          ?.map((v, index) => (
            <VersionItem key={v.id + index} version={v} isCurrentVersion={version.id === v.id} />
          ))}
      </div>
    </div>
  );
}

export default Historic;
