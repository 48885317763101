import { errorAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { usePropositionContext } from 'context/PropositionContext';
import { Version } from 'JSUtils/schema/generated/models';
import React, { ReactElement, useState } from 'react';
import Edit2 from 'react-feather/dist/icons/edit-2';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './EditVersion.scss';

type EditVersionProps = {
  version: Version;
};

function EditVersion({ version }: EditVersionProps): ReactElement {
  const [modalActive, setModalActive] = useState(false);

  const { updateVersion } = usePropositionContext();
  const { t } = useTranslation();

  const { errors, register, handleSubmit, reset } = useForm({
    defaultValues: {
      title: version.title,
    },
    submitFocusError: true,
    validateCriteriaMode: 'all',
    mode: 'onBlur',
  });

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const submitForm = async (data): Promise<void> => {
    try {
      const updateVersionMutationVariables = {
        id: version.id,
        title: data.title,
      };
      const updatedVersion = await updateVersion(updateVersionMutationVariables);
      reset({
        title: updatedVersion.title,
      });
    } catch (err) {
      errorAlert(t('project:proposition:sidebar.version_history.action.update.error'));
      console.error(err);
      return;
    }
    toggleModal();
  };

  return (
    <div>
      <Button
        className="edit-version-button button"
        onClick={(e): any => {
          e.stopPropagation();
          toggleModal();
        }}
      >
        <Edit2 id="edit-icon" />
      </Button>

      <Modal isOpen={modalActive} centered toggle={toggleModal}>
        <form className="crea-form" onSubmit={handleSubmit(submitForm)}>
          <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))}>{t('project:proposition:sidebar.version_history.action.update.label')}</ModalHeader>
          <ModalBody>
            <section>
              <label htmlFor="title">{t('project:proposition:version.attribute.title')}</label>
              <input
                type="text"
                name="title"
                id="title"
                className={`${errors.title && 'error'}`}
                ref={register({
                  required: {
                    value: true,
                    message: t('common:form:validation.required_field'),
                  },
                  maxLength: {
                    value: 254,
                    message: t('common:form:validation.string_max_255'),
                  },
                })}
              />
              <ErrorMessage errors={errors} name="title">
                {({ messages }): any =>
                  messages
                  && Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))}
              </ErrorMessage>
            </section>
          </ModalBody>
          <ModalFooter>
            <div className="cancel">
              <button className="creaflow-primary-button" type="button" onClick={toggleModal}>
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="success">
              <button className="creaflow-primary-button" type="submit">
                {t('common:form:action.save.label')}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default EditVersion;
