import { useProjectContext } from 'context/ProjectContext';
import React, { ReactElement } from 'react';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './ProjectNavBar.scss';

type ProjectNavBarProps = {
  propositionTitle?: string;
};

function ProjectNavBar({ propositionTitle }: ProjectNavBarProps): ReactElement {
  const { project } = useProjectContext();
  const { t } = useTranslation();
  // const [responsiveMenu, setResponsiveMenu] = useState(false);

  const history = useHistory();

  const openProjectDashboard = (id): void => {
    history.push(`/project/${id}/dashboard`);
  };

  const openWorkflow = (id): void => {
    history.push(`/project/${id}/workflow`);
  };

  const openDocuments = (id): void => {
    history.push(`/project/${id}/documents/`);
  };

  return (
    <>
      {project && (
        <div className="projectnavbar-container">
          <div className="title">
            <h1>{project.title}</h1>
          </div>

          <ul className={`navbar ${propositionTitle ? 'extra-item' : ''}`}>
            <li
              onClick={(): void => openProjectDashboard(project.id)}
              className={
                history.location.pathname === `/project/${project.id}/dashboard` ? ' active' : ''
              }
            >
              <span>{t('project:common:nav.dashboard')}</span>
            </li>
            <li
              onClick={(): void => openWorkflow(project.id)}
              className={
                history.location.pathname === `/project/${project.id}/workflow` ? ' active' : ''
              }
            >
              <span>{t('project:common:nav.workflow')}</span>
            </li>
            <li
              onClick={(): void => openDocuments(project.id)}
              className={
                history.location.pathname.includes(`/project/${project.id}/documents/`) ? ' active' : ''
              }
            >
              <span>{t('project:common:nav.documents')}</span>
            </li>
            {propositionTitle && (
              <>
                <li className="chevron-right">
                  <ChevronRight />
                </li>
                <li className="nav-link active extra-item">
                  <span>{propositionTitle}</span>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default ProjectNavBar;
