/* eslint-disable @typescript-eslint/camelcase */
import { Event, Event_Type, Thread_Message_Answer, Thread_Message_New } from 'JSUtils/schema/generated/models';
import React, { ReactElement } from 'react';
import {
  Annotation_Duplicate,
  Annotation_New,
  Annotation_Update_Position,
  Annotation_Update_Status,
  Document_New,
  Document_Update_Title,
  Proposition_New,
  Proposition_Update_Title,
  Review_Asked,
  Review_Finished,
  Thread_Message_Update,
  Version_New,
  Version_Update_Drawings,
  Version_Update_Title,
} from 'schema/generated/models';
import { GetLink } from './EventRow';

export default function DocumentLink({ event }: { event: Event }): ReactElement {
  switch (event.type) {
    case Event_Type.ThreadMessageNew: {
      const e: Thread_Message_New = event as Thread_Message_New;
      return (
        <GetLink
          entity={e.threadMessage?.annotation.version.proposition.document}
          reachable={e.threadMessage?.annotation.version.proposition !== undefined}
        />
      );
    }
    case Event_Type.ThreadMessageAnswer: {
      const e: Thread_Message_Answer = event as Thread_Message_Answer;
      return (
        <GetLink
          entity={e.threadMessage?.annotation.version.proposition.document}
          reachable={e.threadMessage !== undefined}
        />
      );
    }
    case Event_Type.ThreadMessageUpdate: {
      const e: Thread_Message_Update = event as Thread_Message_Update;
      return (
        <GetLink
          entity={e.threadMessage?.annotation.version.proposition.document}
          reachable={e.threadMessage?.id !== undefined}
        />
      );
    }
    case Event_Type.AnnotationNew: {
      const e: Annotation_New = event as Annotation_New;
      return <GetLink entity={e.annotation?.version.proposition.document} reachable={e.annotation?.id !== undefined} />;
    }
    case Event_Type.AnnotationUpdatePosition: {
      const e: Annotation_Update_Position = event as Annotation_Update_Position;
      return <GetLink entity={e.annotation?.version.proposition.document} reachable={e.annotation?.id !== undefined} />;
    }
    case Event_Type.AnnotationUpdateStatus: {
      const e: Annotation_Update_Status = event as Annotation_Update_Status;
      return <GetLink entity={e.annotation?.version.proposition.document} reachable={e.annotation?.id !== undefined} />;
    }
    case Event_Type.AnnotationDuplicate: {
      const e: Annotation_Duplicate = event as Annotation_Duplicate;
      return <GetLink entity={e.annotation?.version.proposition.document} reachable={e.annotation?.id !== undefined} />;
    }
    case Event_Type.VersionNew: {
      const e: Version_New = event as Version_New;
      return <GetLink entity={e.version?.proposition.document} reachable={e.version?.id !== undefined} />;
    }
    case Event_Type.VersionUpdateDrawings: {
      const e: Version_Update_Drawings = event as Version_Update_Drawings;
      return <GetLink entity={e.version?.proposition.document} reachable={e.version?.id !== undefined} />;
    }
    case Event_Type.VersionUpdateTitle: {
      const e: Version_Update_Title = event as Version_Update_Title;
      return <GetLink entity={e.version?.proposition.document} reachable={e.version?.id !== undefined} />;
    }
    case Event_Type.PropositionNew: {
      const e: Proposition_New = event as Proposition_New;
      return <GetLink entity={e.proposition?.document} reachable={e.proposition?.id !== undefined} />;
    }
    case Event_Type.PropositionUpdateTitle: {
      const e: Proposition_Update_Title = event as Proposition_Update_Title;
      return <GetLink entity={e.proposition?.document} reachable={e.proposition?.id !== undefined} />;
    }
    case Event_Type.DocumentNew: {
      const e: Document_New = event as Document_New;
      return <GetLink entity={e.document || undefined} defaultTitle={e.title} />;
    }
    case Event_Type.DocumentUpdateTitle: {
      const e: Document_Update_Title = event as Document_Update_Title;
      return <GetLink entity={e.document || undefined} />;
    }
    case Event_Type.ReviewAsked: {
      const e: Review_Asked = event as Review_Asked;
      return <GetLink entity={e.version?.proposition.document} reachable={e.version?.id !== undefined} />;
    }
    case Event_Type.ReviewFinished: {
      const e: Review_Finished = event as Review_Finished;
      return <GetLink entity={e.version?.proposition.document} reachable={e.version?.id !== undefined} />;
    }

    default:
      return <span className="link-placeholder">/</span>;
  }
}
