/* eslint-disable @typescript-eslint/camelcase */
import enUSCommonForm from 'i18n/common/form/en-US.json';
import frFRCommonForm from 'i18n/common/form/fr-FR.json';
import enUSCommonMisc from 'i18n/common/misc/en-US.json';
import frFRCommonMisc from 'i18n/common/misc/fr-FR.json';
import enUSHelpCenter from 'i18n/help_center/en-US.json';
import frFRHelpCenter from 'i18n/help_center/fr-FR.json';
import enUSMainDashboard from 'i18n/main_dashboard/en-US.json';
import frFRMainDashboard from 'i18n/main_dashboard/fr-FR.json';
import enUSNav from 'i18n/nav/en-US.json';
import frFRNav from 'i18n/nav/fr-FR.json';
import enUSProjectCommon from 'i18n/project/common/en-US.json';
import frFRProjectCommon from 'i18n/project/common/fr-FR.json';
import enUSProjectDocuments from 'i18n/project/documents/en-US.json';
import frFRProjectDocuments from 'i18n/project/documents/fr-FR.json';
import enUSProjectProposition from 'i18n/project/proposition/en-US.json';
import frFRProjectProposition from 'i18n/project/proposition/fr-FR.json';
import enUSProjectWorkflow from 'i18n/project/workflow/en-US.json';
import frFRProjectWorkflow from 'i18n/project/workflow/fr-FR.json';
import enUSProjectList from 'i18n/project_list/en-US.json';
import frFRProjectList from 'i18n/project_list/fr-FR.json';
import enUSUser from 'i18n/user/en-US.json';
import frFRUser from 'i18n/user/fr-FR.json';
import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

// common
//   form: common translations for forms: buttons labels, common validation (e.g. "Missing value for field"), ...
//   nav: translations for main navigation
//   misc: extra file for uncategorized translations
// project
//   common: All translations related to projects, not only the project page itself but related components (e.g. CreateProject)
//   proposition: All translations related to propositions, not only the page itself but related components
//   workflow: All translations related to the workflow, not only the page itself but related components
// nav: Main navigation
// main_dashboard: For translations of the Main Dashboard page ONLY
// project_list: For translations of the Project List page ONLY

const resources = {
  'en-US': {
    common: {
      form: enUSCommonForm,
      misc: enUSCommonMisc,
    },
    project: {
      common: enUSProjectCommon,
      proposition: enUSProjectProposition,
      workflow: enUSProjectWorkflow,
      documents: enUSProjectDocuments,
    },
    user: enUSUser,
    nav: enUSNav,
    main_dashboard: enUSMainDashboard,
    project_list: enUSProjectList,
    help_center: enUSHelpCenter,
  },
  'fr-FR': {
    common: {
      form: frFRCommonForm,
      misc: frFRCommonMisc,
    },
    project: {
      common: frFRProjectCommon,
      proposition: frFRProjectProposition,
      workflow: frFRProjectWorkflow,
      documents: frFRProjectDocuments,
    },
    user: frFRUser,
    nav: frFRNav,
    main_dashboard: frFRMainDashboard,
    project_list: frFRProjectList,
    help_center: frFRHelpCenter,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('user.culture') || 'en-US',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format(value, format): string {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
  });

export default i18n;
