import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Alert.scss';

function successAlert(msg: string): void {
  toast.success(`${msg}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'success-background',
    bodyClassName: 'grow-font-size',
    progressClassName: 'fancy-progress-bar',
  });
}

function errorAlert(msg: string): void {
  toast.error(`⚠️ ${msg}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'error-background',
    bodyClassName: 'grow-font-size',
    progressClassName: 'fancy-progress-bar',
  });
}

function stickyInfoAlert(msg: string, id: string | number): void {
  toast.info(`${msg}`, {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    draggable: true,
    bodyClassName: 'grow-font-size',
    progressClassName: 'fancy-progress-bar',
    toastId: id,
  });
}

export { errorAlert, successAlert, stickyInfoAlert };
