/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './CookiesConsent.scss';

export default function CookiesConsent(): ReactElement {
  const [consent, setConsent] = useState(true);
  const localStorageKey = 'user.cookies_consent';
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem(localStorageKey)) {
      // When submitting login form (on big screens)
      window.addEventListener('submit', () => {
        setConsent(true);
        localStorage.setItem(localStorageKey, 'true');
      });

      // To close the modal when keyboard opens up on mobile
      window.addEventListener('touch', () => {
        setConsent(true);
        localStorage.setItem(localStorageKey, 'true');
      });
    }
  }, []);

  useEffect(() => {
    setConsent(localStorage.getItem(localStorageKey) === 'true');
  }, []);

  const onAccept = (): void => {
    setConsent(true);
    localStorage.setItem(localStorageKey, 'true');
  };

  return !consent ? (
    <div className="cookies-container">
      <div>
        <div className="header">
          <h1>
            <span role="img" aria-label="Cookie">
              🍪
            </span>
            {` ${t('common:misc:cookies.label')}`}
          </h1>
        </div>
        <p>
          <Trans i18nKey="common:misc:cookies.content">
            <a target="_blank" rel="noopener noreferrer" href="https://www.creativs.cc/cgu#cookies" />
          </Trans>
        </p>
        <div className="footer">
          <button type="button" onClick={onAccept} className="creaflow-primary-button">
            {t('common:misc:cookies.accept')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
