import { HelpCenter } from 'components/HelpCenter/HelpCenter';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './OnboardingInvitation.scss';

interface OnboardingInvitationProps {
  project: {
    sender: string;
    title: string;
  };
  expired: boolean;
}

export default function OnboardingInvitation({ project, expired }: OnboardingInvitationProps): ReactElement {
  const [aboutModalActive, setAboutModalActive] = useState(false);
  const { t } = useTranslation();

  const onToggleAboutModal = (): void => {
    setAboutModalActive(!aboutModalActive);
  };

  return (
    <div className="onboarding-invitation-container">
      <p>{t('user:onboarding.welcome_aboard', { sender: project.sender, projectTitle: project.title })}</p>
      {expired && <p>{t('user:onboarding.token_expired')}</p>}
      <button onClick={onToggleAboutModal} className="creaflow-primary-button" type="button">
        {t('help_center:whats_creaflow.label')}
      </button>
      <p>{t('user:onboarding.call_to_action')}</p>
      {aboutModalActive && <HelpCenter close={(): void => setAboutModalActive(false)} />}
    </div>
  );
}
