import classnames from 'classnames';
import { useProjectContext } from 'context/ProjectContext';
import 'moment/locale/fr';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { DisplayEvents, TabsNames } from './EventList';
import './ProjectDashboardEvents.scss';

function ProjectDashboardEvents(): ReactElement {
  const { updateFilter, actions, events, isLoadingEvents } = useProjectContext();
  const [activeTab, setActiveTab] = useState<TabsNames>(TabsNames.ACTION);
  const [hasLoadedUnseenEvents, setHasLoadedUnseenEvents] = useState(false);
  const { t } = useTranslation();

  useEffect((): void => {
    if (actions.length > 0) {
      setActiveTab(TabsNames.ACTION);
    }
  }, [actions]);
  useEffect((): void => {
    if (hasLoadedUnseenEvents) return;
    if (!isLoadingEvents) {
      setHasLoadedUnseenEvents(true);
      if (actions.length > 0) return;
      if (events.length > 0) {
        setActiveTab(TabsNames.EVENTS_UNSEEN);
      } else {
        updateFilter(false);
        setActiveTab(TabsNames.EVENTS);
      }
    }
  }, [isLoadingEvents]);

  return (
    <div className="events-container">
      <h1>{t('project:common:dashboard.events')}</h1>
      <div id="events-content">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TabsNames.ACTION })}
              onClick={(): void => setActiveTab(TabsNames.ACTION)}
            >
              {t('project:common:event.table.unread_actions')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TabsNames.EVENTS_UNSEEN })}
              onClick={(): void => {
                setActiveTab(TabsNames.EVENTS_UNSEEN);
                updateFilter(true);
              }}
            >
              {t('project:common:event.table.unread_events')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TabsNames.EVENTS })}
              onClick={(): void => {
                setActiveTab(TabsNames.EVENTS);
                updateFilter(false);
              }}
            >
              {t('project:common:event.table.event_log')}
            </NavLink>
          </NavItem>
        </Nav>
        <DisplayEvents type={activeTab} />
      </div>
    </div>
  );
}

export default ProjectDashboardEvents;
