import { useMutation } from '@apollo/client';
import { InviteByMail } from 'components/Project/InviteCollaborators/InviteCollaborators';
import { useProjectListContext } from 'context/ProjectListContext';
import { createProject as GACreateProject } from 'JSUtils/ReactGA/manage';
import {
  AddContractMutationVariables,
  AddPhaseMutationVariables,
  AddProjectMutationVariables,
  ContractQuantificationWhat,
  ContractRecipient,
  ContractRule,
  ContractTemporality,
  Status,
} from 'JSUtils/schema/generated/models';
import { ADD_COLLABORATORS } from 'JSUtils/schema/project';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, FormContext, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert } from 'Utilities/Alert/Alert';
import './CreateProject.scss';

export default function CreateProject(): ReactElement {
  const { addProject } = useProjectListContext();
  const methods = useForm({
    mode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'all',
    defaultValues: {
      title: '',
      description: '',
      emailAddresses: [],
    },
  });
  const emailAddressesArrayMethods = useFieldArray({
    control: methods.control,
    name: 'emailAddresses',
  });
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  const history = useHistory();

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const [addCollabsByEmail] = useMutation(ADD_COLLABORATORS);
  const postProject = async (data): Promise<void> => {
    try {
      const messages: CreaPopupMessage[] = [];
      const addProjectInput: AddProjectMutationVariables = {
        title: data.title,
        description: data.description,
      };
      const addPhaseInput: AddPhaseMutationVariables = {
        projectId: '',
        title: t('project:workflow:phase.placeholder_values.title'),
        startAt: new Date(),
        endAt: moment().add(2, 'w').toDate(),
        status: Status.ToSpecify,
      };
      const addContractInput: AddContractMutationVariables = {
        phaseId: '',
        temporality: ContractTemporality.While,
        recipient: ContractRecipient.Client,
        rule: ContractRule.CanUse,
        quantificationHowMuch: 3,
        quantificationWhat: ContractQuantificationWhat.Version,
      };
      const [project] = await addProject(addProjectInput, addPhaseInput, addContractInput);
      messages.push(new CreaPopupMessage(EPopupMessageType.success, t('project:common:action.add.success')));

      if (data?.emailAddresses) {
        await addCollabsByEmail({ variables: { projectId: project.id, collaboratorsEmails: data?.emailAddresses } });
        messages.push(
          new CreaPopupMessage(EPopupMessageType.success, t('project:common:collaborators.invite.by_mail.success')),
        );
      }
      GACreateProject();
      const locationState: CreaLocationState = {
        pageParams: {},
        popupMessages: messages,
      };
      history.push({
        pathname: `/project/${project.id}/dashboard`,
        state: locationState,
      });
    } catch (err) {
      errorAlert(t('project:common:action.add.error'));
      console.error(err);
    }
  };

  return (
    <div className="management-header-right">
      <button type="button" className="creaflow-secondary-button" onClick={toggleModal}>
        <FaPlus />
        {` ${t('project:common:action.add.label')}`}
      </button>

      <Modal isOpen={modalActive} toggle={toggleModal} centered>
        <FormContext {...methods}>
          <form className="crea-form create-project-form" onSubmit={methods.handleSubmit((data) => postProject(data))}>
            <ModalHeader toggle={toggleModal} className="modal-head">
              {t('project:common:action.add.label')}
            </ModalHeader>
            <ModalBody>
              <section>
                <label htmlFor="title">{t('project:common:attribute.title')}</label>
                <input
                  className={`${methods.errors.title && 'error'}`}
                  ref={methods.register({
                    required: {
                      value: true,
                      message: t('common:form.validation.required_field'),
                    },
                    maxLength: {
                      value: 254,
                      message: t('common:form.validation.string_max_255'),
                    },
                  })}
                  type="text"
                  name="title"
                  id="title"
                />
                <ErrorMessage errors={methods.errors} name="title">
                  {({ messages }): any =>
                    messages
                    && Object.entries(messages).map(([type, message]) => (
                      <p className="error" key={type}>
                        {message}
                      </p>
                    ))}
                </ErrorMessage>
              </section>
              <section>
                <label htmlFor="description">{t('project:common:attribute.description')}</label>
                <textarea ref={methods.register} rows={5} name="description" id="description" />
              </section>
              <p>{t('project:common:attribute.collaborators')}</p>
              <InviteByMail emailAddressesArrayMethods={emailAddressesArrayMethods} />
            </ModalBody>
            <ModalFooter>
              <button className="creaflow-primary-button" type="submit">
                {t('project:common:action.add.label')}
              </button>
            </ModalFooter>
          </form>
        </FormContext>
      </Modal>
    </div>
  );
}
