import { getPresignedPostUrl, s3CreateFile } from 'JSUtils/API/s3';
import React, { ReactElement, useEffect, useState } from 'react';
import Upload from 'react-feather/dist/icons/upload';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import { errorAlert } from 'Utilities/Alert/Alert';
import './ImageUploader.scss';

export enum UploadState {
  notStarted,
  uploading,
  finished,
  failed,
}

class S3Post {
  constructor(public uploadState: UploadState, public key: string) {}
}

interface S3UploaderType {
  // Actually trigger the upload to S3
  postFile: (f: File, n: string, onError: Function) => Promise<S3Post>;
  progress: number;
  cancelUpload: () => void;
}

export function useS3Uploader(): S3UploaderType {
  const [progress, setProgress] = useState<number>(0);
  const [cancel, setCancel] = useState<Function>(() => (): unknown => null);

  const progressCallback = (progressEvent): void => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length')
        || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    if (totalLength !== null) {
      setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
    }
  };

  const cancelUpload = (): void => {
    cancel();
  };

  const postFile = async (file: File, name: string, onError: Function): Promise<S3Post> => {
    if (file) {
      try {
        setProgress(0);
        const postData = await getPresignedPostUrl(name);
        await s3CreateFile(file, postData, progressCallback, setCancel);
        return new S3Post(UploadState.finished, postData.formData.key);
      } catch (err) {
        if (err.canceled) {
          return new S3Post(UploadState.notStarted, '');
        }
        onError(err);
        return new S3Post(UploadState.failed, '');
      }
    } else {
      return new S3Post(UploadState.failed, '');
    }
  };

  return { postFile, progress, cancelUpload };
}

interface ProgressbarProps {
  progress: number;
  uploadState: UploadState;
}

export function ProgressBar({ progress, uploadState }: ProgressbarProps): ReactElement {
  const { t } = useTranslation();

  let pbColor = '';
  let pbLabel = '';
  switch (uploadState) {
    case UploadState.uploading:
      pbColor = 'info';
      pbLabel = t('common:form:file_upload.progress.label', { progress });
      break;
    case UploadState.failed:
      pbColor = 'danger';
      pbLabel = t('common:form:file_upload.progress.error');
      break;
    case UploadState.finished:
      pbColor = 'success';
      pbLabel = t('common:form:file_upload.progress.success');
      break;
    default:
      break;
  }
  return (
    <Progress bar animated={uploadState === UploadState.uploading} color={pbColor} value={progress}>
      {pbLabel}
    </Progress>
  );
}

interface ImageUploaderProps {
  setImageKey: (s: string) => void;
  cancel: boolean;
}

export function ImageUploader({ setImageKey, cancel }: ImageUploaderProps): ReactElement {
  const { t } = useTranslation();
  const [imageName, setImageName] = useState('');
  const [uploadState, setUploadState] = useState(UploadState.notStarted);

  const { postFile, progress, cancelUpload } = useS3Uploader();

  const onCancelUpload = (e?): void => {
    e.stopPropagation();
    e.preventDefault();
    cancelUpload();
  };

  useEffect(() => {
    let isSubscribed = true;
    if (cancel && isSubscribed) {
      onCancelUpload();
    }
    return (): void => {
      isSubscribed = false;
    };
  }, []);

  const onChange = async (e): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    setImageName('');
    setImageKey('');
    setUploadState(UploadState.uploading);
    if (e.target.files !== null) {
      const onError = (err): void => {
        let error = t('common:misc:error.generic');
        switch (err.response?.status) {
          case 422:
            error = t('common:misc.error.img.wrong_format');
            break;
          case 400:
            error = t('common:misc.error.img.too_big');
            break;
          default:
            break;
        }
        errorAlert(error);
      };
      setImageName(e.target.files[0].name);
      const result = await postFile(e.target.files[0], e.target.files[0].name, onError);
      setUploadState(result.uploadState);
      if (result.uploadState !== UploadState.notStarted) {
        setImageKey(result.key);
      } else {
        setImageName('');
      }
    }
  };

  return (
    <section className="crea-img-upload">
      {uploadState !== UploadState.uploading ? (
        <>
          <input type="file" id="image" accept="image/*" onChange={onChange} />
          <label htmlFor="image">
            <Upload className="icon" />
            {uploadState !== UploadState.finished
              ? t('common:form:file_upload.select_file')
              : t('common:form:file_upload.select_another')}
          </label>
          {imageName && <p>{imageName}</p>}
        </>
      ) : (
        <>
          <button type="button" className="creaflow-primary-button" onClick={onCancelUpload}>{t('common:form:file_upload.cancel')}</button>
          {imageName && <p>{imageName}</p>}
        </>
      )}
      {uploadState !== UploadState.notStarted && <ProgressBar progress={progress} uploadState={uploadState} />}
    </section>
  );
}
