import useQueryString from 'JSUtils/custom-hooks/useQueryString';
import { InvitationToken } from 'JSUtils/jwt';
import { CreaLocationState } from 'JSUtils/types';
import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Stripes from 'Utilities/Background/Stripes';
import CreaSpinner from 'Utilities/Spinner/CreaSpinner';
import './ReceiveInvitation.scss';

function ReceiveInvitation(): ReactElement {
  const [token] = useQueryString('token');
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('invitation-token', token);
    const invit = new InvitationToken(token);
    const senderFirstName = invit.data.creatorFirstName;
    const senderLastName = invit.data.creatorLastName;
    const title = invit.data.projectTitle;

    const locationState: CreaLocationState = {
      pageParams: {
        fromInvitation: true,
        project: {
          sender: `${senderFirstName} ${senderLastName}`,
          title,
        },
        expired: invit.isExpired,
      },
      popupMessages: [],
    };
    history.replace({
      pathname: '/',
      state: locationState,
    });
  });
  return (
    <div>
      <Stripes mode={0} />
      <CreaSpinner />
    </div>
  );
}

export default ReceiveInvitation;
