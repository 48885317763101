type invitationToken = {
  exp: number;
  iat: number;
  creatorId: number;
  creatorFirstName: string;
  creatorLastName: string;
  createdAt: string;
  projectId: number;
  projectTitle: string;
};

type resetPasswordToken = {
  type: string;
  exp: number;
  iat: number;
};

class JWTToken {
  isExpired: boolean;
  isInvalid: boolean;
  data: invitationToken | resetPasswordToken;

  hasTokenExpired = (exp: number): boolean => {
    const currentDate = new Date();
    const expireDate = new Date(exp * 1000); // exp is is a date in sec, new Date requires a date in ms
    return currentDate > expireDate;
  };

  constructor(token: string) {
    try {
      this.data = JSON.parse(window.atob(token.split('.')[1]));
      this.isExpired = this.hasTokenExpired(this.data.exp);
      this.isInvalid = false;
    } catch (error) {
      this.isInvalid = true;
    }
  }
}

export class InvitationToken extends JWTToken {
  data: invitationToken;
  constructor(token: string) {
    super(token);
  }
}

export class ResetPasswordToken extends JWTToken {
  data: resetPasswordToken;
  constructor(token: string) {
    super(token);
  }
}
