import { useMutation } from '@apollo/client';
import CloseButton from 'components/Utilities/CloseButton';
import { ASK_RESET_PASSWORD } from 'JSUtils/schema/user';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import './ForgottenPassword.scss';

function ForgottenPassword(): ReactElement {
  const { handleSubmit, errors, register } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: localStorage.getItem('user.email') || '',
    },
  });
  const { t } = useTranslation();

  const [modalActive, setModalActive] = useState(false);
  const [askResetPassword] = useMutation(ASK_RESET_PASSWORD);

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const onForgotPasswordClick = async (data): Promise<any> => {
    try {
      await askResetPassword({ variables: {
        email: data.email,
      } });
    } catch (err) {
      errorAlert(t('user:ask_reset_password.error.no_matching_email'));
      return;
    }
    successAlert(t('user:ask_reset_password.success'));
    toggleModal();
  };

  return (
    <div className="forgotten-password-container">
      <button onClick={toggleModal} type="button">
        {t('user:ask_reset_password.label')}
      </button>

      <Modal isOpen={modalActive} toggle={toggleModal} centered>
        <form onSubmit={handleSubmit(onForgotPasswordClick)} className="crea-form">
          <ModalHeader toggle={toggleModal} close={React.createElement((): ReactElement => CloseButton(toggleModal))}>{t('user:ask_reset_password.label')}</ModalHeader>
          <ModalBody>
            <section>
              <label htmlFor="email">{t('user:attribute:email')}</label>
              <input
                type="text"
                name="email"
                id="email"
                className={errors.email && 'error'}
                ref={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <ErrorMessage errors={errors} name="email">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
            <p>{t('user:reset_password.explanation')}</p>
          </ModalBody>
          <ModalFooter>
            <div className="cancel">
              <button className="creaflow-primary-button" type="button" onClick={toggleModal}>
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="success">
              <button className="creaflow-primary-button" type="submit">
                {t('user:ask_reset_password.label')}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default ForgottenPassword;
