import { UserCulture } from './generated/models';

export function userCultureToString(userCulture: UserCulture): string {
  switch (userCulture) {
    case UserCulture.FrFr:
      return 'fr-FR';
    case UserCulture.EnUs:
    default:
      return 'en-US';
  }
}

export function stringToUserCulture(str: string): UserCulture {
  switch (str) {
    case 'fr-FR': return UserCulture.FrFr;
    case 'en-US':
    default: return UserCulture.EnUs;
  }
}

export function cultureToHumanReadableString(str: string): string {
  switch (str) {
    case 'fr-FR':
      return 'Français (France)';
    case 'en-US':
      return 'English (US)';
    default:
      return 'Français (France)';
  }
}
