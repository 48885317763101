import help from 'assets/help-circle.svg';
import { HelpCenter } from 'components/HelpCenter/HelpCenter';
import * as NavigationGA from 'JSUtils/ReactGA/navigation';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'Utilities/Overlay/Overlay';
import BugReportModal from '../BugReportModal/BugReportModal';
import './HelpOverlay.scss';

enum ModalType {
  none,
  bugReport,
  helpCenter,
}

function HelpOverlay(): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(ModalType.none);

  const toggleModal = (modalType: ModalType): void => {
    setActiveModal(modalType);
    if (modalType === ModalType.helpCenter) NavigationGA.openHelpCenter();
    setIsOpen(false);
  };

  return (
    <>
      <img className="interaction help" src={help} alt="help" onClick={(): void => setIsOpen(true)} />

      {isOpen && (
        <Overlay close={(): void => setIsOpen(false)} className="help-container">
          <div className="helpTitle">{t('common:misc:help_overlay.label')}</div>
          <hr />
          <div className="actions">
            {localStorage.getItem('user.authenticated') === 'true' && (
              <p onClick={(): void => toggleModal(ModalType.bugReport)}>
                {t('common:misc:help_overlay.bug_report.label')}
              </p>
            )}
            <p className="trigger-click" onClick={(): void => toggleModal(ModalType.helpCenter)}>
              {t('common:misc:help_overlay.open_help_center')}
            </p>
            <a href="https://www.creativs.cc/cgu" target="_blank" rel="noopener noreferrer">
              {t('common:misc:help_overlay.cgu')}
            </a>
            <a href="https://headwayapp.co/creativs-changelog" target="_blank" rel="noopener noreferrer">
              {t('common:misc:help_overlay.changelog')}
            </a>
          </div>
        </Overlay>
      )}
      {activeModal === ModalType.bugReport && <BugReportModal close={(): void => setActiveModal(ModalType.none)} />}
      {activeModal === ModalType.helpCenter && (
        <HelpCenter
          close={(): void => {
            setActiveModal(ModalType.none);
            NavigationGA.closeHelpCenter();
          }}
        />
      )}
    </>
  );
}

export default HelpOverlay;
