/* eslint-disable @typescript-eslint/camelcase */
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import * as ManageGA from 'JSUtils/ReactGA/manage';
import { GET_EVENTS, MARK_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import {
  ADD_PROPOSITION,
  ADD_VERSION,
  DELETE_DOCUMENT,
  DELETE_PROJECT,
  GET_PROJECT,
  REMOVE_COLLABORATORS,
  UPDATE_PROJECT,
} from 'JSUtils/schema/project';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  AddPropositionMutationVariables,
  AddVersionMutationVariables,
  ConsultationRequest,
  ConsultationRequestStatus,
  DeleteContractMutationVariables,
  DeleteDocumentMutationVariables,
  DeleteProjectMutationVariables,
  Document,
  Event,
  EventFilter,
  EventPagination,
  Project,
  Proposition,
  RemoveCollaboratorsMutationVariables,
  UpdateProjectMutationVariables,
  User,
  Version,
} from 'schema/generated/models';
import { ADD_DOCUMENT, UPDATE_ANNOTATION } from 'schema/project';
import createCtx from './createContextHelper';
import { useUserContext } from './UserContext';

type ProjectContextType = {
  project: Project;
  projectError?: ApolloError;
  isLoading: boolean;
  updateAnnotation: Function;
  updateProject: (u: UpdateProjectMutationVariables) => Promise<Project>;
  deleteProject: (d: DeleteContractMutationVariables) => Promise<boolean>;
  removeCollaborators: (removeCollaboratorsMutationVariables: RemoveCollaboratorsMutationVariables) => Promise<User[]>;
  addDocument: Function;
  deleteDocument: (d: DeleteDocumentMutationVariables) => Promise<boolean>;
  addProposition: (
    addPropositionMutationVariables: AddPropositionMutationVariables,
    addVersionMutationVariables: AddVersionMutationVariables,
  ) => Promise<readonly [Proposition, Version]>;
  filter: string;
  setFilter: (s: string) => void;
  getFilteredDocumentsAndPropositions: () => Document[];
  events: Event[];
  eventsCount: number;
  eventPagination: EventPagination;
  isLoadingEvents: boolean;
  eventsError?: ApolloError;
  eventsNextPage: () => void;
  eventsPrevPage: () => void;
  eventsSetPage: (n: number) => void;
  currentPage: number;
  maxPage: number;
  markEventsAsSeen: (eventIds: string[]) => void;
  updateFilter: (unSeenOnly: boolean) => void;
  actions: ConsultationRequest[];
};

const [useProjectContext, CtxProvider] = createCtx<ProjectContextType>();

type ProjectContextProviderV2Props = {
  children: React.ReactNode;
  projectId: string;
};

const ProjectContextProvider = (props: ProjectContextProviderV2Props): ReactElement => {
  const { children, projectId } = props;

  const [project, setProject] = useState({} as Project);
  const [isLoading, setIsLoading] = useState(true);

  const [filter, setFilter] = useState('');

  const { error: projectError, refetch: refetchProject } = useQuery(GET_PROJECT, {
    variables: { id: projectId },
    onCompleted: (data) => {
      if (data.project) {
        setProject(data.project as Project);
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    // Force refetch to update local propositions/versions counts
    refetchProject();
  }, []);

  const [events, setEvents] = useState<Event[]>([]);
  const [eventsCount, setEventsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [eventFilter, setEventFilter] = useState<EventFilter>({ projectId, unSeen: true });
  const [eventPagination, setEventPagination] = useState<EventPagination>({ limit: 20, offset: 0 });

  useEffect(() => {
    setCurrentPage(Math.floor((eventPagination.offset || 0) / (eventPagination.limit || 0)) + 1);
    let newMaxPage = Math.floor(eventsCount / (eventPagination.limit || 0)) + 1;
    if (eventsCount % (eventPagination.limit || 0) === 0) newMaxPage -= 1;
    if (newMaxPage < 1) newMaxPage = 1;
    setMaxPage(newMaxPage);
  }, [eventsCount, eventPagination]);

  const eventsNextPage = (): void => {
    setEventPagination((oldPagination) => ({
      limit: oldPagination.limit,
      offset: (oldPagination.offset || 0) + (oldPagination.limit || 0),
    }));
  };

  const eventsPrevPage = (): void => {
    setEventPagination((oldPagination) => ({
      limit: oldPagination.limit,
      offset: (oldPagination.offset || 0) - (oldPagination.limit || 0),
    }));
  };

  const eventsSetPage = (page: number): void => {
    setEventPagination((oldPagination) => ({
      limit: oldPagination.limit,
      offset: (oldPagination.limit || 0) * (page - 1),
    }));
  };

  const [markAsSeen] = useMutation(MARK_EVENTS_AS_SEEN);
  const markEventsAsSeen = (eventIds: string[]): void => {
    if (eventIds.length > 0) markAsSeen({ variables: { eventIds } });
    setEvents((oldEvents) =>
      oldEvents.map((e) => {
        if (e.unSeen === true && eventIds.findIndex((ei) => ei === e.id) !== -1) {
          return {
            ...e,
            unSeen: false,
          };
        }
        return e;
      }));
  };

  const { error: eventsError, refetch: refetchEvents, loading: isLoadingEvents } = useQuery(GET_EVENTS, {
    variables: { filter: eventFilter, pagination: eventPagination },
    onCompleted: (data) => {
      if (data.events) {
        setEvents(data.events.events as Event[]);
        setEventsCount(data.events.count);
      }
    },
  });

  useEffect(() => {
    refetchEvents();
  }, [eventFilter, eventPagination]);

  const updateFilter = (unSeenOnly: boolean): void => {
    setEventFilter((f) => {
      return { ...f, unSeen: unSeenOnly };
    });
  };

  const [_updateProject] = useMutation(UPDATE_PROJECT);
  const updateProject = async (updateProjectMutationVariables: UpdateProjectMutationVariables): Promise<Project> => {
    try {
      const {
        data: { updateProject: updatedProject },
      } = await _updateProject({
        variables: {
          ...updateProjectMutationVariables,
          id: project.id,
        },
      });
      ManageGA.updateProject(updateProjectMutationVariables);

      setProject(updatedProject);
      return updatedProject;
    } catch (err) {
      console.error(err);
    }
    return {} as Project; // return undefined instead
  };

  const [_deleteProject] = useMutation(DELETE_PROJECT);
  const deleteProject = async (deleteProjectMutationVariables: DeleteProjectMutationVariables): Promise<boolean> => {
    try {
      const {
        data: { deleteProject: result },
      } = await _deleteProject({
        variables: deleteProjectMutationVariables,
      });
      return result;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const [_removeCollaborators] = useMutation(REMOVE_COLLABORATORS);
  const removeCollaborators = async (
    removeCollaboratorsMutationVariables: RemoveCollaboratorsMutationVariables,
  ): Promise<User[]> => {
    try {
      const {
        data: { removeCollaborators: collaborators },
      } = await _removeCollaborators({
        variables: removeCollaboratorsMutationVariables,
      });
      ManageGA.removeCollaborators(removeCollaboratorsMutationVariables.collaboratorsIds.length);

      setProject({
        ...project,
        contributors: [collaborators],
      });
      return collaborators;
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const [addDocument] = useMutation(ADD_DOCUMENT, {
    onCompleted: (data) => {
      ManageGA.createDocument();

      setProject({
        ...project,
        documents: project.documents.concat([data.addDocument]),
      });
    },
  });

  const [_deleteDocument] = useMutation(DELETE_DOCUMENT);
  const deleteDocument = async (deleteDocumentMutationVariables: DeleteDocumentMutationVariables): Promise<boolean> => {
    try {
      const {
        data: { deleteDocument: deleteDocumentResult },
      } = await _deleteDocument({ variables: deleteDocumentMutationVariables });
      ManageGA.deleteDocument();

      const updatedDocuments = [...project.documents];
      const indexOfDeleted = updatedDocuments.findIndex((d: Document) => d.id === deleteDocumentMutationVariables.id);
      updatedDocuments.splice(indexOfDeleted, 1);
      const {
        data: { project: updatedProject },
      } = await refetchProject();
      setProject({
        ...updatedProject,
        documents: updatedDocuments,
      });
      return deleteDocumentResult;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const [_addProposition] = useMutation(ADD_PROPOSITION);
  const [_addVersion] = useMutation(ADD_VERSION);
  const addProposition = async (
    addPropositionMutationVariables: AddPropositionMutationVariables,
    addVersionMutationVariables: AddVersionMutationVariables,
  ): Promise<readonly [Proposition, Version]> => {
    try {
      const {
        data: { addProposition: newProposition },
      } = await _addProposition({ variables: addPropositionMutationVariables });
      ManageGA.createProposition();
      const {
        data: { addVersion: newVersion },
      } = await _addVersion({
        variables: {
          ...addVersionMutationVariables,
          propositionId: newProposition.id,
        },
      });
      ManageGA.createVersion();

      refetchProject().then(({ data: { project: updatedProject } }) => {
        setProject(updatedProject);
      });
      return [newProposition, newVersion] as const;
    } catch (err) {
      console.error(err);
    }
    // todo: Return [undefined, undefined] to use undefined entities instead of {} (graphql best practices but we have to check !== undefined everywhere)
    return [{} as Proposition, {} as Version] as const;
  };

  const [updateAnnotation] = useMutation(UPDATE_ANNOTATION);

  const getFilteredDocumentsAndPropositions = (): Document[] => {
    return project.documents
      ?.map((d: Document) => d)
      ?.filter(
        (document) =>
          document.title.toLowerCase().includes(filter)
          || document.propositions.some((p: Proposition) => p.title.toLowerCase().includes(filter)),
      );
  };

  const { user } = useUserContext();
  const [actions, setActions] = useState<ConsultationRequest[]>([]);
  useEffect(() => {
    setActions(
      project.documents
        ?.map((d) => {
          return d.propositions
            .map((p) => {
              return p.versions
                .map((v) => {
                  return v.consultationRequests
                    .filter((cr) => cr.status === ConsultationRequestStatus.Waiting && cr.target.id === user.id)
                    .map((cr) => ({
                      ...cr,
                      version: { ...v, proposition: { ...p, document: d } },
                    }));
                })
                .flat();
            })
            .flat();
        })
        .flat() || [],
    );
  }, [project]);

  const ProjectContext: ProjectContextType = {
    project,
    projectError,
    isLoading,
    updateAnnotation,
    updateProject,
    removeCollaborators,
    addDocument,
    deleteDocument,
    addProposition,
    filter,
    setFilter,
    getFilteredDocumentsAndPropositions,
    events,
    eventsCount,
    eventPagination,
    isLoadingEvents,
    eventsError,
    deleteProject,
    eventsNextPage,
    eventsPrevPage,
    eventsSetPage,
    currentPage,
    maxPage,
    markEventsAsSeen,
    updateFilter,
    actions,
  };

  return <CtxProvider value={ProjectContext}>{children}</CtxProvider>;
};

export { useProjectContext, ProjectContextProvider };
