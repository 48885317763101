import Tutorial from 'components/Tutorial/Tutorial';
import CreaSpinner from 'components/Utilities/Spinner/CreaSpinner';
import { PropositionContextProvider, usePropositionContext } from 'context/PropositionContext';
import useQueryString from 'JSUtils/custom-hooks/useQueryString';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect } from 'react';
import Layers from 'react-feather/dist/icons/layers';
import Tool from 'react-feather/dist/icons/tool';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import ProjectNavBar from '../../components/ProjectNavBar/ProjectNavBar';
import Canvas from './components/Canvas/Canvas';
import CanvasActions from './components/CanvasActions/CanvasActions';
import Historic from './components/Sidebar/components/Historic/Historic';
import Toolbox from './components/Sidebar/components/Toolbox/Toolbox';
import Sidebar from './components/Sidebar/Sidebar';
import './PropositionContainer.scss';

const MobileToolboxButton = (): ReactElement => {
  const { t } = useTranslation();
  const {
    mobiletoolboxVisibility,
    setMobiletoolboxVisibility,
    toolboxVisibility,
    setToolboxVisibility,
  } = usePropositionContext();

  return (
    <button
      className="creaflow-secondary-button"
      type="button"
      onClick={(): void => {
        setMobiletoolboxVisibility(!mobiletoolboxVisibility);
        setToolboxVisibility(!toolboxVisibility);
      }}
    >
      <Tool />
      {t('project:proposition:sidebar.toolbox.tab_title')}
    </button>
  );
};

const MobileHistoryButton = (): ReactElement => {
  const {
    mobileHistoryVisibility,
    setMobileHistoryVisibility,
    historyVisibility,
    setHistoryVisibility,
  } = usePropositionContext();
  const { t } = useTranslation();

  return (
    <button
      className="creaflow-secondary-button"
      type="button"
      onClick={(): void => {
        setMobileHistoryVisibility(!mobileHistoryVisibility);
        setHistoryVisibility(!historyVisibility);
      }}
    >
      <Layers />
      {t('project:proposition:sidebar.version_history.tab_title')}
    </button>
  );
};

const MobileHistoryModal = (): ReactElement => {
  const { t } = useTranslation();
  const { mobileHistoryVisibility, setMobileHistoryVisibility } = usePropositionContext();

  const toggle = (): void => setMobileHistoryVisibility(!mobileHistoryVisibility);
  return (
    <Modal className="HistoryModal" isOpen={mobileHistoryVisibility} toggle={toggle} onClick={toggle} centered>
      <Historic isMobile />
      <p>{t('project:proposition:sidebar.version_history.limited_on_mobile')}</p>
    </Modal>
  );
};

const MobileToolboxModal = (): ReactElement => {
  const { mobiletoolboxVisibility, setMobiletoolboxVisibility } = usePropositionContext();
  const { t } = useTranslation();
  const toggle = (): void => setMobiletoolboxVisibility(!mobiletoolboxVisibility);
  return (
    <Modal className="ToolboxModal" isOpen={mobiletoolboxVisibility} toggle={toggle} onClick={toggle} centered>
      <Toolbox isMobile />
      <p>{t('project:proposition:sidebar.toolbox.limited_on_mobile')}</p>
    </Modal>
  );
};

const PropositionContainerConsumer = (): ReactElement => {
  const { isLoading, version, proposition } = usePropositionContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (version?.id) {
      window.history.replaceState(null, '', `${version.id}`);
    }
  }, [version?.id]);

  return isLoading ? (
    <CreaSpinner />
  ) : (
    <>
      <ProjectNavBar propositionTitle={`${proposition.document.title} ${proposition.title}`} />
      <div className="project-content">
        <div className="left">
          <div className="desktop project-canvasActions">
            <CanvasActions />
          </div>
          <div className="desktop project-sidebar">
            <Sidebar />
          </div>
        </div>
        <div className="mobile proposition-buttons">
          <div className="mobile project-menu-toolbox project-menu">
            <MobileToolboxButton />
            <MobileToolboxModal />
          </div>
          <div className="mobile project-menu-historic project-menu">
            <MobileHistoryButton />
            <MobileHistoryModal />
          </div>
        </div>

        <div className="canvasWindow">
          <Canvas />
        </div>
      </div>
      <Tutorial
        tutorialType="proposition_page"
        title={t('help_center:proposition.tutorial_label')}
        callToActionLabel={t('common:form:got_it')}
      >
        <p>{t('help_center:proposition.tutorial')}</p>
      </Tutorial>
    </>
  );
};

const PropositionContainer = (): ReactElement => {
  const location = useLocation();
  const { propositionId, versionId } = useParams();

  const [currentAnnotationId] = useQueryString('annotation');

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  return (
    <>
      {propositionId && (
        <PropositionContextProvider
          currentPropositionId={propositionId?.toString()}
          currentVersionId={versionId?.toString()}
          currentAnnotationId={currentAnnotationId}
        >
          <PropositionContainerConsumer />
        </PropositionContextProvider>
      )}
    </>
  );
};

export default PropositionContainer;
