import enFlag from 'assets/locale/en.png';
import frFlag from 'assets/locale/fr.png';
import ResetPasswordForm from 'containers/User/Unauthenticated/ResetPassword/components/ResetPasswordForm';
import { ResetPasswordToken } from 'JSUtils/jwt';
import locale from 'JSUtils/Locale/locale';
import { stringToUserCulture } from 'JSUtils/schema/enumUtils';
import { CurrentUser } from 'JSUtils/schema/generated/models';
import { EPopupMessageType } from 'JSUtils/types';
import HelpOverlay from 'Nav/MainNavBar/HelpOverlay/HelpOverlay';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import '../Login/Login.scss';

function ResetPassword(): ReactElement {
  const [tmpUser, setTmpUser] = useState<CurrentUser>({
    id: '0',
    firstName: '',
    lastName: '',
    avatarUrl: '',
    email: localStorage.getItem('user.email') || '',
    culture: stringToUserCulture(localStorage.getItem('user.culture') || locale),
    jobs: [''],
    mailingPreferences: '{}',
  });
  const location = useLocation();
  const { t } = useTranslation();

  const onCultureClick = (newCulture: string): void => {
    setTmpUser({
      ...tmpUser,
      culture: stringToUserCulture(newCulture),
    });
  };

  const history = useHistory();
  useEffect(() => {
    let error = '';

    try {
      const token = location.search.substring(location.search.indexOf('?') + 1).split('=');
      const resetPassword = new ResetPasswordToken(token[1]);
      if (resetPassword.isExpired) error = t('user:reset_password.error.token_expired');
      if (resetPassword.isInvalid) error = t('user:reset_password.error.invalid_token');
    } catch (err) {
      console.error(err);
      error = t('user:reset_password.error.invalid_token');
    }

    if (error) {
      history.replace({
        pathname: '/',
        state: {
          pageParams: {},
          popupMessage: {
            type: EPopupMessageType.error,
            message: error,
          },
        },
      });
    }
  }, []);

  return (
    <div className="login-container">
      <div className="login-header">
        <img
          className="locale-icon"
          alt="fr-flag"
          src={frFlag}
          style={{ opacity: tmpUser.culture === 'fr_FR' ? 1 : 0.5 }}
          onClick={(): void => {
            onCultureClick('fr_FR');
          }}
        />
        <img
          className="locale-icon"
          alt="en-flag"
          src={enFlag}
          style={{ opacity: tmpUser.culture === 'en_US' ? 1 : 0.5 }}
          onClick={(): void => {
            onCultureClick('en_US');
          }}
        />
        <HelpOverlay />
      </div>
      <div className="login-main">
        <ResetPasswordForm />
      </div>
    </div>
  );
}

export default ResetPassword;
