import { useUserContext } from 'context/UserContext';
import { cultureToHumanReadableString, userCultureToString } from 'JSUtils/schema/enumUtils';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import PersonalPageHeader from '../components/PersonalPageHeader';
import './Account.scss';

function Account(): ReactElement {
  const { user, updateUser, askDeleteCurrentUser, askResetPassword } = useUserContext();
  const { i18n } = useTranslation();
  const { setValue, register, watch, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      culture: userCultureToString(user.culture),
      mailingPreferences: JSON.parse(user.mailingPreferences),
    },
  });

  const cultureWatch = watch('culture');
  const [t, setT] = useState(() => i18n.getFixedT(i18n.language));

  const location = useLocation();

  useEffect(() => {
    if (cultureWatch) {
      setT(() => i18n.getFixedT(cultureWatch as string));
    }
  }, [cultureWatch]);

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  useEffect(() => {
    setValue([{ firstName: user.firstName }, { lastName: user.lastName }]);
    setValue('mailingPreferences', user.mailingPreferences);
  }, [user.firstName]);

  useEffect(() => {
    localStorage.removeItem('dab.disabled');
    localStorage.removeItem('rpb.disabled');
  }, []);

  const askToDeleteAccount = async (): Promise<void> => {
    const dabDisabled = localStorage.getItem('dab.disabled');
    if (dabDisabled) {
      successAlert(t('user:account_management.delete.ask_delete.success'));
    } else {
      try {
        await askDeleteCurrentUser();
        successAlert(t('user:account_management.delete.ask_delete.success'));
        localStorage.setItem('dab.disabled', 'true');
        setTimeout(() => {
          localStorage.removeItem('dab.disabled');
        }, 60_000); // 60 secs
      } catch (err) {
        errorAlert(t('user:account_management.delete.ask_delete.error'));
      }
    }
  };

  const sendUserUpdateToApi = async (data): Promise<void> => {
    try {
      await updateUser({
        id: user.id,
        ...data,
        culture: data.culture?.replace('-', '_'),
        mailingPreferences: JSON.stringify(data.mailingPreferences),
      });
      successAlert(t('user:account_management.save.success'));
    } catch (err) {
      console.error(err);
      errorAlert(err);
    }
  };

  const askPasswordChange = async (): Promise<void> => {
    const rpbDisabled = localStorage.getItem('rpb.disabled');
    if (rpbDisabled) {
      successAlert(t('user:account_management.ask_reset_password.success'));
    } else {
      try {
        await askResetPassword({
          variables: {
            email: user.email,
          },
        });
        successAlert(t('user:account_management.ask_reset_password.success'));
        localStorage.setItem('rpb.disabled', 'true');
        setTimeout(() => {
          localStorage.removeItem('rpb.disabled');
        }, 60_000); // 60 secs
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <PersonalPageHeader active="account" />
      <div className="account-content">
        <div className="centered">
          <form className="crea-form" onSubmit={handleSubmit(sendUserUpdateToApi)}>
            <div id="account-management" className="section">
              <h1>{t('user:account_management.title')}</h1>
              <section>
                <label htmlFor="firstName">{t('user:attribute.firstName')}</label>
                <input
                  className={errors.firstName && 'error'}
                  type="text"
                  ref={register({ required: true })}
                  name="firstName"
                />
                <ErrorMessage errors={errors} name="firstName">
                  {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
                </ErrorMessage>
              </section>
              <section>
                <label htmlFor="lastName">{t('user:attribute.lastName')}</label>
                <input
                  className={errors.lastName && 'error'}
                  ref={register({ required: true })}
                  type="text"
                  name="lastName"
                />
                <ErrorMessage errors={errors} name="lastName">
                  {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
                </ErrorMessage>
              </section>
              <section>
                <label htmlFor="email">{t('user:attribute.email')}</label>
                <input
                  className={errors.email && 'error'}
                  ref={register({ required: true })}
                  type="text"
                  name="email"
                />
                <ErrorMessage errors={errors} name="email">
                  {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
                </ErrorMessage>
              </section>
              <section>
                <label htmlFor="culture">{t('user:attribute.culture')}</label>
                <select name="culture" ref={register()}>
                  <option value="" disabled>
                    {t('common:form:select')}
                  </option>
                  {['en-US', 'fr-FR'].map((culture) => (
                    <option key={`${culture}`} id={culture} value={culture}>
                      {cultureToHumanReadableString(culture)}
                    </option>
                  ))}
                </select>
              </section>
              <section>
                <label htmlFor="ask-password-change">{t('user:attribute.password')}</label>
                <div className="ask-password-change">
                  <button
                    type="button"
                    className="action-button creaflow-primary-button"
                    color="info"
                    onClick={askPasswordChange}
                  >
                    {t('common:form:action.edit.label')}
                  </button>
                </div>
              </section>
            </div>
            <hr />

            <div id="mailing-prefs" className="section">
              <h1>{t('user:mailing_preferences.title')}</h1>
              <p>{t('user:mailing_preferences.subtitle')}</p>
              <section>
                <label htmlFor="mailingPreferences.promotional" className="checkbox">
                  <input
                    type="checkbox"
                    ref={register}
                    name="mailingPreferences.promotional"
                    id="mailingPreferences.promotional"
                  />
                  <span className="label">{t('user:mailing_preferences.promotional')}</span>
                </label>
              </section>
              <section>
                <label className="checkbox" htmlFor="mailingPreferences.onProjectAddedToLibrary">
                  <input
                    type="checkbox"
                    ref={register}
                    name="mailingPreferences.onProjectAddedToLibrary"
                    id="mailingPreferences.onProjectAddedToLibrary"
                  />
                  <span className="label">{t('user:mailing_preferences.project_related')}</span>
                </label>
              </section>
              <section>
                <label className="checkbox" htmlFor="mailingPreferences.onVersionAddedToProject">
                  <input
                    type="checkbox"
                    ref={register}
                    id="mailingPreferences.onVersionAddedToProject"
                    name="mailingPreferences.onVersionAddedToProject"
                  />
                  <span className="label">{t('user:mailing_preferences.version_related')}</span>
                </label>
              </section>
              <section>
                <label className="checkbox" htmlFor="mailingPreferences.onCollaboratorAddedToProject">
                  <input
                    ref={register}
                    id="mailingPreferences.onCollaboratorAddedToProject"
                    name="mailingPreferences.onCollaboratorAddedToProject"
                    type="checkbox"
                  />
                  <span className="label">{t('user:mailing_preferences.collab_related')}</span>
                </label>
              </section>
            </div>
            <hr />

            <div className="action-buttons">
              <div className="delete">
                <button type="button" className="action-button creaflow-primary-button" onClick={askToDeleteAccount}>
                  {t('user:account_management.delete.ask_delete.label')}
                </button>
              </div>
              <div className="save">
                <button type="submit" className="action-button creaflow-primary-button">
                  {t('common:form:action.save.label')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Account;
