import React, { ReactElement, useRef } from 'react';
import XCircle from 'react-feather/dist/icons/x-circle';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from 'schema/generated/models';
import './SelectContributor.scss';

type SelectContributorProps = {
  /** The list of users that the current user can pick from */
  users: User[];
  /** A function helper used to determine if we are allowed to remove a row after picking it up
   * e.g. if you want to allow all rows to be deleted, set to a function returning always true.
   * If you want the user to be able to delete only their row, set to a function returning userItem.id === user.id
   */
  computeAuthorizeRemove: (userItem) => boolean;
  /** Object returned by useFieldArray() in the parent form (see react hook form documentation) */
  fieldArrayMethods: any; // Real type is too long to be readable, see react-hook-form/updateFieldArray.d.ts
  /** Optional - If you need to mark this form section as dirty (most of the time keep to null) */
  markAsDirty?: (() => void) | null;
};

/** Allows the current user to pick some users from a given list.
 * The selected users will be available for retrieval in the data.contributor attribute
 * in the parent form's submit method (This component is to be used as a child of a reac-hook-form"atted" component).
 * See react-hook-form documentation for more information: https://react-hook-form.com/api/.
 * For this to work you can inject form default values in the parent, e.g:
 * const methods = useForm({
    defaultValues: {
      contributors: users.map((u: User) => ({
        user: {
          id: u.id, // important --> Will be used internally to retrieve the user
          fullName: `${u.firstName} ${u.lastName}`, // How the user will be displayed by <option> fields in the select box
        },
      })),
    },
  });
 */
export default function SelectContributor({
  users,
  computeAuthorizeRemove,
  fieldArrayMethods,
  markAsDirty = null,
}: SelectContributorProps): ReactElement {
  const { register } = useFormContext();
  const { t } = useTranslation();
  const selectRef = useRef<HTMLSelectElement>(document.createElement('select'));

  const getRemoveButton = (userItem, idx): ReactElement => {
    const isAllowedToRemove = computeAuthorizeRemove(userItem);
    if (isAllowedToRemove) {
      return (
        <XCircle
          className="icon"
          onClick={(): void => {
            // eslint-disable-next-line react/prop-types
            fieldArrayMethods.remove(idx);
            if (markAsDirty) {
              markAsDirty();
            }
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <section className="collaborators-container">
      <select
        name="select"
        defaultValue=""
        ref={selectRef}
        onChange={(e): void => {
          if (e.target.value !== '') {
            const u = JSON.parse(e.target.value);
            if (markAsDirty) {
              markAsDirty();
            }
            fieldArrayMethods.append({ user: u, name: 'contributors' });
            e.target.value = '';
            // eslint-disable-next-line no-unused-expressions
            selectRef?.current?.blur();
          }
        }}
      >
        <option value="" disabled>
          {t('common:form:select')}
        </option>
        {users
          .filter((u) => !fieldArrayMethods?.fields?.find((addedUser) => addedUser.user.id === u.id))
          .map((u) => (
            <option key={u.id} value={JSON.stringify({ id: u.id, fullName: `${u.firstName} ${u.lastName}` })}>
              {u.firstName}
              {' '}
              {u.lastName}
            </option>
          ))}
      </select>
      {fieldArrayMethods?.fields?.map((item, idx) => (
        <div key={item.user.id} className="collaborator-row">
          <input
            className="crea-hidden"
            disabled
            ref={register()}
            type="number"
            value={item.user.id}
            name={`contributors[${idx}].id`}
          />
          <input
            ref={register()}
            name={`contributors[${idx}].fullName`}
            readOnly
            type="text"
            className="contributorInput"
            value={item.user.fullName}
          />
          {getRemoveButton(item.user, idx)}
        </div>
      )) ?? <div />}
    </section>
  );
}
