import { errorAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './CreateDocument.scss';

export default function CreateDocument(): ReactElement {
  const { user } = useUserContext();
  const { addDocument, project } = useProjectContext();
  const [modalActive, setModalActive] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'all',
    defaultValues: {
      title: '',
    },
  });

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const onSubmit = async (data): Promise<void> => {
    try {
      await addDocument({
        variables: {
          projectId: project.id,
          title: data.title,
        },
      });
      const locationState: CreaLocationState = {
        pageParams: {},
        popupMessages: [new CreaPopupMessage(EPopupMessageType.success, t('project:documents:action.add.success'))],
      };
      history.push({
        pathname: history.location.pathname,
        state: locationState,
      });
    } catch (err) {
      console.error(err);
      errorAlert(t('project:documents:action.add.error'));
    }
  };

  return (
    <div className="create-document">
      {project.owner.id === user.id && (
        <button type="button" className="creaflow-secondary-button" onClick={toggleModal}>
          <FaPlus />
          {` ${t('project:documents:action.add.label')}`}
        </button>
      )}

      <Modal isOpen={modalActive} toggle={toggleModal} centered>
        <form className="crea-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal} close={React.createElement((): ReactElement => CloseButton(toggleModal))} className="modal-head">
            {t('project:documents:action.add.label')}
          </ModalHeader>
          <ModalBody>
            <section>
              <label htmlFor="title">{t('project:documents:attribute.title')}</label>
              <input
                className={`${methods.errors.title && 'error'}`}
                ref={methods.register({
                  required: {
                    value: true,
                    message: t('common:form:validation.required_field'),
                  },
                  maxLength: {
                    value: 254,
                    message: t('common:form:validation.string_max_255'),
                  },
                })}
                type="text"
                name="title"
                id="title"
              />
              <ErrorMessage errors={methods.errors} name="title">
                {({ messages }): any =>
                  messages
                  && Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))}
              </ErrorMessage>
            </section>
          </ModalBody>
          <ModalFooter className="actions">
            <button className="creaflow-primary-button" color="success" type="submit">
              {t('project:documents:action.add.label')}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}
