import { successAlert } from 'components/Utilities/Alert/Alert';
import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { Mode, useWorkflowContext } from 'context/WorkflowContext';
import { getStatusHumanReadable } from 'models/EStatus';
import moment from 'moment';
import 'moment/locale/fr';
import React, { ReactElement, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, ErrorMessage, FormContext, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AddContractMutationVariables, AddPhaseMutationVariables, Contract, DeleteContractMutationVariables, Status, UpdateContractMutationVariables } from 'schema/generated/models';
import ContractForm from './EditContract';
import './EditPhase.scss';

function EditPhase(): ReactElement {
  const { user } = useUserContext();
  const { setMode, currentPhase, updatePhase, addPhase } = useWorkflowContext();
  const { project } = useProjectContext();
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      title: currentPhase.title,
      startDate: new Date(currentPhase.startAt),
      endDate: new Date(currentPhase.endAt),
      status: currentPhase.status ?? '',
      contracts:
        currentPhase?.contracts?.map((c: Contract) => ({
          id: c.id,
          temporality: c.temporality ?? '',
          recipient: c.recipient ?? '',
          rule: c.rule ?? '',
          quantificationHowMuch: c.quantificationHowMuch,
          quantificationWhat: c.quantificationWhat ?? '',
        })) ?? [],
    },
  });
  const fieldArrayMethods = useFieldArray({
    control: methods.control,
    name: 'contracts',
  });

  const [modalActive, setModalActive] = useState(false);
  const watchStartDate = methods.watch('startDate') as any;

  const toggleModal = (): void => {
    setModalActive(!modalActive);
    setMode(Mode.hidden);
  };

  const onSubmit = async (data: Record<string, any>): Promise<void> => {
    if (currentPhase.id === '0') {
      const phaseInput: AddPhaseMutationVariables = {
        status: data.status,
        startAt: data.startDate,
        endAt: data.endDate,
        title: data.title,
        projectId: project.id,
      };
      const contractInputs: AddContractMutationVariables[] = data.contracts?.map((c) => ({
        ...c,
        quantificationHowMuch: parseInt(c.quantificationHowMuch, 10),
      })) ?? [];
      await addPhase(phaseInput, contractInputs);
      successAlert(t('project:workflow:phase.action.add.success'));
    } else {
      const newContracts: AddContractMutationVariables[] = [];
      const editedContracts: UpdateContractMutationVariables[] = [];
      const removedContracts: DeleteContractMutationVariables[] = [];
      if (data.contracts) {
        data.contracts.forEach((c) => {
          if (c.id === '') {
            newContracts.push({
              ...c,
              quantificationHowMuch: parseInt(c.quantificationHowMuch, 10),
            });
          } else if (currentPhase.contracts.map((contratTyped: Contract) => contratTyped).some((k) => k.id === c.id)) {
            editedContracts.push({
              ...c,
              quantificationHowMuch: parseInt(c.quantificationHowMuch, 10),
            });
          }
        });
      }
      if (currentPhase.contracts) {
        currentPhase.contracts.forEach((c: Contract) => {
          if (!data.contracts) {
            removedContracts.push({ id: c.id });
          } else if (!data.contracts.some((k) => k.id === c.id)) {
            removedContracts.push({ id: c.id });
          }
        });
      }
      await updatePhase(
        {
          id: currentPhase.id,
          status: data.status,
          startAt: data.startDate,
          endAt: data.endDate,
          title: data.title,
        },
        newContracts,
        editedContracts,
        removedContracts,
      );
      successAlert(t('project:workflow:phase.action.edit.success'));
    }
  };

  return (
    <Modal isOpen toggle={toggleModal} centered className="edit-phase-modal">
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="crea-form">
          <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))} toggle={toggleModal}>
            <section>
              <div className="form-row">
                <label htmlFor="title">{t('project:workflow:phase.attribute.title')}</label>
                <input
                  className={`${methods.errors.title && 'error'} title-input`}
                  type="text"
                  name="title"
                  id="title"
                  ref={methods.register({
                    required: true,
                  })}
                />
              </div>
              <ErrorMessage errors={methods.errors} name="title">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
          </ModalHeader>
          <ModalBody>
            <section>
              <div className="form-row">
                <Col>
                  <label htmlFor="startDate">{t('project:workflow:phase.attribute.start_at')}</label>
                </Col>
                <Col className="value">
                  <Controller
                    as={DatePicker}
                    locale={user.culture.replace('_', '-')}
                    name="startDate"
                    className={`${methods.errors.startDate && 'error'} edit-phase-datepicker`}
                    rules={{ required: true }}
                    control={methods.control}
                  />
                </Col>
              </div>
              <ErrorMessage errors={methods.errors} name="startDate">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <div className="form-row">
                <Col>
                  <label htmlFor="endDate">{t('project:workflow:phase.attribute.end_at')}</label>
                </Col>
                <Col className="value">
                  <Controller
                    as={DatePicker}
                    locale={user.culture.replace('_', '-')}
                    name="endDate"
                    className={`${methods.errors.endDate && 'error'} edit-phase-datepicker`}
                    minDate={moment(watchStartDate ?? '')
                      .add(1, 'd')
                      .toDate()}
                    rules={{ required: true }}
                    control={methods.control}
                  />
                </Col>
              </div>
              <ErrorMessage errors={methods.errors} name="endDate">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <div className="form-row">
                <Col>
                  <label htmlFor="status">{t('project:workflow:phase.attribute.status')}</label>
                </Col>
                <Col className="value">
                  <select
                    ref={methods.register({ required: true })}
                    name="status"
                    className={`${methods.errors.status ? 'error' : ''}`}
                  >
                    <option value="" disabled>
                      {t('common:form:select')}
                    </option>
                    {Object.values(Status).map((status: Status) => (
                      <option key={status} id={status} value={status}>
                        {getStatusHumanReadable(status)}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage errors={methods.errors} name="status">
                    {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
                  </ErrorMessage>
                </Col>
              </div>
            </section>
            <section>
              <div className="form-row contracts-add">
                <Col>
                  <h2>{t('project:workflow:phase.attribute.contracts')}</h2>
                </Col>
                <Col>
                  <button type="button" className="creaflow-secondary-button-v2" onClick={(): void => fieldArrayMethods.append({ name: 'contracts' })}>
                    <FaPlus />
                    {t('project:workflow:contract.action.add.label')}
                  </button>
                </Col>
              </div>
            </section>
            {fieldArrayMethods.fields.length !== 0 && (
              <div>
                <div className="contract-label-row">
                  <div>{t('project:workflow:contract.temporality.label')}</div>
                  <div>{t('project:workflow:contract.recipient.label')}</div>
                  <div>{t('project:workflow:contract.rule.label')}</div>
                  <div>{t('project:workflow:contract.how_much.label')}</div>
                  <div>{t('project:workflow:contract.what.label')}</div>
                  <div />
                </div>
                {fieldArrayMethods.fields.map((item, index) => (
                  <ContractForm key={item.id} item={item} index={index} fieldArrayMethods={fieldArrayMethods} />
                ))}
              </div>
            )}
            <button type="submit" className="creaflow-primary-button action-button">
              {t('common:form:action.save.label')}
            </button>
          </ModalBody>
        </form>
      </FormContext>
    </Modal>
  );
}

export default EditPhase;
