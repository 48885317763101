import { useUserContext } from 'context/UserContext';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CreaDate.scss';

interface DateProps {
  date: Date;
}

export default function CreaDate({ date }: DateProps): ReactElement {
  const { user } = useUserContext();
  const [formatedDate, setFormatedDate] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const dateAsObj = moment(date);
    if (dateAsObj.isSame(moment(), 'day')) {
      setFormatedDate(moment(date).locale(user.culture).format('LT'));
    } else if (dateAsObj.isSame(moment().subtract(1, 'd'), 'day')) {
      const duration = moment.duration(moment().diff(dateAsObj));
      let hours = Math.trunc(duration.asHours());
      if (hours === 0) {
        hours = 1;
      }
      setFormatedDate(t('project:common:event.hours_ago', { count: hours }));
    } else if (dateAsObj.isBetween(moment().subtract(7, 'd'), moment().subtract(1, 'd'))) {
      setFormatedDate(dateAsObj.locale(user.culture).format('dddd'));
    } else if (dateAsObj.isSameOrBefore(moment().subtract(7, 'd'))) {
      setFormatedDate(dateAsObj.locale(user.culture).format('lll'));
    }
  }, []);

  return <>{formatedDate !== '' && formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1)}</>;
}
