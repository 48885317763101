import {
  ContractQuantificationWhat,
  ContractRecipient,
  ContractRule,
  ContractTemporality,
} from 'JSUtils/schema/generated/models';
import 'moment/locale/fr';
import React, { ReactElement } from 'react';
import XCircle from 'react-feather/dist/icons/x-circle';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getEQuantificationWhat, getERecipientAsStr, getERuleAsStr, getETemporalityAsStr } from '../utils';
import './EditPhase.scss';

type ContractFormProps = {
  item: any;
  index: number;
  fieldArrayMethods: any;
};

function ContractForm({ item, index, fieldArrayMethods }: ContractFormProps): ReactElement {
  const { t } = useTranslation();
  const { errors, register } = useFormContext();
  const getFieldName = (): string => `contracts[${index}]`;
  return (
    <section className="contract-row">
      <input className="crea-hidden" ref={register()} name={`${getFieldName()}.id`} />

      <div className="small-screen-row">
        <div className="small-screen">{t('project:workflow:contract.temporality.label')}</div>
        <select
          ref={register({ required: true })}
          name={`${getFieldName()}.temporality`}
          defaultValue={item.temporality ?? ''}
          className={`${
            errors.contracts && errors.contracts[index] && errors?.contracts[index].temporality ? 'error' : ''
          }`}
        >
          <option value="" disabled>
            {t('common:form:select')}
          </option>
          {Object.values(ContractTemporality).map((temporality) => (
            <option key={temporality} id={temporality} value={temporality}>
              {getETemporalityAsStr(temporality)}
            </option>
          ))}
        </select>
        <ErrorMessage errors={errors} name={`${getFieldName()}.temporality`}>
          {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
        </ErrorMessage>
      </div>

      <div className="small-screen-row">
        <div className="small-screen">{t('project:workflow:contract.recipient.label')}</div>
        <select
          ref={register({ required: true })}
          defaultValue={item.recipient ?? ''}
          name={`${getFieldName()}.recipient`}
          className={`${
            errors.contracts && errors.contracts[index] && errors?.contracts[index].recipient ? 'error' : ''
          }`}
        >
          <option value="" disabled>
            {t('common:form:select')}
          </option>
          {Object.values(ContractRecipient).map((recipient) => (
            <option key={recipient} id={recipient} value={recipient}>
              {getERecipientAsStr(recipient)}
            </option>
          ))}
        </select>
        <ErrorMessage errors={errors} name={`${getFieldName()}.recipient`}>
          {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
        </ErrorMessage>
      </div>

      <div className="small-screen-row">
        <div className="small-screen">{t('project:workflow:contract.rule.label')}</div>
        <select
          ref={register({ required: true })}
          defaultValue={item.rule ?? ''}
          className={`${errors.contracts && errors.contracts[index] && errors?.contracts[index].rule ? 'error' : ''}`}
          name={`${getFieldName()}.rule`}
        >
          <option value="" disabled>
            {t('common:form:select')}
          </option>
          {Object.values(ContractRule).map((rule) => (
            <option key={rule} id={rule} value={rule}>
              {getERuleAsStr(rule)}
            </option>
          ))}
        </select>
        <ErrorMessage errors={errors} name={`${getFieldName()}.rule`}>
          {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
        </ErrorMessage>
      </div>

      <div className="small-screen-row">
        <div className="small-screen">{t('project:workflow:contract.how_much.label')}</div>
        <input
          type="number"
          ref={register({ required: true })}
          min="0"
          max="999"
          className={`input-number ${
            errors.contracts && errors.contracts[index] && errors?.contracts[index].quantificationHowMuch && 'error'
          }`}
          id="quantificationHowMuch"
          name={`${getFieldName()}.quantificationHowMuch`}
        />
        <ErrorMessage errors={errors} name={`${getFieldName()}.quantificationHowMuch`}>
          {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
        </ErrorMessage>
      </div>

      <div className="small-screen-row">
        <div className="small-screen">{t('project:workflow:contract.what.label')}</div>
        <select
          ref={register({ required: true })}
          defaultValue={item.quantificationWhat ?? ''}
          name={`${getFieldName()}.quantificationWhat`}
          className={`${
            errors.contracts && errors.contracts[index] && errors?.contracts[index].quantificationWhat ? 'error' : ''
          }`}
        >
          <option value="" disabled>
            {t('common:form:select')}
          </option>
          {Object.values(ContractQuantificationWhat).map((quantificationWhat) => (
            <option key={quantificationWhat} id={quantificationWhat} value={quantificationWhat}>
              {getEQuantificationWhat(quantificationWhat)}
            </option>
          ))}
        </select>
        <ErrorMessage errors={errors} name={`${getFieldName()}.quantificationWhat`}>
          {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
        </ErrorMessage>
      </div>
      <div className="big-screen">
        <XCircle className="delete-contract-icon" onClick={(): void => fieldArrayMethods.remove(index)} />
      </div>
      <div className="small-screen small-screen-row">
        <button type="button" className="creaflow-primary-button" onClick={(): void => fieldArrayMethods.remove(index)}>
          {t('project:workflow:contract.action.delete.label')}
        </button>
      </div>
    </section>
  );
}

export default ContractForm;
