import React, { ReactElement, useRef } from 'react';
import Search from 'react-feather/dist/icons/search';
import './Header.scss';

type HeaderProps = {
  setFilter: (s: string) => void;
  WrappedComponent: React.ComponentType;
  searchLabel: string;
}

export default function Header({ setFilter, searchLabel, WrappedComponent }: HeaderProps): ReactElement {
  const searchBar = useRef<HTMLInputElement>(null);
  return (
    <div className="browsable-page-header">
      <form onSubmit={(e): void => e.preventDefault()} className="search-bar">
        <section>
          <Search className="icon" />
          <input
            type="text"
            ref={searchBar}
            placeholder={searchLabel}
            onChange={(e): void => setFilter(e.target.value.toLowerCase())}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                // eslint-disable-next-line no-unused-expressions
                searchBar?.current?.blur();
              }
            }}
          />
        </section>
      </form>
      <WrappedComponent />
    </div>
  );
}
