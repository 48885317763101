import { EventRow } from 'components/Project/EventRow/EventRow';
import { useProjectContext } from 'context/ProjectContext';
import 'moment/locale/fr';
import React, { ReactElement } from 'react';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import ActionRow from './ActionRow';
import './EventList.scss';

enum TabsNames {
  ACTION = 1,
  EVENTS_UNSEEN,
  EVENTS,
}

function PaginationUpdator(): ReactElement {
  const {
    eventsNextPage,
    eventsCount,
    eventsPrevPage,
    eventPagination,
    isLoadingEvents,
    currentPage,
    maxPage,
  } = useProjectContext();
  const offset = (eventPagination.offset || 0) + 1;
  let maxOffset = offset + (eventPagination.limit || 1000);
  if (maxOffset > eventsCount) maxOffset = eventsCount;
  const canPrevPage = currentPage > 1;
  const canNextPage = currentPage < maxPage;
  const { t } = useTranslation();

  return (
    <div className="pagination">
      <span>
        {t('common:misc:records')}
        {' '}
        {offset}
        {' '}
        -
        {' '}
        {maxOffset}
        {' '}
        /
        {' '}
        {eventsCount}
      </span>
      <div className="chevron">
        <ChevronLeft
          onClick={(): void => {
            if (canPrevPage) eventsPrevPage();
          }}
        />
      </div>
      <div className="chevron">
        <ChevronRight
          onClick={(): void => {
            if (canNextPage) eventsNextPage();
          }}
        />
      </div>
      {isLoadingEvents && <span>{t('common:misc:loading')}</span>}
    </div>
  );
}

function DisplayEvents({ type }: {type: TabsNames}): ReactElement {
  const { events } = useProjectContext();
  const { actions } = useProjectContext();
  const { t } = useTranslation();
  return (
    <>
      {
        type !== TabsNames.ACTION
        && <PaginationUpdator />
      }
      <div className="events-formater">
        <Table>
          <thead>
            <tr>
              <th>{t('project:common:event.table.date')}</th>
              <th>{t('project:common:event.table.document')}</th>
              <th>{t('project:common:event.table.proposition')}</th>
              <th>{t('project:common:event.table.version')}</th>
              <th>{t('project:common:event.table.event')}</th>
              {
                type === TabsNames.ACTION && <th>{t('project:common:actions.table.action')}</th>
              }
              {
                type === TabsNames.EVENTS_UNSEEN && <th>{t('project:common:event.table.seen')}</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              type === TabsNames.ACTION
                ? actions.map((a) => (
                  <ActionRow key={a.id} cr={a} />
                ))
                : events.map((ev) => (
                  <EventRow key={ev.id} event={ev} type={type} />
                ))
            }
          </tbody>
        </Table>
      </div>
    </>
  );
}

export {
  TabsNames, DisplayEvents,
};
