import { useMutation } from '@apollo/client';
import ProjectNavBar from 'containers/Project/components/ProjectNavBar/ProjectNavBar';
import { useProjectContext } from 'context/ProjectContext';
import { MARK_DOCUMENTS_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import Header from 'Project/Header/Header';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from 'schema/generated/models';
import CreateDocument from './components/CreateDocument/CreateDocument';
import DocumentRow from './components/DocumentRow/DocumentRow';
import './DocumentList.scss';

const DocumentsContent: React.FC = () => {
  const { project, isLoading, getFilteredDocumentsAndPropositions, filter } = useProjectContext();
  const [selection, setSelection] = useState<Document[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading) {
      setSelection(getFilteredDocumentsAndPropositions());
    }
  }, [filter, isLoading, project.documents]);

  return (
    <>
      <div className="documents">
        {selection !== undefined && selection.length !== 0 ? (
          selection.map(
            (document: Document, i: number, arr: Document[]): ReactElement => (
              <div key={document.id}>
                <DocumentRow document={document} activeProp />
                {
                  arr.length - 1 !== i && <hr /> // if array element is not the last item
                }
              </div>
            ),
          )
        ) : (
          <p className="document-not-found">{t('project:documents:action.search.not_found')}</p>
        )}
      </div>
    </>
  );
};

export default function DocumentList(): ReactElement {
  const { t } = useTranslation();
  const { setFilter, project } = useProjectContext();

  const [markVersionEventsAsSeen] = useMutation(MARK_DOCUMENTS_EVENTS_AS_SEEN);
  useEffect((): void => {
    markVersionEventsAsSeen({ variables: { projectId: project.id } });
  }, []);

  return (
    <>
      <ProjectNavBar />
      <div className="document-list-content">
        <Header
          setFilter={setFilter}
          searchLabel={t('project:documents:action.search.label')}
          WrappedComponent={CreateDocument}
        />
        <DocumentsContent />
        <div />
      </div>
    </>
  );
}
