import AddProjectImage from 'assets/project/add_project.png';
import CreateProject from 'components/Project/CreateProject/CreateProject';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import { Project } from 'schema/generated/models';
import './ProjectCard.scss';

interface ProjectCardProps {
  project?: Project | null;
  clickableLinks?: boolean;
}

function ProjectCard({ project }: ProjectCardProps): ReactElement {
  const history = useHistory();
  const projectLink = `/project/${project?.id}/dashboard`;
  const openProject = (): void => history.push(projectLink);
  const { t } = useTranslation();

  const getProjectImage = (): ReactElement => (
    <>
      {project?.previewImageUrl && <img src={project?.previewImageUrl} alt="preview" />}
      <div className={`${!project?.previewImageUrl && 'default-placeholder'} image-cover`}>
        {!project?.previewImageUrl && <p>{project?.title}</p>}
      </div>
    </>
  );

  return (
    <div className="project-card-container">
      <div className={`${project === null && 'add-project'} img-container`}>
        {project === null
          ? <img src={AddProjectImage} alt="preview" />
          : getProjectImage()}
      </div>
      <div className="description">
        <Link to={projectLink}>
          <h2 className="title">
            {project === null && t('project:common:card.add_project_prompt')}
            {project !== undefined && project !== null && project.title.length < 3 ? (
              project.title
            ) : (
              <TextTruncate line={2} element="span" truncateText="…" text={project?.title} />
            )}
          </h2>
        </Link>
        {project && <p>{t('project:common:card.x_new_events', { count: project.unSeenEventsCount })}</p>}
        {
          project
            ? (
              <button type="button" onClick={openProject} className={project.awaitingActionsCount > 0 ? 'creaflow-primary-button' : 'creaflow-secondary-button'}>
                {project.awaitingActionsCount > 0
                  ? t('project:common:card.x_new_actions', { count: project.awaitingActionsCount })
                  : t('project:common:card.open')}
              </button>
            )
            : <CreateProject />
        }
      </div>
    </div>
  );
}

export default ProjectCard;
