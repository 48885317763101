import { useMutation } from '@apollo/client';
import Tutorial from 'components/Tutorial/Tutorial';
import { useProjectContext } from 'context/ProjectContext';
import { MARK_PROJECT_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import ProjectNavBar from '../components/ProjectNavBar/ProjectNavBar';
import ProjectDashboardEvents from './components/Events/ProjectDashboardEvents';
import MainInfo from './components/MainInfo/MainInfo';
import './ProjectDashboard.scss';

function ProjectDashboard(): ReactElement {
  const { project } = useProjectContext();
  const [projectTitle, setProjectTitle] = useState('');

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  useEffect(() => {
    if (!project.title && project.title.length > 50) setProjectTitle(`${projectTitle.slice(0, 50)}...`);
    else setProjectTitle(project.title);
  }, []);

  const [markProjectEventsAsSeen] = useMutation(MARK_PROJECT_EVENTS_AS_SEEN);
  useEffect((): void => {
    markProjectEventsAsSeen({ variables: { projectId: project.id } });
  }, []);

  return (
    <>
      <ProjectNavBar />
      <div className="project-dashboard-content">
        <MainInfo />
        <ProjectDashboardEvents />
        <Tutorial
          tutorialType="project_architecture"
          title={t('help_center:project.tutorial_label')}
          callToActionLabel={t('help_center:project:call_to_action')}
        >
          <Trans i18nKey="help_center:project.tutorial">
            <strong />
          </Trans>
        </Tutorial>
      </div>
    </>
  );
}

export default ProjectDashboard;
