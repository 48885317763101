/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import CloseButton from 'components/Utilities/CloseButton';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import './Changelog.scss';

export default function Changelog({ markChangelogAsRead }: {markChangelogAsRead: any}): ReactElement {
  return (
    <div className="widget-container changelog">
      <div className="center">
        <p>
          <Trans i18nKey="main_dashboard:widget.changelog.content">
            <a onClick={markChangelogAsRead} href="https://headwayapp.co/creativs-changelog" target="_blank" rel="noopener noreferrer" />
          </Trans>
        </p>
      </div>
      {React.createElement((): ReactElement => CloseButton(markChangelogAsRead))}
    </div>

  );
}
