import { usePropositionContext } from 'context/PropositionContext';
import { useUserContext } from 'context/UserContext';
import { getStatusColorKey } from 'models/EStatus';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import Draggable from 'react-draggable';
import { Annotation } from 'schema/generated/models';

interface Vector2 {
  x: number;
  y: number;
}

const ANNOTATION_GAP = 19;
const getDisplayAnnotationPosition = ({ x, y }): Vector2 => ({ x: x - ANNOTATION_GAP, y: y - ANNOTATION_GAP });
const getRealAnnotationPosition = ({ x, y }): Vector2 => ({ x: x + ANNOTATION_GAP, y: y + ANNOTATION_GAP });

interface DraggableAnnotationProps {
  annotation: Annotation;
  position: Vector2;
}

function DraggableAnnotation({ annotation, position }: DraggableAnnotationProps): ReactElement {
  const { user } = useUserContext();
  const {
    getCanvasSize,
    getImagePosition,
    setAnnotation,
    updateAnnotation,
    proposition,
    version,
  } = usePropositionContext();
  // const [activeDrag, setActiveDrag] = useState(false);
  const [startDragTimestamp, setStartDragTimestamp] = useState(0);
  const [endDragTimestamp, setEndDragTimestamp] = useState(0);

  const isLastVersion = proposition.versions[proposition.versions.length - 1]?.id === version.id;
  const versionCreatedInLast24Hours = moment().subtract(1, 'd').isBefore(moment(version.createdAt));
  const canMoveAsVersionAuthor = user.id === version.author?.id && versionCreatedInLast24Hours;
  const canMoveAsAnnotationAuthor = user.id === annotation.author?.id;
  const canMoveAnnotation = isLastVersion && (canMoveAsVersionAuthor || canMoveAsAnnotationAuthor);

  return (
    <Draggable
      disabled={!canMoveAnnotation}
      position={getDisplayAnnotationPosition(position)}
      onStart={(e): void => {
        // setActiveDrag(true);
        setStartDragTimestamp(e.timeStamp);
      }}
      onStop={(e, data): void => {
        // setActiveDrag(false);
        // Fix mobile usage. Cancel double click
        if (startDragTimestamp - endDragTimestamp <= 100) return;
        setEndDragTimestamp(e.timeStamp);
        if (e.timeStamp - startDragTimestamp > 200) {
          let { x, y } = getRealAnnotationPosition(data);
          const canvasSize = getCanvasSize ? getCanvasSize() : null;
          if (!canvasSize) return;
          // Avoid annotation to be move out the canvas (especialy for mobile since you can't move canvas)
          if (x < 0) x = 0;
          if (y < 0) y = 0;
          if (x > canvasSize.width) x = canvasSize.width;
          if (y > canvasSize.height) y = canvasSize.height;

          const imagePos = getImagePosition ? getImagePosition(x, y) : null;
          if (imagePos) updateAnnotation({ id: annotation.id, x: imagePos.x, y: imagePos.y });
        } else setAnnotation(annotation, e.timeStamp);
      }}
    >
      <div
        className={`roundedAnnotation status-${getStatusColorKey(annotation.status)}`}
        onClick={(e): void => {
          if (!canMoveAnnotation) setAnnotation(annotation, e.timeStamp);
        }}
      >
        <div className="annotation-number">{annotation.idInCanvas}</div>
        <img
          src={annotation.author?.avatarUrl}
          onDragStart={(e): void => {
            e.preventDefault();
          }}
          alt="1"
        />
      </div>
    </Draggable>
  );
}

export default DraggableAnnotation;
