import logo from 'assets/logo/256x256.png';
import * as NavigationGA from 'JSUtils/ReactGA/navigation';
import React, { ReactElement, useState } from 'react';
import Grid from 'react-feather/dist/icons/grid';
import Menu from 'react-feather/dist/icons/menu';
import Trello from 'react-feather/dist/icons/trello';
import X from 'react-feather/dist/icons/x';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HelpOverlay from './HelpOverlay/HelpOverlay';
import './MainNavBar.scss';
import UserActionsOverlay from './UserActionsOverlay/UserActionsOverlay';

type MainNavBarProps = {
  extendMainNavBar?: boolean;
};

function MainNavBar({ extendMainNavBar = false }: MainNavBarProps): ReactElement {
  const { t } = useTranslation();

  const [responsiveMenu, setResponsiveMenu] = useState(false);

  const trackToDashboard = (src: string): void => {
    if (src === 'logo') NavigationGA.goToDashboardFromLogo();
    else NavigationGA.goToDashboardFromTab();
  };

  const onResponsiveMenuClicked = (): void => {
    setResponsiveMenu(!responsiveMenu);
  };

  return (
    <div className={`navbar-container ${extendMainNavBar ? 'supercharged' : ''}`}>
      {responsiveMenu ? (
        <X className="nav-menu-responsive" onClick={onResponsiveMenuClicked} />
      ) : (
        <Menu className="nav-menu-responsive" onClick={onResponsiveMenuClicked} />
      )}
      <div className={responsiveMenu ? 'nav-item-left-responsive' : 'nav-item-left'}>
        <Link to="/mainDashboard" onClick={(): void => trackToDashboard('logo')}>
          <img className="nav-logo" src={logo} alt="logo" />
        </Link>
        <Link to="/mainDashboard" onClick={(): void => trackToDashboard('tab')} className="nav-link">
          <Trello className="icon" />
          {t('nav:dashboard')}
        </Link>
        <Link to="/projectList/" className="nav-link">
          <Grid className="icon" />
          {t('nav:project_list')}
        </Link>
      </div>

      <div className={responsiveMenu ? 'nav-item-right-responsive' : 'nav-item-right'}>
        <HelpOverlay />
        <UserActionsOverlay />
      </div>
    </div>
  );
}

export default MainNavBar;
