import CloseButton from 'components/Utilities/CloseButton';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import React, { ReactElement, useState } from 'react';
import Trash2 from 'react-feather/dist/icons/trash-2';
import { useTranslation } from 'react-i18next';
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './DeleteDocument.scss';

type DeleteDocumentProps = {
  documentId: string;
};

export default function DeleteDocument({ documentId }: DeleteDocumentProps): ReactElement {
  const { user } = useUserContext();
  const { deleteDocument, project } = useProjectContext();
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const onDeleteClick = async (): Promise<void> => {
    await deleteDocument({ id: documentId });
    toggleModal();
  };

  return (
    <div className="delete-document">
      {project.owner.id === user.id && (
        <button type="button" className="creaflow-secondary-button red-button" onClick={toggleModal}>
          <Trash2 id="trash-icon" />
          <span className="button-text">{t('project:documents:action.delete.label')}</span>
        </button>
      )}

      <Modal isOpen={modalActive} toggle={toggleModal} centered className="delete-document-modal">
        <ModalHeader close={React.createElement((): ReactElement => CloseButton(toggleModal))} toggle={toggleModal} className="modal-head">
          {t('project:documents:action.delete.label')}
        </ModalHeader>
        <ModalBody>
          <Label>{t('project:documents:action.delete.ask_confirmation')}</Label>
        </ModalBody>
        <ModalFooter>
          <div className="cancel">
            <button type="button" className="creaflow-primary-button" onClick={toggleModal}>
              {t('common:form:action.cancel.label')}
            </button>
          </div>
          <div className="danger">
            <button type="button" className="creaflow-primary-button" onClick={onDeleteClick}>
              {t('common:form:action.delete.label')}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
