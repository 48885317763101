/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import { useMutation } from '@apollo/client';
import CreaSpinner from 'components/Utilities/Spinner/CreaSpinner';
import EditPhase from 'containers/Project/WorkflowSpace/components/EditPhase/EditPhase';
import ViewPhase from 'containers/Project/WorkflowSpace/components/ViewPhase/ViewPhase';
import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import { Mode, useWorkflowContext, WorkflowContextProvider } from 'context/WorkflowContext';
import useQueryString from 'JSUtils/custom-hooks/useQueryString';
import { MARK_WORKFLOW_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import { getStatusColorKey } from 'models/EStatus';
import 'moment/locale/fr';
import moment, { Moment } from 'moment/moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Timeline, { CustomHeader, TimelineHeaders } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Phase, Status } from 'schema/generated/models';
import Tutorial from 'Tutorial/Tutorial';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import ProjectNavBar from '../components/ProjectNavBar/ProjectNavBar';
import './Workflow.scss';

function PhaseModal(): ReactElement {
  const { mode } = useWorkflowContext();
  switch (mode) {
    case Mode.view:
      return <ViewPhase />;
    case Mode.edit:
      return <EditPhase />;
    default:
      return <></>;
  }
}

function WorkflowConsumer(): ReactElement {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const { updatePhase, setMode, phases, isLoading, currentPhase, setCurrentPhase } = useWorkflowContext();

  const location = useLocation();

  const [groups, setGroups] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [timelineStartDate, setTimelineStartDate] = useState<Moment | null>(null);

  useEffect(() => {
    moment.locale(user.culture);
  }, []);

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, ''); // avoid 2nd trigger of success if page reload
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (!isLoading) {
      const thisItems: any[] = [];
      const thisGroups: any[] = [];
      phases
        .filter((p: Phase) => p.id !== '0')
        .forEach((phase: Phase, idx: number) => {
          const data = {
            id: parseInt(phase.id, 10),
            group: idx + 1,
            title: phase.title,
            start_time: moment(phase.startAt), // Must be a moment object, the library does not accept regular Date objects
            end_time: moment(phase.endAt), // Must be a moment object, the library does not accept regular Date objects
            minResizeWidth: 50,
            itemProps: {
              // these optional attributes are passed to the root <div /> of each
              // item as <div {...itemProps} />
              className: `status-${getStatusColorKey(phase.status ?? Status.ToSpecify)}`,
            },
          };
          const group = { id: idx + 1 };
          thisItems.push(data);
          thisGroups.push(group);
        });
      if (isSubscribed) {
        setGroups(thisGroups);
        setItems(thisItems);
      }
      const phaseStartDates = phases.map((p: Phase) => moment(p.startAt));
      const latestStartDate = moment.max(phaseStartDates).add(-2, 'day');
      const todaysDate = moment().add(-2, 'day');
      if (latestStartDate.isBefore(todaysDate) && isSubscribed) {
        setTimelineStartDate(latestStartDate);
      } else {
        setTimelineStartDate(todaysDate);
      }
    }
    return (): void => {
      isSubscribed = false;
    };
  }, [isLoading, phases, currentPhase.startAt, currentPhase.endAt]);

  const onItemClick = (itemId: number): void => {
    setCurrentPhase(phases.find((p: Phase) => p.id === itemId.toString()) ?? ({} as Phase));
    setMode(Mode.view);
  };

  const onItemSelect = (itemId: number, e): void => {
    e.preventDefault();
    setCurrentPhase(phases.find((p: Phase) => p.id === itemId.toString()) ?? ({} as Phase));
    setMode(Mode.view);
  };

  const onItemMove = async (itemId: number, dragTime): Promise<void> => {
    const phaseTimespan = moment(currentPhase.endAt).diff(moment(currentPhase.startAt), 'days');
    const newStartDate = moment(dragTime);
    await updatePhase({
      ...currentPhase,
      startAt: newStartDate.toDate(),
      endAt: newStartDate.add(phaseTimespan, 'days').toDate(),
    });
  };

  const onItemResize = async (itemId: number, time, edge): Promise<void> => {
    const newDate = new Date(time);
    if (edge === 'left') {
      await updatePhase({
        ...currentPhase,
        startAt: newDate,
      });
    } else if (edge === 'right') {
      await updatePhase({
        ...currentPhase,
        endAt: newDate,
      });
    }
  };

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }): ReactElement => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    return (
      <div {...getItemProps(item.itemProps)}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <div className="rct-item-content" style={{ maxHeight: `${itemContext.dimensions.height}` }}>
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    );
  };

  return isLoading || !timelineStartDate ? (
    <CreaSpinner />
  ) : (
    <div className="workflow-content">
      {project.owner.id === user.id && (
        <div className="add-phase-button">
          <button
            type="button"
            onClick={(): void => {
              const defaultValues: Phase = {
                id: '0',
                title: '',
                startAt: new Date(),
                endAt: moment().add(7, 'd').toDate(),
                contracts: [],
                createdAt: new Date(),
                project,
              };
              setCurrentPhase(defaultValues);
              setMode(Mode.edit);
            }}
            className="creaflow-secondary-button"
            color="primary"
          >
            <FaPlus />
            {` ${t('project:workflow:phase.action.add.label')}`}
          </button>
        </div>
      )}

      <PhaseModal />
      <div className="display-as-flexbox">
        <Timeline
          groups={groups}
          items={items}
          sidebarWidth={0}
          lineHeight={80}
          canMove
          useResizeHandle
          canResize="both"
          defaultTimeStart={moment(timelineStartDate)}
          defaultTimeEnd={moment(timelineStartDate).add(28, 'day')}
          onItemClick={onItemClick}
          onItemSelect={onItemSelect}
          onItemMove={onItemMove}
          onItemResize={onItemResize}
          itemRenderer={itemRenderer}
        >
          <TimelineHeaders className="sticky">
            <CustomHeader unit="month">
              {({ headerContext: { intervals }, getRootProps, getIntervalProps }): ReactElement => (
                <div {...getRootProps()}>
                  {intervals.map(
                    (interval): ReactElement => {
                      const intervalStyle = {
                        textAlign: 'center',
                      };
                      return (
                        <div
                          onClick={(): void => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              /* Must be kept empty to override onClick handler of timeline library */
                              /* DO NOT DELETE */
                            }
                          }}
                          {...getIntervalProps({
                            interval,
                            style: intervalStyle,
                          })}
                        >
                          <div className="sticky">{interval.startTime.format('MMMM YYYY')}</div>
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </CustomHeader>
            <CustomHeader unit="day">
              {({ headerContext: { intervals }, getRootProps, getIntervalProps }): ReactElement => (
                <div {...getRootProps()}>
                  {intervals.map(
                    (interval): ReactElement => {
                      const intervalStyle = {
                        textAlign: 'center',
                      };
                      return (
                        <div
                          onClick={(): void => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              /* Must be kept empty to override onClick handler of timeline library */
                              /* DO NOT DELETE */
                            }
                          }}
                          {...getIntervalProps({
                            interval,
                            style: intervalStyle,
                          })}
                        >
                          {interval.labelWidth > 30 && <div className="sticky">{interval.startTime.format('DD')}</div>}
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </CustomHeader>
          </TimelineHeaders>
        </Timeline>
        <Tutorial
          tutorialType="workflow_page"
          title={t('help_center:workflow.label')}
          callToActionLabel={t('common:form:got_it')}
        >
          <p>{t('help_center:workflow.tutorial')}</p>
        </Tutorial>
      </div>
    </div>
  );
}

function Workflow(): ReactElement {
  const [currentPhaseId] = useQueryString('phase');
  const { project } = useProjectContext();
  const [markWorkflowEventsAsSeen] = useMutation(MARK_WORKFLOW_EVENTS_AS_SEEN);
  useEffect((): void => {
    markWorkflowEventsAsSeen({ variables: { projectId: project.id } });
  }, []);

  return (
    <>
      <ProjectNavBar />
      <WorkflowContextProvider currentPhaseId={currentPhaseId}>
        <WorkflowConsumer />
      </WorkflowContextProvider>
    </>
  );
}

export default Workflow;
