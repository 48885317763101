import ReactGA from 'react-ga';

export const sendReport = (): void => {
  ReactGA.event({
    category: 'Other',
    action: 'Send report',
  });
};

export const _ = (): void => {
  // Avoid Linter "prefer default export"
};
