import CloseButton from 'components/Utilities/CloseButton';
import { useUserContext } from 'context/UserContext';
import { cultureToHumanReadableString, userCultureToString } from 'JSUtils/schema/enumUtils';
import { CustomErrorId, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { errorAlert } from 'Utilities/Alert/Alert';
import './CreateAccount.scss';

const cultureToGQLCulture = {
  'fr-FR': 'fr_FR',
  'en-US': 'en_US',
};

function CreateAccount(): ReactElement {
  const { signup, user } = useUserContext();
  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: 'onBlur',
  });
  const { t: _t, i18n } = useTranslation();

  const cultureWatch = watch('culture');
  const [t, setT] = useState(() => _t);

  useEffect(() => {
    if (cultureWatch) {
      setT(() => i18n.getFixedT(cultureWatch as string));
    } else {
      setT(() => _t);
    }
  }, [cultureWatch, user.culture]);

  const history = useHistory();
  const [modalActive, setModalActive] = useState(false);

  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const onClickedToSubmit = async (data): Promise<void> => {
    if (data.firstName && data.lastName && data.email && data.password && data.culture) {
      try {
        await signup(data.email, data.password, data.firstName, data.lastName, cultureToGQLCulture[data.culture]);
      } catch ({ graphQLErrors }) {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions, message }) => {
            if (extensions?.exception?.errorId === CustomErrorId.SIGNUP_ALREADY_USED) {
              errorAlert(t('user:signup.error.duplicate_entry'));
            } else {
              errorAlert(message);
            }
          });
        }
        return;
      }
      localStorage.setItem('user.authenticated', 'true');
      history.push({
        pathname: '/mainDashboard',
        state: {
          pageParams: {},
          popupMessages: [
            {
              type: EPopupMessageType.success,
              message: t('user:signup.success'),
            },
          ],
        },
      });
    } else {
      errorAlert(t('common:form:validation.all_fields_required'));
    }
  };

  return (
    <div className="create-account-container">
      <button onClick={toggleModal} type="button">
        {t('user:signup.label')}
      </button>

      <Modal isOpen={modalActive} toggle={toggleModal} centered>
        <form onSubmit={handleSubmit(onClickedToSubmit)} className="crea-form">
          <ModalHeader toggle={toggleModal} close={React.createElement((): ReactElement => CloseButton(toggleModal))}>{t('user:signup.label')}</ModalHeader>
          <ModalBody>
            <section>
              <label className={errors.firstName && 'error'} htmlFor="firstName">
                {t('user:attribute.firstName')}
              </label>
              <input
                className={errors.firstName && 'error'}
                name="firstName"
                type="text"
                id="firstName"
                ref={register({ required: true })}
              />
              <ErrorMessage errors={errors} name="firstName">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <label className={errors.lastName && 'error'} htmlFor="lastName">
                {t('user:attribute.lastName')}
              </label>
              <input
                className={errors.lastName && 'error'}
                name="lastName"
                type="text"
                id="lastName"
                ref={register({ required: true })}
              />
              <ErrorMessage errors={errors} name="lastName">
                {(): ReactElement => <p className="error">{t('common:form:validation.required_field')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <label className={errors.email && 'error'} htmlFor="email">
                {t('user:attribute.email')}
              </label>
              <input
                className={errors.email && 'error'}
                name="email"
                id="email"
                type="text"
                ref={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <ErrorMessage errors={errors} name="email">
                {(): ReactElement => <p className="error">{t('common:form:validation.invalid_email_format')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <label className={errors.password && 'error'} htmlFor="password">
                {t('user:attribute.password')}
              </label>
              <input
                className={errors.password && 'error'}
                type="password"
                name="password"
                id="password"
                ref={register({
                  required: true,
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/,
                })}
              />
              <ErrorMessage errors={errors} name="password">
                {(): ReactElement => <p className="error">{t('user:signup.error.invalid_password')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <label className={errors.confirmationPassword && 'error'} htmlFor="confirmationPassword">
                {t('user:attribute.confirmation_password')}
              </label>
              <input
                type="password"
                className={errors.confirmationPassword && 'error'}
                name="confirmationPassword"
                id="confirmationPassword"
                ref={register({
                  validate: (value) => value === watch('password'),
                })}
              />
              <ErrorMessage errors={errors} name="confirmationPassword">
                {(): ReactElement => <p className="error">{t('user:signup.error.password_mismatch')}</p>}
              </ErrorMessage>
            </section>
            <section>
              <label htmlFor="culture">{t('user:attribute.culture')}</label>
              <Controller
                control={control}
                as={(
                  <select>
                    <option value="" disabled>
                      {t('common:form:select')}
                    </option>
                    {['en-US', 'fr-FR'].map((culture) => (
                      <option key={`${culture}`} id={culture} value={culture}>
                        {cultureToHumanReadableString(culture)}
                      </option>
                    ))}
                  </select>
                )}
                defaultValue={userCultureToString(user.culture)}
                name="culture"
              />
            </section>
          </ModalBody>
          <ModalFooter>
            <div className="cancel">
              <button className="creaflow-primary-button" type="button" onClick={toggleModal}>
                {t('common:form:action.cancel.label')}
              </button>
            </div>
            <div className="success">
              <button className="creaflow-primary-button" type="submit">
                {t('user:signup.label')}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default CreateAccount;
