/**
 * Canvas related functions (data fetch)
 */

import axios from 'axios';
import * as querystring from 'querystring';
import { SERVER_BASE_URL, authorize } from './common';
import handle401 from 'JSUtils/handle401';

/**
 * Get presigned url and formData used to post a file on s3
 * @param {string} fileInfo
 */
function getPresignedPostUrl(objectName) {
  return axios
    .get(`${SERVER_BASE_URL}/s3presignedposturl?${querystring.stringify({ objectName })}`, {
      headers: { Authorization: authorize() },
    })
    .then(response => response.data)
    .catch(handle401);
}

/**
 * Post file to s3
 * @param {object} file
 * @param {object} postData
 * @param {function} progressCallback
 */
function s3CreateFile(file, postData, progressCallback, setCancel) {
  const CancelToken = axios.CancelToken;
  const { postURL, formData } = postData;
  const swPostURL = postURL.slice(0, postURL.lastIndexOf('/'));
  const data = new FormData();
  Object.keys(formData).forEach(key => data.append(key, formData[key]));
  data.append('file', file);
  const config = {
    onUploadProgress: progressCallback,
    headers: {
      'content-type': 'multipart/form-data',
    },
    cancelToken: new CancelToken(function executor(c) {
      setCancel(() => () => c()); // That is some weird shit for sure
    }),
  };
  return axios
    .post(swPostURL, data, config)
    .then(response => response.data)
    .catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        throw { canceled: true };
      } else {
        handle401(thrown);
      }
    });
}

export { getPresignedPostUrl, s3CreateFile };
