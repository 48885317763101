/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import annotationEnUs from 'assets/img/help_center/proposition/en-US/annotation.png';
import historyEnUs from 'assets/img/help_center/proposition/en-US/history.png';
import annotationFrFr from 'assets/img/help_center/proposition/fr-FR/annotation.png';
import historyFrFr from 'assets/img/help_center/proposition/fr-FR/history.png';
import calendarEnUs from 'assets/img/help_center/workflow/en-US/Calendar.png';
import calendarFrFr from 'assets/img/help_center/workflow/fr-FR/Calendar.png';
import Modal from 'components/Utilities/Overlay/Modal/Modal';
import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './HelpCenter.scss';


function ProjectCollaborators(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('help_center:project.collaborators.title')}</h2>
      <div className="paragraph">
        <div className="text">
          <Trans i18nKey="help_center:project.collaborators.content">
            <strong />
          </Trans>
          <div className="rules">
            <br />
            <span>{`${t('help_center:rules_label')} `}</span>
            <p>{t('help_center:project.collaborators.rules')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

function ProjectArchitecture(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('help_center:project.label')}</h1>
      <div className="paragraph">
        <div className="text">
          <Trans i18nKey="help_center:project.content">
            <strong />
          </Trans>
          <div className="rules">
            <br />
            <span>{`${t('help_center:rules_label')} `}</span>
            <p>{t('help_center:project.rules')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

function PropositionPageAnnotations(): ReactElement {
  const { t, i18n } = useTranslation();
  const image = {
    'fr-FR': annotationFrFr,
    'en-US': annotationEnUs,
  };
  return (
    <div className="help-proposition">
      <h2>{t('help_center:proposition.annotation.title')}</h2>
      <div className="paragraph">
        <div className="text">
          <p>{t('help_center:proposition.annotation.content')}</p>
          <div className="rules">
            <span>{`${t('help_center:rules_label')} `}</span>
            <p>{t('help_center:proposition.annotation.rules')}</p>
          </div>
        </div>
        <div className="img-container">
          <img src={image[i18n.language]} alt="Annotations" />
        </div>
      </div>
    </div>
  );
}

function PropositionPageHistory(): ReactElement {
  const { t, i18n } = useTranslation();
  const image = {
    'fr-FR': historyFrFr,
    'en-US': historyEnUs,
  };
  return (
    <div className="help-proposition">
      <h2>{t('help_center:proposition.history.title')}</h2>
      <div className="paragraph">
        <div className="text">
          <p>{t('help_center:proposition.history.content')}</p>
          <div className="rules">
            <span>{`${t('help_center:rules_label')} `}</span>
            <p>{t('help_center:proposition.history.rules')}</p>
          </div>
        </div>
        <div className="img-container">
          <img src={image[i18n.language]} alt="Version history" />
        </div>
      </div>
    </div>
  );
}

function PropositionPageRequestReview(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="help-proposition">
      <h2>{t('help_center:proposition.review_request.title')}</h2>
      <div className="paragraph">
        <div className="text">
          <p>{t('help_center:proposition.review_request.content')}</p>
        </div>
      </div>
    </div>
  );
}

function Workflow(): ReactElement {
  const { t, i18n } = useTranslation();
  const images = {
    'fr-FR': calendarFrFr,
    'en-US': calendarEnUs,
  };
  return (
    <div className="help-workflow">
      <h1>{t('help_center:workflow.label')}</h1>
      <img src={images[i18n.language]} alt="Calendar" />
      <Trans i18nKey="help_center:workflow.content">
        <strong />
      </Trans>
      <br />
      <div className="rules">
        <span>{`${t('help_center:rules_label')} `}</span>
        <p>{t('help_center:workflow.rules')}</p>
      </div>
    </div>
  );
}

function WhatsCreaflow(): ReactElement {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('help_center:whats_creaflow.label')}</h1>
      <Trans i18nKey="help_center:whats_creaflow.content">
        <a href="https://www.creativs.cc" target="_blank" rel="noopener noreferrer" />
      </Trans>
    </div>
  );
}

function WorkflowMain(): ReactElement {
  return (
    <Workflow />
  );
}

function PropositionPageMain(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('help_center:proposition.label')}</h1>
      <PropositionPageAnnotations />
      <PropositionPageHistory />
      <PropositionPageRequestReview />
    </>
  );
}

function ProjectMain(): ReactElement {
  return (
    <>
      <ProjectArchitecture />
      <ProjectCollaborators />
    </>
  );
}

type HelpCenterProps = {
  close: () => void;
};

function HelpCenter({ close }: HelpCenterProps): ReactElement {
  const [CurrentlyShowedContent, setCurrentlyShowedContent] = useState<ReactElement>(() => <WhatsCreaflow />);
  const { t } = useTranslation();

  return (
    <Modal toggle={close} className="help-center" title={t('help_center:title')}>
      <div className="help-center-container">
        <div className="section side-menu">
          <ul>
            <li className="level-0" onClick={(): void => setCurrentlyShowedContent(() => <WhatsCreaflow />)}>
              <span role="img" aria-label="art">
                🎨
              </span>
              <span className="label">{t('help_center:whats_creaflow.label')}</span>
            </li>
            <li className="level-0" onClick={(): void => setCurrentlyShowedContent(() => <ProjectMain />)}>
              <span role="img" aria-label="art">
                📂
              </span>
              <span className="label">{t('help_center:project.label')}</span>
            </li>
            <li className="level-1" onClick={(): void => setCurrentlyShowedContent(() => <WorkflowMain />)}>
              <span role="img" aria-label="art">
                📅
              </span>
              <span className="label">{t('help_center:workflow.label')}</span>
            </li>
            <li className="level-1" onClick={(): void => setCurrentlyShowedContent(() => <PropositionPageMain />)}>
              <span role="img" aria-label="art">
                💬
              </span>
              <span className="label">{t('help_center:proposition.label')}</span>
            </li>
          </ul>
        </div>

        <div className="section content">{CurrentlyShowedContent}</div>
      </div>
    </Modal>
  );
}

export {
  HelpCenter,
  ProjectArchitecture,
  ProjectCollaborators,
  PropositionPageAnnotations,
  PropositionPageHistory,
  PropositionPageRequestReview,
  Workflow,
};
