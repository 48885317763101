import CreateProject from 'components/Project/CreateProject/CreateProject';
import Header from 'components/Project/Header/Header';
import { ProjectListContextProvider, useProjectListContext } from 'context/ProjectListContext';
import { useUserContext } from 'context/UserContext';
import { CreaLocationState, CreaPopupMessage, EPopupMessageType } from 'JSUtils/types';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { errorAlert, successAlert } from 'Utilities/Alert/Alert';
import CreaSpinner from 'Utilities/Spinner/CreaSpinner';
import ProjectCarrousel from './components/ProjectCarrousel/ProjectCarrousel';
import './ProjectList.scss';

const ProjectListConsumer = (): ReactElement => {
  const { setFilter, isLoading } = useProjectListContext();
  const { user } = useUserContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="project-list-container-content">
        <Header
          setFilter={setFilter}
          searchLabel={t('project:common:action.search.label')}
          WrappedComponent={CreateProject}
        />
        <div className="content">
          {isLoading && <CreaSpinner />}
          {user.id !== undefined && <ProjectCarrousel personnalOnly />}
          {user.id !== undefined && <ProjectCarrousel contributeOnly />}
        </div>
      </div>
    </>
  );
};

const ProjectList = (): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      const locationState = location.state as CreaLocationState;
      locationState.popupMessages.forEach((message: CreaPopupMessage) => {
        if (message.type === EPopupMessageType.success) successAlert(message.message);
        if (message.type === EPopupMessageType.error) errorAlert(message.message);
      });
      window.history.pushState(null, '');
    }
  }, []);

  return (
    <ProjectListContextProvider>
      <ProjectListConsumer />
    </ProjectListContextProvider>
  );
};

export default ProjectList;
