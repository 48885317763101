import { useMutation } from '@apollo/client';
import { usePropositionContext } from 'context/PropositionContext';
import { MARK_VERSION_EVENTS_AS_SEEN } from 'JSUtils/schema/events';
import React, { ReactElement, useEffect, useRef } from 'react';
import { Annotation } from 'schema/generated/models';
import AnnotationContainer from '../Annotation/AnnotationContainer';
import CanvasDraw from '../react-canvas-draw';
import './Canvas.scss';

const Canvas = (): ReactElement => {
  const canvasRef = useRef<HTMLDivElement>(document.createElement('div'));
  const canvasDraw = useRef<CanvasDraw | null>(null);
  const {
    version,
    annotation,
    addAnnotation,
    updateAnnotationsPositions,
    setCanvasHeight,
    setCanvasWidth,
    createTextAnnotation,
    setCreateTextAnnotation,
    pencilSize,
    pencilColor,
    reviewMode,
    pencilEnabled,
    setGetUserDrawings,
    setUndoDrawings,
    setRedoDrawings,
    setResetZoom,
    setGetWindowPosition,
    setGetImagePosition,
    setGetCanvasSize,
    setDrawingNeedSaving,
  } = usePropositionContext();

  const [markVersionEventsAsSeen] = useMutation(MARK_VERSION_EVENTS_AS_SEEN);
  useEffect((): void => {
    markVersionEventsAsSeen({ variables: { versionId: version.id } });
  }, [version]);

  useEffect(() => {
    // fix drawings not shown
    setTimeout(() => canvasDraw?.current?.updateCanvasSize(), 500);
  }, [version.id]);

  useEffect(() => {
    if (canvasDraw?.current) {
      if (pencilEnabled) {
        canvasDraw.current.enablePencil();
      } else {
        canvasDraw.current.disablePencil();
      }
    }
  }, [pencilEnabled]);

  useEffect(() => {
    if (canvasDraw?.current) {
      setGetUserDrawings(() => (canvasDraw.current as any).getSaveData);
      setUndoDrawings(() => canvasDraw.current?.bUndo);
      setRedoDrawings(() => canvasDraw.current?.bRedo);
      setResetZoom(() => canvasDraw.current?.bUpdateCanvasSize);
      setGetWindowPosition(() => (x, y): any => canvasDraw.current?.getWindowPosition(x, y));
      setGetImagePosition(() => (x, y): any => canvasDraw.current?.getTransformedPoint(x, y));
      setGetCanvasSize(() => (): any => canvasDraw.current?.getCanvasSize());
    }
  }, [canvasDraw.current]);

  const textAnnotations = reviewMode
    ? null
    : version.annotations.map((a: Annotation) => (
      <AnnotationContainer key={a.id} annotation={a} currentOpenAnnotation={annotation} />
    ));

  return (
    <div
      ref={canvasRef}
      onClick={({ nativeEvent }): void => {
        if (!createTextAnnotation) return;
        const { x, y } = canvasDraw.current?.getTransformedPoint(nativeEvent.offsetX, nativeEvent.offsetY);
        addAnnotation(x, y);
        setCreateTextAnnotation(false);
      }}
      style={{ cursor: createTextAnnotation ? 'crosshair' : 'auto', height: '100%', width: '100%' }}
    >
      {textAnnotations}
      <CanvasDraw
        ref={canvasDraw}
        imgSrc={version.imageUrl}
        brushRadius={pencilSize}
        brushColor={pencilColor}
        disabled={!reviewMode}
        hideGrid
        immediateLoading
        saveData={reviewMode ? '' : version.drawings}
        showDrawings={!reviewMode}
        updateAnnotationsPosition={updateAnnotationsPositions}
        hasDrawn={(): void => setDrawingNeedSaving(true)}
        setCanvasWidth={setCanvasWidth}
        setCanvasHeight={setCanvasHeight}
      />
    </div>
  );
};

export default Canvas;
