import { Status } from 'JSUtils/schema/generated/models';
import { useTranslation } from 'react-i18next';

// TODO: Remove once graphql ok
export enum EStatus {
  toSpecify = 1,
  todo,
  inProgress,
  waiting,
  inReview,
  done,
  canceled,
}

export function getStatusColorKey(status: Status): string {
  switch (status) {
    case Status.ToSpecify:
      return 'to-specify';
    case Status.Todo:
      return 'todo';
    case Status.InProgress:
      return 'in-progress';
    case Status.Waiting:
      return 'waiting';
    case Status.InReview:
      return 'in-review';
    case Status.Done:
      return 'done';
    case Status.Canceled:
      return 'canceled';
    default:
      return '';
  }
}

export function getStatusHumanReadable(status: Status): string {
  const { t } = useTranslation();
  switch (status) {
    case Status.ToSpecify:
      return t('project:common:status.to_specify');
    case Status.Todo:
      return t('project:common:status.to_do');
    case Status.InProgress:
      return t('project:common:status.doing');
    case Status.Waiting:
      return t('project:common:status.waiting');
    case Status.InReview:
      return t('project:common:status.reviewing');
    case Status.Done:
      return t('project:common:status.done');
    case Status.Canceled:
      return t('project:common:status.canceled');
    default:
      return '';
  }
}
