import { useProjectContext } from 'context/ProjectContext';
import { useUserContext } from 'context/UserContext';
import React, { ReactElement, useState } from 'react';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import { useTranslation } from 'react-i18next';
import { Document, Proposition } from 'schema/generated/models';
import CreateProposition from '../CreateProposition/CreateProposition';
import DeleteDocument from '../DeleteDocument/DeleteDocument';
import PropositionItem from '../PropositionItem/PropositionItem';
import './DocumentRow.scss';

type DocumentRowProps = {
  document: Document;
  activeProp: boolean;
};

export default function DocumentRow({ document, activeProp }: DocumentRowProps): ReactElement {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { project } = useProjectContext();
  const [active, setActive] = useState(activeProp);

  const onDocumentClick = (): void => {
    setActive(!active);
  };

  const getNoPropositionFoundSection = (): ReactElement => {
    if (project.owner.id === user.id) {
      return (
        <div className="proposition-not-found">
          <p className="desktop-message">{`${t('project:documents:document_row.no_proposition')} ${t('project:documents:document_row.add_one')}`}</p>
          <p className="mobile-message">{t('project:documents:document_row.limited_on_mobile')}</p>
        </div>
      );
    }
    return (
      <div className="proposition-not-found">
        <p className="desktop-message">{t('project:documents:document_row.no_proposition')}</p>
      </div>
    );
  };

  return (
    <div className={`document-row ${active && 'active'}`}>
      <div className="document-row-header">
        <div className="header-left-info" onClick={onDocumentClick}>
          {active ? <ChevronUp className="icon chevron-up" /> : <ChevronDown className="icon chevron-down" />}
          <span className="title">{document.title}</span>
          <span>
            {t('project:documents:document_row.proposition_count', { count: document.propositions.length })}
          </span>
        </div>
        <div className="header-right-buttons" {...(active ? {} : { onClick: onDocumentClick })}>
          {active && <CreateProposition documentId={document.id} />}
          {active && <DeleteDocument documentId={document.id} />}
        </div>
      </div>
      {active && (
        <div className="document-row-content">
          {document.propositions.map((p: Proposition) => {
            const proposition = { ...p, document };
            return <PropositionItem key={proposition.id} proposition={proposition} />;
          })}
          {document.propositions?.length === 0 && getNoPropositionFoundSection() }
        </div>
      )}
    </div>
  );
}
