import { UpdateProjectMutationVariables, UpdateVersionMutationVariables } from 'JSUtils/schema/generated/models';
import ReactGA from 'react-ga';

export const createProject = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Create project',
  });
};

export const updateProject = (v: UpdateProjectMutationVariables): void => {
  if (v.title !== undefined) {
    ReactGA.event({
      category: 'Manage',
      action: 'Update project title',
    });
  }
  if (v.description !== undefined) {
    ReactGA.event({
      category: 'Manage',
      action: 'Update project description',
    });
  }
};

export const deleteProject = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Delete project',
  });
};

export const addCollaborators = (nb: number): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Add collaborators to project',
    value: nb,
  });
};

export const removeCollaborators = (nb: number): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Remove collaborators from project',
    value: nb,
  });
};

export const createDocument = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Create document',
  });
};

export const updateDocument = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Update document',
  });
};

export const deleteDocument = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Delete document',
  });
};

export const createProposition = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Create proposition',
  });
};

export const updateProposition = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Update proposition',
  });
};

export const deleteProposition = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Delete proposition',
  });
};

export const createVersion = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Create version',
  });
};

export const updateVersion = (v: UpdateVersionMutationVariables): void => {
  if (v.title) {
    ReactGA.event({
      category: 'Exchange',
      action: 'Update version title',
    });
  }
  if (v.drawings) {
    ReactGA.event({
      category: 'Exchange',
      action: 'Add/update/delete drawn annotations',
    });
  }
};

export const deleteVersion = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Delete version',
  });
};

export const joinProjectUsingLink = (): void => {
  ReactGA.event({
    category: 'Manage',
    action: 'Join project using link',
  });
};
